<template>
  <div class="tw-w-full">
    <v-row class="tw-w-full px-12 py-4 mt-2 mb-0">
      <v-col cols="6">
        <v-row>
          <v-col cols="4">
            <label class="default-font filter-title tw-text-secondary mb-2 d-block">Amount:</label>
            <amount-filter></amount-filter>
          </v-col>
          <v-col cols="4">
            <label class="default-font filter-title tw-text-secondary mb-2 d-block">From:</label>
            <from-filter></from-filter>
          </v-col>
          <v-col cols="4">
            <label class="default-font filter-title tw-text-secondary mb-2 d-block">To recepient in:</label>
            <recepient-filter></recepient-filter>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="6" class="d-flex justify-end align-center">
        <v-btn
          text
          color="primary"
          class="text-capitalize default-font mr-4"
          @click="showFilters = !showFilters"
        >
          <v-icon>$vuetify.icons.filter-v2</v-icon>
          <span class="tw-text-black">{{ filterText }}</span>
        </v-btn>
        <label class="default-font filter-title tw-text-secondary mr-2 tw-flex-shrink-0">Sort by:</label>
        <v-menu transition="slide-y-transition" bottom offsetY content-class="mt-3 tw-rounded-lg">
          <template v-slot:activator="{ on }">
            <v-btn
              text
              class="text-capitalize default-font d-flex justify-space-between"
              v-on="on"
              min-width="100px"
            >
              {{ sortedItem }}
              <v-icon small class="ml-2">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item-group v-model="sorted" color="primary">
              <v-list-item v-for="(item, i) in sortedList" :key="i">
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <v-expand-transition>
      <div class="d-flex flex-wrap t-filter tw-w-full px-12 py-5 tw-mb-20" v-if="showFilters">
        <div class="tw-min-w-1/5 custom-border">
          <label class="default-font filter-title tw-text-secondary mb-3 d-block">Duration</label>
          <div>
            <v-checkbox
              multiple
              dense
              v-model="durations"
              label="Any duration"
              value="all"
              class="default-check"
            ></v-checkbox>
            <v-checkbox
              multiple
              dense
              v-model="durations"
              label="Within a day"
              value=0
              class="default-check"
            ></v-checkbox>
            <v-checkbox
              multiple
              dense
              v-model="durations"
              label="1 day"
              value=1
              class="default-check"
            ></v-checkbox>
            <v-checkbox
              multiple
              dense
              v-model="durations"
              label="2 days"
              value=2
              class="default-check"
            ></v-checkbox>
            <v-checkbox
              multiple
              dense
              v-model="durations"
              label="3 days"
              value=3
              class="default-check"
            ></v-checkbox>
            <v-checkbox
              multiple
              dense
              v-model="durations"
              label="4 days"
              value=4
              class="default-check"
            ></v-checkbox>
          </div>
        </div>
        <div class="tw-min-w-1/5 pl-8 custom-border">
          <label class="default-font filter-title tw-text-secondary mb-3 d-block">Payment method</label>
          <div>
            <v-checkbox
              multiple
              dense
              v-model="payments"
              label="Any method"
              value="all"
              class="default-check"
            ></v-checkbox>
            <v-checkbox
              multiple
              dense
              v-model="payments"
              label="Within a day"
              value=0
              class="default-check"
            ></v-checkbox>
            <v-checkbox
              multiple
              dense
              v-model="payments"
              label="P2P Bank Transfer"
              value="P2P"
              class="default-check"
            ></v-checkbox>
            <v-checkbox
              multiple
              dense
              v-model="payments"
              label="ACH"
              value="ACH"
              class="default-check"
            ></v-checkbox>
            <v-checkbox
              multiple
              dense
              v-model="payments"
              label="Debit Card"
              value="Debit Card"
              class="default-check"
            ></v-checkbox>
            <v-checkbox
              multiple
              dense
              v-model="payments"
              label="Credit Card"
              value="Credit Card"
              class="default-check"
            ></v-checkbox>
            <v-checkbox
              multiple
              dense
              v-model="payments"
              label="Wire Transfer"
              value="Wire Transfer"
              class="default-check"
            ></v-checkbox>
            <v-checkbox
              multiple
              dense
              v-model="payments"
              label="Cash drop off"
              value="Cash drop off"
              class="default-check"
            ></v-checkbox>
          </div>
        </div>
        <div class="tw-min-w-1/5 pl-8 custom-border">
          <label class="default-font filter-title tw-text-secondary mb-3 d-block">Delivery method</label>
          <div>
            <v-checkbox
              multiple
              dense
              v-model="deliveryMethods"
              label="Any method"
              value="all"
              class="default-check"
            ></v-checkbox>
            <v-checkbox
              multiple
              dense
              v-model="deliveryMethods"
              label="Bank Deposit"
              value="Bank Deposit"
              class="default-check"
            ></v-checkbox>
            <v-checkbox
              multiple
              dense
              v-model="deliveryMethods"
              label="Mobile Money"
              value="Mobile Money"
              class="default-check"
            ></v-checkbox>
            <v-checkbox
              multiple
              dense
              v-model="deliveryMethods"
              label="Cash Pick up"
              value="Cash Pick up"
              class="default-check"
            ></v-checkbox>
            <v-checkbox
              multiple
              dense
              v-model="deliveryMethods"
              label="Cash Delivery"
              value="Cash Delivery"
              class="default-check"
            ></v-checkbox>
          </div>
        </div>
        <div class="tw-min-w-1/5 pl-8">
          <label class="default-font filter-title tw-text-secondary mb-3 d-block">Ratings</label>
          <div>
            <v-checkbox
              multiple
              dense
              v-model="ratings"
              label="Any rating"
              value="all"
              class="default-check"
            ></v-checkbox>
            <v-checkbox multiple dense v-model="ratings" value=1 class="default-check">
              <template slot="label">
                <v-rating small color="#F07877" background-color="#BED1E1" readonly :value="1"></v-rating>
              </template>
            </v-checkbox>
            <v-checkbox multiple dense v-model="ratings" value=2 class="default-check">
              <template slot="label">
                <v-rating small color="#F07877" background-color="#BED1E1" readonly :value="2"></v-rating>
              </template>
            </v-checkbox>
            <v-checkbox multiple dense v-model="ratings" value=3 class="default-check">
              <template slot="label">
                <v-rating small color="#F07877" background-color="#BED1E1" readonly :value="3"></v-rating>
              </template>
            </v-checkbox>
            <v-checkbox multiple dense v-model="ratings" value=4 class="default-check">
              <template slot="label">
                <v-rating small color="#F07877" background-color="#BED1E1" readonly :value="4"></v-rating>
              </template>
            </v-checkbox>
            <v-checkbox multiple dense v-model="ratings" value=5 class="default-check">
              <template slot="label">
                <v-rating small color="#F07877" readonly :value="5"></v-rating>
              </template>
            </v-checkbox>
          </div>
        </div>
        <div class="tw-min-w-1/2 mt-4">
          <v-btn color="primary" class="default-font text-capitalize" @click="applyFilters">Apply filters</v-btn>
          <v-btn
            text
            class="default-font text-capitalize font-12 ml-2 tw-text-black-400 tw-opacity-50"
            @click="cancelFilters"
          >Cancel</v-btn>
        </div>
      </div>
    </v-expand-transition>
  </div>
</template>
<script>
import AmountFilter from './filters/Amount.vue';
import FromFilter from './filters/From.vue';
import RecepientFilter from './filters/Recepient.vue';

export default {
    components: {
        AmountFilter,
        FromFilter,
        RecepientFilter,
    },
    data() {
        return {
            showFilters: false,
            sorted: 4,
            sortedList: [
                'Recipient gets: high to low',
                'Recipient gets: low to high',
                'Duration: fast to long',
                'Duration: long to fast',
                'Transaction fee: high to low',
                'Transaction fee: low to high',
            ],
            durations: ['all'],
            payments: ['all'],
            deliveryMethods: ['all'],
            ratings: ['all'],
        };
    },
    computed: {
        filterText() {
            return this.showFilters ? 'Hide filters' : 'Show filters';
        },
        sortedItem() {
            return this.sortedList[this.sorted];
        },
    },
    methods: {
        cancelFilters() {
            this.durations = [];
            this.payments = [];
            this.deliveryMethods = [];
            this.ratings = [];
            this.$emit('cancel-filters');
            this.showFilters = false;
        },
        applyFilters() {
            const {
                durations, payments, deliveryMethods, ratings,
            } = this;
            this.$emit('apply-filter', {
                durations, payments, deliveryMethods, ratings,
            });
            this.showFilters = false;
        },
    },
};
</script>
<style>
.default-check {
margin-top: 0 !important;
font-family: Muli;
font-style: normal;

/* or 22px */

/* TEXT */
color: #1A244E;
}
.default-check .v-messages {
    display: none !important;
}

.default-check.v-input--is-label-active label {
    font-weight: bold !important;
}
.default-check .v-label {
    font-weight: normal !important;
font-size: 14px !important;
line-height: 160% !important;
}

.custom-border {
    border-right: 1px solid #EAF0F5;
    box-sizing: border-box;
    border-radius: 3px;
}
</style>
