export default {
    state: {
        amountFilter: 0,
        fromFilter: 'All',
        currencyFilter: null,
        recepientFilter: 'All',
    },

    actions: {
        updateAmountFilter({ commit }, payload) {
            commit('SET_AMOUNTFILTER', payload);
        },
        updateFromFilter({ commit }, payload) {
            commit('SET_FROMFILTER', payload);
        },
        updateRecepientFilter({ commit }, payload) {
            commit('SET_RECEPIENTYFILTER', payload);
        },
        updateStatusFilter({ commit }, payload) {
            commit('SET_STATUSFILTER', payload);
        },
        clearAllFilters({ commit }) {
            commit('CLEAR_FILTERS');
        },
    },

    mutations: {
        SET_AMOUNTFILTER(state, payload) {
            state.amountFilter = payload;
        },
        SET_FROMFILTER(state, payload) {
            state.fromFilter = payload;
        },
        SET_RECEPIENTYFILTER(state, payload) {
            state.recepientFilter = payload;
        },
    },

    getters: {
        amountFilter(state) {
            return state.amountFilter;
        },
        fromFilter(state) {
            return state.fromFilter;
        },
        recepientFilter(state) {
            return state.recepientFilter;
        },
    },
};
