<template>
    <v-chip
        v-bind="$attrs"
        :color="color"
        class="text-capitalize">
        {{ value }}
    </v-chip>
</template>

<script>
/* eslint-disable quote-props */

const colorMap = {
    'invited': 'grey',
    'registered': 'yellow',
    'transacted-matched': 'orange',
    'transacted-posted': 'orange',
    'transacted-completed': 'success',
    'banned': 'error',
};

export default {
    props: {
        value: {
            type: String,
            default: 'invited',
        },
    },
    computed: {
        color() {
            return colorMap[this.value];
        },
    },
};
</script>
