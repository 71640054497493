import axios from 'axios';
import Qs from 'qs';
import { addAuthorizationHeader, handleResponseError } from './ApiInterceptors';

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_KAOSHI_API_URL,
    withCredentials: true,
    paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: 'brackets' }),
});

axiosInstance.interceptors.request.use(
    addAuthorizationHeader,
    (error) => error,
);

axiosInstance.interceptors.response.use(
    (response) => response,
    handleResponseError,
);

export default axiosInstance;
