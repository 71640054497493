/* eslint-disable no-restricted-properties */
/* eslint-disable no-restricted-globals */
export const apiKeys = {
    user: {
        user_id: '4fdsdf246obm4fmk573mnxkw',
    },
    sandbox: {
        secret: '4fdsdf246obm4fmk573mnxkw',
    },
    development: {
        secret: '4fdsdf246obm4fmk573mnxkw',
    },
};

export const headers = [{
    text: 'User',
    align: 'start',
    class: 'tw-text-black default-font py-2 text-start tw-rounded-lg tw-rounded-r-none first_cell',
    sortable: false,
    width: '30%',
    value: 'ipAddress',
},
{
    text: 'Order',
    align: 'center',
    value: 'events',
    width: '50%',
    class: 'tw-text-secondary default-font py-2',
},
{
    text: 'Webhooks',
    align: 'end',
    value: 'action',
    sortable: false,
    width: '20%',
    class: 'tw-text-secondary default-font py-2 text-right last_cell tw-rounded-lg tw-rounded-l-none',
},
];

export const events = [
    'Institution status',
    'Republique status',
    'Organisation status',
];

function toFixedFix(n, prec) {
    const k = Math.pow(10, prec);
    return `${Math.round(n * k) / k}`;
}

export function numberFormat(number, decimals, decPoint, thousandsSep) {
    // Strip all characters but numerical ones.
    const fNumber = (`${number}`).replace(/[^0-9+\-Ee.]/g, '');
    const n = !isFinite(+fNumber) ? 0 : +fNumber;
    const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
    const sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep;
    const dec = (typeof decPoint === 'undefined') ? '.' : decPoint;
    let s = '';
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : `${Math.round(n)}`).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}
