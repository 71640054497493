<template>
  <div class="tw-w-full mb-5">
    <app-bar>
      <template slot="title">
        <router-link to="/developer#api-keys">
        <v-icon x-large class="back-style mr-6">$back</v-icon>
      </router-link>
      <v-toolbar-title class="default-font font-21 mr-10">POST  v1 / reporting / report_runs</v-toolbar-title>
      </template>
      <div class="d-flex justify-end">
        <div>
          <v-btn
            text
            color="primary"
            href="https://kaoshi-staging.shanenelson.ca/docs/bank-api/"
            target="_blank"
            class="text-capitalize default-font tw-text-sm"
          >
            <v-icon small>$vuetify.icons.launch</v-icon>API documentation
          </v-btn>
        </div>
      </div>
    </app-bar>
    <v-container d-flex flex-column class="mx-10">
      <v-card class="mt-6 card-style">
        <v-card-title>
          <span class="font-style font-dark-bold-15">Summary</span>
        </v-card-title>
        <v-card-text class="card-content-style">
          <v-container>
            <v-row>
              <v-col cols="8 default-font">
                <v-row>
                  <v-col cols="2" class="d-flex flex-column font-style font-light-13 tw-text-secondary">
                    <span class="my-1">ID</span>
                    <v-spacer></v-spacer>
                    <span class="my-1">Time</span>
                    <v-spacer></v-spacer>
                    <span class="my-1">Method</span>
                    <v-spacer></v-spacer>
                    <span class="my-1">URL</span>
                    <v-spacer></v-spacer>
                    <span class="my-1">Status</span>
                    <v-spacer></v-spacer>
                    <span class="my-1">IP address</span>
                    <v-spacer></v-spacer>
                    <span class="my-1">Version</span>
                    <v-spacer></v-spacer>
                    <span class="my-1">Source</span>
                    <v-spacer></v-spacer>
                    <span class="my-1">Related</span>
                    <v-spacer></v-spacer>
                    <span class="my-1">Origin</span>
                    <v-spacer></v-spacer>
                  </v-col>
                  <v-col cols="10" class="d-flex flex-column font-style font-dark-bold-14">
                    <span class="my-1">{{currentItem.id}}</span>
                    <span class="my-1">{{currentItem.date | date}} at {{currentItem.date | time}}</span>
                    <span class="my-1">{{currentItem.method}}</span>
                    <span class="my-1">{{currentItem.url}}</span>
                    <span class="my-1">{{currentItem.status}}</span>
                    <span class="my-1">{{currentItem.ip_address}}</span>
                    <span class="my-1">{{currentItem.version}}</span>
                    <span class="my-1">{{currentItem.source}}</span>
                    <span class="my-1">{{currentItem.related}}</span>
                    <span class="my-1">{{currentItem.origin}}</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <v-card class="mt-6 card-style">
        <v-card-title>
          <span class="font-style font-dark-bold-15">Request query parameters</span>
        </v-card-title>
        <v-card-text class="card-content-style">
          <v-container>
            <v-row>
              <v-col cols="12" class="font-style font-light-15">
                <v-row class="justify-center">
                  No query parameters
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <v-card class="mt-6 card-style">
        <v-card-title>
          <span class="font-style font-dark-bold-15">Request POST body</span>
        </v-card-title>
        <v-card-text class="card-content-style">
          <v-container>
            <v-row>
              <v-col cols="8 font-style font-dark-bold-14">
                <vue-json-pretty
                  :path="'res'"
                  :data="currentItem.request_body"
                  highlight-mouseover-node
                  >
                </vue-json-pretty>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex';
import AppBar from '@/components/globals/AppBar.vue';
import VueJsonPretty from 'vue-json-pretty';

export default {
    name: 'Developer',
    components: {
        AppBar,
        VueJsonPretty,
    },
    props: {},
    data() {
        return {
            tab: null,
            items: [
                { title: 'API Logs', content: 'Content 1' },
                { title: 'API Status', content: 'Content 2' },
                { title: 'Webhooks', content: 'Content 3' },
                { title: 'Whitelist IP', content: 'Content 4' },
                { title: 'API Keys', content: 'Content 5' },

            ],
        };
    },
    filters: {
        pretty(value) {
            return JSON.stringify(JSON.parse(value), null, 2);
        },
    },
    computed: {
        ...mapGetters(['getLogById']),
        currentItem() {
            return this.getLogById(this.$route.params.id);
        },
    },
};
</script>
<style>
.request-input .v-text-field__details{
  display: none;
}
.back-style{
  height: 45px;
  width: 45px;
}
.card-style{
  box-shadow: none;
}
.card-content-style{
  background-color: #F7FBFC;
  box-shadow: 0px 8px 33px rgba(224, 224, 224, 0.1);
  border-radius: 6px;
}
.font-style{
  font-family: Muli !important;
  font-style: normal !important;
}
.font-dark-bold-14{
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 29px !important;
  color: #2E5677 !important;
}
.font-dark-bold-15{
  font-weight: bold;
  font-size: 15px;
  line-height: 136.5%;
  color: #2E5677;
}
.font-dark-bold-head-15{
  font-weight: bold;
  font-size: 15px;
  line-height: 136.5%;
  color: #404159;
}
.font-dark-14{
  font-weight: 600;
  font-size: 14px;
  line-height: 136.5%;
  color: #2E5677;
}
.font-dark-15{
  font-weight: 600;
  font-size: 15px;
  line-height: 136.5%;
  color: #2E5677;
}
.font-light-13{
font-weight: 600 !important;
font-size: 13px !important;
line-height: 220% !important;
color: #7598B5 !important;
}
.font-light-bold-14{
font-weight: bold !important;
font-size: 14px !important;
line-height: 220% !important;
color: #7598B5 !important;
}
.font-light-15{
font-weight: normal !important;
font-size: 15px !important;
line-height: 136.5% !important;
color: #7598B5 !important;
}
.vjs-key {
  color: #7986CB;
}
</style>
