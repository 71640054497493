/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable quotes */
/* eslint-disable quote-props */
import { range } from 'lodash';

const commonPart = {
    "payment_types": [],
    "can_trade_with": [
        'NGN', 'GHS', 'KES', 'ZAR',
    ],
    "is_poster_from_currency": false,
    "is_matcher_from_currency": true,
    "object": {
        "paypal_id": "email_address",
    },
};

export const currencies = [
    {
        "currency_name": "euro",
        "currency_code": "EUR",
        "symbol": '€',
        "flag": "eur.svg",
        "max_amount": 1000,
        "min_amount": 50,
        "steps": 50,
        "payment_types": [
            {
                code: "paypal_payment",
                name: "Paypal",
            },
            {
                code: "sepa_payment",
                name: "Sepa",
            },
        ],
        "can_trade_with": [
            'NGN', 'GHS', 'KES', 'ZAR',
        ],
        "is_poster_from_currency": false,
        "is_matcher_from_currency": true,
        "object": {
            "paypal_id": "email_address",
        },
    },
    {
        "currency_name": "canadian dollar",
        "currency_code": "CAD",
        "symbol": '$',
        "flag": "cad.svg",
        "max_amount": 1000,
        "min_amount": 50,
        "steps": 50,
        "payment_types": [
            {
                code: "paypal_payment",
                name: "Paypal",
                website: "https://www.paypal.com",
            },
            {
                code: "interac_payment",
                name: "Interac",
                website: "https://www.interac.ca/",
            },
            {
                "code": "st_choice_savings_and_credit_unionpersonal_online_banking",
                "name": "st Choice Savings and Credit Union - Personal Online Banking",
                "website": "https://www.1stchoicesavings.ca/",
            },
            {
                "code": "atb_financial",
                "name": " ATB Financial",
                "website": "https://atb.com/Pages/default.aspx",
            },
            {
                "code": "aldergrove_credit_union",
                "name": "Aldergrove Credit Union",
                "website": "https://www.aldergrovecu.ca",
            },
            {
                "code": "assiniboine_credit_uniononline_banking",
                "name": "Assiniboine Credit Union - Online Banking",
                "website": "https://www.assiniboine.mb.ca/",
            },
            {
                "code": "bmo_bank_of_montreal",
                "name": "BMO Bank of Montreal",
                "website": "https://www.bmo.com",
            },
            {
                "code": "bayview_credit_union",
                "name": "Bayview Credit Union",
                "website": "https://www.bayviewnb.com/Home/",
            },
            {
                "code": "bulkley_valley_credit_union",
                "name": "Bulkley Valley Credit Union",
                "website": "https://www.bvcu.com",
            },
            {
                "code": "cibc",
                "name": " CIBC",
                "website": "https://www.cibc.com",
            },
            {
                "code": "cuapersonal_bankingcanada_",
                "name": " CUA - Personal Banking (Canada)",
                "website": "https://www.cua.com/Home/",
            },
            {
                "code": "cambrian_credit_unioncambrian_online",
                "name": " Cambrian Credit Union - Cambrian Online",
                "website": "https://www.cambrian.mb.ca/",
            },
            {
                "code": "canadian_western_bankonline_banking",
                "name": " Canadian Western Bank - Online Banking",
                "website": "https://www.cwbank.com/",
            },
            {
                "code": "chinook_financialonline_banking",
                "name": "Chinook Financial - Online Banking",
                "website": "https://www.chinookfinancial.com/",
            },
            {
                "code": "coast_capital_savings",
                "name": "Coast Capital Savings",
                "website": "https://www.coastcapitalsavings.com/",
            },
            {
                "code": "columbia_valley_credit_union_",
                "name": "Columbia Valley Credit Union ",
                "website": "https://www.cvcu.bc.ca",
            },
            {
                "code": "community_firsta_division_of_yncuonline_banking_",
                "name": "Community First, a Division of YNCU - Online Banking ",
                "website": "https://www.communityfirst-yncu.com/",
            },
            {
                "code": "community_savings",
                "name": "Community Savings",
                "website": "https://www.comsavings.com/",
            },
            {
                "code": "conexus_credit_uniononline_banking",
                "name": "Conexus Credit Union - Online Banking",
                "website": "https://www.conexus.ca/",
            },
            {
                "code": "copperfin_credit_unionpersonal_banking",
                "name": "Copperfin Credit Union - Personal Banking",
                "website": "https://copperfin.ca/",
            },
            {
                "code": "cornerstone_credit_unionpersonalcanada_",
                "name": " Cornerstone Credit Union - Personal (Canada)",
                "website": "https://www.cornerstonecu.com/",
            },
            {
                "code": "crestondistrict_credit_union",
                "name": "Creston & District Credit Union",
                "website": "https://www.cdcu.com/",
            },
            {
                "code": "diamond_north_credit_unionpersonal_online_banking_",
                "name": " Diamond North Credit Union - Personal Online Banking ",
                "website": "https://www.diamondnorthcu.com/Personal/",
            },
            {
                "code": "east_kootenay_community_credit_union",
                "name": "East Kootenay Community Credit Union",
                "website": "https://www.ekccu.com/",
            },
            {
                "code": "education_credit_union",
                "name": " Education Credit Union",
                "website": "https://www.ecusolutions.com/Personal/",
            },
            {
                "code": "encompass_credit_unioncan_",
                "name": " Encompass Credit Union (CAN)",
                "website": "https://www.encompasscu.ca/Personal/",
            },
            {
                "code": "envision_financial_a_division_of_first_west_credit_uniononline_banking_",
                "name": "Envision Financial a division of First West Credit Union - Online Banking ",
                "website": "https://envisionfinancial.ca/",
            },
            {
                "code": "first_calgary_financialpersonal_banking_",
                "name": "First Calgary Financial - Personal Banking ",
                "website": "https://www.firstcalgary.com/",
            },
            {
                "code": "first_credit_uniononline_banking",
                "name": " First Credit Union - Online Banking",
                "website": "https://www.firstcu.ca/",
            },
            {
                "code": "frontline_credit_unionpersonal_banking",
                "name": "Frontline Credit Union - Personal Banking",
                "website": "https://www.frontlinecu.com/",
            },
            {
                "code": "fusion_credit_uniononline_banking",
                "name": "Fusion Credit Union - Online Banking",
                "website": "https://fusioncu.com/",
            },
            {
                "code": "grand_forks_credit_union",
                "name": "Grand Forks Credit Union",
                "website": "https://www.gfcu.com",
            },
            {
                "code": "gulf_and_fraser_financial_group_",
                "name": " Gulf And Fraser Financial Group ",
                "website": "https://www.gffg.com",
            },
            {
                "code": "healthcare_and_municipal_employeescredit_uniononline_banking",
                "name": " Healthcare and Municipal Employees' Credit Union - Online Banking",
                "website": "https://www.hmecu.com/",
            },
            {
                "code": "hsbc_online_bankingca_",
                "name": " HSBC Online Banking (CA)",
                "website": "https://www.hsbc.ca/",
            },
            {
                "code": "ic_savings",
                "name": "IC Savings",
                "website": "https://www.icsavings.ca/",
            },
            {
                "code": "icici_bank",
                "name": " ICICI Bank",
                "website": "https://infinity.icicibank.com/corp/AuthenticationController?FORMSGROUP_ID__=AuthenticationFG&__START_TRAN_FLAG__=Y&FG_BUTTONS__=LOAD&ACTION.LOAD=Y&AuthenticationFG.LOGIN_FLAG=1&BANK_ID=ICI",
            },
            {
                "code": "interior_savings_credit_union",
                "name": " Interior Savings Credit Union",
                "website": "https://www.interiorsavings.com/",
            },
            {
                "code": "island_savings_credit_unionpersonal_banking",
                "name": "Island Savings Credit Union - Personal Banking",
                "website": "https://www.islandsavings.ca/",
            },
            {
                "code": "kawartha_credit_uniononline_banking",
                "name": "Kawartha Credit Union - Online Banking",
                "website": "https://www.kawarthacu.com/",
            },
            {
                "code": "khalsa_credit_union_",
                "name": "Khalsa Credit Union ",
                "website": "https://khalsacreditunion.ca/",
            },
            {
                "code": "kindred_credit_union",
                "name": " Kindred Credit Union",
                "website": "https://www.kindredcu.com/",
            },
            {
                "code": "kingston_community_credit_uniononline_banking",
                "name": " Kingston Community Credit Union - Online Banking",
                "website": "https://www.kccu.ca/",
            },
            {
                "code": "kootenay_savings_credit_uniononline_banking",
                "name": " Kootenay Savings Credit Union - Online Banking",
                "website": "https://www.kscu.com/",
            },
            {
                "code": "lake_view_credit_union",
                "name": "Lake View Credit Union",
                "website": "https://lakeviewcreditunion.com/",
            },
            {
                "code": "lakeland_credit_unionpersonal",
                "name": "Lakeland Credit Union - Personal",
                "website": "https://www.lakelandcreditunion.com/Personal/",
            },
            {
                "code": "leading_edge_credit_union",
                "name": " Leading Edge Credit Union",
                "website": "https://www.lecu.ca/",
            },
            {
                "code": "libro_credit_uniononline_banking",
                "name": " Libro Credit Union - Online Banking",
                "website": "https://www.libro.ca/",
            },
            {
                "code": "luminus_financialpersonal_banking",
                "name": "Luminus Financial - Personal Banking",
                "website": "https://www.luminusfinancial.com/",
            },
            {
                "code": "mainstreet_credit_uniononline_banking",
                "name": " Mainstreet Credit Union - Online Banking",
                "website": "https://www.mainstreetcu.ca",
            },
            {
                "code": "meridian_credit_union",
                "name": " Meridian Credit Union",
                "website": "https://www.meridiancu.ca/",
            },
            {
                "code": "momentum_credit_uniononline_banking",
                "name": "Momentum Credit Union - Online Banking",
                "website": "https://momentumcu.ca/",
            },
            {
                "code": "members_choice_credit_union_",
                "name": " Members Choice Credit Union ",
                "website": "https://www.mccu.com/",
            },
            {
                "code": "motusbank",
                "name": " motusbank",
                "website": "https://www.motusbank.ca/",
            },
            {
                "code": "mount_lehman_credit_union",
                "name": " Mount Lehman Credit Union",
                "website": "https://www.mtlehman.com/",
            },
            {
                "code": "mountain_view_credit_unionpersonal",
                "name": "Mountain View Credit Union - Personal",
                "website": "https://www.mountainviewfinancial.com/",
            },
            {
                "code": "moya_financial_credit_uniononline_banking",
                "name": " Moya Financial Credit Union - Online Banking",
                "website": "https://www.moyafinancial.ca/",
            },
            {
                "code": "nelsondistrict_credit_union",
                "name": " Nelson & District Credit Union",
                "website": "https://www.nelsoncu.com/Personal/",
            },
            {
                "code": "newfoundlandlabrador_credit_union",
                "name": "Newfoundland & Labrador Credit Union",
                "website": "https://www.nlcu.com/Home/",
            },
            {
                "code": "north_peace_savings_and_credit_union",
                "name": "North Peace Savings and Credit Union",
                "website": "https://www.npscu.ca/",
            },
            {
                "code": "northern_credit_unionpersonal",
                "name": "Northern Credit Union - Personal",
                "website": "https://www.northerncu.com/",
            },
            {
                "code": "northern_savings_credit_uniononline_banking",
                "name": "Northern Savings Credit Union - Online Banking",
                "website": "https://www.northsave.com/",
            },
            {
                "code": "noventis_credit_union",
                "name": "Noventis Credit Union",
                "website": "https://www.noventis.ca/",
            },
            {
                "code": "osoyoos_credit_union",
                "name": "Osoyoos Credit Union",
                "website": "https://ocubc.com/",
            },
            {
                "code": "pace_credit_unionpersonal_banking",
                "name": " PACE Credit Union - Personal Banking",
                "website": "https://www.pacecu.ca/",
            },
            {
                "code": "penfinancial_credit_unionpersonal_banking",
                "name": "PenFinancial Credit Union - Personal Banking",
                "website": "https://www.penfinancial.com/",
            },
            {
                "code": "prospera_credit_union",
                "name": "Prospera Credit Union",
                "website": "https://www.prospera.ca/",
            },
            {
                "code": "public_service_credit_unioncanada_",
                "name": "Public Service Credit Union (Canada)",
                "website": "https://www.pscu.ca/Home/",
            },
            {
                "code": "quinte_first_credit_uniononline_banking",
                "name": "Quinte First Credit Union - Online Banking",
                "website": "https://www.quintefirst.ca/",
            },
            {
                "code": "rbc_royal_bank",
                "name": "RBC Royal Bank",
                "website": "https://www1.royalbank.com/cgi-bin/rbaccess/rbunxcgi?F6=1&F7=IB&F21=IB&F22=IB&REQUEST=ClientSignin&LANGUAGE=ENGLISH",
            },
            {
                "code": "resurrection_credit_uniononline_banking",
                "name": "Resurrection Credit Union - Online Banking",
                "website": "https://www.rpcul.com/",
            },
            {
                "code": "revelstoke_credit_union_",
                "name": " Revelstoke Credit Union ",
                "website": "https://www.revcu.com/Personal/",
            },
            {
                "code": "scotiabank",
                "name": " Scotiabank",
                "website": "https://www.scotiaonline.scotiabank.com",
            },
            {
                "code": "servus_credit_unionpersonal_online_banking",
                "name": " Servus Credit Union - Personal Online Banking",
                "website": "https://www.servus.ca/Pages/default.aspx",
            },
            {
                "code": "sharons_credit_union",
                "name": "Sharons Credit Union",
                "website": "http://sharons.ca",
            },
            {
                "code": "simplii_financial",
                "name": "Simplii Financial",
                "website": "https://www.simplii.com/en/home.html",
            },
            {
                "code": "southwest_regional_credit_unionpersonal_banking",
                "name": " Southwest Regional Credit Union - Personal Banking",
                "website": "https://www.southwestcu.com/",
            },
            {
                "code": "spruce_credit_union",
                "name": " Spruce Credit Union",
                "website": "https://www.sprucecu.bc.ca",
            },
            {
                "code": "ststanislausstcasimir_s_polish_parishes_credit_unionpersonal_banking",
                "name": "St. Stanislaus - St. Casimir's Polish Parishes Credit Union - Personal Banking",
                "website": "https://www.polcu.com/",
            },
            {
                "code": "steinbach_credit_unionpersonal_banking",
                "name": "Steinbach Credit Union - Personal Banking",
                "website": "https://www.scu.mb.ca/",
            },
            {
                "code": "stride_credit_union",
                "name": "Stride Credit Union",
                "website": "https://www.stridecu.ca/",
            },
            {
                "code": "sunova_credit_unionpersonal",
                "name": "Sunova Credit Union - Personal",
                "website": "https://www.sunovacu.ca/",
            },
            {
                "code": "sunrise_credit_unionpersonal_bankingcanada_",
                "name": "Sunrise Credit Union - Personal Banking (Canada)",
                "website": "https://sunrisecu.mb.ca/",
            },
            {
                "code": "sunshine_coast_credit_union",
                "name": "Sunshine Coast Credit Union",
                "website": "https://www.sunshineccu.com/",
            },
            {
                "code": "sydney_credit_union",
                "name": "Sydney Credit Union",
                "website": "https://www.sydneycreditunion.com/Home/",
            },
            {
                "code": "synergy_credit_unioncanadapersonal",
                "name": " Synergy Credit Union (Canada) - Personal",
                "website": "https://www.synergycu.ca",
            },
            {
                "code": "td_canada_trust",
                "name": "TD Canada Trust",
                "website": "https://www.td.com/",
            },
            {
                "code": "tandia_financial_credit_unionpersonal_banking_",
                "name": "Tandia Financial Credit Union - Personal Banking ",
                "website": "https://www.tandia.com/",
            },
            {
                "code": "tangerinebusiness_banking_login",
                "name": " Tangerine - Business Banking Login",
                "website": "https://www.tangerine.ca/en",
            },
            {
                "code": "union_bay_credit_unionpersonal_banking",
                "name": " Union Bay Credit Union - Personal Banking",
                "website": "https://www.ubcu.ca/",
            },
            {
                "code": "union_bay_view_credit_union",
                "name": "Union Bay view Credit Union",
                "website": "https://www.bayviewnb.com/Home/",
            },
            {
                "code": "valley_first_credit_union_",
                "name": "Valley First Credit Union ",
                "website": "http://www.valleyfirstcu.org/",
            },
            {
                "code": "vancitypersonal_banking",
                "name": "Vancity - Personal Banking",
                "website": "https://www.vancity.com",
            },
            {
                "code": "vantageone_credit_uniononline_banking",
                "name": "VantageOne Credit Union - Online Banking",
                "website": "https://onlinebanking.vantageone.net/",
            },
            {
                "code": "venture_credit_unioncanada_",
                "name": "Venture Credit Union (Canada)",
                "website": "https://www.venturecu.ca/Home/",
            },
            {
                "code": "westoba_credit_uniononline_banking_",
                "name": " Westoba Credit Union - Online Banking ",
                "website": "https://westobacu.com/",
            },
            {
                "code": "williams_lakedistrict_credit_union",
                "name": "Williams Lake & District Credit Union",
                "website": "https://www.wldcu.com/",
            },
            {
                "code": "windsor_family_credit_unionpersonal_online_banking",
                "name": "Windsor Family Credit Union - Personal Online Banking",
                "website": "https://www.wfcu.ca/",
            },
            {
                "code": "your_credit_unionpersonal_banking",
                "name": " Your Credit Union - Personal Banking",
                "website": "https://www.yourcu.com/",
            },
            {
                "code": "your_neighbourhood_credit_union_limitedpersonal",
                "name": "Your Neighbourhood Credit Union Limited - Personal",
                "website": "https://www.yncu.com/",
            },
        ],
        "can_trade_with": [
            'NGN', 'GHS', 'KES', 'ZAR',
        ],
        "is_poster_from_currency": false,
        "is_matcher_from_currency": true,
        "object": {
            "paypal_id": "email_address",
        },
    },
    {
        "currency_name": "united states dollar",
        "currency_code": "USD",
        "symbol": '$',
        "flag": "usd.svg",
        "max_amount": 1000,
        "min_amount": 50,
        "steps": 50,
        "payment_types": [
            // {
            //     code: "paypal_payment",
            //     name: "Paypal",
            //     website: "https://www.paypal.com"
            // },
            // {
            //     code: "zelle_payment",
            //     name: "Zelle",
            //     website: "https://www.zellepay.com"
            // },

            {
                code: "chase_payment",
                name: "Chase Bank",
                website: "https://secure07b.chase.com/web/auth/dashboard",
            },
            {
                code: "citi_payment",
                name: "Citibank",
                website: "https://online.citi.com/US/login.do",
            },
            {
                code: "wells_payment",
                name: "Wells Fargo",
                website: "https://connect.secure.wellsfargo.com/auth/login/present",
            },
            {
                code: "usbank_payment",
                name: "US Bank",
                website: "https://onlinebanking.usbank.com/Auth/Login",
            },
            {
                code: "pnc_payment",
                name: "PNC Bank",
                website: "https://www.pnc.com/en/personal-banking.html",
            },
            {
                code: "boa_payment",
                name: "Bank of America",
                website: "https://www.bankofamerica.com/online-banking/sign-in/",
            },
            {
                code: "53rd_payment",
                name: "Fifth Third Bank",
                website: "https://www.53.com/content/fifth-third/en/login.html",
            },
            {
                code: "capitalone_payment",
                name: "Capital One",
                website: "https://verified.capitalone.com/auth/signin",
            },
            {
                code: "td_payment",
                name: "TD Bank",
                website: "https://onlinebanking.tdbank.com/#/authentication/login",
            },
            {
                "code": "alden_state_bank",
                "name": "Alden State Bank",
                "website": "http://www.aldenstate.com/",
            },
            {
                "code": "alliance_bankmnindividual_banking_",
                "name": "Alliance Bank (MN) - Individual Banking ",
                "website": "https://www.alliancebanks.com",
            },
            {
                "code": "allsouth_federal_credit_union",
                "name": "AllSouth Federal Credit Union",
                "website": "https://www.allsouth.org",
            },
            {
                "code": "ally_bank",
                "name": "Ally Bank",
                "website": "https://www.ally.com",
            },
            {
                "code": "ambler_savings_bank",
                "name": "Ambler Savings Bank",
                "website": "https://www.amblersavingsbank.com/",
            },
            {
                "code": "amegy_bank_of_texas",
                "name": "Amegy Bank of Texas",
                "website": "https://www.amegybank.com/",
            },
            {
                "code": "america_first_credit_union",
                "name": "America First Credit Union",
                "website": "https://www.americafirst.com",
            },
            {
                "code": "american_investors_bank_and_mortgagepersonal",
                "name": "American Investors Bank and Mortgage - Personal",
                "website": "https://www.aibandm.com/",
            },
            {
                "code": "ameris_bankpersonal_online_banking",
                "name": "Ameris Bank - Personal Online Banking",
                "website": "https://www.amerisbank.com",
            },
            {
                "code": "andover_bank",
                "name": "Andover Bank",
                "website": "http://www.andoverbankohio.com/",
            },
            {
                "code": "apl_federal_credit_union",
                "name": "APL Federal Credit Union",
                "website": "https://www.aplfcu.org/",
            },
            {
                "code": "arvest_bank",
                "name": "Arvest Bank",
                "website": "http://www.arvest.com/",
            },
            {
                "code": "associated_bank",
                "name": "Associated Bank",
                "website": "http://www.associatedbank.com",
            },
            {
                "code": "atlantic_union_bank",
                "name": "Atlantic Union Bank",
                "website": "https://www.atlanticunionbank.com",
            },
            {
                "code": "bank_of_america",
                "name": "Bank of America",
                "website": "https://www.bankofamerica.com",
            },
            {
                "code": "bank_of_ann_arborpersonal",
                "name": "Bank of Ann Arbor - Personal",
                "website": "https://web6.secureinternetbank.com/EBC_EBC1151/Login/072413735",
            },
            {
                "code": "bank_of_botetourt",
                "name": "Bank of Botetourt",
                "website": "https://www.bankofbotetourt.com",
            },
            {
                "code": "bank_of_central_floridapersonal_banking",
                "name": "Bank of Central Florida - Personal Banking",
                "website": "https://cibng.ibanking-services.com/EamWeb/Account/Login.aspx?brand=827_063116261&orgId=827_063116261&FIFID=063116261&appId=CeB&FIORG=827",
            },
            {
                "code": "bank_of_coloradobusiness_banking",
                "name": "Bank of Colorado - Business Banking",
                "website": "https://ebank.pinnbank.com/EBC_EBC1961/EBC1961.ASHX?WCI=Process&WCE=Request&RID=3000&RTN=107002448&MFA=2",
            },
            {
                "code": "the_bank_of_commerce",
                "name": "The Bank of Commerce",
                "website": "http://www.bankofcommerce.org/",
            },
            {
                "code": "magnolia_state_bankbank_of_eastman",
                "name": "Magnolia State BankBank of Eastman",
                "website": "https://olb.bankofeastman.com/LoginAdv.aspx",
            },
            {
                "code": "bank_of_hawaiipersonal",
                "name": "Bank of Hawaii - Personal",
                "website": "https://www.boh.com",
            },
            {
                "code": "bank_of_holland_",
                "name": "Bank of Holland ",
                "website": "https://www.bankofhollandny.com",
            },
            {
                "code": "bank_of_oakridge",
                "name": "Bank of Oakridge",
                "website": "http://www.bankofoakridge.com/",
            },
            {
                "code": "bank_of_springfield",
                "name": "Bank of Springfield",
                "website": "https://www.bankwithbos.com/",
            },
            {
                "code": "bank_of_stockton",
                "name": "Bank of Stockton",
                "website": "https://www.bankofstockton.com",
            },
            {
                "code": "bank_of_the_pacific",
                "name": "Bank of the Pacific",
                "website": "http://www.thebankofpacific.com",
            },
            {
                "code": "bank_of_the_westpersonalsmall_business",
                "name": "Bank of the West - Personal & Small Business",
                "website": "https://bankofthewest.com/",
            },
            {
                "code": "bank_of_the_westtx_",
                "name": "Bank of the West (TX)",
                "website": "https://www.bnkwest.com",
            },
            {
                "code": "bank_of_travelers_rest",
                "name": "Bank of Travelers Rest",
                "website": "https://www.bankoftravelersrest.com/",
            },
            {
                "code": "bank_ozkpersonal",
                "name": "Bank OZK - Personal",
                "website": "https://www.ozk.com/",
            },
            {
                "code": "bank_fund_staff_federal_credit_union",
                "name": "Bank-Fund Staff Federal Credit Union",
                "website": "https://bfsfcu.org/",
            },
            {
                "code": "bank_",
                "name": "Bank ",
                "website": "https://www.bank7.com/",
            },
            {
                "code": "bankesbeasthampton_savings_bankpersonal",
                "name": "bankESB (Easthampton Savings Bank) - Personal",
                "website": "https://secure.myvirtualbranch.com/bankesb7480/signin.aspx",
            },
            {
                "code": "bankhometownhometown_bankma",
                "name": "bankHometown (Hometown Bank (MA))",
                "website": "https://secure.myvirtualbranch.com/HometownBank/SignIn.aspx",
            },
            {
                "code": "banknewport",
                "name": "BankNewport",
                "website": "https://secure.myvirtualbranch.com/BankNewport/signin.aspx",
            },
            {
                "code": "barrington_banktrustbusiness",
                "name": "Barrington Bank & Trust - Business",
                "website": "https://www.barringtonbank.com/",
            },
            {
                "code": "bb_tonline_banking",
                "name": "BB&T - Online Banking",
                "website": "https://www.bbt.com",
            },
            {
                "code": "boeing_employees_credit_unionbecu_",
                "name": "Boeing Employees Credit Union (BECU)",
                "website": "https://www.becu.org/",
            },
            {
                "code": "beehive_federal_credit_union",
                "name": "Beehive Federal Credit Union",
                "website": "https://www.beehive.org/",
            },
            {
                "code": "belmont_banktrustpersonal",
                "name": "Belmont Bank & Trust - Personal",
                "website": "http://www.belmontbank.com",
            },
            {
                "code": "benchmark_community_bank",
                "name": "Benchmark Community Bank",
                "website": "https://www.bcbonline.com/home/home",
            },
            {
                "code": "beverly_bank_and_trust_company",
                "name": "Beverly Bank and Trust Company",
                "website": "https://cibng.ibanking-services.com/EamWeb/Account/Login.aspx?brand=495_071026356&orgId=495_071026356&FIFID=071026356&appId=CeB&FIORG=495",
            },
            {
                "code": "bmoharris_bankbank_of_montreal",
                "name": "BMO (Harris Bank) Bank of Montreal",
                "website": "https://www.bmo.com",
            },
            {
                "code": "bnb_bank",
                "name": "BNB Bank",
                "website": "https://www.bnbbank.com/",
            },
            {
                "code": "bond_community_federal_credit_union",
                "name": "Bond Community Federal Credit Union",
                "website": "https://bondcu.com/",
            },
            {
                "code": "boston_firefighter_s_credit_union",
                "name": "Boston Firefighter's Credit Union",
                "website": "https://www.bosfirecu.com/",
            },
            {
                "code": "bradford_national_bank",
                "name": "Bradford National Bank",
                "website": "https://www.bradfordbank.com/",
            },
            {
                "code": "bremer_bankbusiness",
                "name": "Bremer Bank - Business",
                "website": "https://www.bremer.com/",
            },
            {
                "code": "bridgeview_bankpersonal_online_banking",
                "name": "Bridgeview Bank - Personal Online Banking",
                "website": "https://cibng.ibanking-services.com/EamWeb/Account/Login.aspx?brand=487_071923569&orgId=487_071923569&FIFID=071923569&appId=CeB&FIORG=487",
            },
            {
                "code": "bridgewater_savings",
                "name": "Bridgewater Savings",
                "website": "https://www.bridgewatersavings.com",
            },
            {
                "code": "byline_bankpersonal",
                "name": "Byline Bank - Personal",
                "website": "https://www.bylinebank.com/",
            },
            {
                "code": "c_f_citizens_and_farmers_bank",
                "name": "C&F Citizens and Farmers Bank",
                "website": "https://www.cffc.com/",
            },
            {
                "code": "cafcu_corporate_america_family_credit_union",
                "name": "CAFCU Corporate America Family Credit Union",
                "website": "https://www.cafcu.org/",
            },
            {
                "code": "california_bank_and_trustpersonal",
                "name": "California Bank and Trust (Personal )",
                "website": "https://www.calbanktrust.com/",
            },
            {
                "code": "capital_credit_union",
                "name": "Capital Credit Union",
                "website": "https://www.capitalcu.com/",
            },
            {
                "code": "capital_one",
                "name": "Capital One",
                "website": "https://www.capitalone.com/",
            },
            {
                "code": "carrollton_bank",
                "name": "Carrollton Bank",
                "website": "https://www.carrolltonbanking.com/",
            },
            {
                "code": "cedar_rapids_banktrust",
                "name": "Cedar Rapids Bank & Trust",
                "website": "https://onlinebanking.crbt.com/Login.aspx?ReturnUrl=%2f",
            },
            {
                "code": "centra_credit_union",
                "name": "Centra Credit Union",
                "website": "https://www.centra.org/",
            },
            {
                "code": "central_bank",
                "name": "Central Bank",
                "website": "https://www.centralbank.net",
            },
            {
                "code": "centreville_bank",
                "name": "Centreville Bank",
                "website": "https://www.centrevillebank.com/",
            },
            {
                "code": "chase",
                "name": "Chase",
                "website": "https://www.chase.com",
            },
            {
                "code": "cheaha_bank",
                "name": "Cheaha Bank",
                "website": "https://olb.cheahabank.com/LoginAdv.aspx",
            },
            {
                "code": "chemung_canal_trust_company",
                "name": "Chemung Canal Trust Company",
                "website": "https://www.chemungcanal.com/",
            },
            {
                "code": "cit_bank",
                "name": "CIT Bank",
                "website": "https://cibng.ibanking-services.com/EamWeb/Account/login.aspx?brand=447_124084834&orgId=447_124084834&FIFID=124084834&appId=ceb&FIORG=447",
            },
            {
                "code": "citizens_bank",
                "name": "Citizens Bank",
                "website": "https://www.citizensbank.com",
            },
            {
                "code": "citizens_banktrust_companymnpersonal",
                "name": "Citizens Bank & Trust Company (MN) - Personal",
                "website": "http://www.cbhutch.com",
            },
            {
                "code": "citizens_banktrust_company",
                "name": "Citizens Bank & Trust Company",
                "website": "http://www.citizensbt.com/",
            },
            {
                "code": "citizens_bank_of_the_south",
                "name": "Citizens Bank of the South",
                "website": "https://www.cbots.com",
            },
            {
                "code": "citizens_deposit_bankkyoh",
                "name": "Citizens Deposit Bank (KY & OH) ",
                "website": "https://onlinebanking.cdbt.com/Login.aspx",
            },
            {
                "code": "city_banktrust_company",
                "name": "City Bank & Trust Company",
                "website": "https://www.mycitybank.com",
            },
            {
                "code": "city_credit_union",
                "name": "City Credit Union",
                "website": "https://www.citycu.org/",
            },
            {
                "code": "city_national_bank_of_florida",
                "name": "City National Bank of Florida",
                "website": "https://www.citynationalcm.com",
            },
            {
                "code": "clarion_county_community_bank",
                "name": "Clarion County Community Bank",
                "website": "http://www.clarionbank.com/",
            },
            {
                "code": "classic_bank",
                "name": "Classic Bank",
                "website": "https://www.classicbank.com",
            },
            {
                "code": "clear_mountain_bank",
                "name": "Clear Mountain Bank",
                "website": "https://www.clearmountainbank.com/",
            },
            {
                "code": "cnbcarlsbad_national_bank",
                "name": "(CNB) Carlsbad National Bank",
                "website": "https://www.ourcnb.bank/",
            },
            {
                "code": "coastal_community_bank",
                "name": "Coastal Community Bank",
                "website": "https://www.coastalbank.com/",
            },
            {
                "code": "coconino_federal_credit_union",
                "name": "Coconino Federal Credit Union",
                "website": "https://coconinofcu-dn.financial-net.com/web/",
            },
            {
                "code": "collins_state_bank",
                "name": "Collins State Bank",
                "website": "https://onlinebanking.collinsstatebank.com/LoginAdv.aspx",
            },
            {
                "code": "columbia_banknj_",
                "name": "Columbia Bank (NJ)",
                "website": "https://www.columbiabankonline.com/home/personal",
            },
            {
                "code": "communication_federal_credit_union",
                "name": "Communication Federal Credit Union",
                "website": "https://www.comfedcu.org/",
            },
            {
                "code": "community_banktrust",
                "name": "Community Bank & Trust",
                "website": "https://onlinebanking.communitybt.com/Login.aspx?ReturnUrl=%2f",
            },
            {
                "code": "community_bank_of_pickens_county",
                "name": "Community Bank of Pickens County",
                "website": "https://www.cbopc.com/",
            },
            {
                "code": "community_first_national_bank",
                "name": "Community First National Bank",
                "website": "https://www.cfnbmanhattan.com/index.cfm",
            },
            {
                "code": "community_national_bank",
                "name": "Community National Bank",
                "website": "http://www.communitynationalbank.net/",
            },
            {
                "code": "community_state_bankankenyiapersonal_",
                "name": "\"Community State Bank (Ankeny, IA) - Personal\"",
                "website": "https://www.bankcsb.com/",
            },
            {
                "code": "connectone_bank",
                "name": "ConnectOne Bank",
                "website": "https://www.connectonebank.com/",
            },
            {
                "code": "the_cooperative_bank_of_cape_cod",
                "name": "The Cooperative Bank of Cape Cod",
                "website": "https://www.thecooperativebankofcapecod.com/",
            },
            {
                "code": "core_bank",
                "name": "Core Bank",
                "website": "https://corebank.com/",
            },
            {
                "code": "cornerstone_bank",
                "name": "Cornerstone Bank",
                "website": "https://www.cornerstoneconnect.net/pbi_pbi1961/pbi1961.asp?Rt=104900349&LogonBy=connect3&PRMACCESS=Account",
            },
            {
                "code": "cornerstone_community_bank_",
                "name": "Cornerstone Community Bank ",
                "website": "https://www.bankcornerstone.com/",
            },
            {
                "code": "crest_savings_bank",
                "name": "Crest Savings Bank",
                "website": "https://www.crestsavings.com",
            },
            {
                "code": "croghan_colonial_bank",
                "name": "Croghan Colonial Bank",
                "website": "https://login2.fisglobal.com/idp/0585A/?ClientID=PALUI",
            },
            {
                "code": "crystal_lake_banktrust_companycommunity_banking",
                "name": "Crystal Lake Bank & Trust Company - .COMmunity Banking",
                "website": "https://cibng.ibanking-services.com/EamWeb/Account/Login.aspx?orgId=600_071925787&FIFID=071925787&brand=600_071925787&appId=CeB&FIORG=600",
            },
            {
                "code": "d_levans_bank",
                "name": "D.L. Evans Bank",
                "website": "https://www.dlevans.com/",
            },
            {
                "code": "dairy_state_bank",
                "name": "Dairy State Bank",
                "website": "https://dairystatebank.com/",
            },
            {
                "code": "dean_bank",
                "name": "Dean Bank",
                "website": "https://secure-deanbank.com/Common/SignOn/Start.asp",
            },
            {
                "code": "del_one_fcu",
                "name": "Del-One FCU",
                "website": "https://www.del-one.org/",
            },
            {
                "code": "delta_community_credit_union",
                "name": "Delta Community Credit Union",
                "website": "http://www.deltacommunitycu.com/",
            },
            {
                "code": "desjardins_bankbusinessus_",
                "name": "Desjardins Bank- Business (US)",
                "website": "https://www.desjardinsbank.com/en/index.jsp",
            },
            {
                "code": "discover_",
                "name": "Discover ",
                "website": "https://www.discover.com",
            },
            {
                "code": "dollar_bankpersonal",
                "name": "Dollar Bank - Personal",
                "website": "https://www.dollar.bank/Personal.aspx",
            },
            {
                "code": "drake_bank",
                "name": "Drake Bank",
                "website": "https://drake-bank.com/",
            },
            {
                "code": "eastern_bankma_",
                "name": "Eastern Bank (MA)",
                "website": "https://www.easternbank.com/",
            },
            {
                "code": "embassy_national_bankpersonal_banking",
                "name": "Embassy National Bank - Personal Banking",
                "website": "https://www.embassynationalbank.com/",
            },
            {
                "code": "enterprise_bankne_",
                "name": "Enterprise Bank (NE)",
                "website": "https://www.enterprise.bank/",
            },
            {
                "code": "enterprise_bankpersonal",
                "name": "Enterprise Bank - Personal",
                "website": "https://www.enterprisebanking.com/",
            },
            {
                "code": "envision_bank",
                "name": "Envision Bank",
                "website": "https://www.envisionbank.com/",
            },
            {
                "code": "essa_banktrust",
                "name": "ESSA Bank & Trust",
                "website": "https://www.essabank.com/",
            },
            {
                "code": "f_m_trust",
                "name": "F&M Trust",
                "website": "https://cibng.ibanking-services.com/EamWeb/Account/Login.aspx?brand=400_031304306&orgId=400_031304306&FIFID=031304306&appId=CeB&FIORG=400",
            },
            {
                "code": "fairport_savings_bank",
                "name": "Fairport Savings Bank",
                "website": "https://secure-fairportsavingsbank.com/SignOn/Logon.aspx",
            },
            {
                "code": "farmersmerchants_banktrust_of_burlingtonpersonal",
                "name": "Farmers & Merchants Bank & Trust of Burlington - Personal",
                "website": "http://www.fmbanktrust.com/",
            },
            {
                "code": "farmers_bankidpersonal",
                "name": "Farmers Bank (ID) - Personal",
                "website": "https://www.farmersbankidaho.com/",
            },
            {
                "code": "farmers_insurance_federal_credit_union",
                "name": "Farmers Insurance Federal Credit Union",
                "website": "https://www.figfcu.org/",
            },
            {
                "code": "farmers_state_bank",
                "name": " Farmers State Bank",
                "website": "https://www.farmersebank.com",
            },
            {
                "code": "fauquier_banktfb_the_fauquier_bank_",
                "name": " Fauquier Bank (TFB The Fauquier Bank)",
                "website": "https://www.tfb.bank/",
            },
            {
                "code": "fcnb_of_upper_sanduskythe_first_citizens_national_bank_",
                "name": "FCNB of Upper Sandusky (The First Citizens National Bank)",
                "website": "https://www.firstcitizensnational.com/",
            },
            {
                "code": "fidelity_bankoklahoma_fidelity_bankpersonal_online_banking",
                "name": "Fidelity Bank & Oklahoma Fidelity Bank - Personal Online Banking",
                "website": "https://fidelitybank.com",
            },
            {
                "code": "fidelity_bankncbusiness",
                "name": " Fidelity Bank (NC) - Business",
                "website": "https://www.fidelitybanknc.com/",
            },
            {
                "code": "finemark_national_banktrust",
                "name": "Finemark National Bank & Trust",
                "website": "https://www.finemarkbank.com/",
            },
            {
                "code": "first_alliance_cu",
                "name": "First Alliance CU",
                "website": "https://www.firstalliancecu.com",
            },
            {
                "code": "first_banktrust_companyoklahoma_",
                "name": "First Bank & Trust Company (Oklahoma)",
                "website": "https://www.fbt.bank/",
            },
            {
                "code": "first_bank",
                "name": "First Bank",
                "website": "https://localfirstbank.com/",
            },
            {
                "code": "first_banknew_jerseypersonal",
                "name": "First Bank (New Jersey) - Personal",
                "website": "http://www.firstbanknj.com/",
            },
            {
                "code": "first_bank_of_highland_parkpersonal",
                "name": "First Bank of Highland Park - Personal",
                "website": "http://www.firstbankhp.com/",
            },
            {
                "code": "first_central_savings_bankpersonal",
                "name": "First Central Savings Bank - Personal",
                "website": "http://www.firstcentralsavings.com/",
            },
            {
                "code": "first_citizens_bank",
                "name": "First Citizens Bank",
                "website": "https://www.firstcitizens.com/",
            },
            {
                "code": "first_citizens_national_bankpersonal",
                "name": "First Citizens National Bank - Personal",
                "website": "http://www.firstcitizens-bank.com/",
            },
            {
                "code": "first_commerce_banknj_",
                "name": "First Commerce Bank (NJ)",
                "website": "https://www.firstcommercebk.com/",
            },
            {
                "code": "first_community_bankarmoof_batesville_real_timeonline_banking",
                "name": "First Community Bank (AR &MO) of Batesville Real-Time - Online Banking",
                "website": "https://firstcommunity.net",
            },
            {
                "code": "first_community_bankscca_",
                "name": "First Community Bank (SC & CA)",
                "website": "https://www.firstcommunitysc.com",
            },
            {
                "code": "first_eagle_bankpersonal",
                "name": "First Eagle Bank - Personal",
                "website": "http://www.febank.com",
            },
            {
                "code": "first_farm_bank",
                "name": "First Farm Bank",
                "website": "https://www.firstfarmbank.com/",
            },
            {
                "code": "first_farmersmerchants_bank_",
                "name": "First Farmers & Merchants Bank ",
                "website": "https://cibng.ibanking-services.com/EamWeb/Account/Login.aspx?brand=581_064108113&orgId=581_064108113&FIFID=064108113&appId=CeB&FIORG=581",
            },
            {
                "code": "first_federal_bank_of_louisiana",
                "name": "First Federal Bank of Louisiana",
                "website": "https://www.ffbla.bank/",
            },
            {
                "code": "first_federal_savings_bank",
                "name": "First Federal Savings Bank",
                "website": "https://www.firstfederalbanking.com/",
            },
            {
                "code": "first_financial_northwest_bank",
                "name": "First Financial Northwest Bank",
                "website": "https://www.ffnwb.com/",
            },
            {
                "code": "first_foundation_bankbusiness_banking",
                "name": "First Foundation Bank - Business Banking",
                "website": "https://firstfoundationinc.com/",
            },
            {
                "code": "first_horizon_bankpersonal",
                "name": "First Horizon Bank - Personal",
                "website": "https://www.firsthorizon.com/",
            },
            {
                "code": "first_international_banktrust",
                "name": "First International Bank & Trust",
                "website": "https://www.firstintlbank.com/",
            },
            {
                "code": "first_midwest_bankpersonal",
                "name": "First Midwest Bank - Personal",
                "website": "https://www.firstmidwest.com/",
            },
            {
                "code": "first_national_bank_alaska",
                "name": "First National Bank Alaska",
                "website": "https://www.fnbalaska.com/",
            },
            {
                "code": "first_national_bank_of_deridder",
                "name": "First National Bank of DeRidder",
                "website": "https://bankfnbd.com/",
            },
            {
                "code": "first_national_bank_in_carlyleilfnb_carlyle_",
                "name": "First National Bank in Carlyle (IL) (FNB Carlyle)",
                "website": "https://1nbcarlyle.com/",
            },
            {
                "code": "first_national_bank_minnesotapersonal",
                "name": "First National Bank Minnesota - Personal",
                "website": "https://fnbmn.bank/",
            },
            {
                "code": "first_national_bank_of_central_texas",
                "name": "First National Bank of Central Texas",
                "website": "https://olb.fnbct.com/Login.aspx?",
            },
            {
                "code": "first_national_bank_of_giddings",
                "name": "First National Bank of Giddings",
                "website": "http://www.fnbgiddings.com",
            },
            {
                "code": "first_national_bank_of_louisiana",
                "name": "First National Bank of Louisiana",
                "website": "https://www.fnb-la.com",
            },
            {
                "code": "first_bankn_acrowleyla",
                "name": "\"First Bank, N.A. (Crowley, LA)\"",
                "website": "https://www.fnb-la.com/",
            },
            {
                "code": "first_national_bank_of_mount_dorapersonal",
                "name": "First National Bank of Mount Dora - Personal",
                "website": "https://cibng.ibanking-services.com/EamWeb/Account/Login.aspx?orgId=246_063104312&FIFID=063104312&brand=246_063104312&appId=CeB&FIORG=246",
            },
            {
                "code": "first_national_bank_of_pulaskialtn",
                "name": "\"First National Bank of Pulaski (AL, TN)\"",
                "website": "https://www.fnbforyou.com",
            },
            {
                "code": "first_national_bank_of_scotia",
                "name": "First National Bank of Scotia",
                "website": "https://onlinebanking.firstscotia.com/LoginAdv.aspx",
            },
            {
                "code": "first_national_bank_of_tennesseefnb_tenessepersonal_internet_banking",
                "name": "First National Bank of Tennessee (FNB Tenesse) - Personal Internet Banking",
                "website": "https://cibng.ibanking-services.com/EamWeb/Account/Login.aspx?brand=658_064101233&orgId=658_064101233&FIFID=064101233&appId=CeB&FIORG=658",
            },
            {
                "code": "first_national_bank_of_waterloo",
                "name": "First National Bank of Waterloo",
                "website": "http://www.fnbwaterloo.com",
            },
            {
                "code": "first_national_bank_texas",
                "name": "First National Bank Texas",
                "website": "http://www.1stnb.com/",
            },
            {
                "code": "first_option_bank",
                "name": "First Option Bank",
                "website": "http://www.firstoptionbank.com",
            },
            {
                "code": "first_peoples_bankga_",
                "name": "First Peoples Bank (GA)",
                "website": "https://www.firstpeoplesbank.com/",
            },
            {
                "code": "first_savings_bank_of_hegewisch",
                "name": "First Savings Bank of Hegewisch",
                "website": "https://www.fsbhegewisch.com/",
            },
            {
                "code": "first_securityiapersonal",
                "name": "First Security (IA) - Personal",
                "website": "http://www.1stsecuritybank.com/",
            },
            {
                "code": "first_state_bankoklahoma_cityok",
                "name": "\"First State Bank (Oklahoma City, OK)\"",
                "website": "https://www.thefirstsb.com/",
            },
            {
                "code": "first_state_bank",
                "name": "First State Bank",
                "website": "https://fsbfinancial.com/",
            },
            {
                "code": "first_state_bank_of_uvalde",
                "name": "First State Bank of Uvalde",
                "website": "https://www.fsbuvalde.com/",
            },
            {
                "code": "first_tech_federal_credit_union",
                "name": "First Tech Federal Credit Union",
                "website": "https://www.firsttechfed.com/",
            },
            {
                "code": "first_united_banktrust",
                "name": "First United Bank & Trust",
                "website": "https://www.mybank.com",
            },
            {
                "code": "firstbankcoloradopersonal_online_banking",
                "name": "FirstBank (Colorado) - Personal Online Banking",
                "website": "http://www.efirstbank.com/",
            },
            {
                "code": "firstbank_southwest",
                "name": "FirstBank Southwest",
                "website": "https://online.fbsw.com/LoginAdv.aspx",
            },
            {
                "code": "firstcapital_bank_of_texas",
                "name": "FirstCapital Bank of Texas",
                "website": "https://www.fcbtexas.com/",
            },
            {
                "code": "fitzsimons_credit_union",
                "name": "Fitzsimons Credit Union",
                "website": "https://www.fitzsimonscu.com",
            },
            {
                "code": "flagstar_bankbusiness_online_banking",
                "name": "Flagstar Bank - Business Online Banking",
                "website": "https://online.flagstar.com/FSB/Login.aspx",
            },
            {
                "code": "fnb_community_bank",
                "name": "FNB Community Bank",
                "website": "https://www.fnbmwc.com/",
            },
            {
                "code": "fnbank_creston_",
                "name": "FNBank Creston ",
                "website": "https://www.fnbcreston.com/",
            },
            {
                "code": "fnbc_banktrust",
                "name": "FNBC Bank & Trust",
                "website": "https://cibng.ibanking-services.com/EamWeb/Account/Login.aspx?brand=692_071903929&orgId=692_071903929&FIFID=071903929&appId=CeB&FIORG=692",
            },
            {
                "code": "forreston_state_bank",
                "name": "Forreston State Bank",
                "website": "https://www.fsbil.com",
            },
            {
                "code": "fort_hood_national_bank",
                "name": "Fort Hood National Bank",
                "website": "https://www.fhnb.com/",
            },
            {
                "code": "fort_lee_federal_credit_union",
                "name": "Fort Lee Federal Credit Union",
                "website": "https://www.fortleecu.org/",
            },
            {
                "code": "franklin_savings_bankmepersonal",
                "name": "Franklin Savings Bank (ME) - Personal",
                "website": "https://www.franklinsavings.bank/",
            },
            {
                "code": "franklin_synergy_bankpersonal_",
                "name": "Franklin Synergy Bank \u2013 Personal ",
                "website": "https://www.franklinsynergybank.com",
            },
            {
                "code": "fremont_bankpersonal",
                "name": "Fremont Bank - Personal",
                "website": "https://www.fremontbank.com/",
            },
            {
                "code": "fulton_bank",
                "name": "Fulton Bank",
                "website": "https://www.fultonbank.com/",
            },
            {
                "code": "gecu",
                "name": "GECU",
                "website": "https://www.gecu.com/",
            },
            {
                "code": "gibsland_banktrust",
                "name": "Gibsland Bank & Trust",
                "website": "https://www.gibslandbank.com",
            },
            {
                "code": "glens_falls_national_bank_and_trust_company",
                "name": "Glens Falls National Bank and Trust Company",
                "website": "https://www.gfnationalonline.com",
            },
            {
                "code": "glenview_state_bankonline_banking",
                "name": "Glenview State Bank - Online Banking",
                "website": "https://www.gsb.com/",
            },
            {
                "code": "gorham_savings_bank",
                "name": "Gorham Savings Bank",
                "website": "https://online.gorhamsavingsbank.com/login.aspx?ReturnUrl=%2fCurrent_Client%2fLogin.aspx",
            },
            {
                "code": "grand_ridge_national_bankpersonal",
                "name": "Grand Ridge National Bank - Personal",
                "website": "http://www.grnbank.com/",
            },
            {
                "code": "greenville_savings_bankpersonal",
                "name": "Greenville Savings Bank - Personal",
                "website": "http://www.greenvillesavings.com/",
            },
            {
                "code": "grundy_bank",
                "name": "Grundy Bank",
                "website": "https://www.grundybank.com/",
            },
            {
                "code": "guadalupe_national_bank",
                "name": "Guadalupe National Bank",
                "website": "https://www.guadalupenational.com",
            },
            {
                "code": "guaranty_banktrust_companyms_",
                "name": "Guaranty Bank & Trust Company (MS)",
                "website": "https://gbtonline.com",
            },
            {
                "code": "happy_state_bank",
                "name": "Happy State Bank",
                "website": "https://www.happybank.com/",
            },
            {
                "code": "harrison_county_bank",
                "name": "Harrison County Bank",
                "website": "http://www.hcbwv.com",
            },
            {
                "code": "heritage_bankgapersonal",
                "name": "Heritage Bank (GA) - Personal",
                "website": "https://www.heritagebank.com/",
            },
            {
                "code": "heritage_bank_of_schaumburg",
                "name": "Heritage Bank of Schaumburg",
                "website": "https://cibng.ibanking-services.com/EamWeb/Account/Login.aspx?brand=156_071924131&orgId=156_071924131&FIFID=071924131&appId=CeB&FIORG=156",
            },
            {
                "code": "hinsdale_banktrust_company",
                "name": "Hinsdale Bank & Trust Company",
                "website": "http://www.hinsdalebank.com",
            },
            {
                "code": "home_bank",
                "name": "Home Bank",
                "website": "https://www.home24bank.com/",
            },
            {
                "code": "home_state_bankjefferson_iapersonal",
                "name": "Home State Bank (Jefferson IA) - Personal",
                "website": "http://www.hsbankiowa.com/",
            },
            {
                "code": "home_state_bankpersonal",
                "name": "Home State Bank - Personal",
                "website": "https://www.homestbk.com/",
            },
            {
                "code": "homestreet_bank",
                "name": "HomeStreet Bank",
                "website": "https://cibng.ibanking-services.com/EamWeb/Account/Login.aspx?brand=420_325084426&orgId=420_325084426&FIFID=325084426&appId=CeB&FIORG=420",
            },
            {
                "code": "hoyne_savings_bankpersonal",
                "name": "Hoyne Savings Bank - Personal",
                "website": "http://www.hoyne.com/",
            },
            {
                "code": "hughes_federal_credit_union",
                "name": "Hughes Federal Credit Union",
                "website": "https://www.hughesfcu.org/",
            },
            {
                "code": "huntington_bank",
                "name": "Huntington Bank",
                "website": "https://www.huntington.com",
            },
            {
                "code": "huron_community_bankmobile_banking",
                "name": "Huron Community Bank - Mobile Banking",
                "website": "https://www.bankhcb.com",
            },
            {
                "code": "inbank_international_bank_",
                "name": "InBank International Bank ",
                "website": "https://inbank.com/",
            },
            {
                "code": "independent_bankpersonal",
                "name": "Independent Bank - Personal",
                "website": "https://www.independentbank.com/",
            },
            {
                "code": "investors_bankpersonal",
                "name": "Investors Bank - Personal",
                "website": "https://online.myinvestorsbank.com/auth/SignIn?wa=wsignin1.0&wtrealm=",
            },
            {
                "code": "investors_community_bank",
                "name": "Investors Community Bank",
                "website": "https://www.investorscommunitybank.com/",
            },
            {
                "code": "iron_workers_bank",
                "name": "Iron Workers Bank",
                "website": "https://www.iwsb.com/",
            },
            {
                "code": "iroquois_federal_savingsloan",
                "name": "Iroquois Federal Savings & Loan",
                "website": "http://www.iroquoisfed.com",
            },
            {
                "code": "jeff_davis_bank",
                "name": "Jeff Davis Bank",
                "website": "https://jdbank.com/",
            },
            {
                "code": "jefferson_bank_of_missouri",
                "name": "Jefferson Bank of Missouri",
                "website": "https://secure.jefferson-bank.com/login/login/Login.jsp",
            },
            {
                "code": "john_marshall_bankpersonal",
                "name": "John Marshall Bank - Personal",
                "website": "https://www.johnmarshallbank.com/",
            },
            {
                "code": "jones_national_bank_and_trust",
                "name": "Jones National Bank and Trust",
                "website": "https://www.jonesbank.com/",
            },
            {
                "code": "katahdin_trust_company",
                "name": "Katahdin Trust Company",
                "website": "https://www.katahdintrust.com/",
            },
            {
                "code": "kearny_bank",
                "name": "Kearny Bank",
                "website": "https://www.kearnybank.com/",
            },
            {
                "code": "keybank",
                "name": "KeyBank",
                "website": "https://www.key.com",
            },
            {
                "code": "kingston_national_bankpersonal_",
                "name": "Kingston National Bank \u2013 Personal ",
                "website": "http://www.kingstonnationalbank.com/",
            },
            {
                "code": "lake_city_bank",
                "name": "Lake City Bank",
                "website": "https://cibng.ibanking-services.com/EamWeb/Account/Login.aspx?brand=633_074903719&orgId=633_074903719&FIFID=074903719&appId=CeB&FIORG=633",
            },
            {
                "code": "lake_forest_banktrust_company",
                "name": "Lake Forest Bank & Trust Company",
                "website": "https://cibng.ibanking-services.com/EamWeb/Account/Login.aspx?brand=919_071925334&orgId=919_071925334&FIFID=071925334&appId=CeB&FIORG=919",
            },
            {
                "code": "lake_shore_savingswestern_nypersonal",
                "name": "Lake Shore Savings (Western NY) - Personal",
                "website": "https://www.lakeshoresavings.com/",
            },
            {
                "code": "landmark_national_bank",
                "name": "Landmark National Bank",
                "website": "https://www.banklandmark.com/",
            },
            {
                "code": "lead_bank",
                "name": "Lead Bank",
                "website": "https://www.lead.bank/",
            },
            {
                "code": "lee_bank",
                "name": "Lee Bank",
                "website": "https://www.leebank.com/",
            },
            {
                "code": "level_one_bank",
                "name": "Level One Bank",
                "website": "https://web5.secureinternetbank.com/EBC_EBC1151/Login/072414310",
            },
            {
                "code": "liberty_national_bankokbusiness",
                "name": "Liberty National Bank (OK) - Business",
                "website": "https://www.lnbok.com/",
            },
            {
                "code": "libertyville_banktrustbusiness_banking",
                "name": "Libertyville Bank & Trust - Business Banking",
                "website": "https://www.libertyvillebank.com/",
            },
            {
                "code": "lifestore_bank",
                "name": "LifeStore Bank",
                "website": "https://www.golifestore.com",
            },
            {
                "code": "llano_national_bankmobile_banking",
                "name": "Llano National Bank - Mobile Banking",
                "website": "https://www.llanonationalbank.com",
            },
            {
                "code": "lyons_national_bank",
                "name": "Lyons National Bank",
                "website": "https://secure-lyonsbank.com/SignOn/Logon",
            },
            {
                "code": "m_t_bank",
                "name": "M&T Bank",
                "website": "https://www.mtb.com/",
            },
            {
                "code": "main_street_bankmapersonal",
                "name": "Main Street Bank (MA) - Personal",
                "website": "https://www.bankmainstreet.com",
            },
            {
                "code": "malvern_national_bank",
                "name": "Malvern National Bank",
                "website": "https://mnbbank.com",
            },
            {
                "code": "manasquan_bank",
                "name": "Manasquan Bank",
                "website": "https://web1.secureinternetbank.com/ebc_ebc1961/ebc1961.ashx?wci=Process&wce=Request&RID=3000&RTN=221272167&mfa=2",
            },
            {
                "code": "marblehead_bankmarbleheadohconsumer_",
                "name": "\"Marblehead Bank (Marblehead, OH) - Consumer\"",
                "website": "http://marbleheadbank.com/",
            },
            {
                "code": "marion_center_bankpersonal",
                "name": "Marion Center Bank - Personal",
                "website": "http://www.marioncenterbank.com",
            },
            {
                "code": "marquette_bankchicagomobile_banking",
                "name": "Marquette Bank (Chicago) - Mobile Banking",
                "website": "https://www.emarquettebank.com",
            },
            {
                "code": "marquette_savings_bankpamobile_banking",
                "name": "Marquette Savings Bank (PA) - Mobile Banking",
                "website": "https://www.marquettesavings.com",
            },
            {
                "code": "mars_national_bank",
                "name": "Mars National Bank",
                "website": "https://www.marsbank.com/",
            },
            {
                "code": "martha_s_vineyard_savings_bank",
                "name": "Martha's Vineyard Savings Bank",
                "website": "https://www.mvbank.com/marthas-vineyard-banks-mobile-banking",
            },
            {
                "code": "m_c_banktrust",
                "name": "M C Bank & Trust",
                "website": "https://www.mcbt.com/",
            },
            {
                "code": "mcnb_banks",
                "name": "MCNB Banks",
                "website": "http://www.mcnbbanks.com/",
            },
            {
                "code": "memory_bank",
                "name": "Memory Bank",
                "website": "https://www.mymemorybank.com/home/home",
            },
            {
                "code": "merchants_bank",
                "name": "Merchants Bank",
                "website": "https://www.merchantsbank.com/",
            },
            {
                "code": "merchants_bank_of_alabama",
                "name": "Merchants Bank of Alabama",
                "website": "http://merchantsbankal.com",
            },
            {
                "code": "merck_sharpdohme_fcu",
                "name": "Merck Sharp & Dohme FCU",
                "website": "https://secure-msdfcu.org/SignOn/Logon.aspx",
            },
            {
                "code": "metro_city_bank",
                "name": "Metro City Bank",
                "website": "http://www.metrocitybank.com",
            },
            {
                "code": "metro_federal_credit_union",
                "name": "Metro Federal Credit Union",
                "website": "https://mcu.org/?language=EN",
            },
            {
                "code": "michigan_educational_credit_union",
                "name": "Michigan Educational Credit Union",
                "website": "https://online.michedcu.org/Login.aspx",
            },
            {
                "code": "midfirst_bankpersonal",
                "name": "MidFirst Bank - Personal",
                "website": "https://www.midfirst.com",
            },
            {
                "code": "midsouth_bankalpanama_cityflpersonal_",
                "name": "\"MidSouth Bank (AL & Panama City, FL) - Personal\"",
                "website": "https://www.bankmidsouth.com/",
            },
            {
                "code": "midwest_heritagepersonal",
                "name": "Midwest Heritage - Personal",
                "website": "https://www.mhbank.com",
            },
            {
                "code": "midwestone_bankpersonal",
                "name": "MidWestOne Bank - Personal",
                "website": "https://www.midwestone.com/",
            },
            {
                "code": "milford_bankpersonal",
                "name": "Milford Bank - Personal",
                "website": "https://www.milfordbank.com/",
            },
            {
                "code": "minster_bank",
                "name": "Minster Bank",
                "website": "http://www.minsterbank.com/",
            },
            {
                "code": "mocse_credit_union",
                "name": "Mocse Credit Union",
                "website": "https://www.mocse.org",
            },
            {
                "code": "monona_bank",
                "name": "Monona Bank",
                "website": "https://www.mononabank.com",
            },
            {
                "code": "monson_savings_bank",
                "name": "Monson Savings Bank",
                "website": "https://www.monsonsavings.bank",
            },
            {
                "code": "montgomery_bankpersonal",
                "name": "Montgomery Bank - Personal",
                "website": "https://www.montgomerybank.com",
            },
            {
                "code": "morgan_stanley_client_serv",
                "name": "Morgan Stanley Client Serv",
                "website": "https://login.morganstanleyclientserv.com/",
            },
            {
                "code": "mountain_america_credit_union_",
                "name": "Mountain America Credit Union ",
                "website": "https://www.macu.com/",
            },
            {
                "code": "national_bank_of_arizona",
                "name": "National Bank of Arizona",
                "website": "https://www.nbarizona.com/",
            },
            {
                "code": "navy_federal_credit_union",
                "name": "Navy Federal Credit Union",
                "website": "https://www.navyfederal.org/",
            },
            {
                "code": "nbc_bankoklahoma",
                "name": "NBC Bank - Oklahoma",
                "website": "https://www.nbc.bank/",
            },
            {
                "code": "nbt_bank",
                "name": "NBT Bank",
                "website": "https://www.nbtbank.com/personal",
            },
            {
                "code": "neighbors_federal_credit_union",
                "name": "Neighbors Federal Credit Union",
                "website": "https://www.neighborsfcu.org/personal/online-banking/",
            },
            {
                "code": "nevada_state_bank",
                "name": "Nevada State Bank",
                "website": "https://www.nsbank.com/",
            },
            {
                "code": "north_georgia_national_bankpersonal",
                "name": "North Georgia National Bank - Personal",
                "website": "http://www.ngnb.net/",
            },
            {
                "code": "northbrook_bank_and_trust_companycommunity_banking",
                "name": "Northbrook Bank and Trust Company - .COMmunity Banking",
                "website": "https://cibng.ibanking-services.com/EamWeb/Account/Login.aspx?brand=870_071926184&orgId=870_071926184&FIFID=071926184&appId=CeB&FIORG=870",
            },
            {
                "code": "northfield_bankpersonal",
                "name": "Northfield Bank - Personal",
                "website": "https://www.enorthfield.com/",
            },
            {
                "code": "northumberland_national_bank",
                "name": "Northumberland National Bank",
                "website": "https://norrybank.com",
            },
            {
                "code": "northwest_bank",
                "name": "Northwest Bank",
                "website": "https://www.bank-northwest.com/",
            },
            {
                "code": "northwestern_bankwipersonal",
                "name": "Northwestern Bank (WI) - Personal",
                "website": "https://www.northwesternbank.com/",
            },
            {
                "code": "ocean_financial_federal_credit_union",
                "name": "Ocean Financial Federal Credit Union",
                "website": "https://www.oceanfinancial.org/",
            },
            {
                "code": "ohio_state_bank",
                "name": "Ohio State Bank",
                "website": "https://www.bankatosb.com",
            },
            {
                "code": "old_plank_trail_community_bank",
                "name": "Old Plank Trail Community Bank",
                "website": "https://cibng.ibanking-services.com/EamWeb/Account/Login.aspx?orgId=924_071926582&FIFID=071926582&brand=924_071926582&appId=CeB&FIORG=924",
            },
            {
                "code": "onewest_bankpersonal_banking",
                "name": "OneWest Bank - Personal Banking",
                "website": "https://www.onewestbank.com/",
            },
            {
                "code": "open_bank",
                "name": "Open Bank",
                "website": "https://myopenbank.com/",
            },
            {
                "code": "pacific_premier_bank",
                "name": "Pacific Premier Bank",
                "website": "https://www.ppbi.com/",
            },
            {
                "code": "origin_bank",
                "name": "Origin Bank",
                "website": "https://www.origin.bank",
            },
            {
                "code": "pacific_alliance_bankpersonal",
                "name": "Pacific Alliance Bank - Personal",
                "website": "http://www.pacificalliancebank.com",
            },
            {
                "code": "pacific_nw_federal_credit_union",
                "name": "Pacific NW Federal Credit Union",
                "website": "https://www.pnwfcu.org",
            },
            {
                "code": "park_ridge_community_bankpersonal",
                "name": "Park Ridge Community Bank - Personal",
                "website": "http://www.timetobank.com",
            },
            {
                "code": "parkway_banktrust_co",
                "name": "Parkway Bank & Trust Co",
                "website": "https://www.parkwaybank.com",
            },
            {
                "code": "partners_bank",
                "name": "Partners Bank",
                "website": "https://www.partnersbankonline.com/",
            },
            {
                "code": "b1_bank",
                "name": "b1 Bank",
                "website": "https://www.b1bank.com/pedestal",
            },
            {
                "code": "peoples_bankinil_",
                "name": "Peoples Bank (IN & IL)",
                "website": "https://cibng.ibanking-services.com/EamWeb/Account/Login.aspx?orgId=398_271973924&FIFID=271973924&brand=398_271973924&appId=CeB&FIORG=398",
            },
            {
                "code": "peoples_bankohwvkyretirement_plan_access_",
                "name": " \"Peoples Bank (OH, WV, KY) - Retirement Plan Access\"",
                "website": "https://www.yourplanaccess.net/peoplesfinancialadvisors/",
            },
            {
                "code": "peoples_national_bankil_",
                "name": "Peoples National Bank (IL)",
                "website": "https://cibng.ibanking-services.com/EamWeb/Account/login.aspx?orgId=399_081206807&FIFID=081206807&brand=399_081206807&appId=CeB&FIORG=399",
            },
            {
                "code": "peoples_state_bank_of_plainviewzellepersonal",
                "name": "Peoples State Bank of Plainview (Zelle) - Personal",
                "website": "http://www.peoplesstatebank.com",
            },
            {
                "code": "peoplesbankmapersonal_online_banking_",
                "name": "PeoplesBank (MA) - Personal Online Banking ",
                "website": "https://secure.myvirtualbranch.com/bankatpeoples/SignIn.aspx",
            },
            {
                "code": "philo_exchange_bank",
                "name": "Philo Exchange Bank",
                "website": "https://www.philobank.com/",
            },
            {
                "code": "piedmont_federal_savings_bank",
                "name": "Piedmont Federal Savings Bank",
                "website": "https://secure-piedmontfederal.com/Common/SignOn/Start.asp",
            },
            {
                "code": "pinnacle_bankof_texaswyoming",
                "name": "Pinnacle Bank & (Of Texas & Wyoming) ",
                "website": "https://www.wypinnbank.com/",
            },
            {
                "code": "pioneer_banknew_mexicointernet_banking",
                "name": "Pioneer Bank (New Mexico) - Internet Banking",
                "website": "http://www.pioneerbnk.com/",
            },
            {
                "code": "plainscapital_bank",
                "name": "PlainsCapital Bank",
                "website": "https://cibng.ibanking-services.com/EamWeb/Account/Login.aspx?brand=338_111322994&orgId=338_111322994&FIFID=111322994&appId=CeB&FIORG=338",
            },
            {
                "code": "planters_bank",
                "name": "Planters Bank",
                "website": "https://www.plantersbankonline.com/",
            },
            {
                "code": "pnc",
                "name": "PNC",
                "website": "https://www.pnc.com",
            },
            {
                "code": "premier_bankwv_",
                "name": "Premier Bank (WV)",
                "website": "https://onlinebanking.premierbankinc.com/Login.aspx",
            },
            {
                "code": "premierone_credit_unionca_",
                "name": "PremierOne Credit Union (CA)",
                "website": "https://online.premieronecu.org/banking",
            },
            {
                "code": "primesouth_bankga_",
                "name": "PrimeSouth Bank (GA)",
                "website": "https://www.primesouth.com",
            },
            {
                "code": "provident_banknjpaprovidentconnectbusiness_",
                "name": "\"Provident Bank (NJ, PA) - ProvidentConnect - Business\"",
                "website": "https://www.provident.bank/",
            },
            {
                "code": "pyramax_bank",
                "name": "PyraMax Bank",
                "website": "https://cibng.ibanking-services.com/EamWeb/Account/Login.aspx?brand=701_275071327&orgId=701_275071327&FIFID=275071327&appId=CeB&FIORG=701",
            },
            {
                "code": "quad_city_banktrust",
                "name": "Quad City Bank & Trust",
                "website": "https://onlinebanking.qcbt.com",
            },
            {
                "code": "quontic_bank",
                "name": "Quontic Bank",
                "website": "https://www.quonticbank.com/",
            },
            {
                "code": "redwood_capital_bank",
                "name": "Redwood Capital Bank",
                "website": "https://www.redwoodcapitalbank.com/",
            },
            {
                "code": "regions_bank",
                "name": "Regions Bank",
                "website": "https://www.regions.com",
            },
            {
                "code": "reliance_bankmnredefining_service",
                "name": "Reliance Bank (MN) (Redefining Service) ",
                "website": "http://www.reliancebankmn.com",
            },
            {
                "code": "renasant_bank_business_banking",
                "name": "Renasant Bank Business Banking",
                "website": "http://www.renasantbank.com",
            },
            {
                "code": "republic_banktrust_co_",
                "name": "Republic Bank & Trust Co.",
                "website": "https://www.republicbank.com/home/personal",
            },
            {
                "code": "republic_bank_of_chicagopersonal_online_banking_",
                "name": "Republic Bank of Chicago - Personal Online Banking ",
                "website": "https://republicebank.com",
            },
            {
                "code": "rhinebeck_bankmobile_banking",
                "name": "Rhinebeck Bank - Mobile Banking",
                "website": "https://www.rhinebeckbank.com",
            },
            {
                "code": "river_city_bankgaonline_banking",
                "name": "River City Bank (GA) - Online Banking",
                "website": "https://www.rivercitybankonline.com",
            },
            {
                "code": "s_t_bankpersonal",
                "name": "S&T Bank - Personal",
                "website": "https://www.stbank.com/",
            },
            {
                "code": "salem_fivecorporate",
                "name": "Salem Five - Corporate",
                "website": "https://www.salemfive.com/",
            },
            {
                "code": "san_luis_valley_federal_bank",
                "name": "San Luis Valley Federal Bank",
                "website": "https://www.slvfed.bank/",
            },
            {
                "code": "sandhills_bank",
                "name": "Sandhills Bank",
                "website": "https://www.sandhillsbank.com/",
            },
            {
                "code": "sanibel_captiva_community_bank",
                "name": "Sanibel Captiva Community Bank",
                "website": "https://sancapbank.com/",
            },
            {
                "code": "saratoga_national_banktrust_company_",
                "name": "Saratoga National Bank & Trust Company ",
                "website": "https://www.saratoganationalonline.com",
            },
            {
                "code": "schaumburg_banktrust_company",
                "name": "Schaumburg Bank & Trust Company",
                "website": "https://cibng.ibanking-services.com/EamWeb/Account/Login.aspx?orgId=318_071926197&FIFID=071926197&brand=318_071926197&appId=CeB&FIORG=318",
            },
            {
                "code": "schools_first_fcu",
                "name": "Schools First FCU",
                "website": "https://www.schoolsfirstfcu.org/",
            },
            {
                "code": "sea_west_coast_guard_fcu",
                "name": "Sea West Coast Guard FCU",
                "website": "https://seawest.coop/",
            },
            {
                "code": "seaside_national_banktrust",
                "name": "Seaside National Bank & Trust",
                "website": "https://www.seasidebank.com/",
            },
            {
                "code": "security_first_bank",
                "name": "Security First Bank",
                "website": "https://security1stbank.com",
            },
            {
                "code": "severn_savings_bankbusiness",
                "name": "Severn Savings Bank - Business",
                "website": "https://severnbank.com/",
            },
            {
                "code": "smartbank",
                "name": "SmartBank",
                "website": "https://www.smartbank.com",
            },
            {
                "code": "snb_bank_national_associationpersonal",
                "name": "SNB Bank National Association - Personal",
                "website": "https://www.snbbankna.com/",
            },
            {
                "code": "somerset_savings_bank",
                "name": "Somerset Savings Bank",
                "website": "https://www.somersetsavings.com/home/home",
            },
            {
                "code": "south_georgia_banking_company",
                "name": "South Georgia Banking Company",
                "website": "https://www.sgbconline.com",
            },
            {
                "code": "south_shore_bank",
                "name": "South Shore Bank",
                "website": "https://www.southshorebank.com/",
            },
            {
                "code": "south_state_bankonline_banking",
                "name": "South State Bank - Online Banking",
                "website": "https://www.southstatebank.com/",
            },
            {
                "code": "southern_first_bankpersonal_online_banking",
                "name": "Southern First Bank - Personal Online Banking",
                "website": "https://www.southernfirst.com",
            },
            {
                "code": "southwestern_national_bankoklahoma_",
                "name": "Southwestern National Bank (Oklahoma)",
                "website": "http://www.swnbank.com/",
            },
            {
                "code": "springs_valley_banktrust_company",
                "name": "Springs Valley Bank & Trust Company",
                "website": "https://www.svbt.bank/",
            },
            {
                "code": "stanford_federal_credit_union",
                "name": "Stanford Federal Credit Union",
                "website": "https://www.sfcu.org/",
            },
            {
                "code": "stanne_s_credit_union",
                "name": "St. Anne's Credit Union",
                "website": "https://www.stannes.com/",
            },
            {
                "code": "stcharles_banktrust_companycommunity_banking",
                "name": "St. Charles Bank & Trust Company - .COMmunity Banking",
                "website": "https://cibng.ibanking-services.com/EamWeb/Account/Login.aspx?orgId=529_071926650&FIFID=071926650&brand=529_071926650&appId=CeB&FIORG=529",
            },
            {
                "code": "personal",
                "name": "- Personal",
                "website": "http://www.stmaryscu.org/",
            },
            {
                "code": "starion_financial",
                "name": "Starion Financial",
                "website": "https://starionbank.com/",
            },
            {
                "code": "state_bank_of_the_lakes",
                "name": "State Bank of the Lakes",
                "website": "https://cibng.ibanking-services.com/EamWeb/Account/Login.aspx?brand=669_071909871&orgId=669_071909871&FIFID=071909871&appId=CeB&FIORG=669",
            },
            {
                "code": "state_department_federal_credit_union",
                "name": "State Department Federal Credit Union",
                "website": "https://www.sdfcu.org/",
            },
            {
                "code": "stephenson_national_banktrusttrust_online",
                "name": "Stephenson National Bank & Trust - Trust Online",
                "website": "https://www.snbt.com/",
            },
            {
                "code": "sterling_national_bankbusiness",
                "name": "Sterling National Bank - Business",
                "website": "https://www.snb.com/",
            },
            {
                "code": "sullivan_bank",
                "name": "Sullivan Bank",
                "website": "https://www.sullivanbank.com/",
            },
            {
                "code": "suntrust",
                "name": "SunTrust",
                "website": "https://www.suntrust.com",
            },
            {
                "code": "surrey_banktrust",
                "name": "Surrey Bank & Trust",
                "website": "http://www.surreybank.com",
            },
            {
                "code": "tapco_credit_union",
                "name": "TAPCO Credit Union",
                "website": "https://www.tapcocu.org/",
            },
            {
                "code": "tbk_bankpersonal_",
                "name": "TBK Bank (Personal)",
                "website": "https://www.tbkbank.com/",
            },
            {
                "code": "td_bank",
                "name": "TD Bank",
                "website": "https://www.tdbank.com",
            },
            {
                "code": "terrabank",
                "name": "Terrabank",
                "website": "https://terrabank.com",
            },
            {
                "code": "territorial_savings_bank",
                "name": "Territorial Savings Bank",
                "website": "https://www.territorialsavings.net/",
            },
            {
                "code": "texas_regional_bank",
                "name": "Texas Regional Bank",
                "website": "https://www.texasregionalbank.com/",
            },
            {
                "code": "the_bank",
                "name": "The Bank",
                "website": "https://www.thebank-online.org",
            },
            {
                "code": "the_bank_of_hemet",
                "name": "The Bank of Hemet",
                "website": "https://www.bankofhemet.com/",
            },
            {
                "code": "the_callaway_bankpersonal",
                "name": "The Callaway Bank - Personal",
                "website": "http://www.callawaybank.com/",
            },
            {
                "code": "the_farmers_national_bank_of_emlentonpapersonal",
                "name": "The Farmers National Bank of Emlenton (PA) - Personal",
                "website": "http://www.farmersnb.com/",
            },
            {
                "code": "the_federal_savings_bankpersonal",
                "name": "The Federal Savings Bank - Personal",
                "website": "http://www.thefederalsavingsbank.com/",
            },
            {
                "code": "the_marblehead_bankmapersonal",
                "name": " The Marblehead Bank (MA) - Personal",
                "website": "https://www.marbleheadbank.com/",
            },
            {
                "code": "the_savings_bankmapersonal",
                "name": "The Savings Bank (MA) - Personal",
                "website": "https://www.tsbawake24.com/",
            },
            {
                "code": "the_state_bank",
                "name": "The State Bank",
                "website": "https://www.thestatebank.com/",
            },
            {
                "code": "the_state_bank_group",
                "name": "The State Bank Group",
                "website": "https://www.thestatebankgroup.com/",
            },
            {
                "code": "the_union_bank_company",
                "name": "The Union Bank Company",
                "website": "https://www.theubank.com/",
            },
            {
                "code": "the_westchester_bankbusiness",
                "name": "The Westchester Bank - Business",
                "website": "https://www.thewestchesterbank.com/",
            },
            {
                "code": "thomaston_savings_banktsbdan_online_banking_",
                "name": "Thomaston Savings Bank - TSBDaN Online Banking ",
                "website": "http://www.thomastonsavingsbank.com/",
            },
            {
                "code": "tiaa_bank",
                "name": "TIAA Bank",
                "website": "https://auth.tiaa.org/public/authentication/securelogin",
            },
            {
                "code": "timberline_bankpersonal",
                "name": "Timberline Bank - Personal",
                "website": "http://www.timberlinebank.com/",
            },
            {
                "code": "touchstone_bank",
                "name": "Touchstone Bank",
                "website": "https://www.touchstone.bank/",
            },
            {
                "code": "towncountry_bank_and_trust_coky_",
                "name": "Town & Country Bank and Trust Co (KY)",
                "website": "https://www.mytcbt.bank/",
            },
            {
                "code": "town_bankwibusiness",
                "name": "Town Bank (WI) - Business",
                "website": "https://www.townbank.us/",
            },
            {
                "code": "tradition_capital_bankpersonal",
                "name": "Tradition Capital Bank - Personal",
                "website": "https://www.tradition.bank",
            },
            {
                "code": "travis_credit_union",
                "name": "Travis Credit Union",
                "website": "https://www.traviscu.org/index.aspx",
            },
            {
                "code": "tri_city_national_bank",
                "name": "Tri City National Bank",
                "website": "https://cibng.ibanking-services.com/EamWeb/Account/Login.aspx?brand=855_075001199&orgId=855_075001199&FIFID=075001199&appId=CeB&FIORG=855",
            },
            {
                "code": "troy_banktrustonline_banking",
                "name": "Troy Bank & Trust - Online Banking",
                "website": "https://www.troybankandtrust.com/",
            },
            {
                "code": "tucoemas_federal_credit_union",
                "name": "Tucoemas Federal Credit Union",
                "website": "https://www.tucoemas.org",
            },
            {
                "code": "tucson_federal_credit_union",
                "name": "Tucson Federal Credit Union",
                "website": "https://www.tucsonfcusecure.com",
            },
            {
                "code": "tyndall_federal_credit_union",
                "name": "Tyndall Federal Credit Union",
                "website": "https://tyndall.org",
            },
            {
                "code": "us_bank",
                "name": "US Bank",
                "website": "https://www.usbank.com/",
            },
            {
                "code": "u_scentury_bank",
                "name": "U.S. Century Bank",
                "website": "https://www.uscentury.com/",
            },
            {
                "code": "ulster_savings_bankonline",
                "name": "Ulster Savings Bank - Online",
                "website": "https://www.ulstersavings.com",
            },
            {
                "code": "umpqua_bank",
                "name": "Umpqua Bank",
                "website": "https://umpquabank.com/",
            },
            {
                "code": "union_banktrust_coar_",
                "name": "Union Bank & Trust Co. (AR)",
                "website": "https://www.unionbnk.com",
            },
            {
                "code": "united_bankpersonal",
                "name": "United Bank - Personal",
                "website": "https://www.bankwithunited.com",
            },
            {
                "code": "united_bank_of_michiganpersonal",
                "name": "United Bank of Michigan - Personal",
                "website": "https://www.unitedbank4u.com/",
            },
            {
                "code": "united_community_bank",
                "name": "United Community Bank",
                "website": "https://www.ucbbank.com",
            },
            {
                "code": "united_fidelity_bankpersonal",
                "name": "United Fidelity Bank - Personal",
                "website": "http://www.unitedfidelity.com/index.php",
            },
            {
                "code": "united_southern_bank",
                "name": "United Southern Bank",
                "website": "https://web3.secureinternetbank.com/ebc_ebc1961/ebc1961.asp?wci=process&wce=request&rid=3000&rtn=063105285&rt=063105285&mfa=2",
            },
            {
                "code": "universal_bank",
                "name": "Universal Bank",
                "website": "https://www.universalbank.com/",
            },
            {
                "code": "us_community_credit_union",
                "name": "US Community Credit Union",
                "website": "https://www.usccu.org/",
            },
            {
                "code": "usaa",
                "name": "USAA",
                "website": "https://www.usaa.com",
            },
            {
                "code": "uw_credit_union",
                "name": "UW Credit Union",
                "website": "http://www.uwcu.org/",
            },
            {
                "code": "valliance_bank",
                "name": "Valliance Bank",
                "website": "https://vbank.com/",
            },
            {
                "code": "vectra_bank_coloradopersonal_",
                "name": "Vectra Bank Colorado - Personal ",
                "website": "https://www.vectrabank.com/",
            },
            {
                "code": "village_banktrustcommunity_banking",
                "name": "Village Bank & Trust - .COMmunity Banking",
                "website": "https://cibng.ibanking-services.com/EamWeb/Account/Login.aspx?orgId=629_071925460&FIFID=071925460&brand=629_071925460&appId=CeB&FIORG=629",
            },
            {
                "code": "virginia_commonwealth_bank",
                "name": "Virginia Commonwealth Bank",
                "website": "https://www.vcb.bank/",
            },
            {
                "code": "visionbank",
                "name": "VisionBank",
                "website": "http://www.visionbanking.com",
            },
            {
                "code": "vystar_credit_union",
                "name": "VyStar Credit Union",
                "website": "http://www.vystarcu.org",
            },
            {
                "code": "wafd_bank",
                "name": "WaFd Bank",
                "website": "https://www.wafdbank.com/",
            },
            {
                "code": "washington_savings_bank",
                "name": "Washington Savings Bank",
                "website": "https://www.washingtonsavings.net/",
            },
            {
                "code": "washington_savings_bankmapersonal_",
                "name": "Washington Savings Bank (MA) \u2013 Personal ",
                "website": "https://www.washingtonsavings.com/",
            },
            {
                "code": "water_and_power_community_credit_union",
                "name": "Water and Power Community Credit Union",
                "website": "https://www.wpcu.org/",
            },
            {
                "code": "wayne_bank_and_trust_co_",
                "name": "Wayne Bank and Trust Co.",
                "website": "http://www.waynebankonline.com",
            },
            {
                "code": "wells_fargo",
                "name": "Wells Fargo",
                "website": "https://www.wellsfargo.com",
            },
            {
                "code": "weokie_credit_union",
                "name": "WEOKIE Credit Union",
                "website": "https://www.netbranch.app.fiserv.com/WEOKIECU/",
            },
            {
                "code": "wescom_credit_union",
                "name": "Wescom Credit Union",
                "website": "https://www.wescom.org/",
            },
            {
                "code": "west_community_credit_union",
                "name": "West Community Credit Union",
                "website": "https://www.westcommunitycu.org/index.shtml",
            },
            {
                "code": "west_gate_bank_",
                "name": "West Gate Bank ",
                "website": "http://www.westgatebank.com",
            },
            {
                "code": "western_states_bank",
                "name": "Western States Bank",
                "website": "https://wsb.bank/",
            },
            {
                "code": "westmoreland_fcu",
                "name": "Westmoreland FCU",
                "website": "https://www.netbranch.app.fiserv.com/westmorelandfcu/Default.aspx",
            },
            {
                "code": "wheaton_banktrust_company",
                "name": "Wheaton Bank & Trust Company",
                "website": "https://cibng.ibanking-services.com/EamWeb/Account/Login.aspx?orgId=291_071925389&FIFID=071925389&brand=291_071925389&appId=CeB&FIORG=291",
            },
            {
                "code": "windsor_federal_savings",
                "name": "Windsor Federal Savings",
                "website": "https://secure.myvirtualbranch.com/windsorfederal6319/signin.aspx",
            },
            {
                "code": "wintrust_bank",
                "name": "Wintrust Bank",
                "website": "http://www.wintrustbank.com/",
            },
            {
                "code": "woodlands_bankpa_",
                "name": "Woodlands Bank (PA)",
                "website": "https://olb.woodlandsbank.com/LoginAdv.aspx?",
            },
            {
                "code": "wrentham_co_operative_bankpersonal",
                "name": "Wrentham Co-operative Bank - Personal",
                "website": "http://www.wrenthamcoop.com",
            },
            {
                "code": "wsfs_bankpersonal",
                "name": "WSFS Bank - Personal",
                "website": "https://www.wsfsbank.com/",
            },
            {
                "code": "wyoming_bankcheyenneburns_wipersonal_",
                "name": "\"Wyoming Bank (Cheyenne, Burns WI) - Personal\"",
                "website": "https://www.wyomingbank.bank/",
            },
            {
                "code": "yakima_federal_savingsloan",
                "name": "Yakima Federal Savings & Loan",
                "website": "http://www.yakimafed.com",
            },
            {
                "code": "yampa_valley_bank",
                "name": "Yampa Valley Bank",
                "website": "https://www.yampavalleybank.com/default.aspx",
            },
            {
                "code": "zions_bankbusiness_banking",
                "name": "Zions Bank - Business Banking",
                "website": "https://www.zionsbank.com/",
            },
            {
                "code": "st_bank_of_sea_isle_citypersonal",
                "name": "st Bank of Sea Isle City - Personal",
                "website": "https://www.1stbankseaisle.com/",
            },
            {
                "code": "st_source_bankfirst_source_retirement_plan_services_",
                "name": "st Source Bank (First Source Retirement Plan Services)",
                "website": "https://www.1stsource.com/",
            },
            {
                "code": "st_trust_bank",
                "name": " st Trust Bank",
                "website": "https://www.1sttrustbankinc.com/",
            },
        ],
        "can_trade_with": [
            'NGN', 'GHS', 'KES', 'ZAR',
        ],
        "is_poster_from_currency": false,
        "is_matcher_from_currency": true,
        "object": {
            "paypal_id": "email_address",
        },
    },
    {
        "currency_code": "GBP",
        "currency_name": "british pound",
        "symbol": '£',
        "flag": "gbp.svg",
        "max_amount": 1000,
        "min_amount": 50,
        "steps": 50,
        "payment_types": [
            {
                code: "paypal_payment",
                name: "Paypal",
            },
            {
                code: "faster_payment",
                name: "Faster",
            },
        ],
        "can_trade_with": [
            'NGN', 'GHS', 'KES', 'ZAR',
        ],
        "is_poster_from_currency": false,
        "is_matcher_from_currency": true,
        "object": {
            "paypal_id": "email_address",
        },
    },
    {
        "currency_code": "NGN",
        "currency_name": "nigerian naira",
        "country_name": "Nigeria",
        "symbol": '₦',
        "flag": "ngn.svg",
        "max_amount": 300000,
        "min_amount": 30000,
        "steps": 1000,
        "payment_types": [
            {
                code: "nigerian_bank_payment",
                name: "Nigerian Bank",
            },
        ],
        "can_trade_with": [
            'CAD', 'EUR', 'USD', 'GBP', 'SEK',
        ],
        "is_poster_from_currency": true,
        "is_matcher_from_currency": false,
        "object": {
            "account_name": "",
            "account_number": "",
            "bank_code": "",
        },
    },
    {
        "currency_code": "GHS",
        "currency_name": "Ghana",
        "country_name": "Ghana",
        "flag": "ghana-flag.png",
        "symbol": '₵',
        "max_amount": 10000,
        "min_amount": 500,
        "steps": 500,
        "payment_types": [
            {
                code: "ghana_bank_payment",
                name: "Ghanian Bank",
            },
        ],
        "can_trade_with": [
            'CAD', 'EUR', 'USD', 'GBP',
        ],
        "is_poster_from_currency": true,
        "is_matcher_from_currency": false,
        "object": {
            "account_name": "",
            "account_number": "",
            "bank_code": "",
        },
    },
    {
        "currency_name": "swedish krona",
        "currency_code": "SEK",
        "symbol": 'kr',
        "flag": "sek.svg",
        "max_amount": 9000,
        "min_amount": 450,
        "steps": 450,
        "payment_types": [
            {
                code: "sepa_payment",
                name: "Sepa",
            },
        ],
        "can_trade_with": [
            'NGN', 'GHS', 'KES', 'ZAR',
        ],
        "is_poster_from_currency": false,
        "is_matcher_from_currency": true,
    },
    {
        "currency_code": "KES",
        "currency_name": "Kenya",
        "country_name": "Kenya",
        "flag": "kes.svg",
        "symbol": 'K',
        "max_amount": 150000,
        "min_amount": 5000,
        "steps": 5000,
        "payment_types": [
            {
                code: "kenya_bank_payment",
                name: "Kenya Bank",
            },
        ],
        "can_trade_with": [
            'CAD', 'EUR', 'USD', 'GBP',
        ],
        "is_poster_from_currency": true,
        "is_matcher_from_currency": false,
        "object": {
            "account_name": "",
            "account_number": "",
            "bank_code": "",
        },
    },
    {
        "currency_code": "ZAR",
        "currency_name": "South Africa",
        "country_name": "South Africa",
        "flag": "zar.svg",
        "symbol": 'R',
        "max_amount": 10000,
        "min_amount": 500,
        "steps": 500,
        "payment_types": [
            {
                code: "south_africa_payment",
                name: "South Africa Bank",
            },
        ],
        "can_trade_with": [
            'CAD', 'EUR', 'USD', 'GBP',
        ],
        "is_poster_from_currency": true,
        "is_matcher_from_currency": false,
        "object": {
            "account_name": "",
            "account_number": "",
            "bank_code": "",
        },
    },
    {
        "currency_name": "danish krona",
        "currency_code": "DKK",
        "symbol": 'kr',
        "flag": "dkk.svg",
        "max_amount": 7000,
        "min_amount": 350,
        "steps": 350,
        "payment_types": [
            {
                code: "sepa_payment",
                name: "Sepa",
            },
        ],
        "can_trade_with": [
            'NGN', 'GHS', 'KES', 'ZAR',
        ],
        "is_poster_from_currency": false,
        "is_matcher_from_currency": true,
        "object": {
            "paypal_id": "email_address",
        },
    },
    {
        "currency_name": "Australian dollar",
        "currency_code": "AUD",
        "symbol": '$',
        "flag": "aud.svg",
        "max_amount": 1000,
        "min_amount": 50,
        "steps": 50,
        "payment_types": [
            {
                code: "sepa_payment",
                name: "Sepa",
            },
        ],
        "can_trade_with": [
            'NGN', 'GHS', 'KES', 'ZAR',
        ],
        "is_poster_from_currency": false,
        "is_matcher_from_currency": true,
    },
    {
        "currency_name": "Japanese yen",
        "currency_code": "JPY",
        "symbol": '¥',
        "flag": "jpy.svg",
        "max_amount": 200000,
        "min_amount": 10000,
        "steps": 10000,
        "payment_types": [
            {
                code: "sepa_payment",
                name: "Sepa",
            },
        ],
        "can_trade_with": [
            'NGN', 'GHS', 'KES', 'ZAR',
        ],
        "is_poster_from_currency": false,
        "is_matcher_from_currency": true,
    },
    {
        "currency_name": "Hong Kong dollar",
        "currency_code": "HKD",
        "symbol": 'HK$',
        "flag": "hkd.svg",
        "max_amount": 9000,
        "min_amount": 450,
        "steps": 450,
        "payment_types": [
            {
                code: "sepa_payment",
                name: "Sepa",
            },
        ],
        "can_trade_with": [
            'NGN', 'GHS', 'KES', 'ZAR',
        ],
        "is_poster_from_currency": false,
        "is_matcher_from_currency": true,
    },
    {
        "currency_name": "Singapore dollar",
        "currency_code": "SGD",
        "symbol": 'S$',
        "flag": "sgd.svg",
        "max_amount": 2000,
        "min_amount": 100,
        "steps": 100,
        "payment_types": [
            {
                code: "sepa_payment",
                name: "Sepa",
            },
        ],
        "can_trade_with": [
            'NGN', 'GHS', 'KES', 'ZAR',
        ],
        "is_poster_from_currency": false,
        "is_matcher_from_currency": true,
    },
    {
        "currency_name": "Malaysian ringgit",
        "currency_code": "MYR",
        "symbol": 'RM',
        "flag": "myr.svg",
        "max_amount": 5000,
        "min_amount": 250,
        "steps": 250,
        "payment_types": [
            {
                code: "sepa_payment",
                name: "Sepa",
            },
        ],
        "can_trade_with": [
            'NGN', 'GHS', 'KES', 'ZAR',
        ],
        "is_poster_from_currency": false,
        "is_matcher_from_currency": true,
    },
    {
        "currency_name": "Hungarian forint",
        "currency_code": "HUF",
        "symbol": 'Ft',
        "flag": "huf.svg",
        "max_amount": 300000,
        "min_amount": 15000,
        "steps": 15000,
        "payment_types": [
            {
                code: "sepa_payment",
                name: "Sepa",
            },
        ],
        "can_trade_with": [
            'NGN', 'GHS', 'KES', 'ZAR',
        ],
        "is_poster_from_currency": false,
        "is_matcher_from_currency": true,
    },
    {
        "currency_name": "Swiss franc",
        "currency_code": "CHF",
        "symbol": 'Fr',
        "flag": "chf.svg",
        "max_amount": 1000,
        "min_amount": 50,
        "steps": 50,
        "payment_types": [
            {
                code: "sepa_payment",
                name: "Sepa",
            },
        ],
        "can_trade_with": [
            'NGN', 'GHS', 'KES', 'ZAR',
        ],
        "is_poster_from_currency": false,
        "is_matcher_from_currency": true,
    },
    {
        "currency_name": "Croatian kuna",
        "currency_code": "HRK",
        "symbol": 'kr',
        "flag": "hrk.svg",
        "max_amount": 7000,
        "min_amount": 350,
        "steps": 350,
        "payment_types": [
            {
                code: "sepa_payment",
                name: "Sepa",
            },
        ],
        "can_trade_with": [
            'NGN', 'GHS', 'KES', 'ZAR',
        ],
        "is_poster_from_currency": false,
        "is_matcher_from_currency": true,
    },
    {
        "currency_name": "Czech koruna",
        "currency_code": "CZK",
        "symbol": 'Kč',
        "flag": "czk.svg",
        "max_amount": 3000,
        "min_amount": 150,
        "steps": 150,
        "payment_types": [
            {
                code: "sepa_payment",
                name: "Sepa",
            },
        ],
        "can_trade_with": [
            'NGN', 'GHS', 'KES', 'ZAR',
        ],
        "is_poster_from_currency": false,
        "is_matcher_from_currency": true,
    },
    {
        "currency_name": "Bulgarian lev",
        "currency_code": "BGN",
        "symbol": 'лв',
        "flag": "bgn.svg",
        "max_amount": 2000,
        "min_amount": 100,
        "steps": 100,
        "payment_types": [
            {
                code: "sepa_payment",
                name: "Sepa",
            },
        ],
        "can_trade_with": [
            'NGN', 'GHS', 'KES', 'ZAR',
        ],
        "is_poster_from_currency": false,
        "is_matcher_from_currency": true,
    },
    {
        "currency_name": "Icelandic króna",
        "currency_code": "ISK",
        "symbol": 'kr',
        "flag": "isk.svg",
        "max_amount": 150000,
        "min_amount": 7500,
        "steps": 7500,
        "payment_types": [
            {
                code: "sepa_payment",
                name: "Sepa",
            },
        ],
        "can_trade_with": [
            'NGN', 'GHS', 'KES', 'ZAR',
        ],
        "is_poster_from_currency": false,
        "is_matcher_from_currency": true,
    },
    {
        "currency_name": "New Zealand dollar",
        "currency_code": "NZD",
        "symbol": 'NZ$',
        "flag": "nzd.svg",
        "max_amount": 2000,
        "min_amount": 100,
        "steps": 100,
        "payment_types": [
            {
                code: "sepa_payment",
                name: "Sepa",
            },
        ],
        "can_trade_with": [
            'NGN', 'GHS', 'KES', 'ZAR',
        ],
        "is_poster_from_currency": false,
        "is_matcher_from_currency": true,
    },
    {
        "currency_name": "Norwegian krone",
        "currency_code": "NOK",
        "symbol": 'kr',
        "flag": "nok.svg",
        "max_amount": 9000,
        "min_amount": 450,
        "steps": 450,
        "payment_types": [
            {
                code: "sepa_payment",
                name: "Sepa",
            },
        ],
        "can_trade_with": [
            'NGN', 'GHS', 'KES', 'ZAR',
        ],
        "is_poster_from_currency": false,
        "is_matcher_from_currency": true,
    },
    {
        "currency_name": "Polish Zloty",
        "currency_code": "PLN",
        "symbol": 'zł',
        "flag": "pln.svg",
        "max_amount": 4000,
        "min_amount": 200,
        "steps": 200,
        "payment_types": [
            {
                code: "sepa_payment",
                name: "Sepa",
            },
        ],
        "can_trade_with": [
            'NGN', 'GHS', 'KES', 'ZAR',
        ],
        "is_poster_from_currency": false,
        "is_matcher_from_currency": true,
    },
    {
        "currency_name": "Romanian leu",
        "currency_code": "RON",
        "symbol": 'L',
        "flag": "ron.svg",
        "max_amount": 5000,
        "min_amount": 250,
        "steps": 250,
        "payment_types": [
            {
                code: "sepa_payment",
                name: "Sepa",
            },
        ],
        "can_trade_with": [
            'NGN', 'GHS', 'KES', 'ZAR',
        ],
        "is_poster_from_currency": false,
        "is_matcher_from_currency": true,
    },
    {
        "currency_name": "Gibraltar pound",
        "currency_code": "GIP",
        "symbol": '£',
        "flag": "gip.svg",
        "max_amount": 1000,
        "min_amount": 50,
        "steps": 50,
        "payment_types": [
            {
                code: "sepa_payment",
                name: "Sepa",
            },
        ],
        "can_trade_with": [
            'NGN', 'GHS', 'KES', 'ZAR',
        ],
        "is_poster_from_currency": false,
        "is_matcher_from_currency": true,
    },
    {
        "currency_code": "AED",
        "country_code": "AE",
        "currency_name": "United Arab Emirates Dirham",
        "country_name": "United Arab Emirates",
        "flag": "aed.svg",
        "symbol": "د.إ",
        "max_amount": 9000,
        "min_amount": 1750,
        "steps": 250,
        "payment_types": [],
        ...commonPart,
    },
    {
        "currency_code": "CNY",
        "country_code": "CN",
        "currency_name": "Chinese Yuan",
        "country_name": "China",
        "flag": "cny.svg",
        "symbol": '¥',
        "max_amount": 10000,
        "min_amount": 3500,
        "steps": 500,
        ...commonPart,
    },
    {
        "currency_code": "INR",
        "country_code": "IN",
        "currency_name": "Indian Rupee",
        "country_name": "China",
        "flag": "inr.svg",
        "symbol": "₹",
        "max_amount": 120000,
        "min_amount": 40000,
        "steps": 5000,
        ...commonPart,
    },
    {
        "currency_code": "TRY",
        "country_code": "TR",
        "currency_name": "Turkish Lira",
        "country_name": "China",
        "flag": "try.svg",
        "symbol": "₺",
        "max_amount": 39000,
        "min_amount": 13000,
        "steps": 2000,
        ...commonPart,
    },
    {
        "currency_code": "BRL",
        "country_code": "BR",
        "currency_name": "Brazilian Real",
        "country_name": "Brazil",
        "flag": "brl.svg",
        "symbol": "R$",
        "max_amount": 10000,
        "min_amount": 250,
        "steps": 250,
        ...commonPart,
    },

];

export const exchangeRates = {
    "rates": {
        "GBP": 0.91304,
        "CAD": 1.61538,
        "USD": 1.19132,
        "NGN": 525,
        "GHS": 8,
        "EUR": 1,
        "SEK": 10.34,
        "KES": 130.40,
        "ZAR": 18.10,
    },
    "base": "EUR",
    "margin": 0.05,
};

export function getCurrencySymbol(currency_code) {
    const [currency] = currencies.filter(
        (item) => item.currency_code === currency_code,
    );
    if (currency) {
        return currency.symbol || '';
    }
    return currency_code;
}

export function findCurrency(currencyCode) {
    const foundCurrency = currencies.find((currency) => currency.currency_code === currencyCode);

    return foundCurrency === undefined ? {} : foundCurrency;
}

export function isAStronger(currencyA, currencyB) {
    if (!(exchangeRates.rates[currencyA] && exchangeRates.rates[currencyB])) {
        return null;
    }
    return exchangeRates.rates[currencyA] < exchangeRates.rates[currencyB];
}

export function compare(fromCurrency, toCurrency) {
    const suggestedExchangeRate = getSuggestedExchangeRate();
    const suggestedExchangeRateRange = getSuggestedExchangeRateRange();

    return {
        suggestedExchangeRate,
        lowerBoundsOfSuggestedRange: lowerBoundsOfSuggestedRange(),
        upperBoundsOfSuggestedRange: upperBoundsOfSuggestedRange(),
        fromCurrencyIsLessThanToCurrency: fromCurrencyIsLessThanToCurrency(),
    };

    function getSuggestedExchangeRateRange() {
        const { currency_code } = fromCurrency;
        if (currency_code === 'EUR') return 0.028;
        if (currency_code === 'USD') return 0.09022;
        if (currency_code === 'GBP') return 0.044;
        if (currency_code === 'CAD') return 0.046;
        if (currency_code === 'SEK') return 0.0935;
    }

    function getSuggestedExchangeRate() {
        const fromRate = exchangeRates.rates[fromCurrency.currency_code];
        const toRate = exchangeRates.rates[toCurrency.currency_code];
        return parseFloat(toRate) / parseFloat(fromRate);
    }

    function lowerBoundsOfSuggestedRange() {
        return parseFloat(suggestedExchangeRate) - (parseFloat(suggestedExchangeRate) * parseFloat(suggestedExchangeRateRange));
    }

    function upperBoundsOfSuggestedRange() {
        return parseFloat(suggestedExchangeRate) + (parseFloat(suggestedExchangeRate) * parseFloat(suggestedExchangeRateRange));
    }

    function fromCurrencyIsLessThanToCurrency() {
        return suggestedExchangeRate < 1;
    }
}

export function addFlagLink(currency) {
    currency.flagLink = currency.flag === undefined ? '' : flagLink(currency);
    return currency;

    function flagLink(curr) {
        return `/images/countries/${curr.flag}`;
    }
}

/**
 * Generate Currency steps
 *
 * @param {String} currency Currency Code
 * @param {Number} options.min_amount Override currency min amount
 * @param {Number} options.max_amount Override currency max amount
 */
export function addCurrencySteps(currency, {
    min_amount = null,
    max_amount = null,
} = {}) {
    currency.currencySteps = range(
        min_amount || currency.min_amount,
        (max_amount || currency.max_amount) + currency.steps,
        currency.steps,
    );
    return currency;
}
