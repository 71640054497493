var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column"},[_c('v-row',{staticClass:"px-12 justify-end align-center"},[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"auto"}},[_c('small',{staticClass:"tw-text-secondary"},[_vm._v("Poster Amount")]),_c('p',{staticClass:"text-h5 font-weight-black font-italic tw-text-primary mb-0"},[_vm._v(" "+_vm._s(_vm.transactionsStatistic.total_poster_amount)+" ")])]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"auto"}},[_c('small',{staticClass:"tw-text-secondary"},[_vm._v("Matcher Amount")]),_c('p',{staticClass:"text-h5 font-weight-black font-italic tw-text-primary mb-0"},[_vm._v(" "+_vm._s(_vm.transactionsStatistic.total_matcher_amount)+" ")])]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"auto"}},[_c('small',{staticClass:"tw-text-secondary"},[_vm._v("Poster Fee")]),_c('p',{staticClass:"text-h5 font-weight-black font-italic tw-text-primary mb-0"},[_vm._v(" "+_vm._s(_vm.transactionsStatistic.total_poster_fee)+" ")])])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('SortDropdown')],1)],1),_c('v-data-table',{staticClass:"px-12 py-4 tw-bg-transparent custom-table",attrs:{"item-key":"id","headers":_vm.headers,"items":_vm.transactions,"loading":_vm.loading,"items-per-page":_vm.pagination.pageSize,"hide-default-footer":"","single-expand":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('transaction-row',{attrs:{"item":item,"headers":headers},on:{"row-clicked":function($event){return expand(!isExpanded)}},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tw-cursor-pointer"},[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(item.id))])])]}},{key:"poster_amount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column tw-cursor-pointer"},[_c('span',[_vm._v(_vm._s(_vm._f("price")(item.poster_amount)))]),_c('span',{staticClass:"tw-text-black"},[_vm._v(_vm._s(item.poster_currency))])])]}},{key:"matcher_amount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column tw-cursor-pointer"},[_c('span',[_vm._v(_vm._s(_vm._f("price")(item.matcher_amount)))]),_c('span',{staticClass:"tw-text-black"},[_vm._v(_vm._s(item.matcher_currency))])])]}},{key:"rate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tw-cursor-pointer"},[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm._f("price")(item.exchange_rate)))])])]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [(item.posted_at)?_c('div',{staticClass:"d-flex flex-column tw-cursor-pointer"},[_c('span',[_vm._v(_vm._s(_vm._f("date")(item.created_at)))]),_c('span',[_vm._v("at "+_vm._s(_vm._f("time")(item.created_at)))])]):_vm._e()]}},{key:"completed_at",fn:function(ref){
var item = ref.item;
return [(item.completed_at)?_c('div',{staticClass:"d-flex flex-column tw-cursor-pointer"},[_c('span',[_vm._v(_vm._s(_vm._f("date")(item.completed_at)))]),_c('span',[_vm._v("at "+_vm._s(_vm._f("time")(item.completed_at)))])]):_vm._e()]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('status',{attrs:{"item":item}})]}}],null,true)})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length,"transition":"slide-y-transition"}},[_c('transaction-detail',{attrs:{"id":item.id}})],1)]}}])}),_c('v-pagination',{staticClass:"custom-pagination",attrs:{"value":_vm.pagination.page,"next-icon":"$next","prev-icon":"$prev","color":"#7598B5","total-visible":5,"length":_vm.pagination.pageCount},on:{"input":_vm.onPageChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }