<template>
   <v-row class="ma-0">
        <v-col cols=12>
            <v-row class="mt-8">
                    <v-card
                        class="mx-auto pl-7 pr-7 pt-5 pb-5 tw-border-none"
                        id="loginCard"
                        max-width="500"
                        outlined
                    >
                        <v-card-title id="logTitle" class="justify-center"> Reset your password</v-card-title>
                        <v-card-subtitle class="justify-center mt-5">
                            <p class="text-center font-15 reset__subtitle">Please enter the email address you'd like your password reset information sent to.</p>
                        </v-card-subtitle>
                        <v-card-text>
                            <form>
                                <v-row>
                                    <v-col cols="12" class="d-flex flex-column">
                                        <label class="f-label default-font"> E-mail:</label>
                                        <input
                                            type="email"
                                            v-model="userEmail"
                                            class="f-input default-font pa-2 tw-border-binput focus:tw-border-primary"
                                        />
                                    </v-col>
                                    <v-col cols="12">
                                        <v-btn
                                            color="primary"
                                            x-large
                                            class="remember default-font text-capitalize text-center tw-w-full"
                                            @click="reset"
                                        >
                                            Reset your password
                                        </v-btn>
                                        <v-btn
                                            text
                                            color="dark-black"
                                            class="default-font text-capitalize text-center tw-w-full mt-5"
                                            @click="$router.push('/login')"
                                        >
                                            <v-icon>mdi-arrow-left</v-icon> Back
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </form>
                        </v-card-text>
                    </v-card>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import AuthApi from '../../api/AuthApi';

export default {
    data: () => ({
        userEmail: 'adamsmith@gmail.com',
    }),
    methods: {
        async reset() {
            try {
                const payload = {
                    email: this.userEmail,
                };

                await AuthApi.resetPassword(payload);

                this.$swal({
                    type: 'success',
                    title: 'Request sent',
                    text: 'Click OK to continue',
                    preConfirm: () => {
                        this.$router.push({
                            name: 'Password Reset Instructions',
                        });
                    },
                });
            } catch (error) {
                this.$swal({
                    type: 'error',
                    title: 'Error resetting password',
                    text: error,
                });
            }
        },
    },
};
</script>
<style scoped>
.reset__subtitle {
    line-height: 23px;
    @apply tw-text-black tw-font-semibold
}
</style>
