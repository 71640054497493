<template>
    <v-card class="elevation-12">
        <v-toolbar
            color="indigo"
            class="mb-6"
            dark
            flat
        >
            <v-toolbar-title>Confirm your transaction</v-toolbar-title>
        </v-toolbar>
        <v-card-subtitle>
            You're almost there! Below is the total cost of your transaction.
            After you've verified the information is correct, select confirm to
            post your offer.
        </v-card-subtitle>
        <v-card-text>
            <v-row>
                <v-col>
                    <p class="overline my-0">Recipient</p>
                    <p class="body-1">{{ fullName }}</p>
                </v-col>
                <v-col>
                    <p class="overline my-0">Recipient Gets</p>
                    <p class="body-1">{{getCurrencySymbol(globalOffer.matcher_currency)}}{{ formatAmount(globalOffer.matcher_amount) }}</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <p class="overline my-0">Exchange Rate</p>
                    <p class="body-1"> {{ globalOffer.poster_currency }} {{ formatAmount(globalOffer.exchange_rate) }}</p>
                </v-col>
                <v-col>
                    <p class="overline my-0">Subtotal</p>
                    <v-row no-gutters class="d-flex flex-nowrap align-center">
                        <p class="title flex-grow-0 mb-0">{{ globalOffer.poster_currency }} {{ formatAmount(globalOffer.poster_amount) }}</p>
                        <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="pl-2 dense" color="teal accent-4" size="16">mdi-information-outline</v-icon>
                            </template>
                            <span>{{ amountInfo }}</span>
                        </v-tooltip>
                        <v-spacer></v-spacer>
                    </v-row>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <p class="overline my-0">Fee</p>
                    <p class="body-1 my-0">{{ globalOffer.poster_currency }} {{ formatAmount(feeAmount) }}</p>
                </v-col>
                <v-col>
                    <p class="overline my-0 teal--text text--accent-4"><strong>Total</strong></p>
                    <p class="headline my-0"><strong>{{ globalOffer.poster_currency }} {{totalAmount}}</strong></p>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="pa-6">
            <v-spacer></v-spacer>
            <v-btn
                class="px-6"
                text
                color="red darken-2"
                large
                @click="$emit('cancel')"
            >
                Cancel
            </v-btn>
            <v-btn
                class="px-6"
                color="indigo"
                dark
                depressed
                large
                @click="$emit('confirm')"
            >
                Confirm
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
/* eslint-disable no-use-before-define */
import { getCurrencySymbol } from '../../services/CurrencyService';

export default {
    name: 'confirm-order-modal',
    props: {
        globalOffer: Object,
    },
    methods: getMethods(),
    computed: getComputed(),
};

function getComputed() {
    return {
        totalAmount,
        feeAmount,
        fullName,
        amountInfo() {
            const posterSymbol = this.getCurrencySymbol(this.globalOffer.poster_currency);
            const matcherSymbol = this.getCurrencySymbol(this.globalOffer.matcher_currency);
            const amount = this.currencyFormat(matcherSymbol, this.globalOffer.matcher_amount);
            const rate = this.currencyFormat(posterSymbol, this.globalOffer.exchange_rate);
            return `${rate} x ${amount}`;
        },
    };

    function fullName() {
        const { recipient } = this.globalOffer;

        if (!recipient) return '';

        const {
            firstname,
            lastname,
            firstName,
            lastName,
        } = recipient;

        return [(firstname || firstName), (lastname || lastName)].join(' ');
    }

    function feeAmount() {
        let fee;
        const { poster_currency: posterCurrency } = this.globalOffer;

        if (posterCurrency.toUpperCase() === 'NGN') {
            fee = 2000.00;
        } else {
            const amount = parseFloat(this.globalOffer.poster_amount);
            fee = parseFloat(amount * 0.05);
        }

        return fee;
    }

    function totalAmount() {
        const total = parseFloat(this.globalOffer.poster_amount) + this.feeAmount;
        return this.formatAmount(total);
    }
}

function getMethods() {
    return {
        formatAmount: (amount) => Number(amount).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }),
        // eslint-disable-next-line camelcase
        getCurrencySymbol(currency_code) {
            return getCurrencySymbol(currency_code);
        },
        currencyFormat,
    };

    function currencyFormat(symbol, num) {
        return symbol + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
}
</script>
