<template>
  <div class="tw-flex tw-items-center" v-if="value">
    <file-uploaded :file-name="value.name"></file-uploaded>

    <label class="ml-4">
      <span class="default-font tw-text-black tw-text-xs tw-cursor-pointer">Replace</span>
      <input type="file" class="tw-hidden" @change="loadFile" />
    </label>
  </div>
  <label class="upload-input hover:tw-bg-hoverBg hover:tw-text-black-500 tw-border-dashed" v-else>
    <svg width="20" height="28" viewBox="0 0 20 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.96987 24.8302H3.32584C1.89542 24.8302 0.73584 23.6706 0.73584 22.2402V9.00508L8.32346 1.39771L16.9079 1.39771C18.3383 1.39771 19.4979 2.55729 19.4979 3.98771V22.2402C19.4979 23.6706 18.3383 24.8302 16.9079 24.8302H14.0485"
        stroke="#15ABAB"
      />
      <path
        d="M8.74858 1.51392V7.50116C8.74858 8.54498 7.9024 9.39116 6.85858 9.39116H0.871338"
        stroke="#1E807A"
      />
      <rect x="8.72168" y="15.4861" width="2.79055" height="11.7952" rx="0.5" fill="#15ABAB" />
      <path
        d="M9.67485 12.7807C9.86254 12.4255 10.3713 12.4255 10.559 12.7807L13.5145 18.3737C13.6905 18.7067 13.4491 19.1073 13.0724 19.1073H7.16141C6.78479 19.1073 6.54337 18.7067 6.71933 18.3737L9.67485 12.7807Z"
        fill="#15ABAB"
      />
    </svg>

    <div class="tw-flex tw-flex-col ml-2">
      <span class="default-font tw-text-primary tw-text-sm">Upload</span>
      <span class="default-font tw-text-secondary tw-text-xs">{{subtitle}}</span>
    </div>
    <input type="file" :accept="types" class="tw-hidden" @change="loadFile" />
  </label>
</template>
<script>
import FileUploaded from './FileUploaded.vue';

export default {
    props: {
        types: {
            type: Array,
            default() {
                return ['png', 'pdf', 'jpg'];
            },
        },
        value: {
            default() {
                return null;
            },
        },
    },
    components: { FileUploaded },
    data() {
        return {
            uploadFile: undefined,
        };
    },
    methods: {
        loadFile(e) {
            const {
                target: {
                    files: [file],
                },
            } = e;
            this.$emit('input', file);
        },
    },
    computed: {
        subtitle() {
            return this.types.map((t) => t.toUpperCase()).join(', ');
        },
    },
};
</script>
<style>
.upload-input {
  @apply tw-w-64 tw-flex tw-items-center tw-px-4  tw-py-2 tw-bg-white tw-text-primary tw-rounded-lg tw-box-border tw-tracking-wide tw-border tw-border-binput tw-cursor-pointer;
}
</style>
