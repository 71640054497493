<template>
    <div>
        <v-row class="px-12 py-4 mt-2 mb-0">
            <v-col
                cols="12"
                md="3"
                xl="2">
                <v-select
                    v-model="innerFilters.status"
                    :items="statuses"
                    :menu-props="{
                        bottom: true,
                        offsetY: true,
                        'content-class': 'mt-3 tw-rounded-lg',
                    }"
                    label="Status"
                    hide-details
                    clearable
                    outlined
                    dense
                    @change="updateFilter">
                    <template #selection="{ item }">
                        <span class="default-font filter-title tw-text-black mr-2">
                            {{ item.text }}
                        </span>
                    </template>
                </v-select>
            </v-col>

            <v-col
                cols="12"
                md="3"
                xl="2">
                <v-select
                    v-model="innerFilters.expiry_date"
                    :items="expiryDates"
                    :menu-props="{
                        bottom: true,
                        offsetY: true,
                        'content-class': 'mt-3 tw-rounded-lg',
                    }"
                    label="Expiry Date"
                    hide-details
                    clearable
                    outlined
                    dense
                    @change="updateFilter">
                    <template #selection="{ item }">
                        <span class="default-font filter-title tw-text-black mr-2">
                            {{ item.text }}
                        </span>
                    </template>
                </v-select>
            </v-col>

            <v-col
                v-if="innerFilters.expiry_date === 'month'"
                cols="12"
                md="3"
                xl="2">
                <v-select
                    v-model="innerFilters.expiry_month"
                    :items="months"
                    :menu-props="{
                        bottom: true,
                        offsetY: true,
                        'content-class': 'mt-3 tw-rounded-lg',
                    }"
                    label="Expiry Month"
                    hide-details
                    clearable
                    outlined
                    dense
                    @change="updateFilter"
                />
            </v-col>

            <v-col
                v-if="innerFilters.expiry_date"
                cols="12"
                md="3"
                xl="2">
                <v-select
                    v-model="innerFilters.expiry_year"
                    :items="years"
                    :menu-props="{
                        bottom: true,
                        offsetY: true,
                        'content-class': 'mt-3 tw-rounded-lg',
                    }"
                    label="Expiry Year"
                    hide-details
                    clearable
                    outlined
                    dense
                    @change="updateFilter"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { cloneDeep, range } from 'lodash';

export default {
    props: {
        filters: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        innerFilters: {},
        statuses: [
            {
                text: 'Verified',
                value: 'approved',
            },
            {
                text: 'Not Verified',
                value: 'unapproved',
            },
        ],
        expiryDates: [
            {
                text: 'Month',
                value: 'month',
            },
            {
                text: 'Year',
                value: 'year',
            },
        ],
    }),
    computed: {
        months() {
            return range(1, 13);
        },
        years() {
            const year = new Date().getFullYear();
            return range(year - 21, year + 51);
        },
    },
    methods: {
        async updateFilter() {
            await this.$nextTick();
            this.$emit('update:filters', this.innerFilters);
        },
    },
    watch: {
        filters: {
            immediate: true,
            deep: true,
            handler(val) {
                const clonedFilters = cloneDeep(val);
                this.$set(this, 'innerFilters', clonedFilters);
            },
        },
    },
};
</script>
