<template>
    <v-dialog v-model="visible" width="500px" @click:outside="$emit('close')">
        <v-card>
            <v-card-title>
                <span class="headline">Add New Rate Limit</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form>
                        <v-row class="px-3 py-3 mt-2 mb-0">
                            <v-col cols="12">
                                <v-select
                                    v-model="posterCountry"
                                    :items="countries"
                                    item-value="key"
                                    :menu-props="{ bottom: true, offsetY: true, 'content-class': 'mt-3' }"
                                    class="custom-select"
                                    prefix="From"
                                    return-object
                                    outlined
                                    dense>
                                    <template #item="{ item }">
                                        <span class="default-font font-15 mr-2">
                                            {{ item.country }} ({{ item.currency }})
                                        </span>
                                    </template>

                                    <template #selection="{ item }">
                                        <div v-if="item.locale">
                                            <gb-flag class="mr-1" :code="item.locale" size="small" />
                                            <span class="default-font filter-title font-14">
                                                {{ item.country }} ({{ item.currency }})
                                            </span>
                                        </div>
                                    </template>
                                </v-select>
                            </v-col>

                            <v-col v-if="posterCountry" cols="12">
                                <v-select
                                    v-model="matcherCountry"
                                    :items="countries"
                                    item-value="key"
                                    :menu-props="{ bottom: true, offsetY: true, 'content-class': 'mt-3' }"
                                    class="custom-select"
                                    prefix="To"
                                    return-object
                                    outlined
                                    dense>
                                    <template #item="{ item }">
                                        <span class="default-font font-15 mr-2">
                                            {{ item.country }} ({{ item.currency }})
                                        </span>
                                    </template>

                                    <template #selection="{ item }">
                                        <div v-if="item.locale">
                                            <gb-flag class="mr-1" :code="item.locale" size="small" />
                                            <span class="default-font filter-title font-14">
                                                {{ item.country }} ({{ item.currency }})
                                            </span>
                                        </div>
                                    </template>
                                </v-select>
                            </v-col>

                            <v-col cols="12">
                                <v-text-field
                                    v-model.number="innerModel.min_exchange_rate"
                                    type="number"
                                    prefix="Min Exchange Rate"
                                    outlined
                                    dense
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12">
                                <v-text-field
                                    v-model.number="innerModel.max_exchange_rate"
                                    type="number"
                                    prefix="Max Exchange Rate"
                                    outlined
                                    dense
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12">
                                <v-btn
                                    block
                                    elevation="2"
                                    color="primary"
                                    @click="submit"
                                >
                                    Save
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { find, cloneDeep } from 'lodash';

export default {
    props: {
        visible: {
            type: Boolean,
        },
        countries: {
            type: Array,
            default: () => ([]),
        },
        model: {
            type: Object,
            default: () => ({
                min_exchange_rate: 0,
                max_exchange_rate: 0,
            }),
        },
    },
    data: () => ({
        innerModel: {},
    }),
    computed: {
        posterCountry: {
            get() {
                return find(this.countries, {
                    abbreviation: this.innerModel?.poster_country_abbreviation,
                    currency: this.innerModel?.poster_currency_code,
                });
            },
            set({
                abbreviation,
                currency,
            }) {
                this.$set(this.innerModel, 'poster_country_abbreviation', abbreviation);
                this.$set(this.innerModel, 'poster_currency_code', currency);
            },
        },
        matcherCountry: {
            get() {
                return find(this.countries, {
                    abbreviation: this.innerModel?.matcher_country_abbreviation,
                    currency: this.innerModel?.matcher_currency_code,
                });
            },
            set({
                abbreviation,
                currency,
            }) {
                this.$set(this.innerModel, 'matcher_country_abbreviation', abbreviation);
                this.$set(this.innerModel, 'matcher_currency_code', currency);
            },
        },
    },
    methods: {
        submit() {
            return this.$emit('save', this.innerModel);
        },
    },
    watch: {
        model: {
            immediate: true,
            deep: true,
            handler(val) {
                const clonedModel = cloneDeep(val);
                this.$set(this, 'innerModel', clonedModel);
            },
        },
    },
};
</script>
