/* eslint-disable no-restricted-properties */
/* eslint-disable no-restricted-globals */
export const apiLogs = [
    {
        status: '200',
        description: 'POST  v1 / reporting / report_runs',
        date: '20-10-19 5:23:10',
        action: '',
        id: 'req_JAAmdSKKGpg',
        method: 'POST',
        url: '/v1/reporting/report_runs',
        ip_address: '123.655.35.136 (from server at 45.45.335.351)',
        version: '2019-11-05',
        source: 'Dashboard - useraddress@gmail.com',
        related: 'reporting.report_run - frr_JFgkkfdfgGKm46yKG',
        origin: 'https://dashboard.kaoshi.com',
        request_body: {
            report_type: 'payouts.summary.1',
            parameters: {
                interval_start: '1575158400',
                interva_end: '1575244800',
            },
            include: [
                'reporting_query.result',
            ],
        },
    },
    {
        status: '200',
        description: 'POST  v1 / reporting / report_runs',
        date: '20-10-19 5:23:10',
        action: '',
        id: 'req_JAAmdSKKGpguuj',
        method: 'POST',
        url: '/v1/reporting/report_runs',
        ip_address: '123.655.35.136 (from server at 45.45.335.351)',
        version: '2019-11-05',
        source: 'Dashboard - useraddress@gmail.com',
        related: 'reporting.report_run - frr_JFgkkfdfgGKm46yKG',
        origin: 'https://dashboard.kaoshi.com',
        request_body: {
            report_type: 'payouts.summary.1',
            parameters: {
                interval_start: '1575158400',
                interva_end: '1575244800',
            },
            include: [
                'reporting_query.result',
            ],
        },
    },
    {
        status: '200',
        description: 'POST  v1 / reporting / report_runs',
        date: '20-10-19 5:23:10',
        action: '',
        id: 'req_JAAmdSKKGptghjg',
        method: 'POST',
        url: '/v1/reporting/report_runs',
        ip_address: '123.655.35.136 (from server at 45.45.335.351)',
        version: '2019-11-05',
        source: 'Dashboard - useraddress@gmail.com',
        related: 'reporting.report_run - frr_JFgkkfdfgGKm46yKG',
        origin: 'https://dashboard.kaoshi.com',
        request_body: {
            report_type: 'payouts.summary.1',
            parameters: {
                interval_start: '1575158400',
                interva_end: '1575244800',
            },
            include: [
                'reporting_query.result',
            ],
        },
    },
    {
        status: '200',
        description: 'POST  v1 / reporting / report_runs',
        date: '20-10-19 5:23:10',
        action: '',
        id: 'req_JAAmdSKKGpghj',
        method: 'POST',
        url: '/v1/reporting/report_runs',
        ip_address: '123.655.35.136 (from server at 45.45.335.351)',
        version: '2019-11-05',
        source: 'Dashboard - useraddress@gmail.com',
        related: 'reporting.report_run - frr_JFgkkfdfgGKm46yKG',
        origin: 'https://dashboard.kaoshi.com',
        request_body: {
            report_type: 'payouts.summary.1',
            parameters: {
                interval_start: '1575158400',
                interva_end: '1575244800',
            },
            include: [
                'reporting_query.result',
            ],
        },
    },
    {
        status: '200',
        description: 'POST  v1 / reporting / report_runs',
        date: '20-10-19 5:23:10',
        action: '',
        id: 'req_JAAmdSKKGpgfghj',
        method: 'POST',
        url: '/v1/reporting/report_runs',
        ip_address: '123.655.35.136 (from server at 45.45.335.351)',
        version: '2019-11-05',
        source: 'Dashboard - useraddress@gmail.com',
        related: 'reporting.report_run - frr_JFgkkfdfgGKm46yKG',
        origin: 'https://dashboard.kaoshi.com',
        request_body: {
            report_type: 'payouts.summary.1',
            parameters: {
                interval_start: '1575158400',
                interva_end: '1575244800',
            },
            include: [
                'reporting_query.result',
            ],
        },
    },
    {
        status: '200',
        description: 'POST  v1 / reporting / report_runs',
        date: '20-10-19 5:23:10',
        action: '',
        id: 'req_JAAmdSKfgndrftgyhu',
        method: 'POST',
        url: '/v1/reporting/report_runs',
        ip_address: '123.655.35.136 (from server at 45.45.335.351)',
        version: '2019-11-05',
        source: 'Dashboard - useraddress@gmail.com',
        related: 'reporting.report_run - frr_JFgkkfdfgGKm46yKG',
        origin: 'https://dashboard.kaoshi.com',
        request_body: {
            report_type: 'payouts.summary.1',
            parameters: {
                interval_start: '1575158400',
                interva_end: '1575244800',
            },
            include: [
                'reporting_query.result',
            ],
        },
    },
    {
        status: '200',
        description: 'POST  v1 / reporting / report_runs',
        date: '20-10-19 5:23:10',
        action: '',
        id: 'req_JAAmdSKtyuiofgtyhu',
        method: 'POST',
        url: '/v1/reporting/report_runs',
        ip_address: '123.655.35.136 (from server at 45.45.335.351)',
        version: '2019-11-05',
        source: 'Dashboard - useraddress@gmail.com',
        related: 'reporting.report_run - frr_JFgkkfdfgGKm46yKG',
        origin: 'https://dashboard.kaoshi.com',
        request_body: {
            report_type: 'payouts.summary.1',
            parameters: {
                interval_start: '1575158400',
                interva_end: '1575244800',
            },
            include: [
                'reporting_query.result',
            ],
        },
    },
    {
        status: '200',
        description: 'POST  v1 / reporting / report_runs',
        date: '20-10-19 5:23:10',
        action: '',
        id: 'req_JAAmdSKKjioltfgyhu',
        method: 'POST',
        url: '/v1/reporting/report_runs',
        ip_address: '123.655.35.136 (from server at 45.45.335.351)',
        version: '2019-11-05',
        source: 'Dashboard - useraddress@gmail.com',
        related: 'reporting.report_run - frr_JFgkkfdfgGKm46yKG',
        origin: 'https://dashboard.kaoshi.com',
        request_body: {
            report_type: 'payouts.summary.1',
            parameters: {
                interval_start: '1575158400',
                interva_end: '1575244800',
            },
            include: [
                'reporting_query.result',
            ],
        },
    },
];

export const headers = [
    {
        text: 'Status',
        align: 'start',
        sortable: false,
        value: 'status',
        width: '15%',
        class: 'tab_header',
    },
    {
        text: 'Desciption',
        value: 'description',
        width: '50%',
        class: 'tab_header',
    },
    {
        text: 'Date',
        value: 'date',
        width: '35%',
        class: 'tab_header',
    },
    {
        value: 'action',
        width: '35%',
        class: 'tab_header',
    },
];
