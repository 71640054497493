<template>
  <v-dialog v-model="display" width="80%">
    <template v-slot:activator="{ on }">
      <v-btn text class="text-capitalize default-font" v-on="on">Validation journey</v-btn>
    </template>

    <v-card flat class="py-2">
      <v-card-title>
        Validation journey for match ID: "{{ id }}"
      </v-card-title>
      <v-data-table
        item-key="id"
        :headers="headers"
        :items="items"
        :loading="loading"
        :items-per-page="items.length"
        hide-default-footer
        single-expand
        class="px-12 py-4 tw-bg-transparent custom-table"
      >
        <template v-slot:item.created_at="{ item }">
          {{ item.created_at | date }} {{ item.created_at | time }}
        </template>
        <template v-slot:item.event="{ item }">
          {{ eventToText(item.event) }}
        </template>
        <template v-slot:item.matcher_paid="{ item }">
          {{ item.event !== 'paid_button_clicked' ? booleanToText(item.matcher_paid) : '' }}
        </template>
        <template v-slot:item.payment_received="{ item }">
          {{ item.event !== 'paid_button_clicked' ? booleanToText(item.payment_received) : '' }}
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>
<script>

import MatchesApi from '@/api/MatchesApi';

const headers = [
    {
        text: 'Date',
        value: 'created_at',
        sortable: false,
    },
    {
        text: 'Event',
        value: 'event',
        sortable: false,
    },
    {
        text: 'Matcher Paid',
        value: 'matcher_paid',
        sortable: false,
    },
    {
        text: 'Payment Received',
        value: 'payment_received',
        sortable: false,
    },
];

export default {
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            display: false,
            headers,
            loading: false,
            items: [],
        };
    },
    watch: {
        display(val) {
          if (val) {
            this.loadItems();
          }
        },
    },
    methods: {
        loadItems() {
            this.loading = true;
            MatchesApi.getValidationJourney(this.id)
                .then((response) => {
                    this.loading = false;
                    const { models } = response.data;
                    this.items = models;
                })
                .catch(() => {
                    this.loading = false;
                });
          },
          eventToText(event) {
              return event ? event.split('_').join(' ') : null;
          },
          booleanToText(v) {
              if (v) return 'True';
              if (v === null) return 'Null';
              return 'False';
          },
    },
};
</script>
