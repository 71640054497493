var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('v-data-table',{staticClass:"px-12 py-4 tw-bg-transparent custom-table",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.transactions,"items-per-page":_vm.pagination.pageSize,"single-expand":"","page":_vm.pagination.page,"server-items-length":_vm.pagination.rowCount,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.poster_amount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"tw-text-black"},[_vm._v(_vm._s(item.poster_currency))]),_vm._v(" "+_vm._s(_vm._f("price")(_vm.getPosterAmount(item)))+" ")]}},{key:"item.payment_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPaymentType(item))+" ")]}},{key:"item.is_partial_match_allowed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.is_partial_match_allowed ? 'Yes' : 'No')+" ")]}},{key:"item.matcher_amount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"tw-text-black"},[_vm._v(_vm._s(item.matcher_currency))]),_vm._v(" "+_vm._s(_vm._f("price")(_vm.getMatcherAmount(item)))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('transaction-status',{attrs:{"item":item}})]}},{key:"item.view",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"depressed":"","color":"primary","to":_vm.getOrderRouterLink(item.id)}},[_vm._v(" View ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('transaction-actions',{attrs:{"item":item,"isAdmin":_vm.isAdmin,"isPrincipal":_vm.isPrincipal}})]}}],null,true)}),_c('v-pagination',{staticClass:"custom-pagination",attrs:{"value":_vm.pagination.page,"next-icon":"$next","prev-icon":"$prev","color":"#7598B5","length":_vm.pagination.pageCount,"total-visible":_vm.totalVisible},on:{"input":_vm.onPageChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }