<template>
    <v-chip class="ma-2 text-uppercase status tw-cursor-pointer" :color="status.bg" :text-color="status.color">
        {{ status.label }}
    </v-chip>
</template>
<script>
const empty = {
    label: '',
    bg: 'transparent',
    color: '#000000de',
};

export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    computed: {
        status() {
            if (!this.item) return empty;

            const { refund_status: refundStatus } = this.item;
            if (!refundStatus) return empty;

            const colors = {
                initiated: {
                    bg: '#e0e0e0',
                    color: '#000000de',
                },
                pending: {
                    bg: '#FFEBA6',
                    color: '#D89342',
                },
                completed: {
                    bg: '#C5F5E7',
                    color: '#15ABAB',
                },
                failed: {
                    bg: '#FFE3E2',
                    color: '#F07877',
                },
            };

            return {
                label: refundStatus,
                ...colors[refundStatus],
            };
        },
    },
};
</script>
