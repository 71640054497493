<template>
    <v-main>
        <v-dialog
            scrollable
            :value="show"
            @click:outside="decline"
        >
            <v-card>
                <v-toolbar
                dark
                color="primary"
                >
                    <v-btn
                        icon
                        dark
                        @click="decline"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Event Data</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                        dark
                        text
                        @click="copyEventDataToClipBoard"
                        >
                        Copy
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <json-viewer :value="jsonData" />
            </v-card>
        </v-dialog>
    </v-main>
</template>

<script>
import JsonViewer from 'vue-json-viewer';

export default {
    data: () => ({
        show: false,
        jsonData: {},
    }),
    components: {
        JsonViewer,
    },
    methods: {
        showViewer(jsonData) {
            this.jsonData = jsonData;
            this.show = true;
        },
        decline() {
            this.show = false;
        },
        copyEventDataToClipBoard() {
            if (!window.navigator) {
                return;
            }

            const jsonDataText = JSON.stringify(this.jsonData, null, '\t');
            window.navigator.clipboard.writeText(jsonDataText);
        },
    },
};
</script>
