<template>
    <v-dialog
        :value="showDialog"
        @click:outside="decline"
        max-width="400px"
    >
        <v-card
            v-if="step === 1"
            flat class="py-2"
        >
            <v-card-title>
                Select Payment Type
            </v-card-title>
            <v-card-text>
                <v-radio-group v-model="paymentType">
                    <v-radio
                        label="Flutterwave"
                        value="flutterwave"
                    />
                    <v-radio
                        label="Mono"
                        value="mono"
                    />
                </v-radio-group>
            </v-card-text>
            <v-card-actions
                class="justify-center"
            >
                <v-btn
                    large
                    class="text-capitalize default-font font-14 tw-font-bold mr-5"
                    color="cancel"
                    dark
                    @click="decline"
                >
                    Cancel
                </v-btn>
                <v-btn
                    large
                    class="text-capitalize default-font font-14 tw-font-bold"
                    color="secondary"
                    @click="step = 2"
                    :disabled="!paymentType"
                >
                    Next
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-card
            v-if="step === 2"
            flat class="py-2"
        >
            <v-card-title>
                Are you sure you want to take this action - Publish Order
            </v-card-title>
            <v-card-actions
                class="justify-center"
            >
                <v-btn
                    large
                    class="text-capitalize default-font font-14 tw-font-bold mr-5"
                    color="cancel"
                    dark
                    @click="step = 1"
                >
                    Back
                </v-btn>
                <v-btn
                    large
                    class="text-capitalize default-font font-14 tw-font-bold"
                    color="secondary"
                    @click="approve"
                    :disabled="!paymentType"
                >
                    Yes
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    data() {
        return {
            showDialog: false,
            resolve: () => {},
            reject: () => {},
            step: 1,
            paymentType: null,
        };
    },
    methods: {
        show() {
            this.showDialog = true;
            this.step = 1;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        approve() {
            this.showDialog = false;
            if (this.paymentType) {
                this.resolve(this.paymentType);
            }
        },
        decline() {
            this.showDialog = false;
            this.reject();
        },
    },
};
</script>
