<template>
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
      <div class="menubar">
        <v-btn icon
          class="menubar__button"
          @click="$refs.attachMsg.click();"
        >
          <v-icon small color="secondary">$attach</v-icon>
        </v-btn>

        <v-btn icon
          class="menubar__button"
          :class="{ 'is-active': isActive.bold() }"
          @click="commands.bold"
        >
          <v-icon color="secondary">mdi-format-bold</v-icon>
        </v-btn>

        <v-btn icon
          class="menubar__button"
          :class="{ 'is-active': isActive.italic() }"
          @click="commands.italic"
        >
          <v-icon color="secondary">mdi-format-italic</v-icon>
        </v-btn>

        <v-btn icon
          class="menubar__button"
          :class="{ 'is-active': isActive.strike() }"
          @click="commands.strike"
        >
          <v-icon color="secondary">mdi-format-strikethrough-variant</v-icon>
        </v-btn>

        <v-btn icon
          class="menubar__button"
          :class="{ 'is-active': isActive.underline() }"
          @click="commands.underline"
        >
          <v-icon color="secondary">mdi-format-underline</v-icon>
        </v-btn>

        <v-btn icon
          class="menubar__button"
          :class="{ 'is-active': isActive.code() }"
          @click="commands.code"
        >
          <v-icon color="secondary">mdi-xml</v-icon>
        </v-btn>

        <v-btn icon
          class="menubar__button"
          :class="{ 'is-active': isActive.paragraph() }"
          @click="commands.paragraph"
        >
         <v-icon color="secondary">mdi-format-paragraph</v-icon>
        </v-btn>

        <v-btn icon
          class="menubar__button"
          :class="{ 'is-active': isActive.bullet_list() }"
          @click="commands.bullet_list"
        >
          <v-icon color="secondary">mdi-format-list-bulleted</v-icon>
        </v-btn>

        <v-btn icon
          class="menubar__button"
          :class="{ 'is-active': isActive.ordered_list() }"
          @click="commands.ordered_list"
        >
         <v-icon color="secondary">mdi-format-list-numbered</v-icon>
        </v-btn>

        <v-btn icon
          class="menubar__button"
          :class="{ 'is-active': isActive.blockquote() }"
          @click="commands.blockquote"
        >
          <v-icon color="secondary">mdi-format-quote-close</v-icon>
        </v-btn>

        <v-btn icon
          class="menubar__button"
          :class="{ 'is-active': isActive.code_block() }"
          @click="commands.code_block"
        >
          <v-icon color="secondary">mdi-code-braces</v-icon>
        </v-btn>

        <v-btn icon
          class="menubar__button"
          @click="commands.undo"
        >
          <v-icon color="secondary">mdi-undo</v-icon>
        </v-btn>

        <v-btn icon
          class="menubar__button"
          @click="commands.redo"
        >
          <v-icon color="secondary">mdi-redo</v-icon>
        </v-btn>
        <input type="file" multiple ref="attachMsg" @change="onFileChange" class="tw-invisible tw-absolute"/>

      </div>
    </editor-menu-bar>
</template>
<script>
import { Editor, EditorMenuBar } from 'tiptap';

export default {
    props: {
        editor: {
            type: Editor,
            required: true,
        },
    },
    components: {
        EditorMenuBar,
    },
    methods: {
        onFileChange(event) {
            this.$emit('file-attached', event);
        },
    },
    beforeDestroy() {
        this.editor.destroy();
    },
};
</script>
<style>
