/* eslint-disable camelcase */
import api from './index';

const resource = 'admin/users';

export default {

    /**
     * Get all users
     * @param {Object} params query params
     */
    getAll(params) {
        return api.get(`${resource}`, {
            params,
        });
    },

    /**
     * Export all users
     * @param {Object} params query params
     */
    export(params) {
        return api.get(`${resource}/export`, {
            params,
        });
    },

    /**
     * Get specific User
     * @param {Number} userId User ID
     */
    get(userId) {
        return api.get(`${resource}/${userId}`);
    },

    /**
     * Get specific User
     * @param {Number} userId User ID
     */
    delete(userId) {
        return api.delete(`${resource}/${userId}`);
    },

    /**
     * Ban specific User
     * @param {Number} userId User ID
     */
    ban(userId) {
        return api.patch(`${resource}/${userId}/ban`);
    },

    /**
     * Unban specific User
     * @param {Number} userId User ID
     */
    unban(userId) {
        return api.patch(`${resource}/${userId}/unban`);
    },

    /**
     * Get all orders for User
     * @param {Number} userId User ID
     * @param {Object} params Query Params
     */
    getOrders(userId, params) {
        return api.get(`${resource}/${userId}/orders`, {
            params,
        });
    },

    /**
     * Export all orders for User
     * @param {Number} userId User ID
     * @param {Object} params Query Params
     */
    exportOrders(userId, params) {
        return api.get(`${resource}/${userId}/orders/export`, {
            params,
        });
    },

    /**
     * Get all received orders for User
     * @param {Number} userId User ID
     * @param {Object} params Query Params
     */
    getReceivedOrders(userId, params) {
        return api.get(`${resource}/${userId}/received-orders`, {
            params,
        });
    },

    /**
     * Export all received orders for User
     * @param {Number} userId User ID
     * @param {Object} params Query Params
     */
    exportReceivedOrders(userId, params) {
        return api.get(`${resource}/${userId}/received-orders/export`, {
            params,
        });
    },

    /**
     * Get all pending matches for User
     * @param {Number} userId User ID
     * @param {Object} params Query Params
     */
    getPendingMatches(userId, params) {
        return api.get(`${resource}/${userId}/pending-matches`, {
            params,
        });
    },

    /**
     * Export all pending matches for User
     * @param {Number} userId User ID
     * @param {Object} params Query Params
     */
    exportPendingMatches(userId, params) {
        return api.get(`${resource}/${userId}/pending-matches/export`, {
            params,
        });
    },

    /**
     * Get all payment requests for User
     * @param {Number} userId User ID
     * @param {Object} params Query Params
     */
    getPaymentRequests(userId, params) {
        return api.get(`${resource}/${userId}/payment-requests`, {
            params,
        });
    },

    /**
     * Export all payment requests for User
     * @param {Number} userId User ID
     * @param {Object} params Query Params
     */
    exportPaymentRequests(userId, params) {
        return api.get(`${resource}/${userId}/payment-requests/export`, {
            params,
        });
    },

    /**
     * Create Kaoshi Email for User
     * @param {String} userId User ID
     */
    createKaoshiEmail(userId) {
        return api.patch(`${resource}/${userId}/create-kaoshi-email`);
    },

    /**
     * Get User's statistic
     * @param {String} userId User ID
     * @param {Object} params Query params
     */
    getStatistic(userId, params) {
        return api.get(`${resource}/${userId}/statistic`, {
            params,
        });
    },

    /**
     * Get User's invited Users
     * @param {String} userId User ID
     * @param {Object} params Query params
     */
    getInvitedUsers(userId, params) {
        return api.get(`${resource}/${userId}/invited-users`, {
            params,
        });
    },

    /**
     * Get User's Notifications
     * @param {String} userId User ID
     * @param {Object} params Query params
     */
    getNotifications(userId, params) {
        return api.get(`${resource}/${userId}/notifications`, {
            params,
        });
    },

    /**
     * Mark User's Notifications as Read
     * @param {String} userId User ID
     * @param {Number[]} notification_ids List of Notification IDs
     */
    markNotificationsAsRead(userId, notification_ids) {
        return api.put(`${resource}/${userId}/notifications/read`, {
            notification_ids,
        });
    },
};
