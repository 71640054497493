<template>
    <div class="tw-w-full">
        <app-bar>
            <template slot="title">
                <v-toolbar-title class="default-font font-21 mr-10 tw-text-black">Transactions</v-toolbar-title>
            </template>
            <div class="d-flex justify-space-between mr-10">
                <search v-model="search" placeholder="Search by Transaction ID or Name..."></search>
                <div>
                    <export-modal
                        @exportToPdf="exportTransactions('pdf')"
                        @exportToXls="exportTransactions('xlsx')"
                    />
                    <v-btn text color="primary" class="text-capitalize default-font"
                           @click="showFilters = !showFilters">
                        <v-icon class="mr-3">$vuetify.icons.filter</v-icon>{{ filterText }}
                    </v-btn>
                </div>
            </div>
        </app-bar>
        <v-expand-transition>
            <transaction-filter v-if="showFilters" :disabled="loading"></transaction-filter>
        </v-expand-transition>
        <transaction-list
            :transactions="transactions"
            :pagination="pagination"
            :loading="loading"
            @pageChange="onPageChange"
            :search="search"
            class="mt-5"
        ></transaction-list>
    </div>
</template>
<script>
import AppBar from '@/components/globals/AppBar.vue';
import TransactionFilter from '@/components/transactions/Filter.vue';
import TransactionList from '@/components/transactions/TransactionList.vue';
import ExportModal from '@/components/transactions/ExportModal.vue';
import Search from '@/components/globals/Search.vue';
import Socket from '@/services/Socket';
import TransactionsApi from '@/api/TransactionsApi';
import { mapState } from 'vuex';

// import eventBus from '@/utils/eventBus';

export default {
    components: {
        AppBar,
        TransactionFilter,
        TransactionList,
        ExportModal,
        Search,
    },
    data() {
        return {
            filters: [],
            showFilters: false,
            loading: true,
            transactions: [],
            search: null,
            debounce: null,
            pagination: {
                page: 1,
                pageSize: 20,
            },
            transactionsStatistic: {},
        };
    },
    watch: {
        search() {
            this.pagination.page = 1;
            this.loadItemsDebounced();
        },
        sentFilter: {
            deep: true,
            handler() {
                this.pagination.page = 1;
                this.loadItemsDebounced();
            },
        },
        receivedFilter: {
            deep: true,
            handler() {
                this.pagination.page = 1;
                this.loadItemsDebounced();
            },
        },
        matcherCountryFilter: {
            deep: true,
            handler() {
                this.pagination.page = 1;
                this.loadItemsDebounced();
            },
        },
        currencyFilter: {
            deep: true,
            handler() {
                this.pagination.page = 1;
                this.loadItemsDebounced();
            },
        },
        periodFilter: {
            deep: true,
            handler(period) {
                const { key, range } = period;
                if (key === 'custom') {
                    if (!range[0] || !range[1]) {
                        return false;
                    }
                }
                return this.loadItemsDebounced();
            },
        },
        periodFilterTarget: {
            deep: true,
            handler() {
                this.pagination.page = 1;
                this.loadItemsDebounced();
            },
        },
        statusFilter: {
            deep: true,
            handler() {
                this.pagination.page = 1;
                this.loadItemsDebounced();
            },
        },
        typeFilter: {
            deep: true,
            handler() {
                this.pagination.page = 1;
                this.loadItemsDebounced();
            },
        },
        partialMatchFilter: {
            deep: true,
            handler() {
                this.pagination.page = 1;
                this.loadItemsDebounced();
            },
        },
        paymentTypeFilter: {
            deep: true,
            handler() {
                this.pagination.page = 1;
                this.loadItemsDebounced();
            },
        },
        orderBy: {
            deep: true,
            handler(orderBy) {
                this.orderBy = orderBy;
                return this.loadItemsDebounced();
            },
        },
    },
    methods: {
        loadItemsDebounced() {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.loadItems();
            }, 300);
        },
        loadItems() {
            this.loading = true;
            const { page, pageSize } = this.pagination;
            const filters = {
                posterAmount: this.sentFilter,
                matcherAmount: this.receivedFilter,
                matcherCountry: this.matcherCountryFilter,
                currency: this.currencyFilter,
                period: this.periodFilter.range || [],
                statuses: this.statusFilter,
                type: this.typeFilter,
                partialMatch: this.partialMatchFilter,
                periodTarget: this.periodFilterTarget,
                paymentType: this.paymentTypeFilter,
            };

            const payload = {
                page,
                pageSize,
                filters,
                orderBy: this.orderBy,
            };

            if (this.search) {
                payload.search = this.search;
            }

            TransactionsApi.getAll(payload)
                .then((response) => {
                    this.loading = false;
                    const { models, pagination, transactionsStatistic } = response.data;
                    this.transactions = models;
                    this.pagination = pagination;
                    this.transactionsStatistic = transactionsStatistic;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        onPageChange(page) {
            this.pagination.page = page;
            this.loadItems();
        },
        addSocketEventListeners() {
            const listener = () => {
                this.loadItemsDebounced();
            };

            Socket.on('connected', listener); // fetch again if connection updated
            Socket.on('newOrder', listener);
            Socket.on('orderMatched', listener);
            Socket.on('matchCancelled', listener);
            Socket.on('orderRequested', listener);
            Socket.on('orderCancelled', listener);
            Socket.on('orderRemoved', listener);
            Socket.on('orderHiddenChange', listener);
            Socket.on('orderSaved', listener);
        },
        removeSocketEventListeners() {
            Socket.remove('connected');
            Socket.remove('newOrder');
            Socket.remove('orderMatched');
            Socket.remove('matchCancelled');
            Socket.remove('orderRequested');
            Socket.remove('orderCancelled');
            Socket.remove('orderRemoved');
            Socket.remove('orderHiddenChange');
            Socket.remove('orderSaved');
        },
        exportTransactions(format) {
            this.loading = true;
            const { page, pageSize } = this.pagination;
            const filters = {
                posterAmount: this.sentFilter,
                matcherAmount: this.receivedFilter,
                matcherCountry: this.matcherCountryFilter,
                currency: this.currencyFilter,
                period: this.periodFilter.range || [],
                statuses: this.statusFilter,
                periodTarget: this.periodFilterTarget,
                type: this.typeFilter,
                partialMatch: this.partialMatchFilter,
                paymentType: this.paymentTypeFilter,
            };

            const payload = {
                page,
                pageSize,
                filters,
                orderBy: this.orderBy,
                format,
            };

            if (this.search) {
                payload.search = this.search;
            }

            TransactionsApi.export(payload)
                .then((response) => {
                    this.loading = false;
                    const { url } = response.data;
                    window.open(url); // eslint-disable-line
                })
                .catch(() => {
                    this.loading = false;
                });
        },
    },
    computed: {
        ...mapState('transaction', [
            'sentFilter',
            'receivedFilter',
            'matcherCountryFilter',
            'currencyFilter',
            'periodFilter',
            'periodFilterTarget',
            'paymentTypeFilter',
            'statusFilter',
            'typeFilter',
            'partialMatchFilter',
            'orderBy',
        ]),
        filterText() {
            return this.showFilters ? 'Hide filters' : 'Show filters';
        },
    },
    mounted() {
        this.addSocketEventListeners();
        this.loadItems();
    },
    beforeDestroy() {
        this.removeSocketEventListeners();
    },
};
</script>
<style>
.font-21 {
  font-size: 21px !important;
}
.font-14 {
  font-size: 14px !important;
}
.font-17 {
    font-size: 17px !important;
}
.font-18 {
      font-size: 18px !important;
}
.filter-title .v-btn__content {
  font-size: 13px !important;
}
</style>
