<template>
    <v-timeline
        align-top
        :dense="$vuetify.breakpoint.smAndDown"
    >
        <v-timeline-item
            color="indigo"
            icon="mdi-star"
            fill-dot
            v-if="item"
        >
            <v-card
                color="indigo"
            >
                <v-card-title class="text-h6 white--text">
                    Poster
                </v-card-title>
                <v-card-text class="white text--primary">
                    <v-simple-table>
                        <template v-slot:default>
                            <tbody>
                                <tr>
                                    <td> Name </td>
                                    <td>
                                        <router-link
                                            :to="{
                                                name: 'User',
                                                params: {
                                                    id: item.user.id,
                                                },
                                            }">
                                            {{ item.user.full_name }}
                                        </router-link>
                                        <v-tooltip bottom content-class="tooltip" transition="fade-transition">
                                            <template v-slot:activator="{ on }">
                                                <v-icon
                                                    v-on="on"
                                                    small
                                                >
                                                    mdi-alert-circle
                                                </v-icon>
                                            </template>
                                            <person-info :person="item.user"></person-info>
                                        </v-tooltip>
                                    </td>
                                </tr>
                                <tr v-if="item.user.country_abbreviation">
                                    <td> Country </td>
                                    <td>
                                        <gb-flag
                                            v-if="item.user.country_abbreviation"
                                            class="mr-1"
                                            :code="item.user.country_abbreviation"
                                            size="small"
                                        />
                                        <span>{{ item.user.country }}</span>
                                    </td>
                                </tr>
                                <tr v-if="item.posterBank">
                                    <td> Bank Status </td>
                                    <td>
                                        <bank-status :bank="item.posterBank" />
                                        <span>
                                            <v-img
                                                v-if="posterBankLogo"
                                                width="70"
                                                :src="posterBankLogo"
                                            />
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td> Poster Amount </td>
                                    <td> {{item.poster_currency}} {{ getPosterAmount(item) | price }}</td>
                                </tr>
                                <tr>
                                    <td> Poster Fee </td>
                                    <td> {{item.poster_currency}} {{ getPosterFee(item) | price }}</td>
                                </tr>
                                <tr>
                                    <td> Total Poster Amount </td>
                                    <td> {{item.poster_currency}} {{ getTotalPosterAmount(item) | price }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
            </v-card>
        </v-timeline-item>
        <v-timeline-item
            color="indigo"
            icon="mdi-book-variant"
            fill-dot
            v-if="item"
        >
            <v-card
                color="indigo"
            >
                <v-card-title class="text-h6 white--text">
                    Poster Beneficiary
                </v-card-title>
                <v-card-text class="white text--primary">
                    <v-simple-table>
                        <template v-slot:default>
                            <tbody>
                                <tr>
                                    <td> Name </td>
                                    <td>
                                        <router-link
                                            :to="{
                                                name: 'User',
                                                params: {
                                                    id: item.postersBeneficiary.id,
                                                },
                                            }">
                                            {{ item.postersBeneficiary.full_name }}
                                        </router-link>
                                        <v-tooltip bottom content-class="tooltip" transition="fade-transition">
                                            <template v-slot:activator="{ on }">
                                                <v-icon
                                                    v-on="on"
                                                    small
                                                >
                                                    mdi-alert-circle
                                                </v-icon>
                                            </template>
                                            <person-info :person="item.postersBeneficiary"></person-info>
                                        </v-tooltip>
                                    </td>
                                </tr>
                                <tr v-if="item.postersBeneficiary.country_abbreviation">
                                    <td> Country </td>
                                    <td>
                                        <gb-flag
                                            v-if="item.postersBeneficiary.country_abbreviation"
                                            class="mr-1"
                                            :code="item.postersBeneficiary.country_abbreviation"
                                            size="small"
                                        />
                                        <span>{{ item.postersBeneficiary.country }}</span>
                                    </td>
                                </tr>
                                <tr v-if="item.postersBeneficiaryBank">
                                    <td> Bank Status </td>
                                    <td>
                                        <bank-status :bank="item.postersBeneficiaryBank" />
                                    </td>
                                </tr>
                                <tr>
                                    <td> Poster Beneficiary Amount </td>
                                    <td> {{item.matcher_currency}} {{ getMatcherAmount(item) | price }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
            </v-card>
        </v-timeline-item>
        <v-timeline-item
            color="green lighten-1"
            icon="mdi-book-variant"
            fill-dot
            v-if="item && item.match"
        >
            <v-card
                color="green lighten-1"
            >
                <v-card-title class="text-h6 white--text">
                    Matcher
                </v-card-title>
                <v-card-text class="white text--primary">
                    <v-simple-table>
                        <template v-slot:default>
                            <tbody>
                                <tr>
                                    <td> Name </td>
                                    <td>
                                        <router-link
                                            :to="{
                                                name: 'User',
                                                params: {
                                                    id: item.match.user.id,
                                                },
                                            }">
                                            {{ item.match.user.full_name }}
                                        </router-link>
                                        <v-tooltip bottom content-class="tooltip" transition="fade-transition">
                                            <template v-slot:activator="{ on }">
                                                <v-icon
                                                    v-on="on"
                                                    small
                                                >
                                                    mdi-alert-circle
                                                </v-icon>
                                            </template>
                                            <person-info :person="item.match.user"></person-info>
                                        </v-tooltip>
                                    </td>
                                </tr>
                                <tr v-if="item.match.user.country_abbreviation">
                                    <td> Country </td>
                                    <td>
                                        <gb-flag
                                            v-if="item.match.user.country_abbreviation"
                                            class="mr-1"
                                            :code="item.match.user.country_abbreviation"
                                            size="small"
                                        />
                                        <span>{{ item.match.user.country }}</span>
                                    </td>
                                </tr>
                                <tr v-if="item.matcherBank">
                                    <td> Bank Status </td>
                                    <td>
                                        <bank-status :bank="item.matcherBank" />
                                    </td>
                                </tr>
                                <tr>
                                    <td> Matcher Payment Provider </td>
                                    <td> {{ item.matcherPaymentProvider.name }}</td>
                                </tr>
                                <tr>
                                    <td> Matcher Amount </td>
                                    <td> {{item.matcher_currency}} {{ matcherAmount | price }}</td>
                                </tr>
                                <tr>
                                    <td> Matcher Fee </td>
                                    <td> {{item.matcher_currency}} {{ item.match.matcher_fee | price }}</td>
                                </tr>
                                <tr>
                                    <td> Total Matcher Amount </td>
                                    <td>
                                        {{item.matcher_currency}} {{ (item.match.matcher_fee + matcherAmount) | price }}
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
            </v-card>
        </v-timeline-item>
        <v-timeline-item
            color="green lighten-1"
            icon="mdi-book-variant"
            fill-dot
            v-if="item && item.match"
        >
            <v-card
                color="green lighten-1"
            >
                <v-card-title class="text-h6 white--text">
                    Matcher Beneficiary
                </v-card-title>
                <v-card-text class="white text--primary">
                    <v-simple-table>
                        <template v-slot:default>
                            <tbody>
                                <tr>
                                    <td> Name </td>
                                    <td>
                                        {{ matchersBeneficiary.full_name }}
                                        <v-tooltip bottom content-class="tooltip" transition="fade-transition">
                                            <template v-slot:activator="{ on }">
                                                <v-icon
                                                    v-on="on"
                                                    small
                                                >
                                                    mdi-alert-circle
                                                </v-icon>
                                            </template>
                                            <person-info :person="matchersBeneficiary"></person-info>
                                        </v-tooltip>
                                    </td>
                                </tr>
                                <tr v-if="matchersBeneficiary.country_abbreviation">
                                    <td> Country </td>
                                    <td>
                                        <gb-flag
                                            v-if="matchersBeneficiary.country_abbreviation"
                                            class="mr-1"
                                            :code="matchersBeneficiary.country_abbreviation"
                                            size="small"
                                        />
                                        <span>{{ matchersBeneficiary.country }}</span>
                                    </td>
                                </tr>
                                <tr v-if="item.matcherBank">
                                    <td> Bank Status </td>
                                    <td>
                                        <bank-status :bank="matchersBeneficiary.bank" />
                                    </td>
                                </tr>
                                <tr>
                                    <td> Matcher Beneficiary Amount </td>
                                    <td> {{item.poster_currency}} {{ item.match.poster_amount | price }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
            </v-card>
        </v-timeline-item>
    </v-timeline>
</template>

<script>
import BankStatus from '@/components/transactions/BankStatus.vue';
import PersonInfo from '@/components/transactions/tooltips/PersonInfo.vue';

import TransactionMixin from '../../mixins/transactions';

export default {
    components: {
        BankStatus,
        PersonInfo,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    mixins: [
        TransactionMixin,
    ],
    computed: {
        posterBankLogo() {
            const {
                posterBank,
                poster_country: country,
            } = this.item;

            if (!posterBank) {
                return null;
            }

            const bankCode = posterBank.account.bank_code;

            if (!bankCode) {
                return null;
            }

            return `/img/banks/${country.toLowerCase()}/${bankCode}.png`;
        },
        matcherAmount() {
            const {
                match,
            } = this.item;

            return match.matcher_amount;
        },
        matchersBeneficiary() {
            if (!this.item.match) return {};

            const { matcherRecipient } = this.item.match;
            if (!matcherRecipient) return {};

            const {
                recipient,
            } = matcherRecipient;
            if (!recipient) return {};

            const { recipientInfo } = recipient;
            if (!recipientInfo) return {};

            const {
                name,
                email,
                phone,
                address,
                address2,
                city,
                country,
                street_number: streetNumber,
            } = recipientInfo;

            const fullAddress = [
                streetNumber,
                address,
                address2,
                city,
                country,
            ].filter(Boolean).join(', ');

            return {
                name,
                full_name: name,
                email,
                phone,
                address: fullAddress,
                bank_code: recipientInfo.bank_code,
                institution_name: matcherRecipient.institution_name,
                account_number: recipientInfo.account_number,
                country_abbreviation: this.item.user.country_abbreviation,
                country: this.item.user.country,
                bank: {
                    name: matcherRecipient.institution_name,
                },
            };
        },
    },
    methods: {
        getPBAvatar(item) {
            return item.postersBeneficiary.avatar || '/img/man.svg';
        },
    },
};
</script>
