<template>
  <v-select
  :items="items"
   outlined
   dense
   class="custom-select"
   :value="amountFilter"
   @change="updateAmountFilter"
   :menu-props="{ bottom: true, offsetY: true, 'content-class': 'mt-3 tw-rounded-lg' }">
    <template v-slot:item="{ item }">
      <div class="tw-w-full"  v-if="item !== 0">
        <span class="default-font tw-text-black-400 font-15 mr-2">{{ item }}</span>
        <span class="default-font font-12 tw-text-black-400">USD</span>
      </div>
      <template v-else> <span class="default-font filter-title tw-text-black"> All amount </span></template>

    </template>

    <template v-slot:selection="{ item }">
       <div class="d-flex justify-space-between tw-w-full" v-if="item !== 0">
        <span class="default-font filter-title tw-text-black font-14 ">{{ item }}</span>
        <span class="default-font filter-title tw-text-black font-14 tw-opacity-50">USD</span>
      </div>
      <template v-else> <span class="default-font filter-title tw-text-black"> All amount </span></template>

      </template>
  </v-select>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    data: () => ({
        items: [0, 50, 100, 150, 200, 250, 300],
    }),
    methods: {
        ...mapActions(['updateAmountFilter']),
    },
    computed: {
        ...mapGetters(['amountFilter']),
    },
};
</script>
