<template>
    <div>
        <v-row class="px-12 py-4 mt-2 mb-0">
            <v-col cols="6">
                <v-row>
                    <v-col cols="4">
                        <label class="default-font filter-title mb-2 d-block">From:</label>
                        <v-autocomplete
                            v-model="posterCountry"
                            :items="countries"
                            :menu-props="{ bottom: true, offsetY: true, 'content-class': 'mt-3' }"
                            item-text="country"
                            item-value="country_abbreviation"
                            placeholder="All"
                            class="custom-select"
                            return-object
                            clearable
                            outlined
                            dense
                            @change="updateFilter">

                            <template #item="{ item }">
                                <span class="default-font-400 font-15 mr-2">
                                    {{ item.country }}
                                </span>
                            </template>

                            <template #selection="{ item }">
                                <div v-if="item.locale">
                                    <gb-flag class="mr-1" :code="item.locale" size="small" />
                                    <span class="default-font filter-title font-14">
                                        {{ item.country }}
                                    </span>
                                </div>
                            </template>
                        </v-autocomplete>
                    </v-col>

                    <v-col cols="4">
                        <label class="default-font filter-title mb-2 d-block">To recepient in:</label>
                        <v-autocomplete
                            v-model="matcherCountry"
                            :items="countries"
                            :menu-props="{ bottom: true, offsetY: true, 'content-class': 'mt-3' }"
                            item-text="country"
                            item-value="country_abbreviation"
                            placeholder="All"
                            class="custom-select"
                            return-object
                            clearable
                            outlined
                            dense
                            @change="updateFilter">

                            <template #item="{ item }">
                                <span class="default-font-400 font-15 mr-2">
                                    {{ item.country }}
                                </span>
                            </template>

                            <template #selection="{ item }">
                                <div v-if="item.locale">
                                    <gb-flag class="mr-1" :code="item.locale" size="small" />
                                    <span class="default-font filter-title font-14">
                                        {{ item.country }}
                                    </span>
                                </div>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="6" class="d-flex justify-end align-center">
                <v-btn
                    text
                    color="primary"
                    class="text-capitalize default-font mr-4"
                    @click="$emit('show-rate-form')"
                >
                    <v-icon>$vuetify.icons.filter-v2</v-icon>
                    <span>Add New Rate Limit</span>
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { find, cloneDeep } from 'lodash';

export default {
    props: {
        countries: {
            type: Array,
            default: () => ([]),
        },
        filters: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        innerFilters: {},
    }),
    computed: {
        posterCountry: {
            get() {
                return find(this.countries, {
                    abbreviation: this.innerFilters?.poster_country_abbreviation,
                });
            },
            set(val) {
                let abbreviation = null;
                let currency = null;

                if (val) {
                    abbreviation = val.abbreviation;
                    currency = val.currency;
                }

                this.$set(this.innerFilters, 'poster_country_abbreviation', abbreviation);
                this.$set(this.innerFilters, 'poster_currency_code', currency);
            },
        },
        matcherCountry: {
            get() {
                return find(this.countries, {
                    abbreviation: this.innerFilters?.matcher_country_abbreviation,
                });
            },
            set(val) {
                let abbreviation = null;
                let currency = null;

                if (val) {
                    abbreviation = val.abbreviation;
                    currency = val.currency;
                }

                this.$set(this.innerFilters, 'matcher_country_abbreviation', abbreviation);
                this.$set(this.innerFilters, 'matcher_currency_code', currency);
            },
        },
    },
    methods: {
        async updateFilter() {
            await this.$nextTick();
            this.$emit('update:filters', this.innerFilters);
        },
    },
    watch: {
        filters: {
            immediate: true,
            deep: true,
            handler(val) {
                const clonedFilters = cloneDeep(val);
                this.$set(this, 'innerFilters', clonedFilters);
            },
        },
    },
};
</script>
