<template>
    <v-dialog
        v-model="innerValue"
        width="800px"
        class="overflow-hidden">
        <v-card>
            <v-card-title class="d-flex justify-space-between">
                <span class="headline text-capitalize">Data</span>
                <v-btn
                    icon
                    @click="innerValue = false">
                    <v-icon color="#15ABAB">mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>
                <div
                    v-for="(item, key) in data"
                    :key="key">
                    <v-chip small>
                        {{ item.side }}
                    </v-chip>
                    <v-chip small>
                        {{ item.source_type }}
                    </v-chip>
                    <pre>
                        <code>
                            {{ item.data }}
                        </code>
                    </pre>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        data: {
            type: Array,
            required: true,
        },
    },
    data: () => ({
        innerValue: null,
    }),
    watch: {
        value: {
            immediate: true,
            handler(val) {
                this.innerValue = val;
            },
        },
        innerValue(val) {
            this.$emit('input', val);
        },
    },
};
</script>
