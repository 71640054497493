<template >
  <div class>
    <app-bar>
      <template slot="title">
        <v-toolbar-title
          class="default-font font-21 mr-10 text-capitalize tw-text-black"
        >Support Threads</v-toolbar-title>
      </template>
      <div class="tw-flex tw-justify-between tw-w-full pr-10">
        <search v-model="search" placeholder="Search Help & Support"></search>
        <v-btn depressed large color="primary" to="/new-case">Open new case</v-btn>
      </div>
    </app-bar>
    <support-threads-table :search="search"></support-threads-table>
  </div>
</template>

<script>
import AppBar from '@/components/globals/AppBar.vue';
import SupportThreadsTable from '@/components/supports/SupportTab.vue';
import Search from '@/components/globals/Search.vue';

export default {
    components: { AppBar, Search, SupportThreadsTable },
    data: () => ({
        search: null,
    }),
};
</script>

<style>
.font-21 {
  font-size: 21px !important;
}
.font-14 {
  font-size: 14px !important;
}
</style>
