<template>
    <div class="d-flex flex-column mt-5 t-filter tw-w-full px-12 py-4">
        <h4 class="default-font tw-text-black filter-title">Filter results</h4>
        <v-row>
            <v-col cols="2">
                <label class="default-font filter-title tw-text-secondary mb-2 d-block">Sent:</label>
                <sent-filter
                    :disabled="disabled"
                    storeKey="thirdPartyTransaction"
                ></sent-filter>
            </v-col>
            <v-col cols="2">
                <label class="default-font filter-title tw-text-secondary mb-2 d-block">Recieved:</label>
                <recieved-filter
                    :disabled="disabled"
                    storeKey="thirdPartyTransaction"
                ></recieved-filter>
            </v-col>
            <v-col cols="2">
                <label class="default-font filter-title tw-text-secondary mb-2 d-block">Matcher Country:</label>
                <MatcherCountryFilter
                    :disabled="disabled"
                    storeKey="thirdPartyTransaction"
                ></MatcherCountryFilter>
            </v-col>
            <v-col cols="2">
                <label class="default-font filter-title tw-text-secondary mb-2 d-block">Currency:</label>
                <currency-filter
                    :disabled="disabled"
                    storeKey="thirdPartyTransaction"
                ></currency-filter>
            </v-col>
            <v-col cols="2">
                <label class="default-font filter-title tw-text-secondary mb-2 d-block">Status:</label>
                <status-filter :disabled="disabled"></status-filter>
            </v-col>
            <v-col cols="2">
                <label class="default-font filter-title tw-text-secondary mb-2 d-block">Period:</label>
                <TransactionPeriodFilter :disabled="disabled"></TransactionPeriodFilter>
            </v-col>

            <v-col cols="2" class="d-flex justify-end align-center">
                <v-btn text color="secondary" class="text-capitalize" @click="clearAllFilters" :disabled="disabled">
                    <v-icon color="secondary">mdi-close-circle</v-icon>
                    <span class="default-font filter-title ml-2">Clear filters</span>
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import SentFilter from '../transactions/SentFilter.vue';
import RecievedFilter from '../transactions/RecievedFilter.vue';
import MatcherCountryFilter from '../transactions/MatcherCountryFilter.vue';
import CurrencyFilter from '../transactions/CurrencyFilter.vue';
import TransactionPeriodFilter from './TransactionPeriodFilter.vue';
import StatusFilter from './StatusFilter.vue';

export default {
    components: {
        SentFilter,
        RecievedFilter,
        MatcherCountryFilter,
        CurrencyFilter,
        StatusFilter,
        TransactionPeriodFilter,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            status: ['Any status', 'Completed', 'Pending', 'Canced'],
        };
    },
    methods: {
        ...mapActions('thirdPartyTransaction', ['clearAllFilters']),
    },
};
</script>
<style>
.t-filter {
    background-color: #f7fbfc;
}

.filter-title {
    font-size: 13px;
}
</style>
