import { render, staticRenderFns } from "./SupportTab.vue?vue&type=template&id=6c0cf5f1&scoped=true&"
import script from "./SupportTab.vue?vue&type=script&lang=js&"
export * from "./SupportTab.vue?vue&type=script&lang=js&"
import style0 from "./SupportTab.vue?vue&type=style&index=0&id=6c0cf5f1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c0cf5f1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VTab,VTabItem,VTabs})
