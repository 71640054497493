/* eslint-disable no-restricted-properties */
/* eslint-disable no-restricted-globals */
export const billings = [
    {
        date: '20-10-19 5:23:10',
        amount: '35',
        action: '',
    },
    {
        date: '20-10-19 5:23:10',
        amount: '35',
        action: '',
    },
    {
        date: '20-10-19 5:23:10',
        amount: '35',
        action: '',
    },
    {
        date: '20-10-19 5:23:10',
        amount: '35',
        action: '',
    },
    {
        date: '20-10-19 5:23:10',
        amount: '35',
        action: '',
    },
    {
        date: '20-10-19 5:23:10',
        amount: '35',
        action: '',
    },
    {
        date: '20-10-19 5:23:10',
        amount: '35',
        action: '',
    },
    {
        date: '20-10-19 5:23:10',
        amount: '35',
        action: '',
    },
    {
        date: '20-10-19 5:23:10',
        amount: '36',
        action: '',
    },
    {
        date: '20-10-19 5:23:10',
        amount: '35',
        action: '',
    },
];

export const headers = [
    {
        text: 'Date',
        align: 'start',
        sortable: true,
        value: 'date',
        width: '30%',
        class: 'tab_header',
    },
    {
        text: 'Amount',
        value: 'amount',
        width: '40%',
        class: 'tab_header',
    },
    {
        value: 'action',
        align: 'end',
        width: '30%',
        class: 'tab_header',
    },
];

function toFixedFix(n, prec) {
    const k = Math.pow(10, prec);
    return `${Math.round(n * k) / k}`;
}

export function numberFormat(number, decimals, decPoint, thousandsSep) {
    // Strip all characters but numerical ones.
    const fNumber = (`${number}`).replace(/[^0-9+\-Ee.]/g, '');
    const n = !isFinite(+fNumber) ? 0 : +fNumber;
    const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
    const sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep;
    const dec = (typeof decPoint === 'undefined') ? '.' : decPoint;
    let s = '';
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : `${Math.round(n)}`).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}
