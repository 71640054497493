var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('ExportModal',{on:{"exportToPdf":function($event){return _vm.exportOrders('pdf')},"exportToXls":function($event){return _vm.exportOrders('xlsx')}}})],1)],1),_c('user-transactions-table',{attrs:{"items":_vm.orders,"headers":_vm.headers,"pagination":_vm.pagination,"loading":_vm.loading,"single-expand":"","item-class":function () { return "tw-bg-hoverBg tr-row px-6 py-4 mt-2\n        tw-w-full default-font tw-text-black tw-cursor-pointer"; }},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.posted_at",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("datetime")(item.posted_at))+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-menu',{attrs:{"content-class":"mt-3 tw-rounded-lg","transition":"slide-y-transition","offset-y":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"default-font font-12 text-capitalize justify-space-between",attrs:{"color":"secondary","dark":"","outlined":"","elevation":"0","large":""}},on),[_vm._v(" Actions "),_c('v-icon',{staticClass:"ml-2",attrs:{"x-small":""}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{staticClass:"default-font tw-font-normal font-14 tw-text-black-400"},[_c('MatchHistoryModal',{attrs:{"orderId":item.id}})],1)],1)],1)],1)]}},{key:"expanded-item",fn:function(ref){
        var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.headers.length,"transition":"slide-y-transition"}},[_c('TransactionDetail',{attrs:{"id":item.id}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }