<template>
    <div
      class="tw-w-full pb-3"
      style="background: #fff"
    >
        <div class="d-flex flex-column">
            <h3 class="default-font font-21 px-12 py-4  tw-text-black">
                Hybrid Order Options Changelog
            </h3>
            <v-data-table
                item-key="id"
                :headers="headers"
                :items="items"
                :loading="loading"
                :items-per-page="pagination.pageSize"
                hide-default-footer
                class="px-12 py-4 custom-table"
                :item-class="() => 'default-font tw-text-black tw-cursor-pointer'"
            >
              <template v-slot:item.thirdPartyCompany.company.name="{ value }">
                {{ value || 'All' }}
              </template>

              <template v-slot:item.matcherAmountFormatted="{ value }">
                {{ value || 'All' }}
              </template>

              <template v-slot:item.exchangeRateFormatted="{ value }">
                {{ value || 'All' }}
              </template>

              <template v-slot:item.fee="{ value }">
                {{ value || 'All' }}
              </template>

              <template v-slot:item.matcher_currency="{ value }">
                {{ value || 'All' }}
              </template>

              <template v-slot:item.matcher_country="{ value }">
                {{ value || 'All' }}
              </template>

              <template v-slot:item.pb_currency="{ value }">
                {{ value || 'All' }}
              </template>

              <template v-slot:item.pb_country="{ value }">
                {{ value || 'All' }}
              </template>

              <template v-slot:item.user="{ item }">
                <v-avatar
                    size="24">
                    <v-img
                        :src="item.user.avatar || '/img/man.svg'">
                    </v-img>
                </v-avatar> {{ getUsersName(item) }}
              </template>

              <template v-slot:item.created_at="{ value }">
                {{ value | datetime }}
              </template>
            </v-data-table>

            <v-pagination
                v-model="pagination.page"
                :length="pagination.pageCount"
                next-icon="$next"
                prev-icon="$prev"
                class="custom-pagination"
                color="#7598B5">
            </v-pagination>
        </div>
    </div>
</template>
<script>
import HybridOrderOptionLog from '../../models/HybridOrderOptionLog';
import HybridOrderOptionsApi from '../../api/HybridOrderOptionsApi';

export default {
    data: () => ({
        loading: false,
        items: [],
        headers: [
            {
                text: 'User',
                align: 'start',
                value: 'user',
                class: 'default-font py-2 text-center last_cell tw-rounded-lg tw-rounded-l-none',
            },
            {
                text: 'Provider',
                align: 'start',
                value: 'thirdPartyCompany.company.name',
                class: 'default-font py-2 text-center last_cell tw-rounded-lg tw-rounded-l-none',
            },
            {
                text: 'Amount',
                align: 'start',
                value: 'matcherAmountFormatted',
                class: 'default-font py-2 text-center last_cell tw-rounded-lg tw-rounded-l-none',
            },
            {
                text: 'Exchange Rate',
                align: 'start',
                value: 'exchangeRateFormatted',
                class: 'default-font py-2 text-center last_cell tw-rounded-lg tw-rounded-l-none',
            },
            {
                text: 'Fee',
                align: 'start',
                value: 'fee',
                class: 'default-font py-2 text-center last_cell tw-rounded-lg tw-rounded-l-none',
            },
            {
                text: 'Matcher Currency',
                align: 'start',
                value: 'matcher_currency',
                class: 'default-font py-2 text-center last_cell tw-rounded-lg tw-rounded-l-none',
            },
            {
                text: 'Matcher Country',
                align: 'start',
                value: 'matcher_country',
                class: 'default-font py-2 text-center last_cell tw-rounded-lg tw-rounded-l-none',
            },
            {
                text: 'PB Currency',
                align: 'start',
                value: 'pb_currency',
                class: 'default-font py-2 text-center last_cell tw-rounded-lg tw-rounded-l-none',
            },
            {
                text: 'PB Country',
                align: 'start',
                value: 'pb_country',
                class: 'default-font py-2 text-center last_cell tw-rounded-lg tw-rounded-l-none',
            },
            {
                text: 'Date',
                align: 'start',
                value: 'created_at',
                class: 'default-font py-2 text-center last_cell tw-rounded-lg tw-rounded-l-none',
            },
        ],
        pagination: {
            page: 1,
            pageSize: 10,
        },
    }),
    methods: {
        async loadItems() {
            try {
                this.loading = true;

                const {
                    pagination: {
                        page,
                        pageSize,
                    },
                } = this;

                const { data } = await HybridOrderOptionsApi.getChangelog({
                    page,
                    pageSize,
                });

                const { data: models, pagination } = data;
                this.items = models.map((item) => new HybridOrderOptionLog(item));
                this.pagination = pagination;
            } finally {
                this.loading = false;
            }
        },
        getUsersName(item) {
            const { user } = item;
            return `${user.firstname} ${user.lastname}`;
        },
    },
    watch: {
        'pagination.page': {
            immediate: true,
            handler() {
                this.loadItems();
            },
        },
    },
    mounted() {
        this.loadItems();
    },
};
</script>
