export default {
    methods: {
        getPosterAmount(item) {
            const {
                match,
            } = item;

            if (match) {
                return match.poster_amount;
            }

            return item.poster_amount;
        },
        getMatcherAmount(item) {
            const {
                match,
                is_partial_match_allowed: isPartialMatchAllowed,
            } = item;

            if (match && isPartialMatchAllowed) {
                return match.matcher_amount;
            }

            return item.matcher_amount;
        },
        getPosterFee(item) {
            const {
                match,
                is_partial_match_allowed: isPartialMatchAllowed,
                poster_fee: posterFee,
            } = item;

            if (match && isPartialMatchAllowed) {
                const feePercentage = posterFee / item.poster_amount;
                if (match.poster_amount !== item.poster_amount) {
                    return Math.floor(match.poster_amount * feePercentage);
                }
            }

            return posterFee;
        },
        getTotalPosterAmount(item) {
            return this.getPosterFee(item) + this.getPosterAmount(item);
        },
        getPaymentType(item) {
            const {
                is_admin: isAdmin,
                payment_type: paymentType,
            } = item;

            if (isAdmin) {
                return 'Admin';
            }

            return paymentType || '';
        },
        getReference(item) {
            const {
                flwRef,
                poster_manual_payment_ref: manualPaymentRef,
            } = item;

            if (flwRef) {
                return flwRef;
            }

            return manualPaymentRef || '';
        },
        getOrderRouterLink(id) {
            const params = {
                id,
            };
            return {
                name: 'TransactionDetails',
                params,
            };
        },
    },
};
