<template>
    <div class="editor">
        <editor-content class="editor__content pa-5" :editor="editor" />
        <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
            <div class="menubar">
                <v-btn
                    icon
                    class="menubar__button"
                    @click="$refs.attach.click();"
                >
                    <v-icon small color="secondary">$attach</v-icon>
                </v-btn>

                <v-btn
                    icon
                    class="menubar__button"
                    :class="{ 'is-active': isActive.bold() }"
                    @click="commands.bold"
                >
                    <v-icon color="secondary">mdi-format-bold</v-icon>
                </v-btn>

                <v-btn
                    icon
                    class="menubar__button"
                    :class="{ 'is-active': isActive.italic() }"
                    @click="commands.italic"
                >
                    <v-icon color="secondary">mdi-format-italic</v-icon>
                </v-btn>

                <v-btn
                    icon
                    class="menubar__button"
                    :class="{ 'is-active': isActive.strike() }"
                    @click="commands.strike"
                >
                    <v-icon color="secondary">mdi-format-strikethrough-variant</v-icon>
                </v-btn>

                <v-btn
                    icon
                    class="menubar__button"
                    :class="{ 'is-active': isActive.underline() }"
                    @click="commands.underline"
                >
                    <v-icon color="secondary">mdi-format-underline</v-icon>
                </v-btn>

                <v-btn
                    icon
                    class="menubar__button"
                    :class="{ 'is-active': isActive.code() }"
                    @click="commands.code"
                >
                    <v-icon color="secondary">mdi-xml</v-icon>
                </v-btn>

                <v-btn
                    icon
                    class="menubar__button"
                    :class="{ 'is-active': isActive.paragraph() }"
                    @click="commands.paragraph"
                >
                    <v-icon color="secondary">mdi-format-paragraph</v-icon>
                </v-btn>

                <v-btn
                    icon
                    class="menubar__button"
                    :class="{ 'is-active': isActive.bullet_list() }"
                    @click="commands.bullet_list"
                >
                    <v-icon color="secondary">mdi-format-list-bulleted</v-icon>
                </v-btn>

                <v-btn
                    icon
                    class="menubar__button"
                    :class="{ 'is-active': isActive.ordered_list() }"
                    @click="commands.ordered_list"
                >
                    <v-icon color="secondary">mdi-format-list-numbered</v-icon>
                </v-btn>

                <v-btn
                    icon
                    class="menubar__button"
                    :class="{ 'is-active': isActive.blockquote() }"
                    @click="commands.blockquote"
                >
                    <v-icon color="secondary">mdi-format-quote-close</v-icon>
                </v-btn>

                <v-btn
                    icon
                    class="menubar__button"
                    :class="{ 'is-active': isActive.code_block() }"
                    @click="commands.code_block"
                >
                    <v-icon color="secondary">mdi-code-braces</v-icon>
                </v-btn>

                <v-btn
                    icon
                    class="menubar__button"
                    @click="commands.undo"
                >
                    <v-icon color="secondary">mdi-undo</v-icon>
                </v-btn>

                <v-btn
                    icon
                    class="menubar__button"
                    @click="commands.redo"
                >
                    <v-icon color="secondary">mdi-redo</v-icon>
                </v-btn>

            </div>
        </editor-menu-bar>
        <div class="mt-5 tw-w-full" v-if="attachedFiles.length > 0">
            <label class="subject">Attached files:</label>
            <div class="d-flex justify-space-arround mt-3">
                <v-chip
                    close
                    color="#F7FBFC"
                    text-color="#2E5677"
                    label
                    v-for="(attachFile, index) in attachedFiles"
                    :key="attachFile.name"
                    class="attach-files mr-3"
                    @click:close="removeFile(index)"
                >
                    {{attachFile.name}}
                </v-chip>
            </div>
        </div>
        <input type="file" multiple ref="attach" @change="onFileChange" class="tw-invisible"/>
    </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import {
    Blockquote,
    CodeBlock,
    HardBreak,
    Heading,
    HorizontalRule,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Code,
    Italic,
    Link,
    Strike,
    Underline,
    History,
} from 'tiptap-extensions';

export default {
    components: {
        EditorContent,
        EditorMenuBar,
    },
    data() {
        return {
            attachedFiles: [],
            editor: new Editor({
                extensions: [
                    new Blockquote(),
                    new BulletList(),
                    new CodeBlock(),
                    new HardBreak(),
                    new Heading({ levels: [1, 2, 3] }),
                    new HorizontalRule(),
                    new ListItem(),
                    new OrderedList(),
                    new TodoItem(),
                    new TodoList(),
                    new Link(),
                    new Bold(),
                    new Code(),
                    new Italic(),
                    new Strike(),
                    new Underline(),
                    new History(),
                ],
                content: `
          <h2>
            Hi there,
          </h2>
          <p>
            this is a very <em>basic</em> example of tiptap.
          </p>
          <pre><code>body { display: none; }</code></pre>
          <ul>
            <li>
              A regular list
            </li>
            <li>
              With regular items
            </li>
          </ul>
          <blockquote>
            It's amazing 👏
            <br />
            – mom
          </blockquote>
        `,
            }),
        };
    },
    methods: {
        onFileChange(e) {
            this.attachedFiles.push(e.target.files[0]);
        },
        removeFile(index) {
            this.$delete(this.attachedFiles, index);
        },
    },
    beforeDestroy() {
        this.editor.destroy();
    },
};
</script>
<style>
.editor__content {
  background: #F7FBFC;
caret-color: currentColor;
/* Lines */
border: 1px solid #EAF0F5;
box-sizing: border-box;
border-radius: 4px;
}
.menubar .v-input__control{
  display: none;
}
.attach-files .v-chip__content {
  font-family: Muli;
font-style: normal;
font-weight: normal;
font-size: 13px;
line-height: 160%;
}

::after, ::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.editor__content blockquote {
    border-left: 3px solid rgba(0,0,0,.1);
    color: rgba(0,0,0,.8);
    padding-left: .8rem;
    font-style: italic;
}
.editor__content ul {
  list-style: inherit;
  padding-left: 1rem;
}
.editor__content ol {
  padding-left: 1rem;
  list-style: decimal;
}
</style>
