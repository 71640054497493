<template>
  <tr class="tw-bg-hoverBg tr-row px-8 py-4 mt-2 tw-w-full default-font tw-text-black">
    <td class="tr-row" v-for="header in headers" :key="header.value" :class="header.class">
      <slot :name="header.value" :item="item" :header="header">{{ item[header.value] }}</slot>
    </td>
  </tr>
</template>
<script>
export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
        headers: {
            type: Array,
            required: true,
        },
        isExpanded: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
