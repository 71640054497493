<template>
  <v-tabs background-color="white" right active-class="tw-text-black" class="pt-12 pl-12 pr-12">
    <v-tab class="tab-wrapper text-capitalize">All</v-tab>
    <v-tab class="tab-wrapper text-capitalize">Opened</v-tab>
    <v-tab class="tab-wrapper text-capitalize">Closed</v-tab>

    <v-tab-item>
      <support-list :items="items" :search="search"></support-list>
    </v-tab-item>
    <v-tab-item>
        <support-list :items="openedMessages" :search="search"></support-list>
    </v-tab-item>
    <v-tab-item>
        <support-list :items="closedMessages" :search="search"></support-list>
    </v-tab-item>
  </v-tabs>
</template>
<script>
import SupportList from './SupportList.vue';
import messages from '../../utils/messages';

export default {
    props: {
        search: {
            type: String,
        },
    },
    components: { SupportList },

    name: 'SupportThreadsTable',

    data: () => ({
        items: messages,
    }),
    computed: {
        openedMessages() {
            return this.items.filter((msg) => msg.status === 'opened');
        },
        closedMessages() {
            return this.items.filter((msg) => msg.status === 'closed');
        },
    },
};
</script>
<style scoped>
.tab-wrapper {
    font-family: Muli;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 136.5%;

/* or 19px */
text-align: right;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
color: #7598B5;
}
</style>
