<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="apiLogs"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      class="elevation-0 log-tab-style  tab-title"
      @page-count="pageCount = $event"
      @click:row="handleClick"
    >
      <template v-slot:item.date="{ item }">
        <span class="font-style font-light-13">{{item.date | date}} at {{item.date | time}}</span>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip
          class="ma-2 text-uppercase tab-content"
          :color="statusColor(item.status).bg"
          :text-color="statusColor(item.status).color" dark
        >
          {{ (item.status == '200') ? '200 OK' : item.status }}
        </v-chip>
      </template>
      <template v-slot:item.description="{ item }">
        <span class="font-style font-dark-14">{{ item.description }}</span>
      </template>
      <template v-slot:item.action="{ item }">
        {{ item.action }} <v-icon class="mr-2" small="">$sup</v-icon>
      </template>
    </v-data-table>
    <v-pagination
      v-model="page"
      next-icon="$next"
      prev-icon="$prev"
      color="#7598B5"
      :length="pageCount"
      class="mt-2 custom-pagination"
    ></v-pagination>
  </div>
</template>
<script>
import { headers, apiLogs } from '@/utils/Developer/api-logs';

export default {
    data() {
        return {
            page: 1,
            pageCount: 0,
            itemsPerPage: 5,
            headers,
            apiLogs,
        };
    },
    methods: {
        statusColor(status) {
            switch (status) {
            case '200':
                return { bg: '#C5F5E7', color: '#15ABAB' };
            default:
                return { bg: '#FFE3E2', color: '#F07877' };
            }
        },
        handleClick(value) {
            this.$router.push(`/developer-log-details/${value.id}`);
        },
    },
    created() {
        this.$store.dispatch('loadApiLogs', apiLogs);
    },
};
</script>
<style>

.log-tab-style table {
  box-shadow: none !important;
  border-spacing: 0 0.0em !important
}
.log-tab-style tbody tr, .log-tab-style tbody tr:hover{
background-color: #F7FBFC !important;
line-height: 55px !important;
border-radius: 6px !important;
}
.log-tab-style tbody{
box-shadow: 0px 8px 33px rgba(224, 224, 224, 0.1) !important;
}
.log-tab-style tbody tr{
cursor: pointer;
}

.log-tab-style td {
  border-bottom: 1px solid #F0F0F7 !important;
}
.log-tab-style th {
  border-bottom: none !important;
}
</style>
