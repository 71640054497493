import store from '../store';
import middlewarePipeline from './middlewarePipeline';
import guest from './before/guest';
import auth from './before/auth';

export default (to, from, next) => {
    if (store.getters.isAuth) {
        auth(to, from, next);
    } else {
        guest(to, from, next);
    }

    if (!to.meta.middleware) {
        return next();
    }

    const { middleware } = to.meta;

    const context = {
        to,
        from,
        next,
        store,
    };

    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1),
    });
};
