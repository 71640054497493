import api from './index';

const resource = 'third-party-companies';

export default {
    /**
     * Get all Third Party Companies
     * @param {Object} params query params
     */
    getAll(params) {
        return api.get(`${resource}`, {
            params,
        });
    },
};
