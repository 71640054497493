import api from './index';

const resource = 'admin/transactions';

export default {
    get(id) {
        return api.get(`${resource}/${id}`);
    },
    getAll(params) {
        return api.get(`${resource}`, {
            params,
        });
    },
    export(params) {
        return api.get(`${resource}/export`, {
            params,
        });
    },
    statistics(params) {
        return api.get(`${resource}/statistics`, {
            params,
        });
    },
};
