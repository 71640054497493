<template>
  <v-container class="tw-w-full  md:tw-w-2/3">
    <v-row class="mx-sm-0 mx-md-auto bank--block tw-bg-white">
      <v-col cols="4" class="steps d-none lg:tw-px-10 tw-py-10 d-md-flex flex-md-column">
        <h2 class="title-font mb-10 ml-2">Complete registration</h2>
        <stepper :steps="steps"></stepper>
      </v-col>
      <v-col cols="12" md="8" class="d-flex flex-column lg:tw-px-20 tw-py-10">
        <span class="step--text title-font">Step 2 of 4</span>
        <h2 class="text--title mb-10">Details about your bank</h2>
        <form>
          <v-row>
            <v-col cols="12" class="d-flex flex-column pa-2">
              <label class="f-label default-font">Country of business:</label>
              <input
                type="text"
                class="f-input default-font pa-2 tw-border-binput focus:tw-border-primary"
              />
            </v-col>
            <v-col cols="12" class="d-flex flex-column pa-2">
              <label class="f-label default-font">Company name:</label>
              <input class="f-input default-font pa-2 tw-border-binput focus:tw-border-primary" />
            </v-col>
            <v-col cols="12" class="d-flex flex-column pa-2">
              <label class="f-label default-font">Legal entity:</label>
              <input class="f-input default-font pa-2 tw-border-binput focus:tw-border-primary" />
            </v-col>
            <v-col cols="12" class="d-flex flex-column pa-2">
              <label class="f-label default-font">Contact Phone:</label>
              <input class="f-input default-font pa-2 tw-border-binput focus:tw-border-primary" />
            </v-col>
            <v-col cols="12" class="d-flex flex-column pa-2">
              <label class="f-label default-font">Location:</label>
              <v-row class="tw-w-full mr-0 ml-0">
                <v-col cols="12" class="pa-0">
                  <input
                    class="f-input default-font pa-2 tw-border-binput focus:tw-border-primary tw-w-full mb-2"
                  />
                </v-col>
                <v-col cols="12" md="6" class="pa-1 pl-0">
                  <input
                    class="f-input default-font pa-2 tw-border-binput focus:tw-border-primary tw-w-full mr-2"
                  />
                </v-col>
                <v-col cols="12" md="3" class="pa-1">
                  <div class="tw-relative">
                    <select
                      class="f-input tw-block tw-appearance-none tw-w-full tw-text-gray-700 tw-py-2 tw-px-4 tw-pr-8 tw-rounded tw-leading-tight focus:tw-outline-none focus:tw-bg-white focus:tw-border-gray-500"
                      id="grid-state"
                    >
                      <option>New Mexico</option>
                      <option>Missouri</option>
                      <option>Texas</option>
                    </select>
                    <div
                      class="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-px-2 tw-text-gray-700"
                    >
                      <svg
                        class="tw-fill-current tw-h-4 tw-w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path
                          d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                        />
                      </svg>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="3" class="pa-1 pr-0">
                  <input
                    class="f-input default-font pa-2 tw-border-binput focus:tw-border-primary tw-w-full"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-btn
                color="primary"
                id="signBtn"
                large
                class="default-font text-capitalize text-center mt-5"
                to="/billing"
              >Continue</v-btn>
            </v-col>
          </v-row>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Stepper from '../globals/Stepper.vue';

export default {
    data() {
        return {
            e6: 1,
            steps: [
                {
                    label: 'E-mail & Password',
                    state: 'checked',
                    step: 1,
                },
                {
                    label: 'About bank',
                    state: 'active',
                    step: 2,
                },
                {
                    label: 'Billing',
                    state: 'default',
                    step: 3,
                },
                {
                    label: 'Licensure and compliance',
                    state: 'default',
                    step: 4,
                },
            ],
        };
    },
    components: { Stepper },
};
</script>
<style lang="scss">
.steps {
  background: #fcfcff;
  border-radius: 11px;
}
.f-label {
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;

  /* or 22px */

  /* TEXT */
  color: #1a244e;
  opacity: 0.6;
}

.f-input {
  @apply tw-border tw-border-solid tw-rounded tw-font-normal;
}

.f-input {
  font-size: 15px;
  /* identical to box height, or 24px */

  /* Text black */
  color: #2e5677;
}

.bank--block {
  box-shadow: 0px 10px 50px rgba(219, 219, 231, 0.2);
  border-radius: 11px;
}

.text--title {
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 27px;
  line-height: 136.5%;

  /* identical to box height, or 37px */

  color: #404159;
}

.title-font {
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 160%;

  color: #404159;

  /* identical to box height, or 21px */
}
.step--text {
  color: #9f9faa;
}
.default-font {
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  line-height: 160%;
}

.step-label {
  color: #2e5677;
  font-size: 14px;
  &.upcoming-step {
    color: #7598b5;
  }
}
.step-divider {
  min-height: 24px !important;
}

.step {
  @apply tw-border tw-border-solid tw-flex tw-items-center tw-justify-center tw-h-8 tw-w-8 tw-rounded-full tw-text-default;
}

.current-step {
  @apply tw-border-primary;
}

.current-step {
  color: #2e5677;
}
</style>
