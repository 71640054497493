<template>
  <v-container fluid>
    <v-data-iterator
      :items="items"
      :items-per-page="4"
      :page.sync="page"
      @page-count="pageCount = $event"
      :search="search"
      hide-default-footer
    >
      <template v-slot:item="{ item }">
        <support-row :item="item"></support-row>
      </template>
      <template v-slot:footer>
        <v-pagination
          v-model="page"
          v-if="pageCount > 1"
          next-icon="$next"
          prev-icon="$prev"
          color="#7598B5"
          class="custom-pagination"
          :length="pageCount"
        ></v-pagination>
      </template>
    </v-data-iterator>
  </v-container>
</template>
<script>
import SupportRow from './SupportRow.vue';

export default {
    components: { SupportRow },
    props: {
        items: {
            type: Array,
            required: true,
        },
        search: {
            type: String,
        },
    },
    data: () => ({
        itemsPerPage: 5,
        page: 1,
        pageCount: 0,
    }),
};
</script>
