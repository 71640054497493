import api from './index';

const resource = 'admin/matches';

export default {
    cancel(id) {
        return api.post(`${resource}/cancel/${id}`, {});
    },
    approve(id) {
        return api.post(`${resource}/approve/${id}`, {});
    },
    rematch(id) {
        return api.post(`${resource}/${id}/rematch`);
    },
    getValidationJourney(id) {
        return api.get(`${resource}/${id}/validation-journey`);
    },
};
