import api from './index';

const resource = 'admin/exchange-rate-limits';

export default {
    /**
     * Create Exchange Rate Limit
     * @param {Object} payload payload
     */
    create(payload) {
        return api.post(`${resource}`, payload);
    },

    /**
     * Get all Exchange Rate Limits
     * @param {Object} params query params
     */
    getAll(params) {
        return api.get(`${resource}`, {
            params,
        });
    },

    /**
     * Update Exchange Rate Limit
     * @param {Object} payload Exchange Rate Limit Model
     */
    update(payload) {
        const { id } = payload;
        return api.post(`${resource}/${id}`, payload);
    },

    /**
     * Delete Exchange Rate Limit
     * @param {Number} id Exchange Rate Limit Model ID
     */
    delete(id) {
        return api.delete(`${resource}/${id}`);
    },
};
