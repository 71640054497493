export default {
    namespaced: true,

    state: {
        cardVerificationNotifications: [],
    },

    getters: {
        cardVerificationNotifications: ({ cardVerificationNotifications }) => cardVerificationNotifications,
        cardVerificationNotificationsCount: ({ cardVerificationNotifications }) => cardVerificationNotifications?.pagination?.rowCount,
    },

    mutations: {
        SET_CARD_VER_NOTIFICATIONS(state, payload) {
            state.cardVerificationNotifications = payload;
        },
    },

    actions: {
        storeCardVerificationNotifications({ commit }, payload) {
            commit('SET_CARD_VER_NOTIFICATIONS', payload);
        },
    },
};
