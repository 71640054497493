import api from './index';

const resource = 'admin/orders';

export default {

    /**
     * Create new order
     * @param {Object} payload order model
     */
    create(payload) {
        return api.post(`${resource}`, payload);
    },

    /**
     * Get order by id
     * @param {String} id order id
     */
    get(id) {
        return api.post(`${resource}/${id}`);
    },

    complete(id) {
        return api.post(`${resource}/complete/${id}`, {});
    },
    expire(id) {
        return api.post(`${resource}/expire/${id}`, {});
    },
    setHidden(id, payload) {
        return api.post(`${resource}/set-hidden/${id}`, payload);
    },
    bulkSetHidden(payload) {
        return api.post(`${resource}/set-hidden`, payload);
    },
    getMatches(id, params) {
        return api.get(`${resource}/${id}/matches`, {
            params,
        });
    },
    getEvents(id, params) {
        return api.get(`${resource}/${id}/events`, {
            params,
        });
    },
    capture(id) {
        return api.post(`${resource}/capture/${id}`, {});
    },
    void(id) {
        return api.post(`${resource}/void/${id}`, {});
    },
    publish(id, payload) {
        return api.post(`${resource}/publish/${id}`, payload);
    },
    markAsMatcherPaid(id) {
        return api.post(`${resource}/mark-as-matcher-paid/${id}`, {});
    },
    markAsRefunded(id) {
        return api.post(`${resource}/mark-as-refunded/${id}`, {});
    },
    investigate(id) {
        return api.post(`${resource}/investigate/${id}`, {});
    },
};
