<template>
  <div class="d-flex flex-column mt-5 t-filter tw-w-full px-12 py-4">
    <h4 class="default-font tw-text-black filter-title">Filter results</h4>
    <v-row>
      <v-col cols="2">
        <label class="default-font filter-title tw-text-secondary mb-2 d-block">Sent:</label>
        <sent-filter :disabled="disabled"></sent-filter>
      </v-col>
      <v-col cols="2">
        <label class="default-font filter-title tw-text-secondary mb-2 d-block">Recieved:</label>
        <recieved-filter :disabled="disabled"></recieved-filter>
      </v-col>
      <v-col cols="2">
        <label class="default-font filter-title tw-text-secondary mb-2 d-block">Matcher Country:</label>
        <MatcherCountryFilter :disabled="disabled"></MatcherCountryFilter>
      </v-col>
      <v-col cols="2">
        <label class="default-font filter-title tw-text-secondary mb-2 d-block">Currency:</label>
        <currency-filter :disabled="disabled"></currency-filter>
      </v-col>
      <v-col cols="2">
        <label class="default-font filter-title tw-text-secondary mb-2 d-block">Status:</label>
        <status-filter :disabled="disabled"></status-filter>
      </v-col>
      <v-col cols="2">
        <label class="default-font filter-title tw-text-secondary mb-2 d-block">Type:</label>
        <type-filter :disabled="disabled"></type-filter>
      </v-col>
      <v-col cols="2">
        <label class="default-font filter-title tw-text-secondary mb-2 d-block">Partial Match Allowed:</label>
        <PartialMatchFilter />
      </v-col>
      <v-col cols="2">
        <label class="default-font filter-title tw-text-secondary mb-2 d-block">Period:</label>
        <TransactionPeriodFilter :disabled="disabled"></TransactionPeriodFilter>
      </v-col>
      <v-col cols="2">
        <label class="default-font filter-title tw-text-secondary mb-2 d-block">Payment Type:</label>
        <PaymentTypeFilter />
      </v-col>
      <v-col cols="2">
        <label class="default-font filter-title tw-text-secondary mb-2 d-block">Payment Provider:</label>
        <PaymentProviderFilter :disabled="disabled" />
      </v-col>
      <v-col cols="2">
        <label class="default-font filter-title tw-text-secondary mb-2 d-block">Refund Status:</label>
        <RefundStatusFilter :disabled="disabled" />
      </v-col>

      <v-col cols="2" class="d-flex justify-end align-center">
        <v-btn
          text
          color="secondary"
          class="text-capitalize"
          @click="clearAllFilters"
          :disabled="disabled"
        >
            <v-icon color="secondary">mdi-close-circle</v-icon>
            <span class="default-font filter-title ml-2">Clear filters</span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import SentFilter from './SentFilter.vue';
import RecievedFilter from './RecievedFilter.vue';
import MatcherCountryFilter from './MatcherCountryFilter.vue';
import CurrencyFilter from './CurrencyFilter.vue';
import StatusFilter from './StatusFilter.vue';
import TypeFilter from './TypeFilter.vue';
import PartialMatchFilter from './PartialMatchFilter.vue';
import TransactionPeriodFilter from './TransactionPeriodFilter.vue';
import PaymentTypeFilter from './PaymentTypeFilter.vue';
import PaymentProviderFilter from './PaymentProviderFilter.vue';
import RefundStatusFilter from './RefundStatusFilter.vue';

export default {
    components: {
        SentFilter,
        RecievedFilter,
        MatcherCountryFilter,
        CurrencyFilter,
        StatusFilter,
        TransactionPeriodFilter,
        TypeFilter,
        PartialMatchFilter,
        PaymentTypeFilter,
        PaymentProviderFilter,
        RefundStatusFilter,
    },
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
        return {
            status: ['Any status', 'Completed', 'Pending', 'Canced'],
        };
    },
    methods: {
        ...mapActions('transaction', ['clearAllFilters']),
    },
};
</script>
<style>
.t-filter {
  background-color: #f7fbfc;
}
.filter-title {
  font-size: 13px;
}
</style>
