<template>
    <div class="tw-w-full">
        <AppBar>
            <template #title>
                <v-toolbar-title class="default-font font-21 mr-10 tw-text-black">
                    Health Insurance Payments
                </v-toolbar-title>
            </template>
            <div class="d-flex justify-space-between mr-10">
                <Search
                    v-model="search"
                    placeholder="Search...">
                </Search>
            </div>
        </AppBar>

        <div class="d-flex flex-column">
            <v-data-table
                item-key="id"
                :headers="headers"
                :items="items"
                :loading="loading"
                :items-per-page="pagination.pageSize"
                hide-default-footer
                class="px-12 py-4 tw-bg-transparent custom-table"
                :item-class="() => 'default-font tw-text-black tw-cursor-pointer'">

                <template #item.completed_at="{ value }">
                    {{ value | datetime }}
                </template>

                <template #item.confirmed_at="{ item, value }">
                    <div v-if="value">
                        {{ value | datetime }}
                    </div>
                    <v-btn
                        v-else
                        color="secondary"
                        class="default-font font-12 text-capitalize"
                        elevation="0"
                        dark
                        @click="() => confirm(item)">
                        Confirm
                    </v-btn>
                </template>

            </v-data-table>

            <v-pagination
                v-model="pagination.page"
                :length="pagination.pageCount"
                next-icon="$next"
                prev-icon="$prev"
                class="custom-pagination"
                color="#7598B5">
            </v-pagination>
        </div>
    </div>
</template>
<script>
import HealthInsurancePaymentsApi from '../../api/HealthInsurancePaymentsApi';

import AppBar from '../../components/globals/AppBar.vue';
import Search from '../../components/globals/Search.vue';

export default {
    components: {
        AppBar,
        Search,
    },
    data: () => ({
        loading: false,
        items: [],
        pagination: {
            page: 1,
            pageSize: 10,
        },
        search: '',
    }),
    computed: {
        headers() {
            return [
                {
                    text: 'Enrollee Name',
                    align: 'start',
                    value: 'healthInsuranceEnrollee.full_name',
                    sortable: false,
                    class: 'py-2',
                },
                {
                    text: 'Amount',
                    align: 'start',
                    value: 'amount',
                    sortable: false,
                    class: 'py-2',
                },
                {
                    text: 'Plan',
                    align: 'start',
                    value: 'healthInsuranceEnrollee.healthInsurancePlan.name',
                    sortable: false,
                    class: 'py-2',
                },
                {
                    text: 'Completed At',
                    align: 'start',
                    value: 'completed_at',
                    sortable: false,
                    class: 'py-2',
                },
                {
                    text: 'Confirmed At',
                    align: 'start',
                    value: 'confirmed_at',
                    sortable: false,
                    class: 'py-2',
                },
            ];
        },
    },
    methods: {
        async loadItems() {
            try {
                this.loading = true;

                const {
                    type,
                    search,
                    pagination: {
                        page,
                        pageSize,
                    },
                } = this;

                const { data } = await HealthInsurancePaymentsApi.getAll({
                    type,
                    search,
                    page,
                    pageSize,
                });

                const { models, pagination } = data;
                this.items = models;
                this.pagination = pagination;
            } finally {
                this.loading = false;
            }
        },

        async confirm(item) {
            try {
                const { value } = await this.$swal({
                    icon: 'warning',
                    title: 'Are you sure you want to confirm this payment?',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                });

                if (value) {
                    this.loading = true;
                    await HealthInsurancePaymentsApi.confirm(item.id);

                    this.$swal({
                        icon: 'success',
                        title: 'Confirmed',
                        text: 'Payment successfully confirmed',
                    });
                    this.loadItems();
                }
            } finally {
                this.loading = false;
            }
        },
    },
    watch: {
        type: 'loadItems',
        search: 'loadItems',
        'pagination.page': {
            immediate: true,
            handler() {
                this.loadItems();
            },
        },
    },
};
</script>
