<template>
    <div class="tw-w-full">
        <app-bar>
            <template slot="title">
                <v-toolbar-title class="default-font font-21 mr-10 tw-text-black">Third Party Transactions</v-toolbar-title>
            </template>
            <div class="d-flex justify-space-between mr-10">
                <search v-model="search" placeholder="Search by Transaction ID or Name..."></search>
                <div>
                    <v-btn
                        color="primary"
                        icon
                        @click="loadItems">
                        <v-icon>mdi-autorenew</v-icon>
                    </v-btn>
                    <export-modal
                        @exportToPdf="exportTransactions('pdf')"
                        @exportToXls="exportTransactions('xlsx')"
                    />
                    <v-btn text color="primary" class="text-capitalize default-font"
                           @click="showFilters = !showFilters">
                        <v-icon class="mr-3">$vuetify.icons.filter</v-icon>{{ filterText }}
                    </v-btn>
                </div>
            </div>
        </app-bar>
        <v-expand-transition>
            <transaction-filter v-if="showFilters" :disabled="loading"></transaction-filter>
        </v-expand-transition>
        <transaction-table
            :transactions="transactions"
            :pagination="pagination"
            :transactions-statistic="transactionsStatistic"
            :loading="loading"
            @pageChange="onPageChange"
            :search="search"
            class="mt-5"
        ></transaction-table>
    </div>
</template>
<script>
import AppBar from '@/components/globals/AppBar.vue';
import TransactionFilter from '@/components/thirdPartyTransactions/Filter.vue';
import TransactionTable from '@/components/thirdPartyTransactions/TransactionTable.vue';
import ExportModal from '@/components/transactions/ExportModal.vue';
import Search from '@/components/globals/Search.vue';
import ThirdPartyTransactionsApi from '@/api/ThirdPartyTransactionsApi';
import { mapState } from 'vuex';

export default {
    components: {
        AppBar,
        TransactionFilter,
        TransactionTable,
        ExportModal,
        Search,
    },
    data() {
        return {
            filters: [],
            showFilters: false,
            loading: true,
            transactions: [],
            search: null,
            debounce: null,
            pagination: {
                page: 1,
                pageSize: 20,
            },
            transactionsStatistic: {},
        };
    },
    watch: {
        search() {
            this.pagination.page = 1;
            this.loadItemsDebounced();
        },
        sentFilter: {
            deep: true,
            handler() {
                this.pagination.page = 1;
                this.loadItemsDebounced();
            },
        },
        receivedFilter: {
            deep: true,
            handler() {
                this.pagination.page = 1;
                this.loadItemsDebounced();
            },
        },
        matcherCountryFilter: {
            deep: true,
            handler() {
                this.pagination.page = 1;
                this.loadItemsDebounced();
            },
        },
        currencyFilter: {
            deep: true,
            handler() {
                this.pagination.page = 1;
                this.loadItemsDebounced();
            },
        },
        periodFilter: {
            deep: true,
            handler(period) {
                const { key, range } = period;
                if (key === 'custom') {
                    if (!range[0] || !range[1]) {
                        return false;
                    }
                }
                return this.loadItemsDebounced();
            },
        },
        periodFilterTarget: {
            deep: true,
            handler() {
                this.pagination.page = 1;
                this.loadItemsDebounced();
            },
        },
        statusFilter: {
            deep: true,
            handler() {
                this.pagination.page = 1;
                this.loadItemsDebounced();
            },
        },
        orderBy: {
            deep: true,
            handler(orderBy) {
                this.orderBy = orderBy;
                return this.loadItemsDebounced();
            },
        },
    },
    methods: {
        loadItemsDebounced() {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.loadItems();
            }, 300);
        },
        populateTransactionDataFromResponse(apiResponse) {
            if (apiResponse.status !== 'fulfilled') {
                // do nothing
                return;
            }

            const { models, pagination } = apiResponse.value.data;
            this.transactions = models;
            this.pagination = pagination;
        },
        populateTransactionStatisticsFromResponse(apiResponse) {
            if (apiResponse.status !== 'fulfilled') {
                // do some default setup:
                this.transactionsStatistic = {
                    total_poster_amount: 0,
                    total_matcher_amount: 0,
                    total_poster_fee: 0,
                    total_collection_fee: 0,
                    total_payout_fee: 0,
                    total_poster_payout_fee: 0,
                    total_stampduty_fee: 0,
                };
                return;
            }
            this.transactionsStatistic = apiResponse.value.data.transactionsStatistic;
        },
        loadItems() {
            this.loading = true;
            const { page, pageSize } = this.pagination;
            const fromCurrency = (this.currencyFilter || []).map(({ from }) => from);
            const toCurrency = (this.currencyFilter || []).map(({ to }) => to);
            const filters = {
                posterAmount: this.sentFilter,
                matcherAmount: this.receivedFilter,
                matcherCountry: this.matcherCountryFilter,
                fromCurrency,
                toCurrency,
                period: this.periodFilter.range || [],
                periodTarget: this.periodFilterTarget,
                statuses: this.statusFilter,
            };

            const payload = {
                page,
                pageSize,
                filters,
                orderBy: this.orderBy,
            };

            if (this.search) {
                payload.search = this.search;
            }

            const promises = [];
            promises.push(ThirdPartyTransactionsApi.getAll(payload));
            promises.push(ThirdPartyTransactionsApi.statistics(payload));

            Promise.allSettled(promises)
                .then((results) => {
                    this.loading = false;
                    const [transactionDataResponse, transactionStatisticsResponse] = results;
                    this.populateTransactionDataFromResponse(transactionDataResponse);
                    this.populateTransactionStatisticsFromResponse(transactionStatisticsResponse);
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        onPageChange(page) {
            this.pagination.page = page;
            this.loadItems();
        },
        exportTransactions(format) {
            this.loading = true;
            const { page, pageSize } = this.pagination;
            const fromCurrency = (this.currencyFilter || []).map(({ from }) => from);
            const toCurrency = (this.currencyFilter || []).map(({ to }) => to);
            const filters = {
                posterAmount: this.sentFilter,
                matcherAmount: this.receivedFilter,
                matcherCountry: this.matcherCountryFilter,
                fromCurrency,
                toCurrency,
                period: this.periodFilter.range || [],
                periodTarget: this.periodFilterTarget,
                statuses: this.statusFilter,
            };

            const payload = {
                page,
                pageSize,
                filters,
                orderBy: this.orderBy,
                format,
            };

            if (this.search) {
                payload.search = this.search;
            }

            ThirdPartyTransactionsApi.export(payload)
                .then((response) => {
                    this.loading = false;
                    const { url } = response.data;
                    window.open(url); // eslint-disable-line
                })
                .catch(() => {
                    this.loading = false;
                });
        },
    },
    computed: {
        ...mapState('thirdPartyTransaction', [
            'sentFilter',
            'receivedFilter',
            'matcherCountryFilter',
            'currencyFilter',
            'periodFilter',
            'periodFilterTarget',
            'statusFilter',
            'orderBy',
        ]),
        filterText() {
            return this.showFilters ? 'Hide filters' : 'Show filters';
        },
    },
    mounted() {
        this.loadItems();
    },
};
</script>
<style>
.font-21 {
  font-size: 21px !important;
}
.font-14 {
  font-size: 14px !important;
}
.font-17 {
    font-size: 17px !important;
}
.font-18 {
      font-size: 18px !important;
}
.filter-title .v-btn__content {
  font-size: 13px !important;
}
</style>
