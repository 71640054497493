<template>
  <v-container class="tw-w-full md:tw-w-2/3">
    <v-row class="mx-auto bank--block tw-bg-white">
      <v-col cols="3" lg="4" class="steps d-flex flex-column md:tw-pl-10 tw-py-10" >
          <h2 class="title-font mb-10 ml-2"> Complete registration </h2>
          <stepper :steps="steps"></stepper>
      </v-col>
      <v-col cols="12" md="9" lg="8" class="d-flex flex-column md:tw-px-20 tw-py-10">
        <span class="step--text title-font">Step 4 of 4</span>
        <h2 class="text--title mb-10">Licensure and compliance</h2>
        <form>
          <v-row>
            <v-col cols="12" class="d-flex flex-column pa-2">
              <label class="f-label default-font">Bank license:</label>
              <div class="tw-flex tw-items-center">
                <label class="licence-input tw-bg-hoverBg">
                  <div class="tw-flex tw-flex-col ml-2">
                    <span class="default-font tw-text-black-500 tw-text-sm">Bank license.pdf</span>
                    <div class="default-font tw-text-primary tw-text-xs">
                      <v-icon color="primary">mdi-check</v-icon>
                      Uploaded
                    </div>
                  </div>
                </label>
                <label class="ml-4">
                <span class="default-font tw-text-black tw-text-xs tw-cursor-pointer">Replace</span>
                  <input type='file' class="tw-hidden" />
                </label>
              </div>
            </v-col>
            <v-col cols="12" class="d-flex flex-column pa-2 mt-4">
              <label class="f-label default-font">Registration document:</label>
              <label class="licence-input hover:tw-bg-hoverBg hover:tw-text-black-500 tw-border-dashed">
                <svg width="20" height="28" viewBox="0 0 20 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.96987 24.8302H3.32584C1.89542 24.8302 0.73584 23.6706 0.73584 22.2402V9.00508L8.32346 1.39771L16.9079 1.39771C18.3383 1.39771 19.4979 2.55729 19.4979 3.98771V22.2402C19.4979 23.6706 18.3383 24.8302 16.9079 24.8302H14.0485" stroke="#15ABAB"/>
                <path d="M8.74858 1.51392V7.50116C8.74858 8.54498 7.9024 9.39116 6.85858 9.39116H0.871338" stroke="#1E807A"/>
                <rect x="8.72168" y="15.4861" width="2.79055" height="11.7952" rx="0.5" fill="#15ABAB"/>
                <path d="M9.67485 12.7807C9.86254 12.4255 10.3713 12.4255 10.559 12.7807L13.5145 18.3737C13.6905 18.7067 13.4491 19.1073 13.0724 19.1073H7.16141C6.78479 19.1073 6.54337 18.7067 6.71933 18.3737L9.67485 12.7807Z" fill="#15ABAB"/>
                </svg>

                <div class="tw-flex tw-flex-col ml-2">
                  <span class="default-font tw-text-primary tw-text-sm">Upload</span>
                  <span class="default-font tw-text-secondary tw-text-xs">PDF, PNG, JPEG</span>
                </div>
                <input type='file' class="tw-hidden" />
              </label>
            </v-col>
            <v-col cols="12" class="d-flex justify-space-between">
              <v-btn
                id="signBtn"
                text
                large
                class="default-font text-capitalize text-center mt-12 tw-text-black"
                to="/billing"
              >
              <v-icon>mdi-reply</v-icon>
              Back
              </v-btn>
              <v-btn
                color="primary"
                large
                class="default-font text-capitalize text-center mt-12 px-8"
                to="/login"
              >Finish</v-btn>
            </v-col>
          </v-row>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Stepper from '../globals/Stepper.vue';

export default {
    data() {
        return {
            e6: 1,
            steps: [
                {
                    label: 'E-mail & Password',
                    state: 'checked',
                    step: 1,
                },
                {
                    label: 'About bank',
                    state: 'checked',
                    step: 2,
                },
                {
                    label: 'Billing',
                    state: 'checked',
                    step: 3,
                },
                {
                    label: 'Licensure and compliance',
                    state: 'active',
                    step: 4,
                },
            ],
        };
    },
    components: { Stepper },
};
</script>

<style scoped>
.licence-input {
  @apply tw-w-64 tw-flex tw-items-center tw-px-4  tw-py-2 tw-bg-white tw-text-primary tw-rounded-lg tw-box-border tw-tracking-wide tw-border tw-border-binput tw-cursor-pointer;
}
</style>
