var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-w-4/5 d-flex flex-column justify-center align-center mx-auto my-8"},[(!_vm.item)?_c('div',{staticClass:"tw-w-full d-flex justify-space-between align-center tw-px-24"},[_vm._v(" Loading... ")]):_vm._e(),(_vm.item)?_c('div',{staticClass:"tw-w-full d-flex justify-space-between align-center tw-px-24 mt-3"},[_c('div',{staticClass:"group-17 tw-max-w-sm tw-flex"},[_c('v-tooltip',{attrs:{"bottom":"","content-class":"tooltip","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-avatar',_vm._g({staticClass:"tw-flex-shrink-0"},on),[_c('img',{attrs:{"src":_vm.matcher.avatar || '/img/man.svg',"alt":_vm.matcher.name}})])]}}],null,false,648440984)},[_c('person-info',{attrs:{"person":_vm.matcher}})],1),_c('div',{staticClass:"tw-ml-3 tw-pt-1"},[_c('h4',{staticClass:"tw-text-primary default-font font-14"},[_c('router-link',{attrs:{"to":{
                        name: 'User',
                        params: {
                            id: _vm.matcher.id,
                        },
                    }}},[_vm._v(" "+_vm._s(_vm.matcher.name)+" ")])],1),_c('p',{staticClass:"default-font font-12 tw-text-black d-flex align-center"},[_c('gb-flag',{staticClass:"mr-1",attrs:{"code":_vm.matcher.country_abbreviation,"size":"small"}}),_vm._v(_vm._s(_vm.matcher.country)+" ")],1)])],1),_c('div',{staticClass:"tw-relative d-flex align-center justify-center"},[_c('bottom-arrow'),_c('v-tooltip',{attrs:{"bottom":"","content-class":"tooltip","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('div',_vm._g({staticClass:"tw-absolute tw-inset-x-auto tw-cursor-pointer"},on),[_c('span',{staticClass:"amount default-font tw-text-primary mr-1"},[_vm._v(_vm._s(_vm._f("price")(_vm.item.matcher_amount)))]),_c('span',{staticClass:"currency default-font tw-text-primary"},[_vm._v(_vm._s(_vm.item.matcher_currency))])])]}}],null,false,1473719533)},[_c('amount',{attrs:{"transaction":{
                    to_currency: _vm.item.matcher_currency,
                    from_currency: _vm.item.poster_currency,
                    received: _vm.item.matcher_amount,
                    subtotal: _vm.item.poster_amount ,
                    rate: _vm.item.exchange_rate,
                    total: _vm.item.poster_amount
                }}})],1)],1),_c('div',{staticClass:"group-17 tw-max-w-sm tw-flex tw-flex-row-reverse"},[_c('v-tooltip',{attrs:{"bottom":"","content-class":"tooltip","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-avatar',_vm._g({staticClass:"tw-flex-shrink-0"},on),[_c('img',{attrs:{"src":"/img/man.svg","alt":_vm.recipient.name}})])]}}],null,false,1392576124)},[_c('person-info',{attrs:{"person":_vm.recipient}})],1),_c('div',{staticClass:"tw-mr-3 tw-pt-1"},[_c('h4',{staticClass:"tw-text-primary default-font font-14"},[_vm._v(_vm._s(_vm.recipient.name))]),_c('p',{staticClass:"default-font font-12 tw-text-black d-flex align-center"},[_c('gb-flag',{staticClass:"mr-1",attrs:{"code":_vm.item.poster_country,"size":"small"}}),_vm._v(_vm._s(_vm.recipient.country)+" ")],1)])],1)]):_vm._e(),(_vm.item)?_c('div',{staticClass:"tw-w-full d-flex justify-space-between align-center tw-px-24"},[_c('bank-status',{attrs:{"bank":_vm.item.userBank}}),_c('div',{staticClass:"tw-relative d-flex align-center justify-center"}),_c('p',[_c('span',{staticClass:"default-font font-16 tw-text-black"},[_vm._v(" "+_vm._s(_vm.recipientBank.name)+" ")])])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }