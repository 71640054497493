import Vue from 'vue';
import Vuex from 'vuex';
import auth from '../modules/auth';
import notifications from '../modules/notifications';
import transaction from '../modules/transaction';
import developer from '../modules/developer';
import offers from '../modules/offers';
import users from '../modules/users';
import thirdPartyTransaction from '../modules/thirdPartyTransaction';

import vuexPersist from './plugins/vuexPersist';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        auth,
        notifications,
        transaction,
        developer,
        offers,
        users,
        thirdPartyTransaction,
    },
    plugins: [
        vuexPersist.plugin,
    ],
});
