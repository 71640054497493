<template>
  <div class="tw-w-full">
    <div class="tw-w-full d-flex align-center mb-7" v-if="isToday">
      <v-divider></v-divider>
      <span class="today__head text-uppercase px-5">Today</span>
      <v-divider></v-divider>
    </div>
    <div class="d-flex justify-start align-start tw-w-1/2 tw-mb-16">
      <v-avatar tile class="mr-5 tw-rounded-lg">
        <img :src="message.authorImg" :alt="message.author" />
      </v-avatar>
      <div class="d-flex flex-column justify-start">
        <div class="message__header">
          <p class="author__name mb-1">{{message.author}}</p>
          <p class="message__date">10:14 AM</p>
        </div>
        <div class="message__content">
          <div v-html="message.content"></div>
          <div class="tw-relative" v-if="message.code">
            <div class="d-flex tw-absolute tw-right-20 tw-top-20 tw-z-50">
              <v-btn color="white" small elevation="0" fab class="mr-2">
                <v-icon small color="#7699B5">$export</v-icon>
              </v-btn>
              <v-btn color="white" small elevation="0" fab @click="collapseContent">
                <v-icon small color="#7699B5">mdi-unfold-less-horizontal</v-icon>
              </v-btn>
            </div>
            <codemirror
              ref="cmEditor"
              :value="message.code.content"
              :options="cmOptions"
              class="tw-mt-10"
              :class="{'collapsed': collapse}"
            />
          </div>
          <div class="d-flex justify-center tw-w-full" v-if="lignesCount > 10">
            <v-btn rounded color="#7598B5" class="collapse__toggle" @click="collapseContent">
              <v-icon>mdi-unfold-less-horizontal</v-icon>Collapse
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import 'codemirror/theme/the-matrix.css';
import 'codemirror/mode/javascript/javascript';
import moment from 'moment';

export default {
    props: {
        message: {
            type: Object,
            required: true,
        },
        date: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            code: `import someResource from 'codemirror/some-resource'
              export default {
                data () {
                  // 这是一个包含、代码提示、折叠、选中、sublime模式...的编辑器
                  // 按下Ctrl键可以体验代码提示
                  // 可以尝试sublime下的快捷键操作
                  return {
                    exampleCode: 'const a = 10',
                    cmOption: {
                      tabSize: 4,
                      styleActiveLine: true,
                      lineNumbers: true,
                      line: true,
                      mode: 'text/javascript',
                      lineWrapping: true,
                      theme: 'default'
                    }
                  }
                },
                components: examples
              }`,
            cmOptions: {
                tabSize: 4,
                mode: this.message.code ? this.message.code.type : 'text/javascript',
                lineNumbers: true,
                line: true,
                readOnly: true,
                lineWrapping: true,
                viewportMargin: Infinity,
                // more CodeMirror options...
            },
            collapse: false,
            lignesCount: 0,
        };
    },
    computed: {
        codemirror() {
            return this.$refs.cmEditor.codemirror;
        },
        isToday() {
            const today = moment().format('DD-MM-YYYY');
            return today === this.date;
        },
    },
    methods: {
        collapseContent() {
            this.collapse = !this.collapse;
        },
    },
    mounted() {
        this.lignesCount = this.codemirror.lineCount();
    },
};
</script>
<style scoped>
.today__head {
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 136.5%;

  /* identical to box height, or 15px */
  letter-spacing: 0.06em;

  /* Gray text */
  color: #7598b5;
}

.message__header {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 136.5%;

  color: #7598b5;
}
.author__name {
  font-weight: bold;
  font-size: 15px;

  color: #2e5677;
}

.message__content {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 180%;

  /* Text black */
  color: #2e5677;
}

.collapse__toggle {
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 136.5%;

  /* identical to box height, or 18px */
  text-align: center;

  color: #ffffff;
}
</style>

<style lang="scss">
.CodeMirror {
  background: #f7fbfc !important;
  box-shadow: 0px 8px 33px rgba(224, 224, 224, 0.1) !important;
  border-radius: 6px !important;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 29px;
  height: auto !important;
  max-height: 300px;
  overflow: hidden;

  transition: max-height 0.25s ease-in-out;

  /* or 207% */

  color: #404159;

  pre::before {
    display: none !important;
  }
}

.message__content .collapsed .CodeMirror {
  max-height: inherit !important;
}

.CodeMirror-linenumber {
  color: #404159 !important;
}

.CodeMirror-vscrollbar {
  overflow-y: hidden !important;
}

.CodeMirror-gutters {
  border-right: 1px solid #eaf0f5;
  background-color: #f7fbfc !important;
  white-space: nowrap;
}

.cm-s-default .cm-property {
  color: #7986cb !important;
}

.cm-s-default .cm-string:not(.cm-property) {
  color: #13ce66 !important;
}
</style>
