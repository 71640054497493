var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-select',_vm._b({attrs:{"items":_vm.items,"menu-props":{
            bottom: true,
            offsetY: true,
            'content-class': 'mt-3 tw-rounded-lg',
        },"disabled":_vm.disabled,"prepend-inner-icon":"$calendar","return-object":""},model:{value:(_vm.period),callback:function ($$v) {_vm.period=$$v},expression:"period"}},'v-select',_vm.$attrs,false)),(_vm.period && _vm.period.value === 'custom')?_c('v-dialog',{attrs:{"width":"320px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('input',_vm._g({staticClass:"mb-3 f-input default-font pa-2 tw-border-binput focus:tw-border-primary",style:({
                    minWidth: '210px',
                    background: 'white',
                }),attrs:{"disabled":_vm.disabled,"label":"Date range","placeholder":"Date range","readonly":""},domProps:{"value":_vm.dateRangeText}},on))]}}],null,false,1064443501),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"disabled":_vm.disabled,"scrollable":"","range":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-spacer'),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.disabled,"color":"primary","text":""},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"disabled":_vm.disabled,"color":"primary","text":""},on:{"click":_vm.onDateRangeSelected}},[_vm._v(" OK ")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }