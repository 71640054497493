var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"500px"},on:{"click:outside":function($event){return _vm.$emit('close')}},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Add New Rate Limit")])]),_c('v-card-text',[_c('v-container',[_c('v-form',[_c('v-row',{staticClass:"px-3 py-3 mt-2 mb-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{staticClass:"custom-select",attrs:{"items":_vm.countries,"item-value":"key","menu-props":{ bottom: true, offsetY: true, 'content-class': 'mt-3' },"prefix":"From","return-object":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"default-font font-15 mr-2"},[_vm._v(" "+_vm._s(item.country)+" ("+_vm._s(item.currency)+") ")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [(item.locale)?_c('div',[_c('gb-flag',{staticClass:"mr-1",attrs:{"code":item.locale,"size":"small"}}),_c('span',{staticClass:"default-font filter-title font-14"},[_vm._v(" "+_vm._s(item.country)+" ("+_vm._s(item.currency)+") ")])],1):_vm._e()]}}]),model:{value:(_vm.posterCountry),callback:function ($$v) {_vm.posterCountry=$$v},expression:"posterCountry"}})],1),(_vm.posterCountry)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{staticClass:"custom-select",attrs:{"items":_vm.countries,"item-value":"key","menu-props":{ bottom: true, offsetY: true, 'content-class': 'mt-3' },"prefix":"To","return-object":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"default-font font-15 mr-2"},[_vm._v(" "+_vm._s(item.country)+" ("+_vm._s(item.currency)+") ")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [(item.locale)?_c('div',[_c('gb-flag',{staticClass:"mr-1",attrs:{"code":item.locale,"size":"small"}}),_c('span',{staticClass:"default-font filter-title font-14"},[_vm._v(" "+_vm._s(item.country)+" ("+_vm._s(item.currency)+") ")])],1):_vm._e()]}}],null,false,3929174661),model:{value:(_vm.matcherCountry),callback:function ($$v) {_vm.matcherCountry=$$v},expression:"matcherCountry"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"type":"number","prefix":"Min Exchange Rate","outlined":"","dense":""},model:{value:(_vm.innerModel.min_exchange_rate),callback:function ($$v) {_vm.$set(_vm.innerModel, "min_exchange_rate", _vm._n($$v))},expression:"innerModel.min_exchange_rate"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"type":"number","prefix":"Max Exchange Rate","outlined":"","dense":""},model:{value:(_vm.innerModel.max_exchange_rate),callback:function ($$v) {_vm.$set(_vm.innerModel, "max_exchange_rate", _vm._n($$v))},expression:"innerModel.max_exchange_rate"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"block":"","elevation":"2","color":"primary"},on:{"click":_vm.submit}},[_vm._v(" Save ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }