<template>
    <v-btn
        depressed
        fab
        @click="toggle"
        :disabled="loading"
        :color="state ? '#dc0000' : '#15abab'"
    >
        <v-icon>
            mdi-power
        </v-icon>
    </v-btn>
</template>

<script>
import SiteApi from '@/api/SiteApi';

export default {
    data() {
        return {
            state: false,
            loading: true,
        };
    },
    mounted() {
        this.getState();
    },
    methods: {
        getState() {
            this.loading = true;
            return SiteApi.getSettings('maintenance')
                .then((response) => {
                    const { value } = response.data;
                    this.state = !!value;
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        toggle() {
            this.$swal({
                icon: 'warning',
                title: 'Are You Sure?',
                text: `You are about to turn ${!this.state ? 'On' : 'Off'} the Kaoshi app ${!this.state ? 'for' : 'from'} maintenance`,
                showCancelButton: true,
                confirmButtonText: 'Yes',
            })
            .then((result) => {
                if (!result.value) {
                    return false;
                }

                this.loading = true;
                const value = !this.state;
                return SiteApi.setSettings('maintenance', value)
                    .then(() => {
                        this.state = value;
                        this.loading = false;
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            });
        },
    },
};
</script>
