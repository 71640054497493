<template>
    <tr class="default-font tw-text-black tw-cursor-pointer" @click="$emit('click');">
        <td class="py-2">
            <div class="d-flex">
                <gb-flag
                    :code="item.poster_country.locale"
                    size="small"
                    class="mr-3" />
                <div>
                    {{ item.poster_country.country }} ({{ item.poster_currency_code }})
                </div>
            </div>
        </td>
        <td class="py-2">
            <div class="d-flex">
                <gb-flag
                    :code="item.matcher_country.locale"
                    size="small"
                    class="mr-3" />
                <div>
                    {{ item.matcher_country.country }} ({{ item.matcher_currency_code }})
                </div>
            </div>
        </td>
        <td class="py-2">
            {{ item.min_exchange_rate | price }}
        </td>
        <td class="py-2">
            {{ item.max_exchange_rate | price }}
        </td>
        <td class="py-2">
            <v-menu transition="slide-y-transition" bottom offset-y content-class="mt-3 tw-rounded-lg">
                <template #activator="{ on }">
                    <v-btn
                        color="secondary"
                        dark
                        outlined
                        elevation="0"
                        large
                        class="default-font font-12 text-capitalize justify-space-between"
                        v-on="on"
                    >
                        Actions
                        <v-icon x-small class="ml-2">mdi-chevron-down</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item>
                        <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                            <v-btn @click="editRate" text class="text-capitalize default-font ">
                                Edit
                            </v-btn>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                            <v-btn @click="deleteRate" text class="text-capitalize default-font">
                                Delete
                            </v-btn>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </td>
    </tr>
</template>
<script>
export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    methods: {
        editRate() {
            this.$emit('edit', this.item);
        },
        deleteRate() {
            this.$emit('delete', this.item);
        },
    },
};
</script>
