import scraperApi from '../api/ScraperApi';

class ExchangeRateService {
    static async getLimits(sendingCurrency, receivingCurrency, sendingCountry) {
        const limits = {
            min: 0,
            max: 0,
        };

        try {
            const { data } = await scraperApi.getExchangeRates({
                sending_currency_code: sendingCurrency,
                receiving_currency_code: receivingCurrency,
            });
            const {
                buy = 0,
                sell = 0,
            } = data;

            limits.min = 0.955 * buy;
            limits.max = 1.015 * sell;

            if (receivingCurrency === 'NGN') {
                if (sendingCurrency === 'USD' && sendingCountry === null) { // dom
                    limits.min = 0.985 * buy;
                    limits.max = 1.005 * sell;
                } else if (sendingCurrency === 'GBP') {
                    limits.min = 0.99 * buy;
                }
            }
        } catch (err) {
            this.$swal({
                type: 'error',
                title: 'Error loading exchange rate',
                text: err,
            });
        }
        return limits;
    }
}

export default ExchangeRateService;
