<template>
  <v-chip
    class="ma-2 text-uppercase status tw-cursor-pointer"
    :color="status.bg"
    :text-color="status.color"
  >
    {{ status.label }}
  </v-chip>
</template>
<script>
export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    computed: {
        status() {
            if (!this.item) return {};

            let status = this.item.statusText;
            const statusKey = status.toLowerCase().replace(/\s+/g, '_');
            const colors = {
                unpublished: {
                    bg: '#e0e0e0',
                    color: '#000000de',
                },
                published: {
                    bg: '#dde',
                    color: '#000000aa',
                },
                expired: {
                    bg: '#FFE3E2',
                    color: '#F07877',
                },
                cancelled: {
                    bg: '#FFE3E2',
                    color: '#F07877',
                },
                matched: {
                    bg: '#FFEBA6',
                    color: '#D89342',
                },
                matcher_paid: {
                    bg: '#ffc9a6',
                    color: '#d87d42',
                },
                payment_received: {
                    bg: '#C5F5E7',
                    color: '#15ABAB',
                },
                completed: {
                    bg: '#C5F5E7',
                    color: '#15ABAB',
                },
                unable_to_capture: {
                    bg: '#8b0000',
                    color: '#ffc1c1',
                },
                unable_to_transfer: {
                    bg: '#8b0000',
                    color: '#ffc1c1',
                },
                dispute_resolution_needed: {
                    bg: '#8b0000',
                    color: '#ffc1c1',
                },
                unable_to_payout: {
                    bg: '#8b0000',
                    color: '#ffc1c1',
                },
                unable_to_void: {
                    bg: '#8b0000',
                    color: '#ffc1c1',
                },
                unable_to_refund: {
                    bg: '#8b0000',
                    color: '#ffc1c1',
                },
            };

            if (statusKey === 'expired' && this.item.refunded_at) {
                status = `${status} and Refunded`;
            }

            return {
                label: status,
                ...colors[statusKey],
            };
        },
    },
};
</script>
