<template>
    <div class="tw-flex tw-h-full tw-flex-col wrapper py-5 px-8 mb-3 tw-cursor-pointer">
        <div class="tw-flex tw-justify-between">
            <div class="tw-flex tw-flex-col">
                <v-chip
                    class="ma-2 text-uppercase status tw-cursor-pointer"
                    :color="statusColor(item).bg"
                    :text-color="statusColor(item).color"
                >
                    {{item.is_active ? 'Active' : 'Inactive'}}
                </v-chip>
            </div>
            <div class="tw-flex tw-justify-between tw-items-center">
                <v-menu transition="slide-y-transition" bottom offset-y content-class="mt-3 tw-rounded-lg">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            color="secondary"
                            dark
                            outlined
                            elevation="0"
                            large
                            class="default-font font-12 text-capitalize justify-space-between"
                            v-on="on"
                        >
                            Actions
                            <v-icon x-small class="ml-2">mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item>
                            <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                                <v-btn
                                    text
                                    class="text-capitalize default-font"
                                    @click="$emit('edit')"
                                >
                                    Edit
                                </v-btn>
                            </v-list-item-title>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                                <v-btn
                                    text
                                    class="text-capitalize default-font"
                                    @click="$emit('publish')"
                                    :disabled="!!item.is_active"
                                >
                                    Publish
                                </v-btn>
                            </v-list-item-title>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                                <v-btn
                                    text
                                    class="text-capitalize default-font"
                                    @click="$emit('unpublish')"
                                    :disabled="!item.is_active"
                                >
                                    Unpublish
                                </v-btn>
                            </v-list-item-title>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                                <v-btn
                                    text
                                    class="text-capitalize default-font"
                                    @click="$emit('remove')"
                                >
                                    Remove
                                </v-btn>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </div>
        <div class="tw-flex">
            <div
                class="content"
                v-html="item.content"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    data: () => ({}),
    methods: {
        statusColor(item) {
            if (item.is_active) {
              return { bg: '#C5F5E7', color: '#15ABAB' };
            }

            return { bg: '#FFE3E2', color: '#F07877' };
        },
    },
};
</script>

<style scoped>
.content {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 175%;
    color: #2E5677;
    max-width: 66.66%;
    margin-top: 1rem;
}

.wrapper {
    background: #F7FBFC;
    box-shadow: 0px 8px 33px rgba(224, 224, 224, 0.1);
    border-radius: 6px;
}
</style>
