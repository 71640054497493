<template>
    <v-dialog
        v-model="innerValue"
        width="800px"
        class="overflow-hidden">
        <v-card>
            <v-card-title class="d-flex justify-space-between">
                <!-- <span class="headline text-capitalize">{{ attached.name || '' }} Attachment</span> -->
                <span class="headline text-capitalize">Attachment</span>
                <v-btn
                    icon
                    @click="innerValue = false">
                    <v-icon color="#15ABAB">mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-container>
                <iframe
                    :src="attachment"
                    v-if="isPdf"
                    height="500"
                    width="780">
                </iframe>
                <v-img
                    v-else
                    :src="attachment"
                    height="500"
                    max-width="800"
                    class="mx-auto pa-3">
                </v-img>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        attachment: {
            type: String,
            required: true,
        },
        isPdf: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        innerValue: null,
    }),
    watch: {
        value: {
            immediate: true,
            handler(val) {
                this.innerValue = val;
            },
        },
        innerValue(val) {
            this.$emit('input', val);
        },
    },
};
</script>
