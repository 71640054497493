import api from './index';

const resource = 'admin/site';

export default {
    getSettings(type) {
        return api.get(`${resource}/settings`, {
            params: {
                type,
            },
        });
    },
    setSettings(type, value) {
        return api.post(`${resource}/settings`, {
            type,
            value,
        });
    },
};
