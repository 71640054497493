<template>
    <v-select
        v-model="partialMatchFilter"
        :items="items"
        :menu-props="{
            bottom: true,
            offsetY: true,
            'content-class': 'mt-3 tw-rounded-lg',
        }"
        class="custom-select"
        outlined
        dense
        multiple>

        <template #selection="{ item }">
            <span class="default-font filter-title tw-text-black mr-2">
                {{ item.text }}
            </span>
        </template>

    </v-select>
</template>

<script>
export default {
    data: () => ({
        items: [
            {
                text: 'No',
                value: '0',
            },
            {
                text: 'Yes',
                value: '1',
            },
        ],
    }),
    computed: {
        partialMatchFilter: {
            get() {
                return this.$store.state.transaction.partialMatchFilter;
            },
            set(val) {
                this.$store.dispatch('transaction/updatePartialMatchFilter', val);
            },
        },
    },
};
</script>
