<template>
    <div  class="tw-w-full">
        <v-skeleton-loader :loading="!transaction" v-if="!transaction"  type="table"/>
        <div v-if="transaction">
            <app-bar>
                <template slot="title">
                    <v-toolbar-title class="default-font font-21 mr-10">Order: {{ id }}</v-toolbar-title>
                </template>
                <div class="d-flex justify-space-between tw-pr-20 tw-pl-10">
                    <transaction-actions
                        :item="transaction"
                        :isAdmin="isAdmin"
                        :isPrincipal="isPrincipal"
                    />
                </div>
            </app-bar>

            <v-row class="tw-w-full tw-mt-10 mx-0 tw-absolute ml-n10">
                <v-tabs v-model="tab" class="tw-pl-20 border-btm">
                    <v-tab
                        v-for="item in tabItems"
                        class="text-capitalize default-font"
                        :key="item.title">{{item.title}}</v-tab>
                </v-tabs>
                <v-tabs-items class="col-12 mt-10 tw-ml-20 tw-mr-5" v-model="tab">
                    <v-tab-item >
                        <offer-details :item="transaction" />
                    </v-tab-item>

                    <v-tab-item >
                        <offer-parties :item="transaction" />
                    </v-tab-item>

                    <v-tab-item >
                        <match-validation-events :item="transaction"/>
                    </v-tab-item>
                </v-tabs-items>
            </v-row>
        </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';

import AppBar from '@/components/globals/AppBar.vue';
import TransactionsApi from '@/api/TransactionsApi';

import OfferDetails from '../components/offers/OfferDetails.vue';
import OfferParties from '../components/offers/OfferParties.vue';
import MatchValidationEvents from '../components/offers/MatchValidationEvents.vue';
import TransactionActions from '../components/transactions/TransactionActions.vue';

export default {
    components: {
        AppBar,
        MatchValidationEvents,
        OfferDetails,
        OfferParties,
        TransactionActions,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        tab: null,
        transaction: null,
        tabItems: [
            {
                title: 'Details',
            },
            {
                title: 'Parties',
            },
            {
                title: 'Validation Events',
            },
        ],
    }),
    async mounted() {
        await this.resolveOffer();
    },
    watch: {
        transactionProp: {
            handler() {
                this.resolveOffer();
            },
        },
        id: {
            handler() {
                this.resolveOffer();
            },
        },
    },
    computed: {
        ...mapGetters([
            'user',
        ]),
        isAdmin() {
            return this.user.role === 'admin';
        },
        isPrincipal() {
            return this.user.role === 'principal';
        },
    },
    methods: {
        async resolveOffer() {
            await this.loadItem();
        },
        async loadItem() {
            this.transaction = null;
            try {
                const response = await TransactionsApi.get(this.id);
                this.transaction = response.data;
            } catch (err) {
                this.$swal({
                    icon: 'error',
                    title: 'Unable to load Offer',
                    text: err.message,
                });
            }
        },
    },
};
</script>

<style>
.v-tab--active{
  color: #2E5677 !important;
}
.border-btm {
  border-bottom: 1px solid #EAF0F5;
}
</style>
