<template>
    <form class="tw-flex tw-flex-col pa-12 mb-3">
        <div class="d-flex flex-column tw-w-2/3">
            <label class="subject mb-3">Subject:</label>
            <input
                type="text"
                v-model="subject"
                class="f-input default-font pa-2 tw-border-binput focus:tw-border-primary"/>
        </div>

        <div class="d-flex flex-column tw-w-2/3 mt-5">
            <label class="subject mb-3">Write your message:</label>
            <editor/>
        </div>
        <v-btn class="tw-w-32" depressed large color="primary" to>Open case</v-btn>
    </form>
</template>
<script>
import Editor from '../globals/Editor.vue';

export default {
    components: { Editor },
    data() {
        return {
            subject: undefined,
        };
    },
};
</script>
<style scoped>
.f-input {
    background: #F7FBFC;

/* Lines */
border: 1px solid #EAF0F5;
box-sizing: border-box;
border-radius: 4px;
}
</style>
<style>
.subject {
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 160%;

  /* or 22px */

  /* Gray text */
  color: #7598b5;
}
.subject-input {
  background: #f7fbfc;

  /* Lines */
  border: 1px solid #eaf0f5;
  box-sizing: border-box;
  border-radius: 4px;
}
</style>
