<template>
    <div class="tw-w-full" id="profile">
        <app-bar>
      <template slot="title">
        <v-toolbar-title class="default-font font-21 mr-10 tw-text-black">Profile</v-toolbar-title>
      </template>
    </app-bar>
    <v-container fluid class="px-8 tw-mt-10">
        <v-tabs vertical active-class="active__tab">
      <v-tab>
        E-mail & Password
      </v-tab>
      <v-tab>
        Bank info
      </v-tab>
      <v-tab>
        Licensure and compliance
      </v-tab>

      <v-tab>Billing statement</v-tab>

      <v-tab-item>
        <email-password></email-password>
      </v-tab-item>
      <v-tab-item>
       <bank-info></bank-info>
      </v-tab-item>
      <v-tab-item>
        <licensure></licensure>
      </v-tab-item>
       <v-tab-item>
       <billing-info></billing-info>
      </v-tab-item>
        </v-tabs>
    </v-container>
    </div>
</template>
<script>
import AppBar from '@/components/globals/AppBar.vue';
import BankInfo from '@/components/profile/Bank.vue';
import BillingInfo from '@/components/profile/Billing.vue';
import EmailPassword from '@/components/profile/EmailPassword.vue';
import Licensure from '@/components/profile/Licensure.vue';

export default {
    components: {
        AppBar, BankInfo, BillingInfo, EmailPassword, Licensure,
    },
};
</script>
<style>
.active__tab {
    background: #F7FBFC !important;
    border-radius: 5px;
font-style: normal;
font-weight: bold;
/* or 22px */

/* Accent Green */
color: #15ABAB !important;
}

#profile .v-tab {
    justify-content: start !important;
    text-transform: none !important;
    font-family: Muli;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 160%;

/* or 22px */

/* Text black */
color: #2E5677;
}

#profile .v-tabs-bar {
    padding-right: 5rem;
    border-right: 1px solid #EAF0F5;
}
#profile label {
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;

  /* or 22px */

  /* Gray text */
  color: #7598b5;
}
</style>
