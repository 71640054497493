<template>
    <v-chip
        v-bind="$attrs"
        v-on="$listeners"
        :color="color">
        {{ text }}
    </v-chip>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            required: false,
        },
        type: {
            type: String,
            default: 'Bank',
        },
    },
    computed: {
        color() {
            return this.value
                ? 'success'
                : null;
        },
        text() {
            return this.value
                ? `${this.type} Added`
                : `${this.type} Not Added`;
        },
    },
};
</script>
