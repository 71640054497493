<template>
    <v-select
        :value="matcherCountryFilter"
        :items="items"
        :menu-props="{
            bottom: true,
            offsetY: true,
            'content-class': 'mt-3 tw-rounded-lg',
        }"
        class="custom-select"
        outlined
        dense
        @change="updateMatcherCountryFilter"
        :disabled="disabled"
    >

        <template #item="{ item }">
            <span class="default-font filter-title tw-text-black d-flex tw-w-full">
                {{ item.text }}
            </span>
        </template>

        <template #selection="{ item }">
            <span class="default-font filter-title tw-text-black">
                {{ item.text }}
            </span>
        </template>
    </v-select>
</template>

<script>
import CountriesApi from '../../api/CountriesApi';

export default {
    props: {
      disabled: {
        type: Boolean,
        default: true,
      },
        storeKey: {
            type: String,
            default: 'transaction',
        },
    },
    data: () => ({
        countries: [],
    }),
    computed: {
        matcherCountryFilter() {
            return this.$store.state[this.storeKey].matcherCountryFilter;
        },
        items() {
            const countries = this.countries.map(({ abbreviation, country }) => ({
                text: country,
                value: abbreviation,
                disabled: this.disabled,
            }));

            return [
                {
                    text: 'Any country',
                    value: null,
                    disabled: this.disabled,
                },
                ...countries,
            ];
        },
    },
    methods: {
        updateMatcherCountryFilter(payload) {
            return this.$store.dispatch(`${this.storeKey}/updateMatcherCountryFilter`, payload);
        },
        async loadCountries() {
            try {
                const { data: { data } } = await CountriesApi.getAll();
                this.$set(this, 'countries', data);
            } catch (error) {
                this.$swal({
                    type: 'error',
                    title: 'Error loading countries',
                    text: error,
                });
            }
        },
    },
    mounted() {
        this.loadCountries();
    },
};
</script>
