<template>
  <div class="editor" ref="editor"/>
</template>

<script>
import Quill from 'quill';
import * as Emoji from 'quill-emoji';
import 'quill-emoji/dist/quill-emoji.css';

Quill.register('modules/emoji', Emoji);

const defaultConfig = {
    modules: {
        toolbar: {
          container: [['bold', 'italic', 'underline', 'link', 'emoji']],
          handlers: {
            emoji: () => {},
          },
        },
        'emoji-toolbar': true,
        'emoji-shortname': true,
    },
    placeholder: 'Type text here',
    theme: 'snow',
};

export default {
  props: {
      config: {
          type: Object,
          default: () => ({}),
      },
      value: String,
  },
  data() {
      return {
          editor: null,
      };
  },
  methods: {
      setHTML(value) {
          if (value && this.editor) {
              this.editor.clipboard.dangerouslyPasteHTML(value);
          }
      },
  },
  watch: {
      value(value) {
          this.setHTML(value);
      },
  },
  mounted() {
      this.editor = new Quill(this.$refs.editor, {
          ...defaultConfig,
          ...this.config,
      });

      this.editor.on('text-change', () => {
          this.$emit('change', this.editor.root.innerHTML);
      });

      this.setHTML(this.value);
  },
};
</script>

<style scoped>
  .editor {
    width: 100%;
    min-width: 300px;
    min-height: 200px
  }

  .emoji_completions {
    z-index: 100;
  }
</style>
