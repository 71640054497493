<template>
    <v-dialog
        v-model="innerValue"
        width="800px"
        class="overflow-hidden">
        <v-card>
            <v-card-title class="d-flex justify-space-between">
                <span class="headline text-capitalize">Attachments</span>
                <v-btn
                    icon
                    @click="innerValue = false">
                    <v-icon color="#15ABAB">mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-list>
                <v-list-item-group>
                    <v-list-item
                        v-for="(attachment, key) in attachments"
                        :key="key"
                        @click="showAttachment(attachment)">

                        <v-img
                            :src="attachment.filepath"
                            max-width="250"
                            contain>
                        </v-img>

                        <v-list-item-content class="pl-4">
                            {{ attachment.originalname }}
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-card>

        <AttachmentModal
            v-model="isAttachmentShown"
            :attachment="attachment.filepath"
            :isPdf="attachment.mimetype === 'application/pdf'">
        </AttachmentModal>
    </v-dialog>
</template>

<script>
import AttachmentModal from './AttachmentModal.vue';

export default {
    components: {
        AttachmentModal,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        attachments: {
            type: Array,
            required: true,
        },
    },
    data: () => ({
        innerValue: null,
        attachment: {},
        isAttachmentShown: false,
    }),
    methods: {
        showAttachment(attachment) {
            this.attachment = attachment;
            this.isAttachmentShown = true;
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(val) {
                this.innerValue = val;
            },
        },
        innerValue(val) {
            this.$emit('input', val);
        },
    },
};
</script>
