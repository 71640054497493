/* eslint-disable camelcase */
import Model from './Model';

class Order extends Model {
    static fields() {
        return {
            beneficiary_delivery_method_id: Number,
            can_cancel_order: Boolean,
            cancelled_at: String,
            completed_at: String,
            confirmed_at: String,
            exchange_rate: Number,
            expires_at: String,
            hidden: Boolean,
            id: String,
            is_accepted: Boolean,
            is_cancelled: Boolean,
            is_completed: Boolean,
            is_confirmed: Boolean,
            is_matched: Boolean,
            is_partial_match_allowed: Boolean,
            is_processing: Boolean,
            is_removed: Boolean,
            match: Object,
            match_id: Number,
            matched_at: String,
            matcher_amount: Number,
            matcher_country: String,
            matcher_currency: String,
            matcher_payment_completed_at: String,
            matcher_payment_method_type: String,
            matcher_payment_purpose: String,
            memo: String,
            parent_id: String,
            payment_request_id: Number,
            posted_at: String,
            poster_amount: Number,
            poster_country: String,
            poster_currency: String,
            poster_fee: Number,
            poster_payment_method_type: String,
            poster_payment_purpose: Number,
            postersBeneficiary: Object,
            posters_beneficiary_user_id: String,
            probably_paid: Boolean,
            published_at: String,
            removed_at: String,
            requested_at: String,
            skip_capture: Boolean,
            status: String,
            statusText: String,
            type: String,
            updated_at: String,
            user_id: String,
            user: Object,
        };
    }

    /**
     * Get Poster Amount with currency
     */
    get posterAmountFormatted() {
        let { poster_amount } = this;

        const {
            poster_currency,
            match,
            is_partial_match_allowed,
        } = this;

        if (match && match.poster_amount && is_partial_match_allowed) {
            poster_amount = match.poster_amount;
        }

        return `${poster_amount} ${poster_currency}`;
    }

    /**
     * Get Matcher Amount with currency
     */
    get matcherAmountFormatted() {
        let { matcher_amount } = this;

        const {
            matcher_currency,
            match,
            is_partial_match_allowed,
        } = this;

        if (match && is_partial_match_allowed) {
            matcher_amount = match.matcher_amount;
        }

        return `${matcher_amount} ${matcher_currency}`;
    }

    /**
     * Get Poster Fee with currency
     */
    get posterFeeFormatted() {
        return `${this.poster_fee} ${this.poster_currency}`;
    }

    /**
     * Get Matcher Fee with currency
     */
    get matcherFeeFormatted() {
        if (this.match?.matcher_fee) {
            return `${this.match.matcher_fee} ${this.poster_currency}`;
        }
        return null;
    }
}

export default Order;
