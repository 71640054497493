<template>
    <v-main>
        <v-data-table
            :loading="loading"
            :headers="headers"
            :items="transactions"
            :items-per-page="pagination.pageSize"
            single-expand
            :page="pagination.page"
            :server-items-length="pagination.rowCount"
            hide-default-footer
            class="px-12 py-4 tw-bg-transparent custom-table"
        >
            <template v-slot:[`item.poster_amount`]="{ item }">
                <span class="tw-text-black">{{item.poster_currency}}</span> {{ getPosterAmount(item) | price }}
            </template>

            <template v-slot:[`item.payment_type`]="{ item }">
                {{ getPaymentType(item) }}
            </template>

            <template v-slot:[`item.is_partial_match_allowed`]="{ item }">
                {{ item.is_partial_match_allowed ? 'Yes' : 'No' }}
            </template>

            <template v-slot:[`item.matcher_amount`]="{ item }">
                <span class="tw-text-black">{{item.matcher_currency}}</span> {{ getMatcherAmount(item) | price }}
            </template>

            <template v-slot:[`item.status`]="{ item }">
                <transaction-status :item="item" />
            </template>

            <template v-slot:[`item.view`]="{ item }">
                <v-btn
                    depressed
                    color="primary"
                    :to="getOrderRouterLink(item.id)"
                >
                    View
                </v-btn>
            </template>

            <template v-slot:[`item.action`]="{ item }">
                <transaction-actions
                    :item="item"
                    :isAdmin="isAdmin"
                    :isPrincipal="isPrincipal"
                />
            </template>
        </v-data-table>
        <v-pagination
            :value="pagination.page"
            @input="onPageChange"
            next-icon="$next"
            prev-icon="$prev"
            class="custom-pagination"
            color="#7598B5"
            :length="pagination.pageCount"
            :total-visible="totalVisible"
        />
    </v-main>
</template>

<script>

import { mapGetters } from 'vuex';

import TransactionStatus from './TransactionStatus.vue';
import TransactionActions from './TransactionActions.vue';
import TransactionMixin from '../../mixins/transactions';

const headers = [
    {
        text: 'Transaction ID',
        align: 'center',
        class: 'tw-text-black default-font py-2 text-start tw-rounded-lg tw-rounded-r-none first_cell',
        value: 'id',
    },
    {
        text: 'Type',
        align: 'center',
        class: 'tw-text-black default-font py-2 text-end ',
        value: 'type',
    },
    {
        text: 'Payment Type',
        align: 'center',
        class: 'tw-text-black default-font py-2 text-end',
        value: 'payment_type',
    },
    {
        text: 'Partial Match Allowed',
        align: 'start',
        value: 'is_partial_match_allowed',
        class: 'tw-text-secondary default-font py-2 text-start',
    },
    {
        text: 'Poster Amount',
        align: 'end',
        value: 'poster_amount',
        sortable: false,
        class: 'tw-text-black default-font py-2 text-end ',
    },
    {
        text: 'Matcher Amount',
        align: 'end',
        value: 'matcher_amount',
        sortable: false,
        class: 'tw-text-black default-font py-2 text-end ',
    },
    {
        text: 'Status',
        align: 'center',
        value: 'status',
        sortable: true,
        class: 'tw-text-secondary default-font py-2 text-center',
    },
    {
        align: 'center',
        value: 'action',
        sortable: false,
        class: 'default-font py-2 text-center last_cell tw-rounded-lg tw-rounded-l-none',
    },
    {
        align: 'center',
        value: 'view',
        sortable: false,
        class: 'default-font py-2 text-center last_cell tw-rounded-lg tw-rounded-l-none',
    },
];

export default {
    props: {
        loading: {
            type: Boolean,
            default: true,
        },
        transactions: {
            type: Array,
            default: () => [],
        },
        pagination: {
            type: Object,
            default() {
                return {};
            },
        },
        totalVisible: {
            type: Number,
            default: 10,
        },
    },
    mixins: [
        TransactionMixin,
    ],
    components: {
        TransactionStatus,
        TransactionActions,
    },
    computed: {
        ...mapGetters([
            'user',
        ]),
        isAdmin() {
            return this.user.role === 'admin';
        },
        isPrincipal() {
            return this.user.role === 'principal';
        },
    },
    data: () => ({
        loaderType: 'table',
        headers,
    }),
    methods: {
        onPageChange(page) {
            this.$emit('pageChange', page);
        },
    },
};
</script>
