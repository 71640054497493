var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"80%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"text-capitalize default-font",attrs:{"text":""}},on),[_vm._v("Validation journey")])]}}]),model:{value:(_vm.display),callback:function ($$v) {_vm.display=$$v},expression:"display"}},[_c('v-card',{staticClass:"py-2",attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" Validation journey for match ID: \""+_vm._s(_vm.id)+"\" ")]),_c('v-data-table',{staticClass:"px-12 py-4 tw-bg-transparent custom-table",attrs:{"item-key":"id","headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"items-per-page":_vm.items.length,"hide-default-footer":"","single-expand":""},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.created_at))+" "+_vm._s(_vm._f("time")(item.created_at))+" ")]}},{key:"item.event",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.eventToText(item.event))+" ")]}},{key:"item.matcher_paid",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.event !== 'paid_button_clicked' ? _vm.booleanToText(item.matcher_paid) : '')+" ")]}},{key:"item.payment_received",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.event !== 'paid_button_clicked' ? _vm.booleanToText(item.payment_received) : '')+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }