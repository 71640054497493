import api from './index';

const resource = 'admin/disputes';

export default {
    getAll(params) {
        return api.get(`${resource}`, {
            params,
        });
    },

    update(id, payload) {
        const formData = new FormData();

        Object.entries(payload).forEach((item) => {
            const [key, value] = item;
            formData.append(key, value);
        });

        return api.put(`${resource}/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },

    approve(id) {
        return api.post(`${resource}/${id}/approve`);
    },

    decline(id) {
        return api.post(`${resource}/${id}/decline`);
    },

    refreshTransactionData(id) {
        return api.get(`${resource}/${id}/refresh-transaction-data`);
    },
};
