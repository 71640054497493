<template>
  <div class="tw-w-full d-flex flex-column justify-center">
    <v-data-table
      :headers="headers"
      :items="billings"
      :page.sync="page"
      item-key="date"
      :items-per-page="itemsPerPage"
      hide-default-footer
      class="pb-4 tw-bg-transparent custom-table table-space px-5"
      @page-count="pageCount = $event"
    >
      <template v-slot:item="{ item, headers }">
        <profile-row :item="item" :headers="headers">
          <template v-slot:date="{ item }">
            <span class="billing__font">{{item.date | date}} at {{item.date | time}}</span>
          </template>
          <template v-slot:amount="{ item }">
            <span class="billing__font justify-start">
              USD
              <span class="billing__font tw-font-bold">{{ item.amount | price }}</span>
            </span>
          </template>
          <template v-slot:action="{ item }">
            <v-btn text color="primary" class="text-capitalize default-font tw-text-sm">
              <v-icon class="mr-2" large>$export</v-icon>
              Download PDF receipt{{ item.action }}
            </v-btn>
          </template>
        </profile-row>
      </template>
    </v-data-table>
    <div class="text-center pt-12">
      <v-flex justify-center>
        <v-pagination
          v-model="page"
          next-icon="$next"
          prev-icon="$prev"
          color="#7598B5"
          :length="pageCount"
        ></v-pagination>
      </v-flex>
    </div>
  </div>
</template>
<script>
import { headers, billings } from '@/utils/profile';
import ProfileRow from './ProfileRow.vue';

export default {
    components: { ProfileRow },
    data: () => ({
        email: '',
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        agree: false,
        e6: 1,
        headers,
        billings,
        page: 1,
        pageCount: 0,
        itemsPerPage: 5,
        dialog1: false,
    }),
};
</script>
<style scoped>
.billing__font {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 136.5%;

  /* or 19px */

  /* Text black */
  color: #2e5677;
}
</style>
