var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"default-font tw-text-black tw-cursor-pointer",on:{"click":function($event){return _vm.$emit('click');}}},[_c('td',{staticClass:"py-2"},[_vm._v(_vm._s(_vm._f("date")(_vm.item.created_at))+" "),_c('br'),_vm._v(" "),_c('span',{staticClass:" text-gray-100"},[_vm._v(_vm._s(_vm.item.time))])]),_c('td',{staticClass:"py-2"},[_vm._v(_vm._s(_vm.order.id))]),_c('td',{staticClass:"py-2"},[_vm._v(_vm._s(_vm.match.id))]),_c('td',{staticClass:"py-2"},[_vm._v(_vm._s(_vm._f("price")(_vm.match.matcher_amount))+" "+_vm._s(_vm.match.matcher_currency))]),_c('td',{staticClass:"py-2"},[_vm._v(_vm._s(_vm.matcher.full_name)+" "),_c('br'),_vm._v(" ("+_vm._s(_vm.matcher.email)+")")]),_c('td',{staticClass:"py-2"},[_vm._v(_vm._s(_vm.postersBeneficiary.full_name)+" "),_c('br'),_vm._v(" ("+_vm._s(_vm.postersBeneficiary.email)+")")]),_c('td',{staticClass:"py-2"},[_c('v-chip',[_vm._v(" "+_vm._s(_vm.item.status)+" ")])],1),_c('td',{staticClass:"py-2"},[_c('v-menu',{attrs:{"transition":"slide-y-transition","bottom":"","offset-y":"","content-class":"mt-3 tw-rounded-lg"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"default-font font-12 text-capitalize justify-space-between",attrs:{"color":"secondary","dark":"","outlined":"","elevation":"0","large":""}},on),[_vm._v(" Actions "),_c('v-icon',{staticClass:"ml-2",attrs:{"x-small":""}},[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{staticClass:"default-font tw-font-normal font-14 tw-text-black-400"},[_c('v-btn',{staticClass:"text-capitalize default-font ",attrs:{"text":""},on:{"click":_vm.viewDispute}},[_vm._v(" View ")])],1)],1),_c('v-list-item',[_c('v-list-item-title',{staticClass:"default-font tw-font-normal font-14 tw-text-black-400"},[_c('v-btn',{staticClass:"text-capitalize default-font",attrs:{"text":"","href":_vm.$router.resolve({
                                name: 'TransactionDetails',
                                params: {
                                    id: _vm.item.id,
                                },
                            }).href}},[_vm._v(" Validation Details ")])],1)],1),_c('v-list-item',[_c('v-list-item-title',{staticClass:"default-font tw-font-normal font-14 tw-text-black-400"},[(_vm.item.match_id)?_c('MatchValidationJourneyModal',{attrs:{"id":_vm.item.match_id}}):_vm._e()],1)],1),(_vm.isAdmin)?_c('v-list-item',[_c('v-list-item-title',{staticClass:"default-font tw-font-normal font-14 tw-text-black-400"},[_c('v-btn',{staticClass:"text-capitalize default-font",attrs:{"text":"","disabled":!_vm.canCancelMatch},on:{"click":_vm.cancelMatch}},[_vm._v(" Cancel Match ")])],1)],1):_vm._e(),(_vm.isAdmin)?_c('v-list-item',[_c('v-list-item-title',{staticClass:"default-font tw-font-normal font-14 tw-text-black-400"},[_c('v-btn',{staticClass:"text-capitalize default-font",attrs:{"text":"","disabled":!_vm.canCancelMatch},on:{"click":_vm.approveMatch}},[_vm._v(" Approve Match ")])],1)],1):_vm._e(),_c('v-list-item',[_c('v-list-item-title',{staticClass:"default-font tw-font-normal font-14 tw-text-black-400"},[_c('v-btn',{staticClass:"text-capitalize default-font",attrs:{"text":""},on:{"click":_vm.dismissDispute}},[_vm._v(" Dismiss ")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }