<template>
    <v-select
        :items="items"
        outlined
        dense
        prepend-inner-icon="$filter"
        :value="orderBy"
        @change="onChange"
        itemValue="value"
        itemText="title"
        placeholder="Order By"
        :menu-props="{ bottom: true, offsetY: true, 'content-class': 'mt-3 tw-rounded-lg' }"
        hide-details
    >
        <template v-slot:selection="{ item }">
            <span class="default-font filter-title tw-text-black mr-2">{{ item.title }}</span>
        </template>
    </v-select>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    data() {
        return {
            items: [
                {
                    title: 'Created at',
                    value: 'created_at',
                },
                {
                    value: 'completed_at',
                    title: 'Completed At',
                },
            ],
        };
    },
    mounted() {
    },
    methods: {
        ...mapActions('thirdPartyTransaction', ['updateOrderBy']),
        onChange(e) {
            this.updateOrderBy(e);
        },
    },
    computed: {
        ...mapGetters('thirdPartyTransaction', ['orderBy']),
    },
};
</script>
