<template>
    <div>
        <v-skeleton-loader v-if="loading" :loading="loading" type="list-item" />
        <v-timeline
            dense
            clipped
            v-if="!loading && matchEvents.length"
        >
            <v-timeline-item
                v-for="event in matchEvents"
                :key="event.id"
                class="mb-4"
                :color="getEventColor(event)"
                small
            >
                <v-row justify="space-between">
                    <v-col cols="7">
                        {{ formatEventName(event.event) }}
                    </v-col>
                    <v-col
                        class="text-right"
                        cols="5"
                    >
                        <span>{{ event.created_at | date }}</span>
                        <span> at {{ event.created_at | time }}</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-badge
                            bordered
                            :color="getPaymentStatusColor(event.matcher_paid)"
                            :icon="getPaymentStatusIcon(event.matcher_paid)"
                            overlap
                            class="tw-mr-1"
                        >
                            <v-chip
                                class="white--text"
                                color="secondary"
                                depressed
                            >
                                Matcher Paid
                            </v-chip>
                        </v-badge>
                        <v-badge
                            bordered
                            :color="getPaymentStatusColor(event.payment_received)"
                            :icon="getPaymentStatusIcon(event.payment_received)"
                            overlap
                            class="tw-ml-1"
                        >
                            <v-chip
                                class="white--text"
                                color="secondary"
                                depressed
                            >
                                Payment Received
                            </v-chip>
                        </v-badge>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-btn
                            @click="viewEventData(event)"
                        >
                            View Data
                        </v-btn>
                    </v-col>
                </v-row>
            </v-timeline-item>
        </v-timeline>
        <h2 v-if="!loading && !matchEvents.length"> No Validation Events </h2>
        <json-viewer ref="jsonViewer" />
    </div>
</template>

<script>
import _ from 'lodash';

import OrdersApi from '../../api/OrdersApi';
import JsonViewer from '../globals/JsonViewer.vue';

export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    components: {
        JsonViewer,
    },
    data: () => ({
        loading: false,
        pendingMatches: [],
    }),
    async mounted() {
        await this.loadEvents();
    },
    computed: {
        matchEvents() {
            if (!this.pendingMatches || !this.pendingMatches.length) return [];
            if (!this.item.match) return [];

            const matchId = this.item.match.id;

            let target = null;
            for (let idx = 0; idx < this.pendingMatches.length; idx += 1) {
                const pendingMatch = this.pendingMatches[idx];
                if (pendingMatch.id === matchId) {
                    target = pendingMatch;
                    break;
                }
            }

            if (!target) return [];

            return target.validationEvents || [];
        },
    },
    methods: {
        async loadEvents() {
            if (!this.item || !this.item.match) {
                return;
            }
            this.loading = true;
            try {
                const response = await OrdersApi.getEvents(this.item.id);
                const { pendingMatches } = response.data;
                this.pendingMatches = pendingMatches;
            } catch (err) {
                this.$swal({
                    icon: 'error',
                    title: 'Unable to load events',
                    message: err.message,
                });
            } finally {
                this.loading = false;
            }
        },

        formatEventName(eventName) {
            if (!eventName) return null;

            if (!eventName.includes('_')) return _.upperFirst(eventName);

            const parts = eventName.split(/_+/);

            if (!parts || !parts.length) return _.upperFirst(eventName);

            return parts.map((part) => _.upperFirst(part)).join(' ');
        },

        viewEventData(event) {
            const eventData = event.event_data || {};
            this.$refs.jsonViewer.showViewer(eventData);
        },
        getEventColor(event) {
            const { event_data: eventData, matcher_paid: matcherPaid, payment_received: paymentReceived } = event;
            if (!eventData) return 'gray';

            if (eventData.errors && eventData.errors.length > 0) return 'red';

            if (!matcherPaid && !paymentReceived) return 'gray';

            if (!paymentReceived && matcherPaid) return 'blue';

            return 'green';
        },
        getPaymentStatusColor(status) {
            return status ? 'green' : 'red';
        },
        getPaymentStatusIcon(status) {
            return status ? 'mdi-account-cash' : 'mdi-account-cancel';
        },
    },
};
</script>
