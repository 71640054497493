<template>
  <component :is="current">
    <slot />
  </component>
</template>

<script>

import { mapGetters } from 'vuex';
import Socket from '@/services/Socket';
import Auth from './layouts/Auth.vue';
import Default from './layouts/Default.vue';

export default {
    name: 'App',

    components: {
        Auth,
        Default,
    },

    data: () => ({}),

    computed: {
        ...mapGetters(['current']),
    },
    created() {
      Socket.connect();
    },
};
</script>

<style lang="postcss">
@import 'assets/styles/tailwind.postcss';

</style>
