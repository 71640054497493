/* eslint-disable no-undef */
<template>
  <div class="d-flex flex-column editor tw-relative">
    <div class="d-flex">
      <v-avatar tile class="mr-5 tw-rounded-lg tw-fixed tw-z-50">
        <img :src="user.avatar" :alt="user.name" />
      </v-avatar>
      <editor-content class="tw-w-4/5 pa-1 message__input tw-ml-16" :editor="editor" />
      <v-spacer></v-spacer>
      <v-btn color="#15ABAB" dark id="send_btn"
        class="text-capitalize tw-fixed tw-z-50 mr-5 tw-right-0" @click="sendMessage">
        <v-icon small class="mr-2">$send</v-icon>Send
      </v-btn>
    </div>
    <div class="d-flex justify-space-between tw-w-full mt-2">
      <editor-commands :editor="editor" @file-attached="onFileChange"></editor-commands>
      <div class="tw-max-w-1/3 d-flex align-center" v-if="attachedFiles.length > 0">
        <label class="subject mr-3">Attached files:</label>
        <div class="d-flex justify-space-arround">
          <v-chip
            close
            color="#F7FBFC"
            text-color="#2E5677"
            label
            v-for="(attachFile, index) in attachedFiles"
            :key="attachFile.name"
            class="attach-files mr-3"
            @click:close="removeFile(index)"
          >{{attachFile.name}}</v-chip>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Editor, EditorContent } from 'tiptap';
import {
    Blockquote,
    CodeBlock,
    HardBreak,
    Heading,
    HorizontalRule,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Code,
    Italic,
    Link,
    Strike,
    Underline,
    History,
    Placeholder,
    CodeBlockHighlight,
} from 'tiptap-extensions';
import javascript from 'highlight.js/lib/languages/javascript';
import css from 'highlight.js/lib/languages/css';
import json from 'highlight.js/lib/languages/json';
import markdown from 'highlight.js/lib/languages/markdown';
import html from 'highlight.js/lib/languages/htmlbars';
import EditorCommands from './EditorCommands.vue';

export default {
    components: { EditorCommands, EditorContent },
    data() {
        return {
            attachedFiles: [],
            user: {
                name: 'Ryan King',
                avatar: '/img/ryan.svg',
            },
            editor: new Editor({
                extensions: [
                    new CodeBlockHighlight({
                        languages: {
                            javascript,
                            css,
                            json,
                            markdown,
                            html,
                        },
                    }),
                    new Blockquote(),
                    new BulletList(),
                    new CodeBlock(),
                    new HardBreak(),
                    new Heading({ levels: [1, 2, 3] }),
                    new HorizontalRule(),
                    new ListItem(),
                    new OrderedList(),
                    new TodoItem(),
                    new TodoList(),
                    new Link(),
                    new Bold(),
                    new Code(),
                    new Italic(),
                    new Strike(),
                    new Underline(),
                    new History(),
                    new Placeholder({
                        emptyEditorClass: 'is-editor-empty',
                        emptyNodeClass: 'is-empty',
                        emptyNodeText: 'Type your message here...',
                        showOnlyWhenEditable: true,
                        showOnlyCurrent: true,
                    }),
                ],
                content: '<p>This is <strong>some</strong> inserted text. 👋</p>',
            }),
        };
    },
    methods: {
        onFileChange(e) {
            this.attachedFiles.push(e.target.files[0]);
        },
        removeFile(index) {
            this.$delete(this.attachedFiles, index);
        },
        sendMessage() {},
    },
    beforeDestroy() {
        this.editor.destroy();
    },
};
</script>
<style scoped lang="scss">
.message__input {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 270%;

  /* identical to box height, or 38px */

  /* Gray text */
  color: #7598b5;
}
#send_btn {
  border-radius: 8px;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 270%;

/* identical to box height, or 38px */

color: #FFFFFF;
}
</style>
<style lang="scss">
.message__input pre {
    padding: .7rem 1rem;
    background: #F7FBFC;
    box-shadow: 0px 8px 33px rgba(224, 224, 224, 0.1);
    border-radius: 6px;
    font-size: .8rem;
    overflow-x: auto;
}
.message__input code {
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 29px;
  background-color: transparent !important;
  box-shadow: none !important;

/* or 207% */

  color: #404159 !important;
}
.editor p.is-editor-empty:first-child::before {
  content: attr(data-empty-text);
  float: left;
  color: #7598b5;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 270%;
  pointer-events: none;
  height: 0;
}
</style>
<style lang="scss">
pre {
  &::before {
    content: attr(data-language);
    text-transform: uppercase;
    display: block;
    text-align: right;
    font-weight: bold;
    font-size: 0.6rem;
  }
  code {
    .hljs-comment,
    .hljs-quote {
      color: #999999;
    }
    .hljs-variable,
    .hljs-template-variable,
    .hljs-attribute,
    .hljs-tag,
    .hljs-name,
    .hljs-regexp,
    .hljs-link,
    .hljs-name,
    .hljs-selector-id,
    .hljs-selector-class {
      color: #f2777a;
    }
    .hljs-number,
    .hljs-meta,
    .hljs-built_in,
    .hljs-builtin-name,
    .hljs-literal,
    .hljs-type,
    .hljs-params {
      color: #f99157;
    }
    .hljs-string,
    .hljs-symbol,
    .hljs-bullet {
      color: #99cc99;
    }
    .hljs-title,
    .hljs-section {
      color: #ffcc66;
    }
    .hljs-keyword,
    .hljs-selector-tag {
      color: #6699cc;
    }
    .hljs-emphasis {
      font-style: italic;
    }
    .hljs-strong {
      font-weight: 700;
    }
  }
}
</style>
