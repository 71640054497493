<template>
    <div class="tw-w-full">
        <app-bar>
            <template #title>
                <v-toolbar-title class="default-font font-21 mr-10 tw-text-black">
                    {{ type | humanize }} Verification
                </v-toolbar-title>
            </template>
            <div class="d-flex justify-space-between mr-10">
                <search
                    v-model="search"
                    placeholder="Search...">
                </search>
            </div>
        </app-bar>

        <DocFilters
            :filters.sync="filters">
        </DocFilters>

        <div class="d-flex flex-column">
            <v-data-table
                item-key="id"
                :headers="headers"
                :items="items"
                :loading="loading"
                :items-per-page="pagination.pageSize"
                hide-default-footer
                class="px-12 py-4 tw-bg-transparent custom-table"
                :item-class="() => 'default-font tw-text-black tw-cursor-pointer'"
                @click:row="(item, slot) => slot.expand(!slot.isExpanded)">

                <template #item.user.name="{ item }">
                    <router-link
                        :to="{
                            name: 'User',
                            params: {
                                id: item.user.id,
                            },
                        }">
                        {{ item.user | userFullname }}
                    </router-link>
                </template>

                <template #item.user.dob="{ value }">
                    {{ value | fullYearDate }}
                </template>

                <template #item.user.full_address="{ item }">
                    {{ item.user | userFullAddress }}
                </template>

                <template #item.action="{ item }">
                    <DocActionMenu
                        :item="item"
                        @approve="approve(item)"
                        @decline="decline(item)">
                    </DocActionMenu>
                </template>

                <template #expanded-item="{ headers, item }">
                    <td
                        class="py-2"
                        transition="slide-y-transition">
                        <v-img
                            :src="item.doc_image_url"
                            class="mx-auto"
                            max-width="480"
                            @click="imageDialog = item.doc_image_url"
                        />
                    </td>
                    <td class="py-2" transition="slide-y-transition">
                        <v-btn
                            class="mb-3"
                            text
                            @click="imageDialog = item.doc_image_url">
                            <v-icon>mdi-magnify</v-icon>
                            Zoom
                        </v-btn>
                        <br>
                        <v-btn
                            :href="item.doc_image_url"
                            target="_blank"
                            text>
                            <v-icon>mdi-download</v-icon>
                            Download
                        </v-btn>
                    </td>

                    <template v-if="item.photo_image_url">
                        <td
                            class="py-2"
                            transition="slide-y-transition">
                            <v-img
                                :src="item.photo_image_url"
                                class="mx-auto"
                                max-width="480"
                                @click="imageDialog = item.photo_image_url"
                            />
                        </td>
                        <td class="py-2" transition="slide-y-transition">
                            <v-btn
                                class="mb-3"
                                text
                                @click="imageDialog = item.photo_image_url">
                                <v-icon>mdi-magnify</v-icon>
                                Zoom
                            </v-btn>
                            <br>
                            <v-btn
                                :href="item.photo_image_url"
                                target="_blank"
                                text>
                                <v-icon>mdi-download</v-icon>
                                Download
                            </v-btn>
                        </td>
                    </template>

                    <template v-if="item.extra_image_url">
                        <td
                            class="py-2"
                            transition="slide-y-transition">
                            <v-img
                                :src="item.extra_image_url"
                                class="mx-auto"
                                max-width="480"
                                @click="imageDialog = item.extra_image_url"
                            />
                        </td>
                        <td class="py-2" transition="slide-y-transition">
                            <v-btn
                                class="mb-3"
                                text
                                @click="imageDialog = item.extra_image_url">
                                <v-icon>mdi-magnify</v-icon>
                                Zoom
                            </v-btn>
                            <br>
                            <v-btn
                                :href="item.extra_image_url"
                                target="_blank"
                                text>
                                <v-icon>mdi-download</v-icon>
                                Download
                            </v-btn>
                        </td>
                    </template>
                </template>
            </v-data-table>

            <v-pagination
                v-model="pagination.page"
                :length="pagination.pageCount"
                next-icon="$next"
                prev-icon="$prev"
                class="custom-pagination"
                color="#7598B5">
            </v-pagination>
        </div>

        <v-dialog
            :value="!!imageDialog"
            width="90%"
            @input="() => imageDialog = false">
            <v-img
                v-if="imageDialog"
                :src="imageDialog"
                class="card-image mx-auto"
                max-width="100%">
            </v-img>
        </v-dialog>

        <DocDeclineConfirm ref="docDeclineConfirm" />
    </div>
</template>
<script>
import Socket from '../../services/Socket';
import DocVerificationApi from '../../api/DocVerificationApi';

import AppBar from '../../components/globals/AppBar.vue';
import Search from '../../components/globals/Search.vue';
import DocActionMenu from './DocActionMenu.vue';
import DocFilters from './DocFilters.vue';
import DocDeclineConfirm from './DocDeclineConfirm.vue';

export default {
    components: {
        AppBar,
        Search,
        DocActionMenu,
        DocFilters,
        DocDeclineConfirm,
    },
    props: {
        type: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        loading: false,
        items: [],
        pagination: {
            page: 1,
            pageSize: 10,
        },
        filters: {},
        search: '',
        imageDialog: false,
    }),
    computed: {
        headers() {
            return [
                {
                    text: 'User Name',
                    align: 'start',
                    value: 'user.full_name',
                    sortable: false,
                    class: 'py-2',
                },
                {
                    text: 'Date of Birth',
                    align: 'start',
                    value: 'user.dob',
                    sortable: false,
                    class: 'tw-text-black default-font py-2 text-start',
                },
                ...(this.type === 'identity'
                    ? [{
                        text: 'National ID Number',
                        align: 'start',
                        value: 'data.id_number',
                        sortable: false,
                        class: 'tw-text-black default-font py-2 text-start',
                    }, {
                        text: 'Expiry Date',
                        align: 'start',
                        value: 'expiry_date',
                        sortable: false,
                        class: 'tw-text-black default-font py-2 text-start',
                    }] : []
                ),
                ...(this.type === 'address'
                    ? [{
                        text: 'Full Address',
                        align: 'start',
                        value: 'user.full_address',
                        sortable: false,
                        class: 'tw-text-black default-font py-2 text-start',
                    }] : []
                ),
                {
                    align: 'center',
                    value: 'action',
                    sortable: false,
                    class: 'default-font py-2 text-center last_cell tw-rounded-lg tw-rounded-l-none',
                },
                {
                    value: '',
                },
            ];
        },
    },
    methods: {
        async loadItems() {
            try {
                this.loading = true;

                const {
                    type,
                    search,
                    filters,
                    pagination: {
                        page,
                        pageSize,
                    },
                } = this;

                const { data } = await DocVerificationApi.getAll({
                    ...filters,
                    type,
                    search,
                    page,
                    pageSize,
                });

                const { models, pagination } = data;
                this.items = models;
                this.pagination = pagination;
            } finally {
                this.loading = false;
            }
        },

        addItem(item) {
            const { type } = item;
            if (type === this.type) {
                this.items.push(item);
            }
        },

        confirmApprove() {
            const html = `<p>You confirm ${this.type} of the user</p>`;

            return this.$swal({
                icon: 'warning',
                title: `Are you sure you want to confirm this ${this.type} verification request?`,
                html,
                showCancelButton: true,
                confirmButtonText: 'Yes',
            });
        },

        async approve(item) {
            try {
                const { value } = await this.confirmApprove();

                if (value) {
                    this.loading = true;
                    await DocVerificationApi.approve(item.id);

                    this.$swal({
                        icon: 'success',
                        title: 'Approved',
                        text: 'Document successfully verified',
                    });
                    this.loadItems();
                }
            } finally {
                this.loading = false;
            }
        },

        confirmDecline() {
            return this.$swal({
                icon: 'warning',
                title: `Are you sure you want to decline this ${this.type} verification request?`,
                showCancelButton: true,
                confirmButtonText: 'Yes',
            });
        },

        async decline(item) {
            const { number } = item;

            const comments = await this.$refs.docDeclineConfirm.show();

            if (!comments) return;

            this.confirmDecline()
                .then((result) => {
                    if (!result.value) {
                        return false;
                    }

                    this.loading = true;
                    const payload = {
                        ...item,
                        comments,
                    };

                    return DocVerificationApi.decline(payload)
                        .then(() => {
                            this.loading = false;
                            this.$swal({
                                icon: 'success',
                                title: 'Declined',
                                text: `Card ${number} successfully declined`,
                            });
                            this.loadItems();
                        })
                        .catch(() => {
                            this.loading = false;
                        });
                });
        },

        addSocketEventListeners() {
            Socket.on('docVerificationRequestCreated', this.addItem);
        },

        removeSocketEventListeners() {
            Socket.remove('docVerificationRequestCreated');
        },
    },
    watch: {
        type: 'loadItems',
        search: 'loadItems',
        'pagination.page': {
            immediate: true,
            handler() {
                this.loadItems();
            },
        },
        filters: {
            deep: true,
            handler() {
                this.loadItems();
            },
        },
    },
    mounted() {
        this.addSocketEventListeners();
    },
    beforeDestroy() {
        this.removeSocketEventListeners();
    },
};
</script>
