<template>
    <v-dialog
        :value="showDialog"
        max-width="400px"
        @click:outside="decline"
    >
        <v-card
            class="py-2"
            flat
        >
            <v-card-title>
                Select Decline Reason
            </v-card-title>
            <v-card-text>
                <v-combobox
                    v-model="comments"
                    :items="reasons"
                    :hide-no-data="!search"
                    :search-input.sync="search"
                    label="Decline Reason"
                    deletable-chips
                    small-chips
                    multiple
                    outlined
                    chips
                    dense>
                    <template #no-data>
                        <v-list-item>
                            <span class="subheading">
                                Other reason:
                            </span>
                            <v-chip
                                label
                                small
                            >
                                {{ search }}
                            </v-chip>
                        </v-list-item>
                    </template>
                </v-combobox>
            </v-card-text>
            <v-card-actions
                class="justify-center"
            >
                <v-btn
                    class="text-capitalize default-font font-14 tw-font-bold mr-5"
                    color="cancel"
                    large
                    dark
                    @click="decline"
                >
                    Cancel
                </v-btn>
                <v-btn
                    :disabled="!hasComments"
                    class="text-capitalize default-font font-14 tw-font-bold"
                    color="secondary"
                    large
                    @click="approve"
                >
                    Decline
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    data() {
        return {
            showDialog: false,
            reasons: [
                'Incorrect ID Number',
                'ID type not recognized',
                'Incorrect Date of Birth',
                'Incorrect ID Expiry date',
                'ID Expired',
                'Selfie Image Not Clear',
                'ID Image Not Clear',
                'ID Name does not match account name',
            ],
            comments: [],
            search: null,
            resolve: () => {},
            reject: () => {},
        };
    },
    computed: {
        hasComments() {
            return this.comments?.length > 0;
        },
    },
    methods: {
        show() {
            this.showDialog = true;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        approve() {
            this.showDialog = false;
            if (this.hasComments) {
                this.resolve(this.comments);
            }
            this.comments = [];
        },
        decline() {
            this.showDialog = false;
            this.comments = [];
            this.resolve(false);
        },
    },
};
</script>
