<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    allow-overflow
    offset-y
    bottom
    transition="slide-y-transition"
    content-class="mt-3"
    max-width="300px"
    :disabled="disabled"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        class="default-font text-capitalize px-3 filter-title tw-text-black d-flex justify-space-between sent-filter"
        text
        large
        v-on="on"
        :disabled="disabled"
      >
        {{filterValue}}
        <v-icon color="#7598B5" small>mdi-chevron-down</v-icon>
      </v-btn>
    </template>

    <selector
      @apply-filter="filterChanged"
      v-if="menu"
      :disabled="disabled"
    ></selector>
  </v-menu>
</template>

<script>
import Selector from './Selector.vue';

export default {
    components: { Selector },
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
        storeKey: {
            type: String,
            default: 'transaction',
        },
    },
    data() {
        return {
            fav: true,
            menu: false,
            message: false,
            hints: true,
            to: undefined,
            from: undefined,
        };
    },
    methods: {
        filterChanged(filterData) {
            this.menu = false;
            this.to = filterData.to;
            this.from = filterData.from;
            this.updateReceivedFilter({ to: this.to, from: this.from });
        },
        updateReceivedFilter(payload) {
            return this.$store.dispatch(`${this.storeKey}/updateReceivedFilter`, payload);
        },
    },
    watch: {
      receivedFilter: {
        deep: true,
        handler() {
            this.to = this.receivedFilter.to;
            this.from = this.receivedFilter.from;
        },
      },
    },
    computed: {
        receivedFilter() {
            return this.$store.state[this.storeKey].receivedFilter;
        },
        filterValue() {
            const isAnyValu = !this.to && !this.from;
            const to = !this.to ? 'Any Amount' : this.to;
            const from = !this.from ? 'Any Amount' : this.from;
            return isAnyValu ? 'Any Amount' : `${from} - ${to}`;
        },
    },
    mounted() {
        this.to = this.receivedFilter.to;
        this.from = this.receivedFilter.from;
    },
};
</script>
