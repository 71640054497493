import io from 'socket.io-client';
import store from '../store';

class Socket {
    constructor(origin) {
        this.socket = {
            connected: false,
        };

        this.origin = origin;
        this.options = {
            transports: ['websocket'],
        };

        this.events = {};
    }

    async connect() {
        this.socket = await io(this.origin, this.options); // eslint-disable-line

        this.socket.on('connected', () => this.applyEvents('connected'));

        this.socket.on('reconnect', () => this.applyEvents('reconnect'));

        this.socket.on('connect', () => {
            const { authToken } = store.state.auth;
            if (authToken) {
                const auth = `${authToken.token_type} ${authToken.access_token}`;

                this.socket.emit(
                    'Authorization',
                    auth,
                );
            }
        });
    }

    /**
     * Attempts to apply any queued events, if present.
     */
    applyEvents() {
        Object.keys(this.events).forEach((event) => {
            const callbacks = this.events[event];
            callbacks.forEach((cb) => {
                this.socket.on(event, cb);
            });
        });

        this.events = {};
    }

    /**
     * Add an event listener.
     *
     * @param event
     * @param cb
     * @returns {Promise<void>}
     */
    async on(event, cb) {
        if (this.socket && this.socket.connected) {
            this.socket.on(event, cb);
        } else {
            if (!this.events[event]) {
                this.events[event] = [];
            }

            this.events[event].push(cb);
        }
    }

    remove(event) {
        this.socket.removeListener(event);
    }

    disconnect() {
        if (!this.socket || !this.socket.disconnect) return;
        this.socket.disconnect();
    }
}

const instance = new Socket(process.env.VUE_APP_KAOSHI_API_URL);

export default instance;
