var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('v-navigation-drawer',{staticClass:"tw-bg-navBg",attrs:{"app":"","mini-variant":"","permanent":"","mini-variant-width":120},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{staticClass:"d-flex flex-column justify-space-around tw-mt-5 pa-5",attrs:{"flat":""}},[_c('v-list-item',{staticClass:"px-2"},[_c('v-list-item-avatar',{attrs:{"size":70}},[_c('v-img',{attrs:{"src":require("../assets/logo2.svg")}})],1)],1),_vm._l((_vm.items),function(item){return [_c('v-tooltip',{key:item.title,attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"ma-2 tw-rounded-lg hover:tw-bg-linkHoverBg",attrs:{"link":"","to":item.link,"active-class":"tw-bg-primary text--white"}},'v-list-item',attrs,false),on),[_c('v-badge',_vm._g(_vm._b({attrs:{"overlap":"","color":"error lighten-1","content":item.badge,"value":!!item.badge}},'v-badge',attrs,false),on),[_c('v-list-item-avatar',{attrs:{"tile":"","size":"25"}},[_c('v-icon',{attrs:{"color":_vm.iconColor(item.link)}},[_vm._v(_vm._s(item.icon))])],1)],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])])]}),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"tw-rounded-lg mt-5 hover:tw-bg-linkHoverBg"},'v-list-item',attrs,false),on),[_c('MaintenanceButton')],1)]}}])},[_c('span',[_vm._v("Maintenance Mode")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"tw-rounded-lg mt-5 hover:tw-bg-linkHoverBg",on:{"click":_vm.logout}},'v-list-item',attrs,false),on),[_c('v-list-item-avatar',{attrs:{"tile":"","size":"25"}},[_c('v-icon',{attrs:{"color":_vm.iconColor('logout')}},[_vm._v("$logout")])],1)],1)]}}])},[_c('span',[_vm._v("Logout")])])],2)],1),_c('v-main',[_c('v-slide-y-transition',{attrs:{"mode":"out-in"}},[_c('router-view')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }