<template>
  <div class="tw-w-full">
    <app-bar>
      <template slot="title">
        <v-toolbar-title class="default-font font-21 mr-10 tw-text-black">Global Offers</v-toolbar-title>
      </template>
      <div class="d-flex justify-space-between mr-10">
        <search v-model="search" placeholder="Search companies"></search>
      </div>
    </app-bar>

    <filters @apply-filter="applyFilters" @cancel-filters="cancelFilters"></filters>

    <div class="tw-w-full px-12 tw-mb-10 tw-mt-5">
        <list :items="offers" :search="search"></list>
    </div>
  </div>
</template>
<script>
import AppBar from '@/components/globals/AppBar.vue';
import Filters from '@/components/offers/Filters.vue';
import List from '@/components/offers/List.vue';
import Search from '@/components/globals/Search.vue';
import offersList from '../utils/offers';

export default {
    components: {
        AppBar,
        Filters,
        List,
        Search,
    },
    data() {
        return {
            showFilters: false,
            durations: [],
            payments: [],
            deliveryMethods: [],
            ratings: [],
            offers: offersList,
            search: null,
        };
    },
    computed: {
        filterText() {
            return this.showFilters ? 'Hide filters' : 'Show filters';
        },
        sortedItem() {
            return this.sortedList[this.sorted];
        },
    },
    methods: {
        cancelFilters() {
            this.durations = [];
            this.payments = [];
            this.deliveryMethods = [];
            this.ratings = [];
            this.showFilters = false;
        },
        applyFilters(filters) {
            this.durations = filters.durations;
            this.ratings = filters.ratings;
            this.deliveryMethods = filters.deliveryMethods;
            this.payments = filters.payments;
            this.showFilters = false;
        },
    },
};
</script>
