<template>
    <div class="tw-w-full">
        <app-bar>
            <template #title>
                <v-toolbar-title class="default-font font-21 mr-10 tw-text-black">
                    Hybrid Order Options
                </v-toolbar-title>
            </template>
        </app-bar>

        <v-container>
            <v-row>
                <v-col
                    cols="12"
                    md="4"
                    lg="3">
                    <v-text-field
                        v-model="exchange_rate"
                        label="Exchange rate"
                        class="form-control"
                        type="number"
                        step="2"
                        name="exchange_rate"
                        persistent-hint
                        outlined
                        dense
                    />
                </v-col>

                <v-col
                    cols="12"
                    md="4"
                    lg="3">
                    <v-text-field
                        v-model.number="fee"
                        label="Fee"
                        class="form-control"
                        type="number"
                        step="2"
                        name="fee"
                        persistent-hint
                        outlined
                        dense
                    />
                </v-col>

                <v-col
                    cols="12"
                    md="4"
                    lg="3">
                    <v-btn
                        color="primary"
                        :disabled="isSaveAllDisabled"
                        depressed
                        @click="updateItems">
                        Save ALl
                    </v-btn>
                </v-col>

            </v-row>
            <v-row>
                <v-col
                    cols="12"
                    md="3"
                    lg="2"
                    class="ml-auto">
                    <v-select
                        v-model="filters.third_party_company_id"
                        :items="thirdPartyCompanies"
                        item-text="company.name"
                        item-value="id"
                        :menu-props="{
                            bottom: true,
                            offsetY: true,
                            'content-class': 'mt-3 tw-rounded-lg',
                        }"
                        placeholder="Provider"
                        prepend-inner-icon="$filter"
                        clearable
                        outlined
                        dense>
                    </v-select>
                </v-col>

                <v-col
                    cols="12"
                    md="3"
                    lg="2"
                    class="ml-auto">
                    <v-select
                        v-model="filters.matcher_amount"
                        :items="matcherAmounts"
                        :menu-props="{
                            bottom: true,
                            offsetY: true,
                            'content-class': 'mt-3 tw-rounded-lg',
                        }"
                        placeholder="Amount"
                        prepend-inner-icon="$filter"
                        clearable
                        outlined
                        dense>
                    </v-select>
                </v-col>

                <v-col
                    cols="12"
                    md="3"
                    lg="2"
                    class="ml-auto">
                    <v-select
                        v-model="filters.matcher_country"
                        :items="matcherCountries"
                        item-text="country"
                        item-value="abbreviation"
                        :menu-props="{
                            bottom: true,
                            offsetY: true,
                            'content-class': 'mt-3 tw-rounded-lg',
                        }"
                        placeholder="From Country"
                        prepend-inner-icon="$filter"
                        clearable
                        outlined
                        dense>
                    </v-select>
                </v-col>

                <v-col
                    cols="12"
                    md="3"
                    lg="2"
                    class="ml-auto">
                    <v-select
                        v-model="filters.pb_country"
                        :items="pbCountries"
                        item-text="country"
                        item-value="abbreviation"
                        :menu-props="{
                            bottom: true,
                            offsetY: true,
                            'content-class': 'mt-3 tw-rounded-lg',
                        }"
                        placeholder="To Country"
                        prepend-inner-icon="$filter"
                        clearable
                        outlined
                        dense>
                    </v-select>
                </v-col>

                <v-col
                    cols="12"
                    md="3"
                    lg="2"
                    class="ml-auto">
                    <v-select
                        v-model="filters.matcher_currency"
                        :items="matcherCurrencies"
                        :menu-props="{
                            bottom: true,
                            offsetY: true,
                            'content-class': 'mt-3 tw-rounded-lg',
                        }"
                        item-text="currency_code"
                        item-value="currency_code"
                        placeholder="From Currency"
                        prepend-inner-icon="$filter"
                        clearable
                        outlined
                        dense>
                    </v-select>
                </v-col>

                <v-col
                    cols="12"
                    md="3"
                    lg="2"
                    class="ml-auto">
                    <v-select
                        v-model="filters.pb_currency"
                        :items="pbCurrencies"
                        :menu-props="{
                            bottom: true,
                            offsetY: true,
                            'content-class': 'mt-3 tw-rounded-lg',
                        }"
                        placeholder="To Currency"
                        prepend-inner-icon="$filter"
                        clearable
                        outlined
                        dense>
                    </v-select>
                </v-col>

                <v-col>
                    <v-btn
                        color="primary"
                        depressed
                        @click="showLogDialog = true"
                    >
                        Show Log
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>

        <div class="d-flex flex-column">
            <v-data-table
                item-key="id"
                :headers="headers"
                :items="items"
                :loading="loading"
                :items-per-page="pagination.pageSize"
                hide-default-footer
                class="px-12 py-4 tw-bg-transparent custom-table"
                :item-class="() => 'default-font tw-text-black tw-cursor-pointer'"
                @click:row="(item, slot) => editItem(item)">
            </v-data-table>

            <v-pagination
                v-model="pagination.page"
                :length="pagination.pageCount"
                next-icon="$next"
                prev-icon="$prev"
                class="custom-pagination"
                color="#7598B5">
            </v-pagination>
        </div>

        <HybridOrderOptionsEditDialog
            v-model="showEditDialog"
            :hybrid-order-option="editedItem"
            @input="editedItem = {}"
            @save="updateItem">
        </HybridOrderOptionsEditDialog>

        <v-dialog
            v-model="showLogDialog"
            max-width="1200"
        >
            <HybridOrderOptionsChangelog v-if="showLogDialog"/>
        </v-dialog>
    </div>
</template>
<script>
/* eslint-disable camelcase */

import { uniq, compact, range } from 'lodash';

import HybridOrderOption from '../../models/HybridOrderOption';
import HybridOrderOptionsApi from '../../api/HybridOrderOptionsApi';
import CountriesApi from '../../api/CountriesApi';
import ThirdPartyCompaniesApi from '../../api/ThirdPartyCompaniesApi';

import AppBar from '../../components/globals/AppBar.vue';
import HybridOrderOptionsEditDialog from '../../components/hybridOrderOptions/HybridOrderOptionsEditDialog.vue';
import HybridOrderOptionsChangelog from './HybridOrderOptionsChangelog.vue';

export default {
    components: {
        AppBar,
        HybridOrderOptionsEditDialog,
        HybridOrderOptionsChangelog,
    },
    data: () => ({
        loading: false,
        countries: [],
        thirdPartyCompanies: [],
        items: [],
        editedItem: {},
        headers: [
            {
                text: 'Provider',
                align: 'start',
                value: 'thirdPartyCompany.company.name',
                class: 'default-font py-2 text-center last_cell tw-rounded-lg tw-rounded-l-none',
            },
            {
                text: 'Amount',
                align: 'start',
                value: 'pbAmountFormatted',
                class: 'default-font py-2 text-center last_cell tw-rounded-lg tw-rounded-l-none',
            },
            {
                text: 'Exchange Rate',
                align: 'start',
                value: 'exchangeRateFormatted',
                class: 'default-font py-2 text-center last_cell tw-rounded-lg tw-rounded-l-none',
            },
            {
                text: 'Fee',
                align: 'start',
                value: 'fee',
                class: 'default-font py-2 text-center last_cell tw-rounded-lg tw-rounded-l-none',
            },
            {
                text: 'Matcher Currency',
                align: 'start',
                value: 'matcher_currency',
                class: 'default-font py-2 text-center last_cell tw-rounded-lg tw-rounded-l-none',
            },
            {
                text: 'Matcher Country',
                align: 'start',
                value: 'matcher_country',
                class: 'default-font py-2 text-center last_cell tw-rounded-lg tw-rounded-l-none',
            },
            {
                text: 'PB Currency',
                align: 'start',
                value: 'pb_currency',
                class: 'default-font py-2 text-center last_cell tw-rounded-lg tw-rounded-l-none',
            },
            {
                text: 'PB Country',
                align: 'start',
                value: 'pb_country',
                class: 'default-font py-2 text-center last_cell tw-rounded-lg tw-rounded-l-none',
            },
        ],
        filters: {
            matcher_amount: null,
            pb_country: null,
            matcher_country: null,
            third_party_company_id: null,
        },
        pagination: {
            page: 1,
            pageSize: 10,
        },
        exchange_rate: '',
        fee: '',
        showEditDialog: false,
        showLogDialog: false,
    }),
    computed: {
        isSaveAllDisabled() {
            return (this.exchange_rate === '' || this.exchange_rate === null)
                && (this.fee === '' || this.fee === null);
        },
        pbCountries() {
            return this.countries.filter(({ receiving, abbreviation }) => {
                if (receiving) {
                    return true;
                }

                if ([
                    'CN',
                    'IN',
                    'TR',
                    'AE',
                    'BR',
                ].indexOf(abbreviation) >= 0) {
                    return true;
                }

                return false;
            });
        },
        matcherCountries() {
            return this.countries.filter((country) => country.sending);
        },
        pbCurrencies() {
            return compact(uniq(this.pbCountries.map((country) => country.currency)));
        },
        matcherCurrencies() {
            return compact(uniq(this.matcherCountries.map((country) => country.currencyModel)));
        },
        matcherAmounts() {
            const matcherCurrencyModel = this.matcherCurrencies.find(({ currency_code }) => this.filters?.matcher_currency === currency_code);
            if (matcherCurrencyModel) {
                const { max_amount, step } = matcherCurrencyModel;
                return range(step, (max_amount + step), step);
            }
            return [
                50, 100, 150, 200, 250, 300, 350, 400, 450, 500,
            ];
        },
    },
    methods: {
        async loadCountries() {
            try {
                const { data: { data } } = await CountriesApi.getAll();
                this.$set(this, 'countries', data);
            } catch (error) {
                this.$swal({
                    type: 'error',
                    title: 'Error loading countries',
                    text: error,
                });
            }
        },
        async loadThirdPartyCompanies() {
            try {
                const { data: { data } } = await ThirdPartyCompaniesApi.getAll();
                this.thirdPartyCompanies = data;
            } catch (error) {
                this.$swal({
                    type: 'error',
                    title: 'Error loading third party companies',
                    text: error,
                });
            }
        },
        async loadItems() {
            try {
                this.loading = true;

                const {
                    filters,
                    pagination: {
                        page,
                        pageSize,
                    },
                } = this;

                const { data } = await HybridOrderOptionsApi.getAll({
                    ...filters,
                    page,
                    pageSize,
                });

                const { data: models, pagination } = data;
                this.items = models.map((item) => new HybridOrderOption(item));
                this.pagination = pagination;
            } finally {
                this.loading = false;
            }
        },
        editItem(item) {
            this.$set(this, 'editedItem', item);
            this.$nextTick(() => {
                this.showEditDialog = true;
            });
        },
        async updateItem(item) {
            try {
                this.loading = true;

                const {
                    thirdPartyCompany,
                    ...payload
                } = item;

                await HybridOrderOptionsApi.update(payload);

                this.showEditDialog = false;
                this.loadItems();
            } finally {
                this.loading = false;
            }
        },
        async updateItems() {
            try {
                const { rowCount } = this.pagination;

                const { isConfirmed } = await this.$swal({
                    icon: 'warning',
                    title: 'Are You Sure?',
                    text: `Are You sure to update all these record? (${rowCount} rows will be affected)`,
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                });

                if (isConfirmed) {
                    this.loading = true;

                    const {
                        exchange_rate, // eslint-disable-line camelcase
                        fee,
                        filters,
                    } = this;

                    const payload = {
                        exchange_rate,
                        fee,
                    };

                    await HybridOrderOptionsApi.updateAll(filters, this.removeEmpty(payload));

                    this.exchange_rate = '';
                    this.fee = '';

                    this.loadItems();
                }
            } finally {
                this.loading = false;
            }
        },
        /**
         * Remove empty and nullable values from object
         * @param {Object} obj
         */
        removeEmpty(obj) {
            const o = {};
            Object.entries(obj).forEach(([key, val]) => {
                if (val === '' || val === null) {
                    return null;
                }
                return o[key] = val; // eslint-disable-line no-return-assign
            });
            return o;
        },
    },
    watch: {
        filters: {
            deep: true,
            handler() {
                this.loadItems();
            },
        },
        'pagination.page': {
            immediate: true,
            handler() {
                this.loadItems();
            },
        },
    },
    mounted() {
        this.loadCountries();
        this.loadThirdPartyCompanies();
    },
};
</script>
