import { render, staticRenderFns } from "./Amount.vue?vue&type=template&id=18779ded&scoped=true&functional=true&"
import script from "./Amount.vue?vue&type=script&lang=js&"
export * from "./Amount.vue?vue&type=script&lang=js&"
import style0 from "./Amount.vue?vue&type=style&index=0&id=18779ded&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "18779ded",
  null
  
)

export default component.exports