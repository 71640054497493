<template>
  <div class="d-flex">
    <div class="step mr-5" :class="{'tw-bg-primary': isCheck}">
      <v-icon v-if="isCheck" color="white">mdi-check</v-icon>
      <div v-else :class="{'current-step': isActive}">{{ stepNumber }}</div>
    </div>
    <h3 class="step-label default-font" :class="{'upcoming-step': !isCheck && !isActive}">{{label}}</h3>
  </div>
</template>

<script>
export default {
    props: {
        state: {
            type: String,
            validator(value) {
                // The value must match one of these strings
                return ['checked', 'active', 'default'].indexOf(value) !== -1;
            },
        },
        stepNumber: {
            type: Number,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
    },
    computed: {
        isCheck() {
            return this.state === 'checked';
        },
        isActive() {
            return this.state === 'active';
        },
    },
};
</script>
