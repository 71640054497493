/* eslint-disable camelcase */
import axios from 'axios';

const api = axios.create({
    baseURL: process.env.VUE_APP_KAOSHI_API_URL,
    withCredentials: true,
});

export default {

    /**
     * Login user
     *
     * @param {Object} payload payload {username, password}
     * @returns {AxiosPromise<any>}
     */
    login(payload) {
        const {
            username,
            password,
        } = payload;

        const params = new URLSearchParams();
        params.append('username', username);
        params.append('password', password);
        params.append('grant_type', 'password');
        params.append('scope', 'read write');

        return axios({
            method: 'post',
            baseURL: process.env.VUE_APP_KAOSHI_API_URL,
            url: 'auth/user/token',
            data: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
    },

    /**
     * Gets a new token, given a valid refresh token
     *
     * @param {Object} payload
     * @returns {AxiosPromise<any>}
     */
    refreshToken(payload) {
        const {
            refresh_token,
        } = payload;

        const params = new URLSearchParams();
        params.append('refresh_token', refresh_token);
        params.append('grant_type', 'refresh_token');
        params.append('scope', 'read write');

        return axios({
            method: 'post',
            baseURL: process.env.VUE_APP_KAOSHI_API_URL,
            url: 'auth/refresh/token',
            data: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
    },

    /**
     * Reset User's Password by Email
     *
     * @param {Object} payload payload { email }
     */
    resetPassword(payload) {
        return api.post('/auth/reset/init', payload);
    },

    /**
     * Logout current user
     */
    logout() {
        return api.get('/session/clear');
    },

};
