<template>
    <tr class="default-font tw-text-black tw-cursor-pointer" @click="$emit('click');">
        <td class="py-2">{{ item.created_at | date }} <br /> <span class=" text-gray-100">{{ item.time }}</span></td>
        <td class="py-2">{{ order.id }}</td>
        <td class="py-2">{{ match.id }}</td>
        <td class="py-2">{{ match.matcher_amount | price }} {{ match.matcher_currency }}</td>
        <td class="py-2">{{ matcher.full_name }} <br /> ({{ matcher.email }})</td>
        <td class="py-2">{{ postersBeneficiary.full_name }} <br /> ({{ postersBeneficiary.email }})</td>
        <td class="py-2">
            <v-chip>
                {{ item.status }}
            </v-chip>
        </td>
        <td class="py-2">
            <v-menu transition="slide-y-transition" bottom offset-y content-class="mt-3 tw-rounded-lg">
                <template v-slot:activator="{ on }">
                    <v-btn
                        color="secondary"
                        dark
                        outlined
                        elevation="0"
                        large
                        class="default-font font-12 text-capitalize justify-space-between"
                        v-on="on"
                    >
                        Actions
                        <v-icon x-small class="ml-2">mdi-chevron-down</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item>
                        <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                            <v-btn @click="viewDispute" text class="text-capitalize default-font ">
                                View
                            </v-btn>
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                            <v-btn
                                text
                                class="text-capitalize default-font"
                                :href="$router.resolve({
                                    name: 'TransactionDetails',
                                    params: {
                                        id: item.id,
                                    },
                                }).href"
                            >
                                Validation Details
                            </v-btn>
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                            <MatchValidationJourneyModal
                                v-if="item.match_id"
                                :id="item.match_id"
                            />
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item v-if="isAdmin">
                        <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                            <v-btn
                                text
                                class="text-capitalize default-font"
                                @click="cancelMatch"
                                :disabled="!canCancelMatch"
                            >
                                Cancel Match
                            </v-btn>
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item v-if="isAdmin">
                        <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                            <v-btn
                                text
                                class="text-capitalize default-font"
                                @click="approveMatch"
                                :disabled="!canCancelMatch"
                            >
                                Approve Match
                            </v-btn>
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                            <v-btn
                                class="text-capitalize default-font"
                                text
                                @click="dismissDispute">
                                Dismiss
                            </v-btn>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </td>
    </tr>
</template>
<script>
import { mapGetters } from 'vuex';
import DisputesApi from '../../api/DisputesApi';
import MatchesApi from '../../api/MatchesApi';

import MatchValidationJourneyModal from '../../components/transactions/MatchValidationJourneyModal.vue';

export default {
    components: {
        MatchValidationJourneyModal,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters([
            'user',
        ]),
        isAdmin() {
            return this.user.role === 'admin';
        },
        match() {
            return this.item?.match || {};
        },
        order() {
            return this.match?.order || {};
        },
        matcher() {
            return this.match?.user || {};
        },
        postersBeneficiary() {
            return this.order?.postersBeneficiary || {};
        },
        canCancelMatch() {
            const {
                id,
                cancelled_at,
                matcher_payment_completed_at,
                completed_at,
            } = this.match;

            if (!id) {
                return false;
            }

            return !cancelled_at && !matcher_payment_completed_at && !completed_at;
        },
    },
    methods: {
        confirm(text = 'Are you sure you want to take this action?') {
            return this.$swal({
                type: 'info',
                title: text,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => result.value);
        },
        viewDispute() {
            this.$emit('view-dispute', this.item);
        },
        async dismissDispute() {
            const { id } = this.item;
            await DisputesApi.update(id, {
                status: 'resolved',
            });
            this.$emit('load-disputes');
        },
        async cancelMatch() {
            if (this.item.match_id) {
                const isConfirmed = await this.confirm('Are you sure you want to take this action - Cancel Match?');

                if (!isConfirmed) return;

                await MatchesApi.cancel(this.item.match_id);

                this.$emit('load-disputes');
            }
        },
        async approveMatch() {
            if (this.item.match_id) {
                const isConfirmed = await this.confirm('Are you sure you want to take this action - Approve Match?');

                if (!isConfirmed) return;

                await MatchesApi.approve(this.item.match_id);

                this.$emit('load-disputes');
            }
        },
    },
};
</script>
