<template>
  <div class="d-flex flex-column tw-h-2/5">
    <div v-for="(item, index) in steps" :key="item.label">
      <step-item :label="item.label" :state="item.state" :step-number="item.step"></step-item>
      <v-divider
        v-if="index !== steps.length - 1"
        vertical
        class="ml-4 mt-2 mb-2 step-divider tw-border"
        v-bind:class="{'tw-bg-primary': isChecked(item.state)}"
      ></v-divider>
    </div>
  </div>
</template>

<script>
import StepItem from './Step.vue';

export default {
    props: {
        steps: {
            type: Array,
            default: () => [],
        },
    },
    components: { StepItem },
    methods: {
        isChecked(state) {
            return state === 'checked';
        },
    },
};
</script>
