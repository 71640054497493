<template>
        <v-row class="ma-0">
            <v-col cols=12>
                <v-row class="mt-8">
                    <v-card
                        class="mx-auto pl-7 pr-7 pt-5 pb-5 tw-border-none"
                        id="loginCard"
                        max-width="500"
                        outlined
                    >
                    <v-card-title id="logTitle" class="justify-center"> Create Account</v-card-title>
                    <v-card-text>
                        <form>
                            <v-row>
                                <v-col cols="12" class="d-flex flex-column pa-2">
                                    <label class="f-label default-font"> E-mail:</label>
                                    <input
                                       type="email"
                                       v-model="email"
                                       class="f-input default-font pa-2 tw-border-binput focus:tw-border-primary"
                                    />
                                </v-col>
                                <v-col cols="12" class="d-flex flex-column pa-2">
                                    <label class="f-label default-font"> Password:</label>
                                    <input
                                        type="password"
                                        v-model="password"
                                        class="f-input default-font pa-2 tw-border-binput focus:tw-border-primary"
                                    />
                                </v-col>
                                <v-col cols="12" class="d-flex flex-column pa-2">
                                    <label class="f-label default-font"> Password:</label>
                                    <input
                                        type="password"
                                        v-model="confirmPassword"
                                        class="f-input default-font pa-2 tw-border-binput focus:tw-border-primary"
                                    />
                                </v-col>
                                <v-col cols="12" class="d-flex justify-start pa-2">
                                    <v-checkbox color="primary" class="mt-0" v-model="agree">
                                        <template v-slot:label>
                                            <span class="agree default-font">
                                                I agree to Kaoshi's Terms of Use and consent to Kaoshi's
                                                Privacy Statement.
                                            </span>
                                        </template>
                                    </v-checkbox>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn
                                        x-large
                                        color="primary"
                                        id="signBtn"
                                        class=" default-font text-capitalize text-center tw-w-full"
                                        to="/about-bank"
                                    >
                                        Create Account
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </form>
                    </v-card-text>
                    </v-card>
                </v-row>
                 <div class="d-flex mt-4 justify-center align-baseline">
                    <span class="default-font account">Already have an account? </span>
                    <v-btn text color="primary" class="remember text-capitalize pa-0" to="/about-bank">
                        <router-link to="/login">Sign In</router-link>
                    </v-btn>
                </div>
            </v-col>
        </v-row>
</template>
<script>
export default {
    data: () => ({
        email: '',
        password: '',
        confirmPassword: '',
        agree: false,
    }),
    methods: {
        signUser() {
            // TODO implement signup
        },
    },
};
</script>
<style scoped>
#loginCard {
    box-shadow: 0px 10px 50px rgba(219, 219, 231, 0.2) !important;
    border-radius: 11px !important;
}
#logTitle {
/* Log in to account */

font-family: Muli;
font-style: normal;
font-weight: bold;
font-size: 27px;
line-height: 136.5%;
/* identical to box height, or 37px */
text-align: center;

/* Text black */
color: #2E5677;

}

.f-label {
font-size: 14px;

/* or 22px */

/* TEXT */
color: #1A244E;
opacity: 0.6;
}

.f-input{
    @apply tw-border tw-border-solid tw-rounded tw-font-normal
}

.f-input{
font-size: 15px;
/* identical to box height, or 24px */

/* Text black */
color: #2E5677;
}

.agree{
font-size: 13px;

/* identical to box height, or 21px */

/* Text black */
color: #1A244E;
opacity: 0.6;
}

.account {
font-size: 14px;
/* or 22px */

color: #404159;
}

.default-font {
font-family: Muli;
font-style: normal;
font-weight: 600;
line-height: 160%;
}

#signBtn {
font-weight: 800;
font-size: 16px;

color: #FFFFFF;
}
</style>
