<template>
    <v-select
        v-model="typeFilter"
        :items="items"
        :menu-props="{
            bottom: true,
            offsetY: true,
            'content-class': 'mt-3 tw-rounded-lg',
        }"
        class="custom-select"
        outlined
        dense
        multiple
        :disabled="disabled"
      >

        <template #selection="{ item }">
            <span class="default-font filter-title tw-text-black mr-2">
                {{ item.text }}
            </span>
        </template>

    </v-select>
</template>

<script>
export default {
    props: {
      disabled: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
        typeFilter: {
            get() {
                return this.$store.state.transaction.typeFilter;
            },
            set(val) {
                this.$store.dispatch('transaction/updateTypeFilter', val);
            },
        },
        items() {
          return [
              {
                  text: 'P2P',
                  value: 'p2p',
                  disabled: this.disabled,
              },
              {
                  text: 'Hybrid',
                  value: 'hybrid',
                  disabled: this.disabled,
              },
          ];
        },
    },
};
</script>
