<template>
    <v-row class="mx-12">
        <v-col>
            <div class="d-flex flex-column">
                <v-data-table
                    item-key="id"
                    :headers="headers"
                    :items="items"
                    :loading="loading"
                    :items-per-page="pagination.pageSize"
                    class="tw-bg-transparent custom-table"
                    hide-default-footer
                    disable-sort>

                    <template v-slot:item="{ item, headers, expand, isExpanded }">
                        <ReferralTransactionRow
                            :item="item"
                            :headers="headers"
                            @row-clicked="expand(!isExpanded)">

                            <template #item.created_at="{ value }">
                                {{ value | datetime }}
                            </template>

                            <template #item.order.confirmed_at="{ value }">
                                {{ value | datetime }}
                            </template>

                            <template #item.order.completed_at="{ value }">
                                {{ value | datetime }}
                            </template>
                        </ReferralTransactionRow>
                    </template>

                    <template #expanded-item="{ headers, item }">
                        <td
                            :colspan="headers.length"
                            transition="slide-y-transition">
                            <ReferralTransactionDetail :item="item" />
                        </td>
                    </template>

                </v-data-table>

                <v-pagination
                    v-model="pagination.page"
                    :length="pagination.pageCount"
                    next-icon="$next"
                    prev-icon="$prev"
                    class="custom-pagination"
                    color="#7598B5">
                </v-pagination>
            </div>
        </v-col>
    </v-row>
</template>
<script>
import ReferralTransactionsApi from '../../api/ReferralTransactionsApi';

import ReferralTransactionRow from './ReferralTransactionRow.vue';
import ReferralTransactionDetail from './ReferralTransactionDetail.vue';

export default {
    components: {
        ReferralTransactionRow,
        ReferralTransactionDetail,
    },
    props: {
        defaultFilters: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        loading: false,
        items: [],
        pagination: {
            page: 1,
            pageSize: 10,
        },
        search: '',
    }),
    computed: {
        headers() {
            return [
                {
                    text: 'Transaction ID',
                    value: 'order_id',
                },
                {
                    text: 'Reference',
                    value: 'reference', // TODO: add value
                },
                {
                    text: 'Poster Amount',
                    value: 'order.posterAmountFormatted',
                },
                {
                    text: 'Poster Fee',
                    value: 'order.posterFeeFormatted',
                },
                {
                    text: 'Referral Fee',
                    value: 'amountFormatted',
                },
                {
                    text: 'Initiated at',
                    value: 'created_at',
                },
                {
                    text: 'Confirmed at',
                    value: 'order.confirmed_at',
                },
                {
                    text: 'Completed at',
                    value: 'order.completed_at',
                },
            ];
        },
    },
    methods: {
        async loadItems() {
            try {
                this.loading = true;

                const {
                    defaultFilters,
                    search,
                    pagination: {
                        page,
                        pageSize,
                    },
                } = this;

                const data = await ReferralTransactionsApi.getAll({
                    ...defaultFilters,
                    type: 'credit',
                    search,
                    page,
                    pageSize,
                });

                const { models, pagination } = data;
                this.items = models;
                this.pagination = pagination;
            } finally {
                this.loading = false;
            }
        },
    },
    watch: {
        search: 'loadItems',
        'pagination.page': {
            immediate: true,
            handler() {
                this.loadItems();
            },
        },
    },
};
</script>
