var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-w-full pb-3",staticStyle:{"background":"#fff"}},[_c('div',{staticClass:"d-flex flex-column"},[_c('h3',{staticClass:"default-font font-21 px-12 py-4  tw-text-black"},[_vm._v(" Hybrid Order Options Changelog ")]),_c('v-data-table',{staticClass:"px-12 py-4 custom-table",attrs:{"item-key":"id","headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"items-per-page":_vm.pagination.pageSize,"hide-default-footer":"","item-class":function () { return 'default-font tw-text-black tw-cursor-pointer'; }},scopedSlots:_vm._u([{key:"item.thirdPartyCompany.company.name",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value || 'All')+" ")]}},{key:"item.matcherAmountFormatted",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value || 'All')+" ")]}},{key:"item.exchangeRateFormatted",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value || 'All')+" ")]}},{key:"item.fee",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value || 'All')+" ")]}},{key:"item.matcher_currency",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value || 'All')+" ")]}},{key:"item.matcher_country",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value || 'All')+" ")]}},{key:"item.pb_currency",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value || 'All')+" ")]}},{key:"item.pb_country",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value || 'All')+" ")]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"24"}},[_c('v-img',{attrs:{"src":item.user.avatar || '/img/man.svg'}})],1),_vm._v(" "+_vm._s(_vm.getUsersName(item))+" ")]}},{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("datetime")(value))+" ")]}}])}),_c('v-pagination',{staticClass:"custom-pagination",attrs:{"length":_vm.pagination.pageCount,"next-icon":"$next","prev-icon":"$prev","color":"#7598B5"},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }