<template>
  <div class="tw-w-full">
    <div class="messages__body tw-absolute tw-pb-16">
      <app-bar>
        <template slot="title">
          <router-link to="/supports">
            <v-icon x-large class="back-style mr-6">$back</v-icon>
          </router-link>
          <div class="d-flex flex-column default-font mr-10 pt-4">
            <v-toolbar-title class="font-21 tw-text-black">Question about live testing</v-toolbar-title>
            <span class="font-13 tw-font-normal tw-text-secondary">{{$route.params.id}}</span>
          </div>
        </template>
        <div class="d-flex justify-space-between mr-10">
          <v-chip
            class="ma-2 text-uppercase status tw-cursor-pointer"
            :color="statusColor(currentCase.status).bg"
            :text-color="statusColor(currentCase.status).color"
          >{{ currentCase.status }}</v-chip>
          <search v-model="search" placeholder="Search messages"></search>
        </div>
      </app-bar>
      <div class="px-12 mt-8" id="messages__list">
        <template v-for="(groupMessages, date) in filteredMessages">
          <message
            v-for="(message, index) in groupMessages"
            :message="message"
            :date="index === 0 ? date : null"
            :key="index + date"
          ></message>
        </template>
      </div>
    </div>
    <div class="footer__block">
      <message-footer />
    </div>
  </div>
</template>
<script>
import AppBar from '@/components/globals/AppBar.vue';
import Message from '@/components/supports/Message.vue';
import MessageFooter from '@/components/supports/MessageFooter.vue';
import Search from '@/components/globals/Search.vue';
import messages from '@/utils/channel';
import cases, { statusColor } from '@/utils/messages';

import moment from 'moment';

import { groupBy } from '@/utils';

export default {
    components: {
        AppBar,
        Message,
        MessageFooter,
        Search,
    },
    data() {
        return {
            messages,
        };
    },
    computed: {
        filteredMessages() {
            const msg = this.messages;

            return groupBy(msg, (a) => moment(a.createdAt, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY'));
        },
        currentCase() {
            return cases.find((el) => el.id === this.$route.params.id);
        },
    },
    methods: {
        statusColor,
    },
};
</script>
<style scoped>
.footer__block {
  padding-right: 140px !important;
  padding: 16px;
  padding-bottom: 50px;
  border-bottom: none !important;
}
.footer__block {
  @apply tw-border tw-w-full tw-border-binput tw-fixed tw-bottom-0 tw-z-50 tw-bg-white tw-max-h-1/2 tw-overflow-auto;
}
.messages__body {
  height: calc(100% - 180px);
  overflow-y: scroll;
}
</style>
