/* eslint-disable camelcase */
import Model from './Model';

class PendingMatch extends Model {
    static fields() {
        return {
            accepted_at: String,
            cancelled_at: String,
            completed_at: String,
            confirmed_at: String,
            exchange_rate: Number,
            expires_at: String,
            id: Number,
            is_accepted: Boolean,
            is_cancelled: Boolean,
            is_completed: Boolean,
            is_confirmed: Boolean,
            is_double_confirmed: Boolean,
            is_matcher_payment_completed: Boolean,
            matcherRecipient: Object,
            matcher_amount: Number,
            matcher_currency: String,
            matcher_fee: Number,
            matcher_payment_completed_at: String,
            matcher_payment_method_fee: String,
            matcher_payment_method_type: String,
            matcher_payment_provider: String,
            memo: String,
            order: Object,
            order_id: String,
            pb_amount: Number,
            pb_currency: String,
            pb_to_matcher_exchange_rate: Number,
            poster_amount: Number,
            poster_currency: String,
            poster_payment_method_type: String,
            requested_at: String,
            type: String,
            updated_at: String,
            user_id: String,
        };
    }

    /**
     * Get Poster Amount with currency
     */
    get posterAmountFormatted() {
        return `${this.poster_amount} ${this.poster_currency}`;
    }

    /**
     * Get Matcher Amount with currency
     */
    get matcherAmountFormatted() {
        return `${this.matcher_amount} ${this.matcher_currency}`;
    }

    /**
     * Get Matcher Fee with currency
     */
    get matcherFeeFormatted() {
        return `${this.matcher_fee} ${this.matcher_currency}`;
    }
}

export default PendingMatch;
