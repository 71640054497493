export default {
    namespaced: true,

    state: {
        filters: {
            status: 'new',
            country: null,
            can_post_offers: null,
            can_accept_offers: null,
            joined_at: [],
            last_logged_at: [],
            identity_verified: null,
            bank_added: null,
            account_officer_tag: null,
            transactions_completed_at: [],
            sort_by: 'users.joined_at',
            sort_dir: 'desc',
        },
        pagination: {
            page: 1,
            pageSize: 10,
        },
    },

    getters: {
        filters: ({ filters }) => filters,
        pagination: ({ pagination }) => pagination,
    },

    mutations: {
        SET_FILTERS(state, payload) {
            state.filters = payload;
        },
        SET_PAGINATION(state, payload) {
            state.pagination = payload;
        },
    },

    actions: {
        updateFilters({ commit, getters }, payload) {
            const filters = {
                ...getters.filters,
                ...payload,
            };
            commit('SET_FILTERS', filters);
        },
        updatePagination({ commit }, payload) {
            commit('SET_PAGINATION', payload);
        },
    },
};
