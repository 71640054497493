<template>
    <div class="mb-8">
        <v-row>
            <v-col
                cols="12"
                md="3"
                xl="2">
                <v-select
                    v-model="status"
                    :items="statuses"
                    :menu-props="{
                        bottom: true,
                        offsetY: true,
                        'content-class': 'mt-3 tw-rounded-lg',
                    }"
                    label="Status"
                    hide-details
                    clearable
                    outlined
                    dense>
                    <template #selection="{ item }">
                        <span class="default-font filter-title tw-text-black mr-2">
                            {{ item.text }}
                        </span>
                    </template>
                </v-select>
            </v-col>

            <v-col
                cols="12"
                md="3"
                xl="2">
                <v-select
                    v-model="type"
                    :items="types"
                    :menu-props="{
                        bottom: true,
                        offsetY: true,
                        'content-class': 'mt-3 tw-rounded-lg',
                    }"
                    label="Type"
                    hide-details
                    clearable
                    outlined
                    dense>
                    <template #selection="{ item }">
                        <span class="default-font filter-title tw-text-black mr-2">
                            {{ item.text }}
                        </span>
                    </template>
                </v-select>
            </v-col>

            <v-col
                cols="12"
                md="3"
                xl="2">
                <v-select
                    v-model="identity_verified"
                    :items="identityStates"
                    label="Identity Verification"
                    :menu-props="{
                        bottom: true,
                        offsetY: true,
                        'content-class': 'mt-3 tw-rounded-lg',
                    }"
                    hide-details
                    clearable
                    outlined
                    dense>
                    <template #selection="{ item }">
                        <span class="default-font filter-title tw-text-black mr-2">
                            {{ item.text }}
                        </span>
                    </template>
                </v-select>
            </v-col>

            <v-col
                cols="12"
                md="3"
                xl="2">
                <v-select
                    v-model="bank_added"
                    :items="bankStates"
                    label="Bank"
                    :menu-props="{
                        bottom: true,
                        offsetY: true,
                        'content-class': 'mt-3 tw-rounded-lg',
                    }"
                    hide-details
                    clearable
                    outlined
                    dense>
                    <template #selection="{ item }">
                        <span class="default-font filter-title tw-text-black mr-2">
                            {{ item.text }}
                        </span>
                    </template>
                </v-select>
            </v-col>

            <v-col
                cols="12"
                md="3"
                xl="2">
                <DateInput
                    v-model="joined_at"
                    label="Joined at"
                    hide-details
                    scrollable
                    range
                    dense/>
            </v-col>

            <v-col
                cols="12"
                md="3"
                xl="2">
                <DateInput
                    v-model="last_logged_at"
                    label="Last Signed In"
                    hide-details
                    scrollable
                    range
                    dense/>
            </v-col>

            <v-col
                cols="12"
                md="3"
                xl="2">
                <v-select
                    label="Country"
                    item-value="abbreviation"
                    item-text="country"
                    v-model="country_abbreviation"
                    :items="countries"
                    :menu-props="{
                        bottom: true,
                        offsetY: true,
                        'content-class': 'mt-3 tw-rounded-lg',
                    }"
                    hide-details
                    clearable
                    outlined
                    dense>
                    <template #selection="{ item }">
                        <span class="default-font filter-title tw-text-black mr-2">
                            {{ item.country }}
                        </span>
                    </template>
                </v-select>
            </v-col>

            <v-col
                cols="12"
                md="3"
                xl="2">
                <v-select
                    label="Customer Support Officer Tag"
                    v-model="account_officer_tag"
                    :items="accountOfficerTags"
                    :menu-props="{
                        bottom: true,
                        offsetY: true,
                        'content-class': 'mt-3 tw-rounded-lg',
                    }"
                    hide-details
                    clearable
                    outlined
                    dense>
                </v-select>
            </v-col>

            <v-col
                cols="12"
                md="3"
                xl="2">
                <PeriodFilter
                    v-model="transactions_completed_at"
                    label="Last Completed Transaction"
                    clearable
                    outlined
                    dense>
                </PeriodFilter>
            </v-col>
        </v-row>
    </div>
</template>

<script>
/* eslint-disable camelcase */
import CountriesApi from '../../api/CountriesApi';

import DateInput from '../globals/DateInput.vue';
import PeriodFilter from '../globals/PeriodFilter.vue';

export default {
    components: {
        DateInput,
        PeriodFilter,
    },
    data: () => ({
        statuses: [
            {
                text: 'New',
                value: ['new'],
            },
            {
                text: 'Transact - Complete',
                value: ['transacted-completed'],
            },
            {
                text: 'Transact',
                value: [
                    'transacted-matched',
                    'transacted-posted',
                ],
            },
            {
                text: 'Banned',
                value: ['banned'],
            },
        ],
        types: [
            {
                text: 'Poster',
                value: {
                    can_post_offers: true,
                    can_accept_offers: false,
                },
            },
            {
                text: 'Matcher',
                value: {
                    can_post_offers: false,
                    can_accept_offers: true,
                },
            },
        ],
        countries: [],
        identityStates: [
            {
                text: 'Verified',
                value: true,
            },
            {
                text: 'Not Verified',
                value: false,
            },
        ],
        bankStates: [
            {
                text: 'Added',
                value: true,
            },
            {
                text: 'Not Added',
                value: false,
            },
        ],
        accountOfficerTags: [
            {
                text: 'All',
                value: null,
            },
            {
                text: '001',
                value: 'KA - 001',
            },
            {
                text: '002',
                value: 'KA - 002',
            },
        ],
    }),
    computed: {
        status: {
            get() {
                return this.$store.state.users.filters.status;
            },
            set(status) {
                this.$store.dispatch('users/updateFilters', { status });
            },
        },
        type: {
            get() {
                const {
                    can_post_offers,
                    can_accept_offers,
                } = this.$store.state.users.filters;
                return {
                    can_post_offers,
                    can_accept_offers,
                };
            },
            set(type) {
                if (type) {
                    this.$store.dispatch('users/updateFilters', { ...type });
                } else {
                    this.$store.dispatch('users/updateFilters', {
                        can_post_offers: null,
                        can_accept_offers: null,
                    });
                }
            },
        },
        joined_at: {
            get() {
                return this.$store.state.users.filters.joined_at;
            },
            set(joined_at) {
                this.$store.dispatch('users/updateFilters', { joined_at });
            },
        },
        last_logged_at: {
            get() {
                return this.$store.state.users.filters.last_logged_at;
            },
            set(last_logged_at) {
                this.$store.dispatch('users/updateFilters', { last_logged_at });
            },
        },
        country_abbreviation: {
            get() {
                return this.$store.state.users.filters.country_abbreviation;
            },
            set(country_abbreviation) {
                this.$store.dispatch('users/updateFilters', { country_abbreviation });
            },
        },
        identity_verified: {
            get() {
                return this.$store.state.users.filters.identity_verified;
            },
            set(identity_verified) {
                this.$store.dispatch('users/updateFilters', { identity_verified });
            },
        },
        bank_added: {
            get() {
                return this.$store.state.users.filters.bank_added;
            },
            set(bank_added) {
                this.$store.dispatch('users/updateFilters', { bank_added });
            },
        },
        account_officer_tag: {
            get() {
                return this.$store.state.users.filters.account_officer_tag;
            },
            set(account_officer_tag) {
                this.$store.dispatch('users/updateFilters', { account_officer_tag });
            },
        },
        transactions_completed_at: {
            get() {
                return this.$store.state.users.filters.transactions_completed_at;
            },
            set(transactions_completed_at) {
                this.$store.dispatch('users/updateFilters', { transactions_completed_at });
            },
        },
    },
    methods: {
        async loadCountries() {
            try {
                const { data: { data } } = await CountriesApi.getAll();
                this.$set(this, 'countries', data);
            } catch (error) {
                this.$swal({
                    type: 'error',
                    title: 'Error loading countries',
                    text: error,
                });
            }
        },
    },
    mounted() {
        this.loadCountries();
    },
};
</script>
