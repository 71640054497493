<template functional>
    <div class="px-2 py-5">
        <div class="d-flex justify-space-between align-start mb-5">
            <label>Name:</label>
            <span class="tw-text-right">{{props.person.name || props.person.full_name}}</span>
        </div>
        <div class="d-flex justify-space-between align-start mb-5" v-if="props.person.phone">
            <label>Phone:</label>
            <span class="tw-text-right">{{props.person.phone}}</span>
        </div>
        <div class="d-flex justify-space-between align-start mb-5" v-if="props.person.email">
            <label>Email:</label>
            <span class="tw-text-right">{{props.person.email}}</span>
        </div>
        <div class="d-flex justify-space-between align-start mb-5" v-if="props.person.address">
            <label>Addresss:</label>
            <span class="tw-text-right tw-max-w-2/3">
              {{props.person.address}}
            </span>
        </div>
        <div class="d-flex justify-space-between align-start mb-5" v-if="props.person.city || props.person.state">
            <label>City/State:</label>
            <span class="tw-text-right tw-max-w-2/3">
              {{props.person.city}}, {{props.person.state}}
            </span>
        </div>
        <div class="d-flex justify-space-between align-start mb-5" v-if="props.person.country || props.person.zipcode">
            <label>Country/Zip:</label>
            <span class="tw-text-right tw-max-w-2/3">
              {{props.person.country}}, {{props.person.zipcode}}
            </span>
        </div>
        <div class="d-flex justify-space-between align-start mb-5" v-if="props.person.birth_date">
            <label>Date of birth:</label>
            <span class="tw-text-right">{{props.person.birth_date | fullYearDate}}</span>
        </div>
        <div class="d-flex justify-space-between align-start mb-5" v-if="props.person.account_number">
            <label>Account number:</label>
            <span class="tw-text-right">{{props.person.account_number}}</span>
        </div>

        <UserBankDetails
            :user="props.person"
            type="payment"
            :copy-buttons="false">
        </UserBankDetails>

        <div>
            <p>
                <strong>Delivery Methods:</strong>
            </p>

            <div
                v-for="deliveryMethod in props.person.delivery_methods"
                :key="deliveryMethod.id"
                style="margin-bottom: 1rem"
            >
                <p style="margin-bottom: 0">{{ deliveryMethod.provider }} Account</p>
                <p style="margin-bottom: 0">Bank: {{ deliveryMethod.institution_name }}</p>
                <p style="margin-bottom: 0">Account Number: {{ deliveryMethod.accountNumber }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        person: {
            type: Object,
            required: true,
        },
    },
};
</script>
<style scoped>
label {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 180%;

    /* or 23px */

    /* Menu gray */
    color: #98BAD7;
}

span {
    font-family: Muli;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 136.5%;

    /* or 19px */
    text-align: right;

    /* BG gray */
    color: #F7FBFC;
}
</style>
