<template>
    <div>
        <v-menu transition="slide-y-transition" bottom offset-y content-class="mt-3 tw-rounded-lg">
            <template v-slot:activator="{ on }">
                <v-btn
                    color="secondary"
                    dark
                    outlined
                    elevation="0"
                    large
                    class="default-font font-12 text-capitalize justify-space-between"
                    v-on="on"
                >
                    Actions
                    <v-icon x-small class="ml-2">mdi-chevron-down</v-icon>
                </v-btn>
            </template>
            <v-list>

                <v-list-item v-if="isAdmin">
                    <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                        <v-btn
                            text
                            class="text-capitalize default-font"
                            @click="() => cancelMatch(item.match)"
                            :disabled="actionInProgress || !canCancelMatch(item)"
                        >
                            Cancel Match
                        </v-btn>
                    </v-list-item-title>
                </v-list-item>

                <v-list-item v-if="isAdmin">
                    <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                        <v-btn
                            text
                            class="text-capitalize default-font"
                            @click="() => approveMatch(item.match)"
                            :disabled="actionInProgress || !canApproveMatch(item)"
                        >
                            Approve Match
                        </v-btn>
                    </v-list-item-title>
                </v-list-item>

                <v-list-item>
                    <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                        <v-btn
                            text
                            class="text-capitalize default-font"
                            @click="() => markAsMatcherPaid(item.id)"
                            :disabled="actionInProgress || !canCancelMatch(item)"
                        >
                            Mark as Paid
                        </v-btn>
                    </v-list-item-title>
                </v-list-item>

                <v-list-item v-if="isAdmin">
                    <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                        <v-btn
                            text
                            class="text-capitalize default-font"
                            @click="() => completeOrder(item.id)"
                            :disabled="actionInProgress || !canCompleteOrder(item)"
                        >
                            Complete Order
                        </v-btn>
                    </v-list-item-title>
                </v-list-item>

                <v-list-item v-if="isAdmin || isPrincipal">
                    <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                        <v-btn
                            text
                            class="text-capitalize default-font"
                            @click="() => expireOrder(item.id)"
                            :disabled="actionInProgress || !canExpireOrder(item)"
                        >
                            Expire Order
                        </v-btn>
                    </v-list-item-title>
                </v-list-item>

                <v-list-item v-if="isAdmin || isPrincipal">
                    <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                        <v-btn
                            text
                            class="text-capitalize default-font"
                            @click="() => setHidden(item.id, !item.hidden)"
                            :disabled="actionInProgress || !canExpireOrder(item)"
                        >
                            {{ item.hidden ? 'Unhide' : 'Hide' }} Order
                        </v-btn>
                    </v-list-item-title>
                </v-list-item>

                <v-list-item>
                    <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                        <MatchHistoryModal :orderId="item.id" />
                    </v-list-item-title>
                </v-list-item>

                <v-list-item v-if="isAdmin">
                    <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                        <MatchValidationJourneyModal
                            v-if="item.match_id"
                            :id="item.match_id"
                        />
                    </v-list-item-title>
                </v-list-item>

                <v-list-item v-if="isAdmin">
                    <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                        <v-btn
                            text
                            class="text-capitalize default-font"
                            @click="() => captureOrder(item.id)"
                            :disabled="actionInProgress || item.status !== 'unable_to_capture'"
                        >
                            Capture
                        </v-btn>
                    </v-list-item-title>
                </v-list-item>

                <v-list-item v-if="isAdmin">
                    <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                        <v-btn
                            text
                            class="text-capitalize default-font"
                            @click="() => voidOrder(item.id)"
                            :disabled="actionInProgress || !canVoid(item)"
                        >
                            Void
                        </v-btn>
                    </v-list-item-title>
                </v-list-item>

                <v-list-item v-if="isAdmin">
                    <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                        <v-btn
                            text
                            class="text-capitalize default-font"
                            @click="() => publishOrder(item.id)"
                            :disabled="actionInProgress || !canPublish(item)"
                        >
                            Publish
                        </v-btn>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <PublishOrderConfirmationDialog ref="publishOrderConfirmationDialog" />
    </div>
</template>
<script>

import MatchValidationJourneyModal from '@/components/transactions/MatchValidationJourneyModal.vue';
import MatchHistoryModal from '@/components/transactions/MatchHistoryModal.vue';
import PublishOrderConfirmationDialog from '@/components/transactions/PublishOrderConfirmationDialog.vue';

import MatchesApi from '../../api/MatchesApi';
import OrdersApi from '../../api/OrdersApi';

const events = {
    cancelMatch: 'cancelMatch',
    approveMatch: 'approveMatch',
    markAsMatcherPaid: 'markAsMatcherPaid',
    completeOrder: 'completeOrder',
    expireOrder: 'expireOrder',
    setHidden: 'setHidden',
    captureOrder: 'captureOrder',
    voidOrder: 'voidOrder',
    publishOrder: 'publishOrder',
};

export default {
    components: {
        MatchValidationJourneyModal,
        MatchHistoryModal,
        PublishOrderConfirmationDialog,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        isAdmin: {
            type: Boolean,
            required: true,
        },
        isPrincipal: {
            type: Boolean,
            required: true,
        },
    },
    data: () => ({
        actionInProgress: false,
    }),
    methods: {
        beginActionInProgress(eventPayload) {
            this.actionInProgress = true;
            this.$emit('actionInProgress', eventPayload);
        },
        completeAction(eventPayload) {
            this.actionInProgress = false;
            this.$emit('actionCompleted', eventPayload);
        },
        confirm(text = 'Are you sure you want to take this action?') {
            return this.$swal({
                type: 'info',
                title: text,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => result.value);
        },
        canCancelMatch(item) {
            const { match } = item;
            if (!match) {
                return false;
            }

            return !match.canceled_at && !match.matcher_payment_completed_at && !match.completed_at;
        },
        async cancelMatch(match) {
            if (!match) {
                return;
            }
            const value = await this.confirm('Are you sure you want to take this action - Cancel Match?');
            if (!value) return;

            const eventPayload = {
                type: events.cancelMatch,
                params: {
                    match,
                },
            };
            this.beginActionInProgress(eventPayload);
            let success = true;
            try {
                await MatchesApi.cancel(match.id);
            } catch (err) {
                success = false;
                this.$swal({
                    type: 'error',
                    title: 'Unable to Cancel Match',
                    text: err.message,
                });
            } finally {
                this.completeAction({ ...eventPayload, success });
            }
        },
        async approveMatch(match) {
            if (!match) {
                return;
            }
            const value = await this.confirm('Are you sure you want to take this action - Approve Match?');
            if (!value) return;

            const eventPayload = {
                type: events.approveMatch,
                params: {
                    match,
                },
            };
            this.beginActionInProgress(eventPayload);
            let success = true;
            try {
                await MatchesApi.approve(match.id);
            } catch (err) {
                this.$swal({
                    type: 'error',
                    title: 'Unable to Approve Match',
                    text: err.message,
                });
                success = false;
            } finally {
                this.completeAction({ ...eventPayload, success });
            }
        },
        async markAsMatcherPaid(id) {
            if (!id) return;

            const value = await this.confirm('Are you sure you want to take this action - Mark as Paid?');
            if (!value) return;

            const eventPayload = {
                type: events.markAsMatcherPaid,
                params: {
                    id,
                },
            };

            this.beginActionInProgress(eventPayload);
            let success = true;
            try {
                await OrdersApi.markAsMatcherPaid(id);
            } catch (err) {
                success = false;
                this.$swal({
                    type: 'error',
                    title: 'Unable to mark match as paid',
                    text: err.message,
                });
            } finally {
                this.completeAction({ ...eventPayload, success });
            }
        },
        canApproveMatch(item) {
            return this.canCancelMatch(item);
        },
        canCompleteOrder(item) {
            if (!item.published_at) return false;
            if (!item.matched_at) return false;
            if (!item.matcher_payment_completed_at) return false;

            if (item.completed_at) return false;
            if (item.cancelled_at) return false;
            if (item.is_removed) return false;

            return true;
        },
        async completeOrder(id) {
            if (!id) return;

            const value = await this.confirm('Are you sure you want to take this action - Complete Order?');

            if (!value) return;

            const eventPayload = {
                type: events.completeOrder,
                params: {
                    id,
                },
            };

            this.beginActionInProgress(eventPayload);
            let success = true;
            try {
                await OrdersApi.complete(id);
            } catch (err) {
                success = false;
                this.$swal({
                    type: 'error',
                    title: 'Unable to complete order',
                    text: err.message,
                });
            } finally {
                this.completeAction({ ...eventPayload, success });
            }
        },
        canExpireOrder(item) {
            if (item.matched_at) return false;
            if (item.matcher_payment_completed_at) return false;
            if (item.completed_at) return false;
            if (item.cancelled_at) return false;
            if (item.is_removed) return false;

            return true;
        },
        async expireOrder(id) {
            if (!id) return;

            const value = await this.confirm('Are you sure you want to take this action - Expire Order?');

            if (!value) return;

            const eventPayload = {
                type: events.expireOrder,
                params: {
                    id,
                },
            };

            this.beginActionInProgress(eventPayload);
            let success = true;
            try {
                await OrdersApi.expire(id);
            } catch (err) {
                success = false;
                this.$swal({
                    type: 'error',
                    title: 'Unable to expire order',
                    text: err.message,
                });
            } finally {
                this.completeAction({ ...eventPayload, success });
            }
        },
        async setHidden(id, hidden) {
            if (!id) return;
            const text = hidden ? 'Hide' : 'Unhide';
            const value = await this.confirm(`Are you sure you want to take this action - ${text} Order?`);

            if (!value) return;

            const eventPayload = {
                type: events.setHidden,
                params: {
                    id,
                    hidden,
                },
            };

            this.beginActionInProgress(eventPayload);
            let success = true;
            try {
                await OrdersApi.setHidden(id, eventPayload.params);
            } catch (err) {
                success = false;
                this.$swal({
                    type: 'error',
                    title: 'Unable to set order as hidden',
                    text: err.message,
                });
            } finally {
                this.completeAction({ ...eventPayload, success });
            }
        },
        async captureOrder(id) {
            if (!id) return;
            const value = await this.confirm('Are you sure you want to take this action - Capture Order?');

            if (!value) return;

            const eventPayload = {
                type: events.captureOrder,
                params: {
                    id,
                },
            };

            this.beginActionInProgress(eventPayload);
            let success = true;
            try {
                await OrdersApi.captureOrder(id);
            } catch (err) {
                success = false;
                this.$swal({
                    type: 'error',
                    title: 'Unable to capture order',
                    text: err.message,
                });
            } finally {
                this.completeAction({ ...eventPayload, success });
            }
        },
        async voidOrder(id) {
            if (!id) return;
            const value = await this.confirm('Are you sure you want to take this action - Void Order?');
            if (!value) return;

            const eventPayload = {
                type: events.voidOrder,
                params: {
                    id,
                },
            };

            this.beginActionInProgress(eventPayload);
            let success = true;
            try {
                await OrdersApi.void(id);
            } catch (err) {
                success = false;
                this.$swal({
                    type: 'error',
                    title: 'Unable to void order',
                    text: err.message,
                });
            } finally {
                this.completeAction({ ...eventPayload, success });
            }
        },
        canVoid(item) {
            if (item.status === 'unable_to_void') {
                return true;
            }

            if (item.removed_at) {
                return true;
            }

            return false;
        },
        canPublish(item) {
            return !item.published_at;
        },
        async publishOrder(id) {
            if (!id) return;
            const paymentType = await this.$refs.publishOrderConfirmationDialog.show();
            if (!paymentType) {
                this.$swal({
                    icon: 'error',
                    title: 'Payment type is required',
                });
                return;
            }

            const eventPayload = {
                type: events.publishOrder,
                params: {
                    id,
                    paymentType,
                },
            };

            this.beginActionInProgress(eventPayload);
            let success = true;
            try {
                await OrdersApi.publish(id, { payment_type: paymentType });
            } catch (err) {
                success = false;
                this.$swal({
                    type: 'error',
                    title: 'Unable to publish order',
                    text: err.message,
                });
            } finally {
                this.completeAction({ ...eventPayload, success });
            }
        },
    },
};
</script>
