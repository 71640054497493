<template>
    <div
        class="chart-container"
        style="position: relative; height: 30vh; width: auto;">
        <canvas ref="chart"></canvas>
    </div>
</template>

<script>
import { isEqual } from 'lodash';
import Chart from 'chart.js/auto';

export default {
    props: {
        type: {
            type: String,
            required: false,
        },
        data: {
            type: Object,
            required: true,
        },
        options: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        chart: null,
    }),
    computed: {
        config() {
            const {
                type,
                options,
                data,
            } = this;

            return {
                type,
                options,
                data,
            };
        },
    },
    methods: {
        init() {
            this.chart = new Chart(this.$refs.chart.getContext('2d'), this.config);
        },
        updateData(newData, oldData) {
            if (!isEqual(newData, oldData)) {
                const {
                    labels,
                    datasets,
                } = newData;

                this.chart.data.labels = labels;
                this.chart.data.datasets = datasets;

                this.chart.update();
            }
        },
        updateOptions(newOptions, oldOptions) {
            if (!isEqual(newOptions, oldOptions)) {
                this.chart.options = newOptions;
                this.chart.update();
            }
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        data: {
            deep: true,
            handler: 'updateData',
        },
        options: {
            deep: true,
            handler: 'updateOptions',
        },
    },
};
</script>
