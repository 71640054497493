<template>
  <div class="tw-w-full d-flex justify-center">
    <form class="tw-w-2/3">
      <v-row>
        <v-col cols="12" class="d-flex flex-column">
          <label class="mb-1">Country of business:</label>
          <input
            type="text"
            value="USA"
            class="f-input default-font pa-2 input-gb-field-color tw-border-binput focus:tw-border-primary"
          />
        </v-col>
        <v-col cols="12" class="d-flex flex-column pa-2">
          <label class="mb-1">Company name:</label>
          <input
            type="text"
            value="Sterling LLC"
            class="f-input default-font pa-2 input-gb-field-color tw-border-binput focus:tw-border-primary"
          />
        </v-col>
        <v-col cols="12" class="d-flex flex-column pa-2">
          <label class="mb-1">Legal entity:</label>
          <input
            type="text"
            value="C Corp"
            class="f-input default-font pa-2 input-gb-field-color tw-border-binput focus:tw-border-primary"
          />
        </v-col>
        <v-col cols="12" class="d-flex flex-column pa-2">
          <label class="mb-1">Contact Phone:</label>
          <input
            type="text"
            value="+1 800-310-1420"
            class="f-input default-font pa-2 input-gb-field-color tw-border-binput focus:tw-border-primary"
          />
        </v-col>
        <v-col cols="12" class="d-flex flex-column pa-2">
          <label class="mb-1">Location:</label>
          <v-row class="tw-w-full mr-0 ml-0">
            <v-col cols="12" class="pa-0">
              <input
                type="text"
                value="1451 East 55th Street"
                class="f-input default-font pa-2 input-gb-field-color tw-border-binput focus:tw-border-primary tw-w-full mb-2"
              />
            </v-col>
            <v-col cols="12" md="6" class="pa-1 pl-0">
              <input
                type="text"
                value="Chicago"
                class="f-input default-font pa-2 input-gb-field-color tw-border-binput focus:tw-border-primary tw-w-full mr-2"
              />
            </v-col>
            <v-col cols="12" md="3" class="pa-1">
              <v-select
                :items="items"
                clearable
                outlined
                dense
                class="custom-select"
                clear-icon="mdi-close-circle-outline"
                :menu-props="{ bottom: true, offsetY: true, 'content-class': 'mt-3 tw-rounded-lg' }"
              >
                <template v-slot:selection="{ item }">
                  <span class="default-font filter-title tw-text-black mr-2">{{ item }}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="3" class="pa-1 pr-0">
              <input
                type="number"
                value="60615"
                class="f-input default-font pa-2 input-gb-field-color tw-border-binput focus:tw-border-primary tw-w-full"
              />
            </v-col>
          </v-row>
          <v-col cols="12" class="d-flex flex-column pa-2">
          <label class="mb-1">Company logo:</label>
          <div class="d-flex justify-start align-center">
            <label>
            <v-avatar size="64" class="tw-border tw-border-binput tw-box-border pa-1 mr-10  hover:tw-bg-hoverBg">
              <v-img :src="logoSrc"></v-img>
            </v-avatar>
            </label>
            <v-btn
            color="primary"
            id="signBtn"
            outlined=""
            class="default-font text-capitalize text-center px-5 mr-5"
             @click="$refs.compLogo.click();"
          >Choose file</v-btn>
          <span class="default-font tw-text-secondary font-13 tw-w-1/3 text-start">
          PNG or JPGD; Up to 2Mb; Dimensions 1:1
          </span>
          <input type="file" accept="['png, 'jpg']"  ref="compLogo" class="tw-hidden" @change="loadFile" />
          </div>
          </v-col>
        </v-col>
        <v-col cols="12" class="tw-mt-4">
          <v-btn
            color="primary"
            id="signBtn"
            class="default-font text-capitalize text-center px-5"
            to="/about-bank"
          >Save change</v-btn>
        </v-col>
      </v-row>
    </form>
  </div>
</template>
<script>
export default {
    data() {
        return {
            items: ['IL', 'Mississipi'],
            companyLogo: null,
            logoSrc: import('../../assets/profile.png'),
        };
    },
    methods: {
        loadFile(e) {
            const {
                target: {
                    files: [file],
                },
            } = e;
            this.companyLogo = file;
            // eslint-disable-next-line no-undef
            const reader = new FileReader();
            reader.onload = () => {
                const dataURL = reader.result;
                this.logoSrc = dataURL;
            };
            reader.readAsDataURL(file);
        },
    },
};
</script>
<style scoped>
.f-input {
  font-weight: 600 !important;
  /* identical to box height, or 24px */

  /* TEXT */
  color: #1a244e !important;
}
</style>
