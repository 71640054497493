<template>
    <div>
        <v-row>
            <v-spacer></v-spacer>
            <v-col cols="auto">
                <ExportModal
                    @exportToPdf="exportOrders('pdf')"
                    @exportToXls="exportOrders('xlsx')">
                </ExportModal>
            </v-col>
        </v-row>
        <user-transactions-table
            :items="orders"
            :headers="headers"
            :pagination.sync="pagination"
            :loading="loading"
            single-expand
            :item-class="() => `tw-bg-hoverBg tr-row px-6 py-4 mt-2
            tw-w-full default-font tw-text-black tw-cursor-pointer`">

            <template #item.posted_at="{ item }">
                {{ item.posted_at | datetime }}
            </template>

            <template #item.actions="{ item }">
                <v-menu
                    content-class="mt-3 tw-rounded-lg"
                    transition="slide-y-transition"
                    offset-y
                    bottom>
                    <template #activator="{ on }">
                        <v-btn
                            color="secondary"
                            dark
                            outlined
                            elevation="0"
                            large
                            class="default-font font-12 text-capitalize justify-space-between"
                            v-on="on">
                            Actions
                            <v-icon x-small class="ml-2">mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item>
                            <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                                <MatchHistoryModal
                                    :orderId="item.id">
                                </MatchHistoryModal>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>

            <template #expanded-item="{ item }">
                <td :colspan="headers.length"
                    transition="slide-y-transition">
                    <TransactionDetail
                        :id="item.id">
                    </TransactionDetail>
                </td>
            </template>
        </user-transactions-table>
    </div>
</template>

<script>
/* eslint-disable camelcase */
import Socket from '../../services/Socket';
import UsersApi from '../../api/UsersApi';
import Order from '../../models/Order';

import UserTransactionsTable from './UserTransactionsTable.vue';
import TransactionDetail from '../transactions/TransactionDetail.vue';
import ExportModal from '../transactions/ExportModal.vue';
import MatchHistoryModal from '../transactions/MatchHistoryModal.vue';

const headers = [
    {
        text: 'Order ID',
        value: 'id',
        align: 'center',
        class: 'tw-text-black default-font py-2 text-end',
    },
    {
        text: 'Poster Amount',
        value: 'posterAmountFormatted',
        align: 'center',
        class: 'tw-text-black default-font py-2 text-end',
    },
    {
        text: 'Matcher Amount',
        value: 'matcherAmountFormatted',
        align: 'center',
        class: 'tw-text-black default-font py-2 text-end',
    },
    {
        text: 'Poster Fee',
        value: 'posterFeeFormatted',
        align: 'center',
        class: 'tw-text-black default-font py-2 text-end',
    },
    {
        text: 'Matcher Fee',
        value: 'matcherFeeFormatted',
        align: 'center',
        class: 'tw-text-black default-font py-2 text-end',
    },
    {
        text: 'Posted At',
        value: 'posted_at',
        align: 'center',
        class: 'tw-text-black default-font py-2 text-end',
    },
    {
        text: 'Actions',
        value: 'actions',
        align: 'center',
        class: 'tw-text-black default-font py-2 text-end',
    },
];

export default {
    components: {
        UserTransactionsTable,
        TransactionDetail,
        ExportModal,
        MatchHistoryModal,
    },
    props: {
        userId: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            requred: false,
        },
        state: {
            type: String,
            requred: false,
        },
        period: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },
    data: () => ({
        headers,
        orders: [],
        pagination: {
            page: 1,
            pageSize: 10,
        },
        loading: false,
    }),
    methods: {
        async loadOrders() {
            try {
                this.loading = true;

                const {
                    type,
                    state,
                    period: {
                        startDate: start_date,
                        endDate: end_date,
                    },
                } = this;

                const params = {
                    type,
                    state,
                    start_date,
                    end_date,
                    ...this.pagination,
                };

                const { data: { data, pagination } } = await UsersApi.getOrders(this.userId, params);
                this.orders = data.map((item) => new Order(item));
                this.pagination = pagination;
            } catch (error) {
                this.$swal({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                });
            } finally {
                this.loading = false;
            }
        },

        async exportOrders(format) {
            try {
                this.loading = true;
                const params = { format };

                const { data: { url } } = await UsersApi.exportOrders(this.userId, params);
                window.open(url);
            } catch (error) {
                this.$swal({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                });
            } finally {
                this.loading = false;
            }
        },

        addSocketEventListeners() {
            Socket.on(`user-${this.userId}-orderSaved`, this.loadOrders);
        },

        removeSocketEventListeners() {
            Socket.remove(`user-${this.userId}-orderSaved`);
        },
    },
    mounted() {
        this.addSocketEventListeners();
    },
    beforeDestroy() {
        this.removeSocketEventListeners();
    },
    watch: {
        'pagination.page': {
            immediate: true,
            handler() {
                this.loadOrders();
            },
        },
        period: {
            deep: true,
            handler() {
                this.loadOrders();
            },
        },
        type: 'loadOrders',
        state: 'loadOrders',
    },
};
</script>
