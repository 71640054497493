<template>
  <label class="upload-input tw-bg-hoverBg">
    <div class="tw-flex tw-flex-col ml-2">
      <span class="default-font tw-text-black-500 tw-text-sm">{{fileName}}</span>
      <div class="default-font tw-text-primary tw-text-xs">
        <v-icon color="primary">mdi-check</v-icon> Uploaded
      </div>
    </div>
  </label>
</template>
<script>
export default {
    props: {
        fileName: {
            type: String,
            required: true,
        },
    },
};
</script>
