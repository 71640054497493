<template>
    <div>
        <v-data-table
            v-bind="$attrs"
            :items-per-page="pagination.pageSize"
            item-key="id"
            hide-default-footer
            class="tw-bg-transparent custom-table"
            @click:row="(item, slot) => slot.expand(!slot.isExpanded)">

            <template
                v-for="(_, slot) in $scopedSlots"
                v-slot:[slot]="{item}">
                <slot :name="slot" :item="item"></slot>
            </template>

        </v-data-table>

        <v-pagination
            v-model="pagination.page"
            :length="pagination.pageCount"
            next-icon="$next"
            prev-icon="$prev"
            class="custom-pagination"
            color="#7598B5">
        </v-pagination>
    </div>
</template>

<script>
export default {
    props: {
        pagination: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>
