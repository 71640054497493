import api from './index';

const resource = 'admin/ribbons';

export default {
    getAll(params = {}) {
        return api.get(`${resource}/`, {
            params,
        });
    },

    create(payload) {
        return api.post(`${resource}/`, payload);
    },

    update(id, payload) {
        return api.post(`${resource}/${id}`, payload);
    },

    delete(id) {
        return api.delete(`${resource}/${id}`);
    },
};
