/* eslint-disable camelcase */
import Model from './Model';

class PaymentRequest extends Model {
    static fields() {
        return {
            amount: Number,
            benefactor_country: String,
            benefactor_currency: String,
            benefactor_payment_method_fee: String,
            benefactor_payment_method_type: String,
            benefactor_payment_provider: String,
            benefactor_user: Object,
            benefactor_user_email: String,
            benefactor_user_id: String,
            company: Object,
            company_slug: String,
            created_at: String,
            delivery_method: String,
            exchange_rate: Number,
            fee: Number,
            id: Number,
            note: String,
            order_id: String,
            payment_method: String,
            payment_request_purpose: String,
            pb_to_matcher_exchange_rate: Number,
            requestorDeliveryMethod: Object,
            requestor_country: String,
            requestor_currency: String,
            requestor_delivery_method_id: Number,
            status: String,
            type: String,
            updated_at: String,
            user: Object,
            user_id: String,
        };
    }

    /**
     * Get Requestor Amount
     */
    get requestorAmount() {
        const {
            type,
            amount,
            exchange_rate,
            fee,
        } = this;

        if (type === '3po') {
            return Number((amount * exchange_rate) - fee).toFixed(2);
        }

        return Number(amount * exchange_rate).toFixed(2);
    }

    /**
     * Get Requestor Amount with currency
     */
    get requestorAmountFormatted() {
        return `${this.requestorAmount} ${this.requestor_currency}`;
    }
}

export default PaymentRequest;
