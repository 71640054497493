<template>
    <v-row class="ma-0">
        <v-col cols=12>
            <v-row class="mt-8">
                <v-card
                    class="mx-auto pl-7 pr-7 pt-5 pb-5 tw-border-none"
                    id="loginCard"
                    max-width="500"
                    outlined
                >
                    <v-card-title id="logTitle" class="justify-center"> Log in to account</v-card-title>
                    <v-card-text>
                        <form>
                            <v-row>
                                <v-col cols="12" class="d-flex flex-column">
                                    <label class="f-label default-font"> E-mail:</label>
                                    <input
                                        type="email"
                                        v-model="userEmail"
                                        class="f-input default-font pa-2 tw-border-binput focus:tw-border-primary"
                                    />
                                </v-col>
                                <v-col cols="12" class="d-flex flex-column pt-0">
                                    <label class="f-label default-font"> Password:</label>
                                    <input
                                        type="password"
                                        v-model="password"
                                        class="f-input default-font pa-2 tw-border-binput focus:tw-border-primary"
                                    />
                                </v-col>
                                <v-col cols="12" class="d-flex justify-space-between pt-0 pb-0">
                                    <v-checkbox color="primary" class="mt-0">
                                        <template v-slot:label>
                                            <span class="remember default-font">Remember me</span>
                                        </template>
                                    </v-checkbox>
                                    <v-btn
                                        text color="primary" class="remember text-capitalize pr-0" to="/reset-password"
                                    >
                                        Forgot Password
                                    </v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn
                                        x-large
                                        color="primary"
                                        class="remember default-font text-capitalize text-center tw-w-full"
                                        @click="submit"
                                    >
                                        Login
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </form>
                    </v-card-text>
                </v-card>
            </v-row>
            <div class="d-flex mt-4 justify-center align-baseline">
                <span class="default-font account">Don’t have an account? </span>
                <v-btn text color="primary" class="remember text-capitalize pa-0">
                    <router-link to="/sign">Sign Up</router-link>
                </v-btn>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    data: () => ({
        userEmail: '',
        password: '',
    }),
    methods: {
        ...mapActions([
            'login',
        ]),
        async submit() {
            try {
                await this.login({
                    username: this.userEmail,
                    password: this.password,
                });
                this.$router.push('/offers/create');
            } catch (error) {
                this.$swal({
                    type: 'error',
                    title: 'Error',
                    text: error,
                });
            }
        },
    },
};

</script>

<style scoped>
.remember{
font-size: 13px;

/* identical to box height, or 21px */

/* Text black */
color: #2E5677;
}
</style>
