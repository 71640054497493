<template>
    <div class="tw-w-full">
        <div class="d-flex justify-center mt-4 mb-6">
            <v-btn-toggle
                v-model="tab"
                dense>
                <v-btn :value="0">
                    Credit
                </v-btn>
                <v-btn :value="1">
                    Withdraw
                </v-btn>
            </v-btn-toggle>
        </div>

        <v-tabs-items v-model="tab">
            <v-tab-item>
                <ReferralTransactions
                    :default-filters="defaultFilters"
                />
            </v-tab-item>
            <v-tab-item>
                <WithdrawReferralTransactions
                    :default-filters="defaultFilters"
                />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>
<script>
import ReferralTransactions from '../referralTransactions/ReferralTransactions.vue';
import WithdrawReferralTransactions from '../referralTransactions/WithdrawReferralTransactions.vue';

export default {
    components: {
        ReferralTransactions,
        WithdrawReferralTransactions,
    },
    props: {
        userId: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        tab: 0,
    }),
    computed: {
        defaultFilters() {
            return {
                user_id: this.userId,
            };
        },
    },
};
</script>
