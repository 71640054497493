import Vue from 'vue';
import '@mdi/font/css/materialdesignicons.css';
import Vuetify from 'vuetify/lib';
import DashboardIcon from '@/components/globals/Icons/DashboardIcon.vue';
import OfferIcon from '@/components/globals/Icons/OfferIcon.vue';
import DeveloperIcon from '@/components/globals/Icons/DeveloperIcon.vue';
import SupportIcon from '@/components/globals/Icons/SupportIcon.vue';
import TransactionIcon from '@/components/globals/Icons/TransactionIcon.vue';
import LogoutIcon from '@/components/globals/Icons/LogoutIcon.vue';
import LeftMark from '@/components/globals/Icons/LeftMark.vue';
import ExportIcon from '@/components/globals/Icons/ExportIcon.vue';
import LaunchIcon from '@/components/globals/Icons/LaunchIcon.vue';
import FilterIcon from '@/components/globals/Icons/FilterIcon.vue';
import PrevIcon from '@/components/globals/Icons/PrevIcon.vue';
import NextIcon from '@/components/globals/Icons/NextIcon.vue';
import RefreshIcon from '@/components/globals/Icons/RefreshIcon.vue';
import EyeIcon from '@/components/globals/Icons/EyeIcon.vue';
import CopyIcon from '@/components/globals/Icons/CopyIcon.vue';
import TriangleListIcon from '@/components/globals/Icons/TriangleListIcon.vue';
import SupIcon from '@/components/globals/Icons/SupIcon.vue';
import RightArrowIcon from '@/components/globals/Icons/RightArrowIcon.vue';
import ClearIcon from '@/components/globals/Icons/ClearIcon.vue';
import CalendarIcon from '@/components/globals/Icons/CalendarIcon.vue';
import SenderIcon from '@/components/globals/Icons/SenderIcon.vue';
import BackIcon from '@/components/globals/Icons/BackIcon.vue';
import DownIcon from '@/components/globals/Icons/DownIcon.vue';
import FilterOfferIcon from '@/components/globals/Icons/FilterOfferIcon.vue';
import StarIcon from '@/components/globals/Icons/StarIcon.vue';
import CardIcon from '@/components/globals/Icons/CardIcon.vue';
import BankIcon from '@/components/globals/Icons/BankIcon.vue';
import CouponIcon from '@/components/globals/Icons/CouponIcon.vue';
import BoldIcon from '@/components/globals/Icons/BoldIcon.vue';
import CodeIcon from '@/components/globals/Icons/CodeIcon.vue';
import ItalicIcon from '@/components/globals/Icons/ItalicIcon.vue';
import LinkIcon from '@/components/globals/Icons/LinkIcon.vue';
import OlIcon from '@/components/globals/Icons/OlIcon.vue';
import UnionIcon from '@/components/globals/Icons/UnionIcon.vue';
import StrikeIcon from '@/components/globals/Icons/StrikeIcon.vue';
import SendIcon from '@/components/globals/Icons/SendIcon.vue';
import SortIcon from '@/components/globals/Icons/SortIcon.vue';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
        values: {
            offer: { // name of our custom icon
                component: OfferIcon, // our custom component
            },
            developer: {
                component: DeveloperIcon,
            },
            dashboard: {
                component: DashboardIcon,
            },
            support: {
                component: SupportIcon,
            },
            transaction: {
                component: TransactionIcon,
            },
            logout: {
                component: LogoutIcon,
            },
            'left-mark': {
                component: LeftMark,
            },
            export: {
                component: ExportIcon,
            },
            launch: {
                component: LaunchIcon,
            },
            filter: {
                component: FilterIcon,
            },
            next: {
                component: NextIcon,
            },
            prev: {
                component: PrevIcon,
            },
            refresh: {
                component: RefreshIcon,
            },
            eye: {
                component: EyeIcon,
            },
            copy: {
                component: CopyIcon,
            },
            triangleList: {
                component: TriangleListIcon,
            },
            sup: {
                component: SupIcon,
            },
            'arrow-right': {
                component: RightArrowIcon,
            },
            clear: {
                component: ClearIcon,
            },
            calendar: {
                component: CalendarIcon,
            },
            sender: {
                component: SenderIcon,
            },
            back: {
                component: BackIcon,
            },
            down: {
                component: DownIcon,
            },
            'filter-v2': {
                component: FilterOfferIcon,
            },
            star: {
                component: StarIcon,
            },
            card: {
                component: CardIcon,
            },
            bank: {
                component: BankIcon,
            },
            coupon: {
                component: CouponIcon,
            },
            bold: {
                component: BoldIcon,
            },
            italic: {
                component: ItalicIcon,
            },
            strike: {
                component: StrikeIcon,
            },
            attach: {
                component: UnionIcon,
            },
            ol: {
                component: OlIcon,
            },
            link: {
                component: LinkIcon,
            },
            code: {
                component: CodeIcon,
            },
            send: {
                component: SendIcon,
            },
            sort: {
                component: SortIcon,
            },
        },
    },
    theme: {
        primary: '#44b02a',
        secondary: '#333F48',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        themes: {
            light: {
                primary: '#15ABAB',
                secondary: '#7598B5',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                bgColor: '#E5E5E5',
                footerTextColor: '#333F48',
                iconColor: '#98BAD7',
                cancel: '#F07877',
                'dark-black': '#2E5677',
            },
        },
    },
});
