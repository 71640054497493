export default {
    state: {
        apiLogs: [],
    },

    actions: {
        loadApiLogs({ commit }, payload) {
            commit('SET_LOADAPILOGS', payload);
        },
    },

    mutations: {
        SET_LOADAPILOGS(state, payload) {
            state.apiLogs = payload;
        },
    },

    getters: {
        getLogById(state) {
            return (id) => state.apiLogs.find((apiLog) => apiLog.id === id);
        },
    },
};
