/* eslint-disable camelcase */
import Model from './Model';
import Order from './Order';

class ReferralTransaction extends Model {
    constructor(record) {
        super(record);
        this.order = new Order(record.order);
    }

    static fields() {
        return {
            amount: Number,
            beneficiaryDeliveryMethod: Object,
            created_at: String,
            currency_code: String,
            id: String,
            order: Object,
            order_id: String,
            status: Object,
            statuses: Array,
            type: String,
            updated_at: String,
            user_id: String,
            user: Object,
        };
    }

    /**
     * Get amount with currency
     */
    get amountFormatted() {
        const amount = this.type === 'withdraw'
            ? this.amount * -1
            : this.amount;
        return `${amount} ${this.currency_code}`;
    }
}

export default ReferralTransaction;
