<template>
    <div class="tw-border tw-rounded-full tw-overflow-hidden tw-flex tw-w-1/2">
          <button class="tw-flex tw-items-center tw-justify-center tw-px-2">
            <svg
              class="tw-h-4 tw-w-4 tw-text-grey-dark"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0
                 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"
              />
            </svg>
          </button>
          <input
            type="text"
            class="tw-px-2 tw-py-2 tw-w-full default-font tw-text-secondary tw-text-sm"
            v-bind="$attrs"
            :value="value"
            v-on="inputListeners"
          />
    </div>
</template>
<script>
export default {
    inheritAttrs: false,
    props: ['value'],
    computed: {
        inputListeners() {
            const vm = this;
            // `Object.assign` merges objects together to form a new object
            return {
                // We add all the listeners from the parent
                ...this.$listeners,
                // Then we can add custom listeners or override the
                // behavior of some listeners.
                // This ensures that the component works with v-model
                input(event) {
                    vm.$emit('input', event.target.value);
                },
            };
        },
    },
};
</script>
