<template>
    <div class="d-flex flex-column">
        <v-row class="px-12 justify-end align-center">

            <v-col
                cols="12"
                md="9">
                <v-row>
                    <v-col
                        cols="auto"
                        class="text-center">
                        <small class="tw-text-secondary">Poster Amount</small>
                        <p class="text-h5 font-weight-black font-italic tw-text-primary mb-0">
                            {{ transactionsStatistic.total_poster_amount }}
                        </p>
                    </v-col>

                    <v-col
                        cols="auto"
                        class="text-center">
                        <small class="tw-text-secondary">Matcher Amount</small>
                        <p class="text-h5 font-weight-black font-italic tw-text-primary mb-0">
                            {{ transactionsStatistic.total_matcher_amount }}
                        </p>
                    </v-col>

                    <v-col
                        cols="auto"
                        class="text-center">
                        <small class="tw-text-secondary">Poster Fee</small>
                        <p class="text-h5 font-weight-black font-italic tw-text-primary mb-0">
                            {{ transactionsStatistic.total_poster_fee }}
                        </p>
                    </v-col>
                </v-row>
            </v-col>

            <v-col
                cols="12"
                md="3">
                <SortDropdown />
            </v-col>
        </v-row>

        <v-data-table
            item-key="id"
            :headers="headers"
            :items="transactions"
            :loading="loading"
            :items-per-page="pagination.pageSize"
            hide-default-footer
            single-expand
            class="px-12 py-4 tw-bg-transparent custom-table"
        >
            <template v-slot:item="{ item, headers, expand, isExpanded }">
                <transaction-row :item="item" :headers="headers" @row-clicked="expand(!isExpanded)">
                    <template v-slot:id="{ item }">
                        <div class="tw-cursor-pointer">
                            <span class="mr-1">{{ item.id }}</span>
                        </div>
                    </template>

                    <template v-slot:poster_amount="{ item }">
                        <div class="d-flex flex-column tw-cursor-pointer">
                            <span>{{ item.poster_amount | price }}</span>
                            <span class="tw-text-black">{{item.poster_currency}}</span>
                        </div>
                    </template>
                    <template v-slot:matcher_amount="{ item }">
                        <div class="d-flex flex-column tw-cursor-pointer">
                            <span>{{ item.matcher_amount | price }}</span>
                            <span class="tw-text-black">{{item.matcher_currency}}</span>
                        </div>
                    </template>
                    <template v-slot:rate="{ item }">
                        <div class="tw-cursor-pointer">
                            <span class="mr-1">{{ item.exchange_rate | price }}</span>
                        </div>
                    </template>
                    <template v-slot:created_at="{ item }">
                        <div class="d-flex flex-column tw-cursor-pointer" v-if="item.posted_at">
                            <span>{{ item.created_at | date }}</span>
                            <span>at {{ item.created_at | time }}</span>
                        </div>
                    </template>
                    <template v-slot:completed_at="{ item }">
                        <div v-if="item.completed_at" class="d-flex flex-column tw-cursor-pointer">
                            <span>{{ item.completed_at | date }}</span>
                            <span>at {{ item.completed_at | time }}</span>
                        </div>
                    </template>
                    <template v-slot:status="{ item }">
                        <status :item="item" />
                    </template>
                </transaction-row>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" transition="slide-y-transition">
                    <transaction-detail :id="item.id"></transaction-detail>
                </td>
            </template>
        </v-data-table>
        <v-pagination
            :value="pagination.page"
            @input="onPageChange"
            next-icon="$next"
            prev-icon="$prev"
            class="custom-pagination"
            color="#7598B5"
            :total-visible="5"
            :length="pagination.pageCount"
        ></v-pagination>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

import TransactionRow from '../transactions/TransactionRow.vue';
import TransactionDetail from './TransactionDetail.vue';
import Status from './Status.vue';
import SortDropdown from './SortDropdown.vue';

const headers = [
    {
        text: 'Transaction ID',
        align: 'center',
        class: 'tw-text-black default-font py-2 text-start tw-rounded-lg tw-rounded-r-none first_cell',
        value: 'id',
        sortable: false,
    },
    {
        text: 'Poster Amount',
        align: 'end',
        value: 'poster_amount',
        class: 'tw-text-black default-font py-2 text-end',
        sortable: false,
    },
    {
        text: 'Matcher Amount',
        align: 'end',
        value: 'matcher_amount',
        class: 'tw-text-black default-font py-2 text-end',
        sortable: false,
    },
    {
        text: 'Rate',
        align: 'end',
        value: 'rate',
        class: 'tw-text-black default-font py-2 text-end',
        sortable: false,
    },
    {
        text: 'Created',
        align: 'start',
        value: 'created_at',
        class: 'tw-text-black default-font py-2 text-end',
        sortable: false,
    },
    {
        text: 'Completed',
        align: 'start',
        value: 'completed_at',
        class: 'tw-text-black default-font py-2 text-end',
        sortable: false,
    },
    {
        text: 'Status',
        align: 'center',
        value: 'status',
        class: 'tw-text-black default-font py-2 text-end',
        sortable: false,
    },
];

export default {
    components: {
        TransactionRow,
        TransactionDetail,
        Status,
        SortDropdown,
    },
    props: {
        transactions: {
            type: Array,
            default() {
                return [];
            },
        },
        pagination: {
            type: Object,
            default() {
                return {};
            },
        },
        transactionsStatistic: {
            type: Object,
            default: () => ({}),
        },
        loading: {
            type: Boolean,
            default() {
                return true;
            },
        },
    },
    data() {
        return {
            headers,
            page: 1,
            pageCount: 0,
            itemsPerPage: 10,
        };
    },
    computed: {
        ...mapGetters([
            'user',
        ]),
        isAdmin() {
            return this.user.role === 'admin';
        },
        isPrincipal() {
            return this.user.role === 'principal';
        },
    },
    methods: {
        expandRow(item) {
            if (this.expanded.includes(item)) {
                this.expanded = [];
            } else {
                this.expanded.push(item);
            }
        },
        isCompleted(status) {
            return status === 'completed';
        },
        actionColor(status) {
            return this.isCompleted(status) ? '#EAF0F5' : '#F07877';
        },
        onPageChange(page) {
            this.$emit('pageChange', page);
        },
        confirm(text = 'Are you sure you want to take this action?') {
            return this.$swal({
                type: 'info',
                title: text,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => result.value);
        },
        canCancelMatch(item) {
            const {
                match_id,
                match_cancelled_at,
                match_matcher_payment_completed_at,
                match_completed_at,
            } = item;
            if (!match_id) {
                return false;
            }

            return !match_cancelled_at && !match_matcher_payment_completed_at && !match_completed_at;
        },
        cancelMatch(item) {
            if (item.match_id) {
                this.confirm('Are you sure you want to take this action - Cancel Match?').then((value) => {
                    if (value) {
                        this.$emit('cancelMatch', {
                            id: item.match_id,
                        });
                    }
                });
            }
        },
        canApproveMatch(item) {
            return this.canCancelMatch(item);
        },
        approveMatch(item) {
            if (item.match_id) {
                this.confirm('Are you sure you want to take this action - Approve Match?').then((value) => {
                    if (value) {
                        this.$emit('approveMatch', {
                            id: item.match_id,
                        });
                    }
                });
            }
        },
        markAsMatcherPaid(id) {
            this.confirm('Are you sure you want to take this action - Mark as Paid?').then((value) => {
                if (value) {
                    this.$emit('markAsMatcherPaid', id);
                }
            });
        },
        canCompleteOrder(item) {
            if (!item.published_at) return false;
            if (!item.matched_at) return false;
            if (!item.matcher_payment_completed_at) return false;

            if (item.completed_at) return false;
            if (item.cancelled_at) return false;
            if (item.is_removed) return false;

            return true;
        },
        completeOrder(id) {
            this.confirm('Are you sure you want to take this action - Complete Order?').then((value) => {
                if (value) {
                    this.$emit('completeOrder', id);
                }
            });
        },
        canExpireOrder(item) {
            if (item.matched_at) return false;
            if (item.matcher_payment_completed_at) return false;
            if (item.completed_at) return false;
            if (item.cancelled_at) return false;
            if (item.is_removed) return false;

            return true;
        },
        expireOrder(id) {
            this.confirm('Are you sure you want to take this action - Expire Order?').then((value) => {
                if (value) {
                    this.$emit('expireOrder', id);
                }
            });
        },
        setHidden(id, hidden) {
            const text = hidden ? 'Hide' : 'Unhide';
            this.confirm(`Are you sure you want to take this action - ${text} Order?`).then((value) => {
                if (value) {
                    this.$emit('setHidden', {
                        id,
                        hidden,
                    });
                }
            });
        },
        bulkSetHidden(hidden) {
            const text = hidden ? 'Hide' : 'Unhide';
            this.confirm(`Are you sure you want to take this action - ${text} selected orders?`).then((value) => {
                if (value) {
                    this.$emit('bulkSetHidden', {
                        hidden,
                    });
                }
            });
        },
        captureOrder(id) {
            this.confirm('Are you sure you want to take this action - Capture Order?').then((value) => {
                if (value) {
                    this.$emit('captureOrder', id);
                }
            });
        },
        voidOrder(id) {
            this.confirm('Are you sure you want to take this action - Void Order?').then((value) => {
                if (value) {
                    this.$emit('voidOrder', id);
                }
            });
        },
        canVoid(item) {
            if (item.status === 'unable_to_void') {
                return true;
            }

            if (item.removed_at) {
                return true;
            }

            return false;
        },
        canPublish(item) {
            return !item.published_at;
        },
        publishOrder(id) {
            this.$refs.publishOrderConfirmationDialog.show().then((paymentType) => {
                if (!paymentType) {
                    this.$swal({
                        icon: 'error',
                        title: 'Payment type is required',
                    });
                } else {
                    this.$emit('publishOrder', {
                        id,
                        paymentType,
                    });
                }
            });
        },
    },
};
</script>
<style lang="scss">
.custom-table {
  .v-data-table-header th.sortable .v-data-table-header__icon {
    color: #2E5677 !important;
  }

  th {
    border: none !important;
  }
}
</style>
