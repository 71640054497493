export default (role) => ({ next, store }) => {
    const { user } = store.getters;

    if (user.role !== role) {
        return next({
            name: 'Login',
        });
    }

    return next();
};
