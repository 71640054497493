<template functional>
    <div class="px-2 py-5">
        <div class="d-flex justify-space-between align-start mb-5">
            <label>Recipient gets:</label>
            <span class="tw-text-right"> {{props.transaction.received}} {{props.transaction.to_currency}}</span>
        </div>
        <div class="d-flex justify-space-between align-start mb-5">
            <label>Exchange rate:</label>
            <span class="tw-text-right">
                {{parseFloat(props.transaction.rate).toFixed(2)}} {{props.transaction.from_currency}}
            </span>
        </div>
        <div class="d-flex justify-space-between align-start mb-5">
            <label>Subtotal:</label>
            <span class="tw-text-right">
                {{props.transaction.subtotal | price}} {{props.transaction.from_currency}}
            </span>
        </div>
        <div class="d-flex justify-space-between align-start mb-5">
            <label>Fee:</label>
            <span class="tw-text-right tw-max-w-2/3">
                {{props.transaction.fee}} {{props.transaction.fee_currency}}
            </span>
        </div>
        <div class="d-flex justify-space-between align-start mb-5 tw-border-t tw-border-solid pt-5 tw-border-dark-1">
            <label>Total</label>
            <span class="tw-text-right"> {{props.transaction.total}}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        transaction: {
            type: Object,
            required: true,
        },
    },
};
</script>
<style scoped>
label {
    font-family: Muli;
font-style: normal;
font-weight: normal;
font-size: 13px;
line-height: 180%;

/* or 23px */

/* Menu gray */
color: #98BAD7;
}

span {
font-family: Muli;
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 136.5%;

/* or 19px */
text-align: right;

/* BG gray */
color: #F7FBFC;
}
</style>
