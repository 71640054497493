import api from './index';

const resource = 'admin/hybrid-order-options';

export default {
    /**
     * Get all Hybrid Orders Option
     * @param {Object} params query params
     */
    getAll(params) {
        return api.get(`${resource}`, {
            params,
        });
    },

    /**
     * Update Hybrid Order Option
     * @param {Object} payload Hybrid Order Option Model
     */
    update(payload) {
        const { id } = payload;
        return api.post(`${resource}/${id}`, payload);
    },

    /**
     * Update all Hybrid Order Options
     * @param {Object} params query params
     * @param {Object} payload payload with updated fields
     */
    updateAll(params, payload) {
        return api.put(`${resource}`, payload, {
            params,
        });
    },

    /**
     * Get log
     * @param {Object} params query params
     */
    getChangelog(params) {
        return api.get(`${resource}/changelog`, {
            params,
        });
    },
};
