<template>
    <v-select
        :items="options"
        outlined
        dense
        class="custom-select"
        :value="statusFilter"
        @change="updateStatusFilter"
        multiple
        :menu-props="{ bottom: true, offsetY: true, 'content-class': 'mt-3 tw-rounded-lg' }"
        :disabled="disabled"
        clearable
    >
        <template v-slot:selection="{ item }">
            <span class="default-font filter-title tw-text-black mr-2">{{ item.text }}</span>
        </template>
    </v-select>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    props: {
        disabled: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        items: [
            {
                text: 'Unpublished',
                value: 'unpublished',
            },
            {
                text: 'Published',
                value: 'published',
            },
            {
                text: 'Matched',
                value: 'matched',
            },
            {
                text: 'Matcher Paid',
                value: 'matcher_paid',
            },
            {
                text: 'Payment Received',
                value: 'payment_received',
            },
            {
                text: 'Expired',
                value: 'removed',
            },
            {
                text: 'Cancelled',
                value: 'cancelled',
            },
            {
                text: 'Completed',
                value: 'completed',
            },
            {
                text: 'Unable to Void',
                value: 'unable_to_void',
            },
            {
                text: 'Unable to Capture',
                value: 'unable_to_capture',
            },
            {
                text: 'Unable to Payout',
                value: 'unable_to_payout',
            },
            {
                text: 'Unable to Transfer',
                value: 'unable_to_transfer',
            },
            {
                text: 'Dispute Resolution Needed',
                value: 'dispute_resolution_needed',
            },
            {
                text: 'Unable to Refund',
                value: 'unable_to_refund',
            },
        ],
    }),
    methods: {
        ...mapActions('transaction', ['updateStatusFilter']),
    },
    computed: {
        ...mapGetters('transaction', ['statusFilter']),
        options() {
            return this.items.map((o) => ({
                ...o,
                disabled: this.disabled,
            }));
        },
    },
};
</script>
