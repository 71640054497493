<template>
    <p>
        <span class="default-font font-16 tw-text-black">
            {{ bank.name }}
        </span>
        <BankStatusChip
            :bank="bank"
            outlined
            dense
            small>
        </BankStatusChip>
        <v-chip
            v-if="bank.methodStatus"
            :color="methodStatusType"
            class="ml-2"
            outlined
            small>
            {{ bank.methodStatus | humanize }}
        </v-chip>

    </p>
</template>

<script>
import BankStatusChip from './BankStatusChip.vue';

export default {
    components: {
        BankStatusChip,
    },
    props: {
        bank: Object,
    },
    computed: {
        statusText() {
            return this.bank.status === 'UP' ? 'Healthy' : 'Unhealthy';
        },
        alertType() {
            return this.bank.status === 'UP' ? 'success' : 'error';
        },
        methodStatusType() {
            switch (this.bank.methodStatus) {
            case 'bank_authenticated':
                return 'success';
            case 'bank_authentication_needed':
                return 'error';
            default:
                return '';
            }
        },
    },
};
</script>
