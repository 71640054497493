<template>
    <v-menu
        ref="datePicker"
        v-model="datePicker"
        :close-on-content-click="false"
        :return-value.sync="innerValue"
        transition="scale-transition"
        min-width="290px"
        offset-y>

        <template #activator="{ on }">
            <v-text-field
                v-model="innerValue"
                v-bind="$attrs"
                v-on="on"
                :disabled="disabled"
                autocomplete="off"
                class=""
                outlined
                readonly
                clearable
                @click:append="datePicker = true">
            </v-text-field>
        </template>

        <v-date-picker
            ref="picker"
            v-model="innerValue"
            v-bind="$attrs"
            :disabled="disabled"
            @change="$refs.datePicker.save(innerValue)">
        </v-date-picker>
    </v-menu>
</template>

<script>
export default {
    props: {
        value: [String, Array],
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            innerValue: null,
            datePicker: false,
        };
    },
    computed: {
        isRange() {
            return this.range;
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(val) {
                this.innerValue = val;
            },
        },
        innerValue(val) {
            this.$emit('input', val);
        },
    },
};
</script>
