<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="webhooks"
      item-key="endpoints"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      hide-default-header
      @page-count="pageCount = $event"
      class="py-4 tw-bg-transparent custom-table table-space"
    >
      <template v-slot:top>
        <v-toolbar flat color="white" class="pl-0 pr-0">
          <v-toolbar-title class="font-style font-dark-bold-head-15">Endpoints</v-toolbar-title>
          <v-spacer></v-spacer>
          <webhooks-add-modal :events="events"></webhooks-add-modal>
        </v-toolbar>
      </template>
      <template v-slot:item="{ item, headers }">
        <developer-row :item="item" :headers="headers">
          <template v-slot:endpoints="{ item, header }">
            <div class="d-flex flex-column ml-6">
              <span class="font-style font-light-13">{{header.text}}</span>
              <span class="font-style font-dark-bold-15 mt-6">{{ item.endpoints }}</span>
              <span class="font-style font-light-13">{{item.created_at | date}} at {{item.created_at | time}}</span>
            </div>
          </template>
          <template v-slot:events="{ item, header }">
            <div class="d-flex flex-column tw-text-black">
              <span class="font-style font-light-13">{{header.text}}</span>
              <span class="font-style font-dark-bold-15 mt-6">{{ item.events }}</span>
            </div>
          </template>
          <template v-slot:action="{ item }">
            <v-btn
              :class="{'completed': isCompleted(item.events)}"
              :color="actionColor(item.events)"
              :outlined="!isCompleted(item.events)"
              elevation="0"
              class="default-font font-12 text-capitalize mr-6"
              @click.stop="dialog1 = true"
            >Delete</v-btn>
            <v-dialog
              v-model="dialog1"
              max-width="550"
            >
              <v-card class="modal-style pt-12 pb-8">
                <v-card-text class="pb-n1">
                <v-container class="headline-style text-center">
                  <div class="tw-text-secondary">
                    Are you sure, you want to delete
                  </div>
                  <div>
                    {{ item.endpoints }} <span class="tw-text-secondary">endpoint?</span>
                  </div>
                </v-container>
                </v-card-text>

                <v-card-actions class="justify-center">
                  <v-btn
                    text @click="deleteEndpoint"
                    elevation="0"
                    class="default-font font-12 delete-btn text-capitalize mr-3 px-6"
                    >Delete endpoint</v-btn>
                    <v-btn
                    text @click="dialog1=false"
                    elevation="0"
                    class="default-font font-12 cancel-btn text-capitalize px-6"
                  >Cancel</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </developer-row>
      </template>
      <template v-slot:expanded-item="{ headers }">
        <td :colspan="headers.length">Peek-a-boo!</td>
      </template>
    </v-data-table>
    <v-pagination
      v-model="page"
      next-icon="$next"
      prev-icon="$prev"
      color="#7598B5"
      class="custom-pagination"
      :length="pageCount"
    ></v-pagination>
  </div>
</template>
<script>
import { headers, webhooks, events } from '@/utils/Developer/webhooks';
import DeveloperRow from './DeveloperRow.vue';
import WebhooksAddModal from './WebhooksAddModal.vue';

export default {
    components: { DeveloperRow, WebhooksAddModal },
    data() {
        return {
            expanded: [],
            singleExpand: false,
            headers,
            webhooks,
            events,
            page: 1,
            pageCount: 0,
            itemsPerPage: 10,
            dialog1: false,
        };
    },
    methods: {
        statusColor(status) {
            switch (status) {
            case 'completed':
                return { bg: '#C5F5E7', color: '#15ABAB' };
            case 'pending':
                return { bg: '#FFEBA6', color: '#D89342' };
            default:
                return { bg: '#FFE3E2', color: '#F07877' };
            }
        },
        isCompleted(status) {
            return status === 'completed';
        },
        actionColor(status) {
            return this.isCompleted(status) ? '#EAF0F5' : '#F07877';
        },
        deleteEndpoint() {
            this.dialog1 = false;
        },
    },
};
</script>

<style scoped>
.input-label, .input-field {
    font-family: Muli !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 160% !important;
    /* or 22px */
    /* Gray text */

    color: #7598B5 !important;
}

.select-input .v-input__slot{
    border: 1px solid #EAF0F5 !important;
    box-sizing: border-box;
    border-radius: 4px;
}
</style>
