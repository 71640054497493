<template>
    <v-dialog v-model="visible" width="500px" @click:outside="$emit('close')">
        <v-card
            :loading="loading">
            <v-card-title class="d-flex justify-space-between">
                <span class="headline">Dispute ({{ order.id }})</span>
                <v-btn
                    icon
                    @click="refreshTransactionData">
                    <v-icon color="#15ABAB">mdi-refresh-circle</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>
                <v-container>
                    <div class="d-flex justify-space-between">
                        <h6 class="font-weight-bold mr-2">Poster Beneficiary</h6>
                        <v-btn
                            small
                            color="secondary"
                            class="mb-3 text-white"
                            @click="uploadPoster"
                        >
                            <v-icon left>mdi-cloud-upload</v-icon>
                            Attach
                        </v-btn>
                        <input
                            ref="poster"
                            id="poster_beneficiary_resp_email"
                            class="d-none"
                            type="file"
                            multiple
                            @change="uploadAttachment"
                        />
                    </div>
                    <hr />
                    <v-row class="mt-2">
                        <v-col cols="12" class="py-0">
                            <div class="d-flex">
                                <h6 class="font-weight-bold mr-2">Name: </h6>
                                <p>{{ postersBeneficiary.full_name }} ({{ postersBeneficiary.email }})</p>
                            </div>
                        </v-col>
                        <!-- <v-col cols="6" class="py-0">
                            <div class="d-flex">
                                <h6 class="font-weight-bold mr-2">Response Text: </h6>
                                <p>Yes</p>
                            </div>
                        </v-col> -->
                        <v-col cols="6" class="py-0">
                            <div class="d-flex">
                                <h6 class="font-weight-bold mr-2">Response In App: </h6>
                                <p>{{ pbResponse }}</p>
                            </div>
                        </v-col>
                        <v-col cols="12" class="py-0">
                            <div class="d-flex">
                                <h6 class="font-weight-bold mr-2">API Status: </h6>
                                <BankStatusChip
                                    :bank="transaction.postersBeneficiaryBank"
                                    small>
                                </BankStatusChip>
                            </div>
                        </v-col>
                        <v-col cols="12" class="py-0 mt-1">
                            <div class="d-flex justify-space-between">
                                <a @click="isPBTransactionDataShown = true">View Poster Beneficiary Transaction Data</a>
                                <a
                                    v-if="dispute.poster_beneficiary_resp_email_files
                                        && dispute.poster_beneficiary_resp_email_files.length > 0"
                                    @click="showAttachments(dispute.poster_beneficiary_resp_email_files)">
                                    View Attachment
                                </a>
                            </div>
                        </v-col>
                    </v-row>

                    <div class="d-flex justify-space-between mt-5">
                        <h6 class="font-weight-bold mr-2">Matcher</h6>
                        <v-btn
                            small
                            color="secondary"
                            @click="uploadMatcher"
                            class="mb-3 text-white"
                        >
                            <v-icon left>mdi-cloud-upload</v-icon>
                            Attach
                        </v-btn>
                        <input
                            ref="matcher"
                            id="matcher_resp_email"
                            class="d-none"
                            type="file"
                            multiple
                            @change="uploadAttachment"
                        />
                    </div>
                    <hr />
                    <v-row class="mt-2">
                        <v-col cols="12" class="py-0">
                            <div class="d-flex">
                                <h6 class="font-weight-bold mr-2">Name: </h6>
                                <p>{{ matcher.full_name }} ({{ matcher.email }})</p>
                            </div>
                        </v-col>
                        <!-- <v-col cols="6" class="py-0">
                            <div class="d-flex">
                                <h6 class="font-weight-bold mr-2">Response Text: </h6>
                                <p>No</p>
                            </div>
                        </v-col>
                        <v-col cols="6" class="py-0">
                            <div class="d-flex">
                                <h6 class="font-weight-bold mr-2">Response In App: </h6>
                                <p>Yes</p>
                            </div>
                        </v-col> -->
                        <v-col cols="12" class="py-0">
                            <div class="d-flex">
                                <h6 class="font-weight-bold mr-2">API Status: </h6>
                                <BankStatusChip
                                    :bank="transaction.matcherBank"
                                    small>
                                </BankStatusChip>
                            </div>
                        </v-col>
                        <v-col cols="12" class="py-0 mt-1">
                            <div class="d-flex justify-space-between">
                                <a @click="isMatcherTransactionDataShown = true">View Matcher Transaction Data</a>
                                <a
                                    v-if="dispute.matcher_resp_email_files
                                        && dispute.matcher_resp_email_files.length > 0"
                                    @click="showAttachments(dispute.matcher_resp_email_files)">
                                    View Attachment
                                </a>
                            </div>
                        </v-col>
                    </v-row>
                    <!-- <hr /> -->

                    <div class="text-center pa-3 mt-3">
                        <v-btn
                            elevation="2"
                            color="primary"
                            @click="approveDispute">
                            Approve
                        </v-btn>

                        <v-btn
                            elevation="2"
                            color="red"
                            class="ml-3 text-white"
                            @click="declineDispute">
                            Decline
                        </v-btn>
                    </div>
                </v-container>
            </v-card-text>
        </v-card>

        <AttachmentsModal
            v-model="isAttachmentsShown"
            :attachments="attachments">
        </AttachmentsModal>

        <DisputeTransactionData
            v-model="isPBTransactionDataShown"
            :data="postersBeneficiaryTransactionData">
        </DisputeTransactionData>

        <DisputeTransactionData
            v-model="isMatcherTransactionDataShown"
            :data="matcherTransactionData">
        </DisputeTransactionData>

    </v-dialog>
</template>

<script>
/* eslint-disable camelcase */
import DisputesApi from '../../api/DisputesApi';
import TransactionsApi from '../../api/TransactionsApi';

import BankStatusChip from '../../components/transactions/BankStatusChip.vue';
import AttachmentsModal from './AttachmentsModal.vue';
import DisputeTransactionData from './DisputeTransactionData.vue';

export default {
    components: {
        BankStatusChip,
        AttachmentsModal,
        DisputeTransactionData,
    },
    props: {
        visible: Boolean,
        dispute: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            loading: false,
            attachment: {
                poster: null,
                matcher: null,
            },
            attached: null,
            transaction: {},
            attachments: [],
            isAttachmentsShown: false,
            isPBTransactionDataShown: false,
            isMatcherTransactionDataShown: false,
        };
    },
    computed: {
        match() {
            return this.dispute?.match || {};
        },
        order() {
            return this.match?.order || {};
        },
        matcher() {
            return this.match?.user || {};
        },
        postersBeneficiary() {
            return this.order?.postersBeneficiary || {};
        },
        latestValidationData() {
            return this.match?.latestValidationData || [];
        },
        matcherTransactionData() {
            return this.latestValidationData.filter(({ side }) => side === 'matcher');
        },
        postersBeneficiaryTransactionData() {
            return this.latestValidationData.filter(({ side }) => side === 'posters_beneficiary');
        },
        pbResponse() {
            if (this.dispute.pb_response === 1) {
                return 'Yes';
            }
            if (this.dispute.pb_response === 0) {
                return 'No';
            }
            return '(no data)';
        },
    },
    methods: {
        async loadTransaction(id) {
            try {
                this.loading = true;
                const { data } = await TransactionsApi.get(id);
                this.transaction = data;
            } finally {
                this.loading = false;
            }
        },
        uploadPoster() {
            this.$refs.poster.click();
        },
        uploadMatcher() {
            this.$refs.matcher.click();
        },
        uploadAttachment(e) {
            const { files, id } = e.target;

            const [file] = files;
            const valid = this.beforeUpload(file);
            if (valid) {
                const payload = {
                    [id]: file,
                };
                this.saveDispute(payload);
            }
        },
        beforeUpload({ type, size }) {
            const isType = type === 'image/jpeg'
                || type === 'image/png'
                || type === 'image/jpg'
                || type === 'application/pdf';

            const imageSize = size / 1024 / 1000;
            if (!isType) {
                this.$swal({
                    icon: 'warning',
                    title: 'You can only upload JPG, JPEG, PNG or PDF files!',
                });

                return false;
            }

            if (imageSize > 2) {
                this.$swal({
                    icon: 'warning',
                    title: 'You can only upload images with maximum size of 2MB!',
                });
                return false;
            }

            return true;
        },
        showAttachments(attachments) {
            this.attachments = attachments;
            this.isAttachmentsShown = true;
        },
        async saveDispute(payload) {
            const { id } = this.dispute;
            await DisputesApi.update(id, payload);
        },
        async approveDispute() {
            const { id } = this.dispute;
            await DisputesApi.approve(id);
        },
        async declineDispute() {
            const { id } = this.dispute;
            await DisputesApi.decline(id);
        },
        async refreshTransactionData() {
            const { id } = this.dispute;
            await DisputesApi.refreshTransactionData(id);
        },
    },
    watch: {
        'order.id': {
            immediate: true,
            handler(val) {
                if (val) {
                    this.loadTransaction(val);
                }
            },
        },
    },
};
</script>
