<template>
    <div>
        <v-avatar height=42 width=45 tile class="tw-inline-block">
            <v-img src="../assets/logo.svg" />
        </v-avatar>
        <h1 class="ml-3 tw-font-medium tw-not-italic tw-text-lg tw-inline-block">Kaoshi</h1>
    </div>
</template>
<script>
export default {
    name: 'Logo',
};
</script>
