<template>
  <v-select
  :items="items"
   outlined
   dense
   class="custom-select"
   item-text="country"
   item-value="country"
   :value="fromFilter"
   @change="updateFromFilter"
   :menu-props="{ bottom: true, offsetY: true, 'content-class': 'mt-3 tw-rounded-lg' }">
    <template v-slot:item="{ item }">
        <span class="default-font tw-text-black-400 font-15 mr-2">{{ item.country }}</span>
    </template>

    <template v-slot:selection="{ item }">
       <div class="tw-w-full" v-if="item.local">
        <gb-flag class="mr-1" :code="item.local" size="small" />
        <span class="default-font filter-title tw-text-black font-14">{{ item.country }}</span>
      </div>
      <template v-else> <span class="default-font filter-title tw-text-black font-14"> All </span></template>

      </template>
  </v-select>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    data: () => ({
        items: [
            {
                country: 'All',
                local: null,
            },
            {
                country: 'USA',
                local: 'us',
            },
            {
                country: 'Nigeria',
                local: 'ng',
            },
            {
                country: 'South Africa',
                local: 'za',
            },
            {
                country: 'Canada',
                local: 'ca',
            },
            {
                country: 'France',
                local: 'fr',
            }],
    }),
    methods: {
        ...mapActions(['updateFromFilter']),
    },
    computed: {
        ...mapGetters(['fromFilter']),
    },
};
</script>
