<template>
    <tr
        class="tw-bg-hoverBg tr-row px-6 py-4 mt-2 tw-w-full default-font tw-text-black tw-cursor-pointer"
        @click="rowClicked">
        <td
            v-for="(header, key) in headers"
            :key="header.value"
            class="tr-row tw-text-black default-font py-2 text-start"
            :class="{
                [header.class]: true,
                'tw-rounded-lg tw-rounded-r-none first_cell': key === 0,
                'tw-rounded-lg tw-rounded-l-none last_cell': key === headers.length - 1,
            }">

            <slot
                :name="`item.${header.value}`"
                :item="item"
                :header="header"
                :value="get(item, header.value)">
                {{ get(item, header.value) }}
            </slot>
        </td>
    </tr>
</template>
<script>
import { get } from 'lodash';

export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
        headers: {
            type: Array,
            required: true,
        },
        isExpanded: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        get,
        rowClicked() {
            this.$emit('row-clicked');
        },
    },
};
</script>
