var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-8"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3","xl":"2"}},[_c('v-select',{attrs:{"items":_vm.statuses,"menu-props":{
                    bottom: true,
                    offsetY: true,
                    'content-class': 'mt-3 tw-rounded-lg',
                },"label":"Status","hide-details":"","clearable":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"default-font filter-title tw-text-black mr-2"},[_vm._v(" "+_vm._s(item.text)+" ")])]}}]),model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","xl":"2"}},[_c('v-select',{attrs:{"items":_vm.types,"menu-props":{
                    bottom: true,
                    offsetY: true,
                    'content-class': 'mt-3 tw-rounded-lg',
                },"label":"Type","hide-details":"","clearable":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"default-font filter-title tw-text-black mr-2"},[_vm._v(" "+_vm._s(item.text)+" ")])]}}]),model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","xl":"2"}},[_c('v-select',{attrs:{"items":_vm.identityStates,"label":"Identity Verification","menu-props":{
                    bottom: true,
                    offsetY: true,
                    'content-class': 'mt-3 tw-rounded-lg',
                },"hide-details":"","clearable":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"default-font filter-title tw-text-black mr-2"},[_vm._v(" "+_vm._s(item.text)+" ")])]}}]),model:{value:(_vm.identity_verified),callback:function ($$v) {_vm.identity_verified=$$v},expression:"identity_verified"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","xl":"2"}},[_c('v-select',{attrs:{"items":_vm.bankStates,"label":"Bank","menu-props":{
                    bottom: true,
                    offsetY: true,
                    'content-class': 'mt-3 tw-rounded-lg',
                },"hide-details":"","clearable":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"default-font filter-title tw-text-black mr-2"},[_vm._v(" "+_vm._s(item.text)+" ")])]}}]),model:{value:(_vm.bank_added),callback:function ($$v) {_vm.bank_added=$$v},expression:"bank_added"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","xl":"2"}},[_c('DateInput',{attrs:{"label":"Joined at","hide-details":"","scrollable":"","range":"","dense":""},model:{value:(_vm.joined_at),callback:function ($$v) {_vm.joined_at=$$v},expression:"joined_at"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","xl":"2"}},[_c('DateInput',{attrs:{"label":"Last Signed In","hide-details":"","scrollable":"","range":"","dense":""},model:{value:(_vm.last_logged_at),callback:function ($$v) {_vm.last_logged_at=$$v},expression:"last_logged_at"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","xl":"2"}},[_c('v-select',{attrs:{"label":"Country","item-value":"abbreviation","item-text":"country","items":_vm.countries,"menu-props":{
                    bottom: true,
                    offsetY: true,
                    'content-class': 'mt-3 tw-rounded-lg',
                },"hide-details":"","clearable":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"default-font filter-title tw-text-black mr-2"},[_vm._v(" "+_vm._s(item.country)+" ")])]}}]),model:{value:(_vm.country_abbreviation),callback:function ($$v) {_vm.country_abbreviation=$$v},expression:"country_abbreviation"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","xl":"2"}},[_c('v-select',{attrs:{"label":"Customer Support Officer Tag","items":_vm.accountOfficerTags,"menu-props":{
                    bottom: true,
                    offsetY: true,
                    'content-class': 'mt-3 tw-rounded-lg',
                },"hide-details":"","clearable":"","outlined":"","dense":""},model:{value:(_vm.account_officer_tag),callback:function ($$v) {_vm.account_officer_tag=$$v},expression:"account_officer_tag"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","xl":"2"}},[_c('PeriodFilter',{attrs:{"label":"Last Completed Transaction","clearable":"","outlined":"","dense":""},model:{value:(_vm.transactions_completed_at),callback:function ($$v) {_vm.transactions_completed_at=$$v},expression:"transactions_completed_at"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }