/* eslint-disable no-restricted-properties */
/* eslint-disable no-restricted-globals */
export default [{
    id: '345847',
    rate: 3.95,
    sent: 500,
    received: 175644.58,
    transferr_fee: 2.99,
    deliver_fee: 3.35,
    from_currency: 'USD',
    to_currency: 'NGN',
    from: 'USA',
    to: 'Nigeria',
    exchange_rate: 353.4,
    gift: 'Amazon Voucher',
    gift_amount: 20,
    duration: 1,
    promo_code: '930FG',
    service: {
        logo_url: '/img/western.svg',
        garanty_by: null,
        garanty_by_logo: null,
    },
    payment_method: 'card',
    delivery_method: 'bank',
    company_name: 'western',
},
{
    id: '800474',
    rate: 3.95,
    sent: 200,
    received: 175644.58,
    transferr_fee: 2.99,
    deliver_fee: 3.35,
    from_currency: 'USD',
    to_currency: 'NGN',
    from: 'Canada',
    to: 'Nigeria',
    exchange_rate: 353.4,
    gift: 'Amazon Voucher',
    gift_amount: 20,
    duration: 2,
    promo_code: '930FG',
    service: {
        logo_url: '/img/p2p.svg',
        garanty_by: 'Sterling Bank',
        garanty_by_logo: '/img/sterling.svg',
    },
    payment_method: 'bank',
    delivery_method: 'card',
    company_name: 'sterling',

},
{
    id: '855474',
    rate: 3.95,
    sent: 300,
    received: 420,
    transferr_fee: 2.99,
    deliver_fee: 3.35,
    from_currency: 'USD',
    to_currency: 'CAD',
    from: 'Canada',
    to: 'USA',
    exchange_rate: 353.4,
    gift: 'Amazon Voucher',
    gift_amount: 20,
    duration: 3,
    promo_code: '930FG',
    service: {
        logo_url: '/img/wordremit.svg',
        garanty_by: null,
        garanty_by_logo: null,
    },
    payment_method: 'bank',
    delivery_method: 'bank',
    company_name: 'word remit',
},
{
    id: '137474',
    rate: 3.95,
    sent: 100,
    received: 89.58,
    transferr_fee: 2.99,
    deliver_fee: 3.35,
    from_currency: 'USD',
    to_currency: 'EUR',
    from: 'USA',
    to: 'France',
    exchange_rate: 353.4,
    gift: 'Amazon Voucher',
    gift_amount: 20,
    duration: 1,
    promo_code: '930FG',
    service: {
        logo_url: '/img/western.svg',
        garanty_by: null,
        garanty_by_logo: null,
    },
    payment_method: 'card',
    delivery_method: 'bank',
    company_name: 'western',
},
{
    id: '437474',
    rate: 3.95,
    sent: 500,
    received: 175644.58,
    transferr_fee: 2.99,
    deliver_fee: 3.35,
    from_currency: 'USD',
    to_currency: 'NGN',
    from: 'South Africa',
    to: 'Canada',
    exchange_rate: 353.4,
    gift: 'Amazon Voucher',
    gift_amount: 20,
    duration: 2,
    promo_code: '930FG',
    service: {
        logo_url: '/img/western.svg',
        garanty_by: null,
        garanty_by_logo: null,
    },
    payment_method: 'bank',
    delivery_method: 'card',
    company_name: 'western',
},
{
    id: '834474',
    rate: 3.95,
    sent: 500,
    received: 175644.58,
    transferr_fee: 2.99,
    deliver_fee: 3.35,
    from_currency: 'USD',
    to_currency: 'NGN',
    from: 'Canada',
    to: 'Nigeria',
    exchange_rate: 353.4,
    gift: 'Amazon Voucher',
    gift_amount: 20,
    duration: 1,
    promo_code: '930FG',
    service: {
        logo_url: '/img/western.svg',
        garanty_by: null,
        garanty_by_logo: null,
    },
    payment_method: 'card',
    delivery_method: 'bank',
    company_name: 'western',
},
{
    id: '837474',
    rate: 3.95,
    sent: 500,
    received: 175644.58,
    transferr_fee: 2.99,
    deliver_fee: 3.35,
    from_currency: 'USD',
    to_currency: 'NGN',
    from: 'USA',
    to: 'Nigeria',
    exchange_rate: 353.4,
    gift: 'Amazon Voucher',
    gift_amount: 20,
    duration: 3,
    promo_code: '930FG',
    service: {
        logo_url: '/img/western.svg',
        garanty_by: null,
        garanty_by_logo: null,
    },
    payment_method: 'card',
    delivery_method: 'bank',
    company_name: 'western',
},
{
    id: '837424',
    rate: 3.95,
    sent: 500,
    received: 175644.58,
    transferr_fee: 2.99,
    deliver_fee: 3.35,
    from_currency: 'USD',
    to_currency: 'NGN',
    from: 'Nigeria',
    to: 'USA',
    exchange_rate: 353.4,
    gift: 'Amazon Voucher',
    gift_amount: 20,
    duration: 4,
    promo_code: '930FG',
    service: {
        logo_url: '/img/western.svg',
        garanty_by: null,
        garanty_by_logo: null,
    },
    payment_method: 'card',
    delivery_method: 'bank',
    company_name: 'western',
},
{
    rate: 3.95,
    sent: 500,
    received: 175644.58,
    transferr_fee: 2.99,
    deliver_fee: 3.35,
    from_currency: 'USD',
    to_currency: 'NGN',
    from: 'Canada',
    to: 'Nigeria',
    exchange_rate: 353.4,
    gift: 'Amazon Voucher',
    gift_amount: 20,
    duration: 2,
    promo_code: '930FG',
    service: {
        logo_url: '/img/western.svg',
        garanty_by: null,
        garanty_by_logo: null,
    },
    payment_method: 'card',
    delivery_method: 'bank',
    company_name: 'western',
},
];
