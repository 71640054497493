import api from './index';

const resource = '/countries';

export default {

    /**
     * Get all third party offers
     * @param {Object} params filters
     */
    getAll(params) {
        return api.get(`${resource}`, {
            params,
        });
    },

};
