<template>
    <tr class="default-font tw-text-black tw-cursor-pointer" @click="$emit('click');">
        <td class="py-2">
            <router-link
                :to="{
                    name: 'User',
                    params: {
                        id: item.user.id,
                    },
                }">
                {{ formatUserName(item.user) }}
            </router-link>
        </td>
        <td class="py-2">
            {{ formatCardNumber(item.number) }}
        </td>
        <td class="py-2">
            {{ item.expiry_date }}
        </td>
        <td class="py-2">
            <v-img
                :src="cardTypeLogoUrl"
                height="48"
                max-width="100"
                contain
            />
        </td>
        <td class="py-2">
            <v-alert
                v-if="item.status"
                dense
                outlined
                :type="alertType"
                class="caption my-0">
                {{ item.status.replace(/_/g, ' ') }}
            </v-alert>
        </td>
        <td class="py-2">
            {{ item.resp_message }}
        </td>
        <td class="py-2">
            <v-menu transition="slide-y-transition" bottom offset-y content-class="mt-3 tw-rounded-lg">
                <template v-slot:activator="{ on }">
                    <v-btn
                        color="secondary"
                        dark
                        outlined
                        elevation="0"
                        large
                        class="default-font font-12 text-capitalize justify-space-between"
                        v-on="on">
                        Actions
                        <v-icon x-small class="ml-2">mdi-chevron-down</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item>
                        <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                            <v-btn
                                :disabled="!isAdmin || item.status !== 'charge_verified'"
                                class="text-capitalize default-font"
                                text
                                @click="approve">
                                Accept
                            </v-btn>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                            <v-btn
                                @click="decline"
                                text
                                class="text-capitalize default-font"
                                :disabled="item.status === 'declined'">
                                Cancel
                            </v-btn>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </td>
    </tr>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
        isExpanded: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters([
            'user',
        ]),
        isAdmin() {
            return this.user.role === 'admin';
        },
        cardType() {
            if (!this.item) return null;
            return this.detectCardType(this.item.number);
        },
        cardTypeLogoUrl() {
            if (!this.cardType) return null;
            return `/img/cards/${this.cardType}.png`;
        },
        alertType() {
            if (this.item.status === 'approved') {
                return 'success';
            }

            if (this.item.status === 'declined') {
                return 'error';
            }

            if (this.item.status === 'charge_verified') {
                return 'info';
            }

            return 'warning';
        },
    },
    methods: {
        formatUserName(user) {
            const { firstname, middlename, lastname } = user;
            if (middlename) {
                return `${firstname} ${middlename} ${lastname}`;
            }
            return `${firstname} ${lastname}`;
        },
        formatCardNumber(number) {
            const formattedNumber = String(number).match(/.{1,4}/g);
            return formattedNumber.join(' ');
        },
        detectCardType(cardNumber) {
            const verveDigits = this.range(506099, 506198);
            if (verveDigits.includes(Number(cardNumber.slice(0, 6))) && cardNumber.length >= 16) {
                return 'verve';
            }

            const re = {
                electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
                maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
                dankort: /^(5019)\d+$/,
                interpayment: /^(636)\d+$/,
                unionpay: /^(62|88)\d+$/,
                visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
                mastercard: /^5[1-5][0-9]{14}$/,
                amex: /^3[47][0-9]{13}$/,
                diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
                discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
                jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
            };

            // eslint-disable-next-line no-restricted-syntax
            for (const key in re) {
                if (re[key].test(cardNumber)) {
                    return key;
                }
            }

            return 'dafault';
        },
        range: (start, end) => Array(end - start + 1).fill().map((_, idx) => start + idx),
        approve() {
            if (this.item.status === 'charge_verified') {
                this.$emit('approve');
            }
        },
        decline() {
            this.$emit('decline');
        },
    },
};
</script>
