<template>
    <v-chip
        v-bind="$attrs"
        :color="alertType"
        class="caption">
        {{ statusText }}
    </v-chip>
</template>

<script>
export default {
    props: {
        bank: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        statusText() {
            return this.bank?.status === 'UP' ? 'Healthy' : 'Unhealthy';
        },
        alertType() {
            return this.bank?.status === 'UP' ? 'success' : 'error';
        },
    },
};
</script>
