<template>
    <div>
        <v-select
            :items="filterByItems"
            outlined
            dense
            class="custom-select"
            :value="periodFilterTarget"
            @change="updatePeriodFilterTarget"
            itemValue="value"
            itemText="title"
            placeholder="Filter by"
            :menu-props="{ bottom: true, offsetY: true, 'content-class': 'mt-3 tw-rounded-lg' }"
            :disabled="disabled"
        >
            <template v-slot:selection="{ item }">
                <span class="default-font filter-title tw-text-black mr-2">{{ item.title }}</span>
            </template>
        </v-select>

        <v-select
            :items="items"
            outlined
            dense
            class="custom-select"
            prepend-inner-icon="$calendar"
            :value="periodFilter"
            @change="updatePeriodFilter"
            return-object
            itemText="title"
            :menu-props="{ bottom: true, offsetY: true, 'content-class': 'mt-3 tw-rounded-lg' }"
            :disabled="disabled"
        >
            <template v-slot:selection="{ item }">
                <span class="default-font filter-title tw-text-black mr-2">{{ item.title }}</span>
            </template>
        </v-select>

        <v-dialog
            ref="dialog"
            v-if="periodFilter.value === 'custom'"
            v-model="modal"
            persistent
            width="320px"
        >
            <template v-slot:activator="{ on }">
                <input
                    class="mb-3 f-input default-font pa-2 tw-border-binput focus:tw-border-primary"
                    :value="dateRangeText"
                    label="Date range"
                    placeholder="Date range"
                    readonly
                    v-on="on"
                    :style="{
                        minWidth: '210px',
                        background: 'white',
                    }"
                    :disabled="disabled"
                />
            </template>
            <v-date-picker
                v-model="dates"
                range
                scrollable
                :disabled="disabled"
            >
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="modal = false"
                    :disabled="disabled"
                >
                    Cancel
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="onDateRangeSelected"
                    :disabled="disabled"
                >
                    OK
                </v-btn>
            </v-date-picker>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

export default {
    props: {
        disabled: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        filterByItems: [
            {
                value: 'created_at',
                title: 'Created At',

            },
            {
                value: 'completed_at',
                title: 'Completed At',
            },
            // {
            //     value: 'cancelled_at',
            //     title: 'Cancelled At',
            // },
            // {
            //     value: 'matcher_payment_completed_at',
            //     title: 'Validated at',
            // },
        ],
        items: [
            {
                range: [
                    moment.utc().startOf('day').format('YYYY-MM-DD'),
                    moment.utc().endOf('day').format('YYYY-MM-DD'),
                ],
                value: 'today',
                title: 'Today',
            },
            {
                range: [
                    moment.utc().subtract(1, 'days').startOf('day').format('YYYY-MM-DD'),
                    moment.utc().subtract(1, 'days').endOf('day').format('YYYY-MM-DD'),
                ],
                value: 'yesterday',
                title: 'Yesterday',
            },
            {
                range: [
                    moment.utc().startOf('week').format('YYYY-MM-DD'),
                    moment.utc().endOf('week').format('YYYY-MM-DD'),
                ],
                value: 'this_week',
                title: 'This week',
            },
            {
                range: [
                    moment.utc().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'),
                    moment.utc().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD'),
                ],
                value: 'last_week',
                title: 'Last week',
            },
            {
                range: [
                    moment.utc().startOf('month').format('YYYY-MM-DD'),
                    moment.utc().endOf('month').format('YYYY-MM-DD'),
                ],
                value: 'this_month',
                title: 'This month',
            },
            {
                range: [
                    moment.utc().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
                    moment.utc().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
                ],
                value: 'last_month',
                title: 'Last month',
            },
            {
                range: [],
                value: 'all_time',
                title: 'All time',
            },
            {
                range: [null, null],
                value: 'custom',
                title: 'Custom period',
            },
        ],
        modal: false,
        dates: [null, null],
    }),
    methods: {
        ...mapActions('thirdPartyTransaction', ['updatePeriodFilter', 'updatePeriodFilterTarget']),
        onDateRangeSelected() {
            this.items[7].range = this.dates;
            this.updatePeriodFilter(this.items[7]);
            this.modal = false;
        },
    },
    computed: {
        ...mapGetters('thirdPartyTransaction', ['periodFilter', 'periodFilterTarget']),
        dateRangeText() {
            if (!this.periodFilter) {
                return false;
            }

            const value = this.periodFilter.range.join(' - ');
            return value === ' - ' ? null : value;
        },
    },
    watch: {
        periodFilter() {
            if (this.periodFilter.value === 'custom') {
                this.modal = true;
            }
        },
    },
};
</script>
<style>
.custom-select.v-input--is-label-active .v-select__slot .v-input__append-inner~.v-input__append-inner {
  display: none;
}
.custom-select .v-input__prepend-inner {
  z-index: 1;
}
</style>
