var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"default-font tw-text-black tw-cursor-pointer",on:{"click":function($event){return _vm.$emit('click');}}},[_c('td',{staticClass:"py-2"},[_c('router-link',{attrs:{"to":{
                name: 'User',
                params: {
                    id: _vm.item.user.id,
                },
            }}},[_vm._v(" "+_vm._s(_vm.formatUserName(_vm.item.user))+" ")])],1),_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm.formatCardNumber(_vm.item.number))+" ")]),_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm.item.expiry_date)+" ")]),_c('td',{staticClass:"py-2"},[_c('v-img',{attrs:{"src":_vm.cardTypeLogoUrl,"height":"48","max-width":"100","contain":""}})],1),_c('td',{staticClass:"py-2"},[(_vm.item.status)?_c('v-alert',{staticClass:"caption my-0",attrs:{"dense":"","outlined":"","type":_vm.alertType}},[_vm._v(" "+_vm._s(_vm.item.status.replace(/_/g, ' '))+" ")]):_vm._e()],1),_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm.item.resp_message)+" ")]),_c('td',{staticClass:"py-2"},[_c('v-menu',{attrs:{"transition":"slide-y-transition","bottom":"","offset-y":"","content-class":"mt-3 tw-rounded-lg"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"default-font font-12 text-capitalize justify-space-between",attrs:{"color":"secondary","dark":"","outlined":"","elevation":"0","large":""}},on),[_vm._v(" Actions "),_c('v-icon',{staticClass:"ml-2",attrs:{"x-small":""}},[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{staticClass:"default-font tw-font-normal font-14 tw-text-black-400"},[_c('v-btn',{staticClass:"text-capitalize default-font",attrs:{"disabled":!_vm.isAdmin || _vm.item.status !== 'charge_verified',"text":""},on:{"click":_vm.approve}},[_vm._v(" Accept ")])],1)],1),_c('v-list-item',[_c('v-list-item-title',{staticClass:"default-font tw-font-normal font-14 tw-text-black-400"},[_c('v-btn',{staticClass:"text-capitalize default-font",attrs:{"text":"","disabled":_vm.item.status === 'declined'},on:{"click":_vm.decline}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }