<template>
    <v-select
        :items="items"
        outlined
        dense
        class="custom-select"
        :value="paymentTypeFilter"
        @change="updatePaymentTypeFilter"
        itemValue="value"
        itemText="title"
        placeholder="Any type"
        :menu-props="{ bottom: true, offsetY: true, 'content-class': 'mt-3 tw-rounded-lg' }"
        multiple
        clearable
    >
        <template v-slot:selection="{ item }">
            <span class="default-font filter-title tw-text-black mr-2">{{ item.title }}</span>
        </template>
    </v-select>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    data() {
        return {
            items: [
                {
                    value: 'admin',
                    title: 'Admin',
                },
                {
                    value: 'mono',
                    title: 'Mono',
                },
                {
                    value: 'flutterwave',
                    title: 'Flutterwave',
                },
            ],
        };
    },
    methods: {
        ...mapActions('transaction', ['updatePaymentTypeFilter']),
    },
    computed: {
        ...mapGetters('transaction', ['paymentTypeFilter']),
    },
};
</script>
