import api from './index';
import ReferralTransaction from '../models/ReferralTransaction';

const resource = 'admin/referral-transactions';

export default {
    /**
     * Get all Referral Transactions
     * @param {Object} params query params
     */
    async getAll(params) {
        const { data } = await api.get(`${resource}`, {
            params,
        });
        const { models, pagination } = data;
        return {
            models: models.map((item) => new ReferralTransaction(item)),
            pagination,
        };
    },

    /**
     * Approve and initiate Referral Transaction
     * @param {String} id Referral Transaction ID
     */
    approve(id) {
        return api.post(`${resource}/${id}/approve`);
    },

    /**
     * Cancel Referral Transaction
     * @param {String} id Referral Transaction ID
     */
    cancel(id) {
        return api.post(`${resource}/${id}/cancel`);
    },

    /**
     * Complete Referral Transaction
     * @param {String} id Referral Transaction ID
     */
    complete(id) {
        return api.post(`${resource}/${id}/complete`);
    },
};
