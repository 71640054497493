var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-w-full d-flex flex-column justify-center"},[_c('v-data-table',{staticClass:"pb-4 tw-bg-transparent custom-table table-space px-5",attrs:{"headers":_vm.headers,"items":_vm.billings,"page":_vm.page,"item-key":"date","items-per-page":_vm.itemsPerPage,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('profile-row',{attrs:{"item":item,"headers":headers},scopedSlots:_vm._u([{key:"date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"billing__font"},[_vm._v(_vm._s(_vm._f("date")(item.date))+" at "+_vm._s(_vm._f("time")(item.date)))])]}},{key:"amount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"billing__font justify-start"},[_vm._v(" USD "),_c('span',{staticClass:"billing__font tw-font-bold"},[_vm._v(_vm._s(_vm._f("price")(item.amount)))])])]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"text-capitalize default-font tw-text-sm",attrs:{"text":"","color":"primary"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"large":""}},[_vm._v("$export")]),_vm._v(" Download PDF receipt"+_vm._s(item.action)+" ")],1)]}}],null,true)})]}}])}),_c('div',{staticClass:"text-center pt-12"},[_c('v-flex',{attrs:{"justify-center":""}},[_c('v-pagination',{attrs:{"next-icon":"$next","prev-icon":"$prev","color":"#7598B5","length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }