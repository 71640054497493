<template>
  <v-container class="tw-w-full  md:tw-w-2/3">
    <v-row class="mx-auto bank--block tw-bg-white">
      <v-col cols="3" lg="4" class="steps d-flex flex-column md:tw-px-10 lg:tw-px-10 tw-py-10" >
          <h2 class="title-font mb-10 ml-2"> Complete registration </h2>
          <stepper :steps="steps"></stepper>
      </v-col>
      <v-col cols="9" lg="8" class="d-flex flex-column md:tw-px-48 lg:tw-px-20 tw-py-10">
        <span class="step--text title-font">Step 3 of 4</span>
        <h2 class="text--title mb-10">Billing info</h2>
        <form>
          <v-row>
            <v-col cols="12" class="d-flex flex-column pa-2">
              <label class="f-label default-font">Contact:</label>
              <input
                type="text"
                class="f-input default-font pa-2 tw-border-binput focus:tw-border-primary"
              />
            </v-col>
            <v-col cols="12" class="d-flex flex-column pa-2">
              <label class="f-label default-font">Phone:</label>
              <input type="phone" class="f-input default-font pa-2 tw-border-binput focus:tw-border-primary" />
            </v-col>
            <v-col cols="12" class="d-flex justify-space-between">
              <v-btn
                id="signBtn"
                text
                large
                class="default-font text-capitalize text-center mt-12 tw-text-black"
                to="/about-bank"
              >
              <v-icon>mdi-reply</v-icon>
              Back
              </v-btn>
              <v-btn
                color="primary"
                large
                class="default-font text-capitalize text-center mt-12 px-8"
                to="/licence"
              >Continue</v-btn>
            </v-col>
          </v-row>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Stepper from '../globals/Stepper.vue';

export default {
    data() {
        return {
            e6: 1,
            steps: [
                {
                    label: 'E-mail & Password',
                    state: 'checked',
                    step: 1,
                },
                {
                    label: 'About bank',
                    state: 'checked',
                    step: 2,
                },
                {
                    label: 'Billing',
                    state: 'active',
                    step: 3,
                },
                {
                    label: 'Licensure and compliance',
                    state: 'default',
                    step: 4,
                },
            ],
        };
    },
    components: { Stepper },
};
</script>
