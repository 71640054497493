<template>
    <v-dialog
        v-bind="$attrs"
        v-on="$listeners"
        max-width="700">

        <v-card
            class="tw-rounded-lg px-5 pb-5 default-font tw-bg-white align-self-center"
            flat>
            <v-card-title>
                <v-spacer></v-spacer>
                <v-btn
                    color="secondary"
                    large
                    dark
                    icon
                    @click="$emit('input', false)">
                    <v-icon dark>$delete</v-icon>
                </v-btn>
            </v-card-title>

            <v-form
                @submit.prevent="submit">
                <v-card-text>
                    <v-row class="align-content-center">
                        <v-col>
                            Provider: {{ thirdPartyCompanyName }}
                        </v-col>
                        <v-col>
                            Matcher Amount: {{ innerHybridOrderOption.matcher_amount }}
                        </v-col>
                    </v-row>
                    <v-row class="align-content-center">
                        <v-col>
                            Poster Beneficiary Currency: {{ innerHybridOrderOption.pb_currency }}
                        </v-col>
                        <v-col>
                            Matcher Currency: {{ innerHybridOrderOption.matcher_currency }}
                        </v-col>
                    </v-row>
                    <v-row class="align-content-center">
                        <v-col>
                            Poster Beneficiary Country: {{ innerHybridOrderOption.pb_country }}
                        </v-col>
                        <v-col>
                            Matcher County: {{ innerHybridOrderOption.matcher_country }}
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <!-- EXCHANGE RATE -->
                            <v-text-field
                                v-model="innerHybridOrderOption.exchange_rate"
                                label="Exchange rate"
                                class="form-control"
                                type="number"
                                step="2"
                                name="exchange_rate"
                                persistent-hint
                                outlined
                            />
                        </v-col>

                        <v-col>
                            <!-- FEE -->
                            <v-text-field
                                v-model.number="innerHybridOrderOption.fee"
                                label="Fee"
                                class="form-control"
                                type="number"
                                step="2"
                                name="fee"
                                persistent-hint
                                outlined
                            />
                        </v-col>

                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="primary"
                        type="submit"
                        depressed
                        dark>
                        Save
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>

    </v-dialog>
</template>

<script>
/* eslint-disable camelcase */
import { cloneDeep } from 'lodash';

export default {
    props: {
        hybridOrderOption: {
            type: Object,
            require: true,
        },
    },
    data: () => ({
        innerHybridOrderOption: {},
    }),
    computed: {
        thirdPartyCompanyName() {
            const company = this.innerHybridOrderOption.thirdPartyCompany;
            return company ? company.company.name : '';
        },
    },
    methods: {
        submit() {
            this.$emit('save', this.innerHybridOrderOption);
        },
    },
    watch: {
        hybridOrderOption: {
            deep: true,
            handler(val) {
                this.$set(this, 'innerHybridOrderOption', cloneDeep(val));
            },
        },
    },
};
</script>
