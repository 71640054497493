var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column"},[_c('v-data-table',{staticClass:"py-4 tw-bg-transparent custom-table table-space",attrs:{"headers":_vm.headers,"items":_vm.ipAddress,"item-key":"endpoints","page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","hide-default-header":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"pl-0 pr-0",attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',{staticClass:"font-style font-dark-bold-head-15"},[_vm._v("Ip address")]),_c('v-spacer'),_c('whitelist-ip-add-modal',{attrs:{"events":_vm.events}})],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('developer-row',{attrs:{"item":item,"headers":headers},scopedSlots:_vm._u([{key:"ipAddress",fn:function(ref){
var item = ref.item;
var header = ref.header;
return [_c('div',{staticClass:"d-flex flex-column ml-6"},[_c('span',{staticClass:"font-style font-light-13"},[_vm._v(_vm._s(header.text))]),_c('span',{staticClass:"font-style font-dark-bold-15 mt-6"},[_vm._v(_vm._s(item.ipAddress))]),_c('span',{staticClass:"font-style font-light-13"},[_vm._v(_vm._s(_vm._f("date")(item.createdAt))+" at "+_vm._s(_vm._f("time")(item.createdAt)))])])]}},{key:"events",fn:function(ref){
var item = ref.item;
var header = ref.header;
return [_c('div',{staticClass:"d-flex flex-column tw-text-black"},[_c('span',{staticClass:"font-style font-light-13"},[_vm._v(_vm._s(header.text))]),_c('span',{staticClass:"font-style font-dark-bold-15 mt-6"},[_vm._v(_vm._s(item.events))])])]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"default-font font-12 text-capitalize mr-6",class:{'completed': _vm.isCompleted(item.events)},attrs:{"color":_vm.actionColor(item.events),"outlined":!_vm.isCompleted(item.events),"elevation":"0"},on:{"click":function($event){$event.stopPropagation();_vm.dialog1 = true}}},[_vm._v("Delete")]),_c('v-dialog',{attrs:{"max-width":"550"},model:{value:(_vm.dialog1),callback:function ($$v) {_vm.dialog1=$$v},expression:"dialog1"}},[_c('v-card',{staticClass:"modal-style pt-12 pb-8"},[_c('v-card-text',{staticClass:"pb-n1"},[_c('v-container',{staticClass:"headline-style text-center"},[_c('div',{staticClass:"tw-text-secondary"},[_vm._v(" Are you sure, you want to delete ")]),_c('div',[_vm._v(" "+_vm._s(item.ipAddress)+" "),_c('span',{staticClass:"tw-text-secondary"},[_vm._v("address?")])])])],1),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{staticClass:"default-font font-12 delete-btn text-capitalize mr-3 px-6",attrs:{"text":"","elevation":"0"},on:{"click":_vm.deleteIpAddress}},[_vm._v("Delete IP address")]),_c('v-btn',{staticClass:"default-font font-12 cancel-btn text-capitalize px-6",attrs:{"text":"","elevation":"0"},on:{"click":function($event){_vm.dialog1=false}}},[_vm._v("Cancel")])],1)],1)],1)]}}],null,true)})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v("Peek-a-boo!")])]}}])}),_c('v-pagination',{staticClass:"custom-pagination",attrs:{"next-icon":"$next","prev-icon":"$prev","color":"#7598B5","length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }