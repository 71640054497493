/* eslint-disable camelcase */
import Model from './Model';

class HybridOrderOptionLog extends Model {
    static fields() {
        return {
            exchange_rate: Number,
            id: Number,
            fee: Number,
            matcher_amount: Number,
            matcher_country: String,
            matcher_currency: String,
            pb_country: String,
            pb_currency: String,
            third_party_company_id: Number,
            thirdPartyCompany: Object,
            user: Object,
            created_at: String,
        };
    }

    /**
     * Get Exchange Rate with currencies
     */
    get exchangeRateFormatted() {
        const {
            exchange_rate: exchangeRate,
            pb_currency: pbCurrency,
            matcher_currency: matcherCurrency,
        } = this;

        if (!exchangeRate) {
            return null;
        }

        if (!pbCurrency || !matcherCurrency) {
            return exchangeRate;
        }

        return `${exchangeRate} ${pbCurrency}/${matcherCurrency}`;
    }

    /**
     * Get Matcher Amount with currency
     */
    get matcherAmountFormatted() {
        return this.matcher_amount ? `${this.matcher_amount}` : this.matcher_amount;
    }
}

export default HybridOrderOptionLog;
