<template>
  <v-container class="key-content">
    <v-row no-gutters>
      <v-col cols="8" sm="8">
        <v-row>
          <v-col cols="12" sm="6" class="font-style font-dark-bold-15">Keys</v-col>
        </v-row>
        <v-row>
          <v-col cols="3" sm="3" class="font-style font-dark-15 key-item-margin">User id</v-col>
          <v-col cols="9" sm="9" class>
            <v-text-field
              solo
              label="Append"
              background-color="#F7FBFC"
              v-model="apiKeys.user.user_id"
              readonly
              class="input-style font-style font-dark-15"
            >
              <template slot="append">
                <v-btn icon @click="copyToClipboard(apiKeys.user.user_id)">
                  <v-icon small class="mr-1">$copy</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-5">
      <v-col cols="8" sm="8">
        <v-row>
          <v-col cols="12" sm="6" class="font-style font-dark-bold-15">Secrets</v-col>
        </v-row>
        <v-row>
          <v-col cols="3" sm="3" class="font-style font-dark-15 key-item-margin">Development</v-col>
          <v-col cols="9" sm="9" class>
            <v-text-field
              solo
              label="Append"
              v-model="apiKeys.development.secret"
              background-color="#F7FBFC"
              readonly
              class="input-style red--text"
              :type="showDev ? 'text': 'password'"
            >
              <template slot="append">
                <v-btn icon>
                  <v-icon small class="mr-1">$refresh</v-icon>
                </v-btn>
                <v-btn icon @click="showDev = !showDev">
                  <v-icon small class="mr-1">$eye</v-icon>
                </v-btn>
                <v-btn icon @click="copyToClipboard(apiKeys.development.secret)">
                  <v-icon small class="mr-1">$copy</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" sm="3" class="font-style font-dark-15 key-item-margin">Sandbox</v-col>
          <v-col cols="9" sm="9" class>
            <v-text-field
              solo
              label="Append"
              v-model="apiKeys.sandbox.secret"
              background-color="#F7FBFC"
              readonly
              class="input-style tw-text-secondary"
              :type="showSandBox ? 'text': 'password'"
            >
              <template slot="append">
                <v-btn icon>
                  <v-icon small class="mr-1">$refresh</v-icon>
                </v-btn>
                <v-btn icon @click="showSandBox = !showSandBox">
                  <v-icon small class="mr-1">$eye</v-icon>
                </v-btn>
                <v-btn icon @click="copyToClipboard(apiKeys.sandbox.secret)">
                  <v-icon small class="mr-1">$copy</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters class="my-12">
      <v-col cols="8" sm="8">
        <v-row class="request-border-style">
          <v-col
            cols="8"
            sm="8"
            class="font-style font-dark-bold-15 key-item-margin text-center"
          >Get production access to use 100 items in Development</v-col>
          <v-col cols="4" sm="4" class="text-center key-title">
            <router-link to="/developer-request">
              <v-btn
                color="primary"
                outlined
                dark
                class="mb-2 default-font font-12 text-capitalize"
              >Request Access</v-btn>
            </router-link>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { apiKeys } from '@/utils/Developer/api-keys';
import { copyToClipboard } from '@/utils';

export default {
    data() {
        return {
            page: 1,
            pageCount: 0,
            itemsPerPage: 5,
            showDev: false,
            showSandBox: false,
            apiKeys,
        };
    },
    methods: {
        statusColor(status) {
            switch (status) {
            case '200 OK':
                return { bg: '#C5F5E7', color: '#15ABAB' };
            default:
                return { bg: '#FFE3E2', color: '#F07877' };
            }
        },
        copyToClipboard,
    },
};
</script>
<style>

</style>
