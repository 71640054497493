import { render, staticRenderFns } from "./Default.vue?vue&type=template&id=4161f856&scoped=true&"
import script from "./Default.vue?vue&type=script&lang=js&"
export * from "./Default.vue?vue&type=script&lang=js&"
import style0 from "./Default.vue?vue&type=style&index=0&id=4161f856&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4161f856",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VApp,VBadge,VIcon,VImg,VList,VListItem,VListItemAvatar,VMain,VNavigationDrawer,VSlideYTransition,VTooltip})
