export default {
    methods: {
        async copyToClipboard(text, successMessage = '') {
            try {
                await navigator.clipboard.writeText(text);
                this.$swal({
                    type: 'success',
                    title: successMessage || 'Copied to clipboard!',
                });
            } catch (err) {
                const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
                    || navigator.userAgent.match(/ipad|iphone/i);
                if (isSafari) {
                    this.copyOnSafari(text, successMessage);
                    return;
                }

                this.$swal({
                    type: 'error',
                    title: 'Unsuccessful',
                });
            }
        },
        copyOnSafari(text, successMessage) {
            const textArea = document.createElement('textarea');
            textArea.value = text;
            textArea.style.display = 'none';

            document.body.appendChild(textArea);
            textArea.select();

            try {
                const successful = document.execCommand('copy');
                if (successful) {
                    this.$swal({
                        type: 'success',
                        title: successMessage || 'Copied to clipboard!',
                    });
                }
            } catch (e) {
                this.$swal({
                    type: 'error',
                    title: 'Unsuccessful',
                });
            }

            document.body.removeChild(textArea);
        },
    },
};
