/* eslint-disable no-undef */
/* eslint-disable no-restricted-properties */
/* eslint-disable no-restricted-globals */
export const transactions = [{
    id: 345847,
    receiver: 'Prince Smith',
    sender: 'John Smith',
    email: 'johnsmith@gmail.com',
    sent: 178346,
    total: 184500,
    subtotal: 180000,
    fee: 4500,
    fee_percent: 2.5,
    received: 500,
    rate: 357.5,
    sender_fee: 1.56,
    deliver_fee: 3.35,
    start_date: '20-10-19 19:23:10',
    end_date: '22-10-19 5:23:10',
    from_currency: 'NGN',
    to_currency: 'USD',
    status: 'completed',
    from_local: 'ng',
    to_local: 'us',
    from_country: 'Nigeria',
    to_country: 'USA',
    from_bank: 'Africa Bank',
    to_bank: 'Zelle',
    to_bank_receiver: 'Mathew Soto',
    from_bank_receiver: 'Keith Soto',
    reciverPerson: {
        name: 'Prince Smith',
        gender: 'Male',
        phone: '+1 212-475-5009',
        address: '3718 37 W 20th St #610, New York, NY 10011',
        birth_date: '10/05/1990',
        national_Id: '4436834',
    },
    senderPerson: {
        name: 'Prince Smith',
        gender: 'Male',
        phone: '+1 212-475-5009',
        address: '3718 37 W 20th St #610, New York, NY 10011',
        birth_date: '10/05/1990',
        national_Id: '4436834',
    },
    from_bank_receiver_person: {
        name: 'Mathew Soto',
        gender: 'Male',
        phone: '+1 212-475-5009',
        address: '3718 37 W 20th St #610, New York, NY 10011',
        birth_date: '10/05/1990',
        national_Id: '4436834',
    },
    to_bank_receiver_person: {
        name: 'Keith Soto',
        gender: 'Male',
        phone: '+1 212-475-5009',
        address: '3718 37 W 20th St #610, New York, NY 10011',
        birth_date: '10/05/1990',
        national_Id: '4436834',
    },
},
{
    sender: 'James Wood',
    receiver: 'Prince Smith',
    id: '356547',
    email: 'johnsmith@gmail.com',
    sent: 946346,
    total: 960000,
    subtotal: 955000,
    fee: 4500,
    fee_percent: 2.5,
    received: 2300,
    rate: 357.5,
    sender_fee: 1.56,
    deliver_fee: 3.35,
    start_date: '20-10-19 5:23:10',
    end_date: '22-10-19 5:23:10',
    from_currency: 'NGN',
    to_currency: 'EUR',
    status: 'completed',
    from_local: 'ng',
    to_local: 'en',
    from_country: 'Nigeria',
    to_country: 'England',
    from_bank: 'Africa Bank',
    to_bank: 'Zelle',
    to_bank_receiver: 'Mathew Soto',
    from_bank_receiver: 'Keith Soto',
    reciverPerson: {
        name: 'Prince Smith',
        gender: 'Male',
        phone: '+1 212-475-5009',
        address: '3718 37 W 20th St #610, New York, NY 10011',
        birth_date: '10/05/1990',
        national_Id: '4436834',
    },
    senderPerson: {
        name: 'Prince Smith',
        gender: 'Male',
        phone: '+1 212-475-5009',
        address: '3718 37 W 20th St #610, New York, NY 10011',
        birth_date: '10/05/1990',
        national_Id: '4436834',
    },
    from_bank_receiver_person: {
        name: 'Mathew Soto',
        gender: 'Male',
        phone: '+1 212-475-5009',
        address: '3718 37 W 20th St #610, New York, NY 10011',
        birth_date: '10/05/1990',
        national_Id: '4436834',
    },
    to_bank_receiver_person: {
        name: 'Keith Soto',
        gender: 'Male',
        phone: '+1 212-475-5009',
        address: '3718 37 W 20th St #610, New York, NY 10011',
        birth_date: '10/05/1990',
        national_Id: '4436834',
    },
},
{
    sender: 'Carolyn Reyes',
    receiver: 'Prince Smith',
    id: '579894',
    email: 'johnsmith@gmail.com',
    sent: 24346,
    total: 27500,
    subtotal: 25000,
    fee: 4500,
    fee_percent: 2.5,
    received: 34,
    rate: 357.5,
    sender_fee: 1.56,
    deliver_fee: 3.35,
    start_date: '20-10-19 5:23:10',
    end_date: '22-10-19 5:23:10',
    from_currency: 'NGN',
    to_currency: 'USD',
    status: 'pending',
    from_local: 'ng',
    to_local: 'us',
    from_country: 'Nigeria',
    to_country: 'USA',
    from_bank: 'Africa Bank',
    to_bank: 'Zelle',
    to_bank_receiver: 'Mathew Soto',
    from_bank_receiver: 'Keith Soto',
    reciverPerson: {
        name: 'Prince Smith',
        gender: 'Male',
        phone: '+1 212-475-5009',
        address: '3718 37 W 20th St #610, New York, NY 10011',
        birth_date: '10/05/1990',
        national_Id: '4436834',
    },
    senderPerson: {
        name: 'Prince Smith',
        gender: 'Male',
        phone: '+1 212-475-5009',
        address: '3718 37 W 20th St #610, New York, NY 10011',
        birth_date: '10/05/1990',
        national_Id: '4436834',
    },
    from_bank_receiver_person: {
        name: 'Mathew Soto',
        gender: 'Male',
        phone: '+1 212-475-5009',
        address: '3718 37 W 20th St #610, New York, NY 10011',
        birth_date: '10/05/1990',
        national_Id: '4436834',
    },
    to_bank_receiver_person: {
        name: 'Keith Soto',
        gender: 'Male',
        phone: '+1 212-475-5009',
        address: '3718 37 W 20th St #610, New York, NY 10011',
        birth_date: '10/05/1990',
        national_Id: '4436834',
    },
},
{
    id: '388510',
    sender: 'Keith Soto',
    receiver: 'Prince Smith',
    email: 'johnsmith@gmail.com',
    sent: 1120346,
    total: 1150000,
    subtotal: 180000,
    fee: 4500,
    fee_percent: 2.5,
    received: 1500,
    rate: 357.5,
    sender_fee: 1.56,
    deliver_fee: 3.35,
    start_date: '20-10-19 5:23:10',
    end_date: '22-10-19 5:23:10',
    from_currency: 'NGN',
    to_currency: 'CAD',
    status: 'completed',
    from_local: 'ng',
    to_local: 'ca',
    from_country: 'Nigeria',
    to_country: 'Canada',
    from_bank: 'Africa Bank',
    to_bank: 'Zelle',
    to_bank_receiver: 'Mathew Soto',
    from_bank_receiver: 'Keith Soto',
    reciverPerson: {
        name: 'Prince Smith',
        gender: 'Male',
        phone: '+1 212-475-5009',
        address: '3718 37 W 20th St #610, New York, NY 10011',
        birth_date: '10/05/1990',
        national_Id: '4436834',
    },
    senderPerson: {
        name: 'Prince Smith',
        gender: 'Male',
        phone: '+1 212-475-5009',
        address: '3718 37 W 20th St #610, New York, NY 10011',
        birth_date: '10/05/1990',
        national_Id: '4436834',
    },
    from_bank_receiver_person: {
        name: 'Mathew Soto',
        gender: 'Male',
        phone: '+1 212-475-5009',
        address: '3718 37 W 20th St #610, New York, NY 10011',
        birth_date: '10/05/1990',
        national_Id: '4436834',
    },
    to_bank_receiver_person: {
        name: 'Keith Soto',
        gender: 'Male',
        phone: '+1 212-475-5009',
        address: '3718 37 W 20th St #610, New York, NY 10011',
        birth_date: '10/05/1990',
        national_Id: '4436834',
    },
},
{
    id: '199385',
    sender: 'Rose Dunn',
    receiver: 'Prince Smith',
    email: 'johnsmith@gmail.com',
    sent: 356346,
    total: 365000,
    subtotal: 180000,
    fee: 4500,
    fee_percent: 2.5,
    received: 1200,
    rate: 368.5,
    sender_fee: 1.56,
    deliver_fee: 3.35,
    start_date: '20-10-19 5:23:10',
    end_date: '22-10-19 5:23:10',
    from_currency: 'NGN',
    to_currency: 'EUR',
    status: 'cancelled',
    from_local: 'ng',
    to_local: 'en',
    from_country: 'Nigeria',
    to_country: 'England',
    from_bank: 'Africa Bank',
    to_bank: 'Zelle',
    to_bank_receiver: 'Mathew Soto',
    from_bank_receiver: 'Keith Soto',
    reciverPerson: {
        name: 'Prince Smith',
        gender: 'Male',
        phone: '+1 212-475-5009',
        address: '3718 37 W 20th St #610, New York, NY 10011',
        birth_date: '10/05/1990',
        national_Id: '4436834',
    },
    senderPerson: {
        name: 'Prince Smith',
        gender: 'Male',
        phone: '+1 212-475-5009',
        address: '3718 37 W 20th St #610, New York, NY 10011',
        birth_date: '10/05/1990',
        national_Id: '4436834',
    },
    from_bank_receiver_person: {
        name: 'Mathew Soto',
        gender: 'Male',
        phone: '+1 212-475-5009',
        address: '3718 37 W 20th St #610, New York, NY 10011',
        birth_date: '10/05/1990',
        national_Id: '4436834',
    },
    to_bank_receiver_person: {
        name: 'Keith Soto',
        gender: 'Male',
        phone: '+1 212-475-5009',
        address: '3718 37 W 20th St #610, New York, NY 10011',
        birth_date: '10/05/1990',
        national_Id: '4436834',
    },
},
{
    id: '643556',
    sender: 'Barbara Obrien',
    receiver: 'Prince Smith',
    email: 'johnsmith@gmail.com',
    sent: 178346,
    total: 185000,
    received: 500,
    subtotal: 180000,
    fee: 4500,
    fee_percent: 2.5,
    rate: 357.5,
    sender_fee: 1.56,
    deliver_fee: 3.35,
    start_date: '20-10-19 5:23:10',
    end_date: '22-10-19 5:23:10',
    from_currency: 'CAD',
    to_currency: 'USD',
    status: 'completed',
    from_local: 'ca',
    to_local: 'us',
    from_country: 'Canada',
    to_country: 'USA',
    from_bank: 'Canada Bank',
    to_bank: 'Zelle',
    to_bank_receiver: 'Mathew Soto',
    from_bank_receiver: 'Keith Soto',
    reciverPerson: {
        name: 'Prince Smith',
        gender: 'Male',
        phone: '+1 212-475-5009',
        address: '3718 37 W 20th St #610, New York, NY 10011',
        birth_date: '10/05/1990',
        national_Id: '4436834',
    },
    senderPerson: {
        name: 'Prince Smith',
        gender: 'Male',
        phone: '+1 212-475-5009',
        address: '3718 37 W 20th St #610, New York, NY 10011',
        birth_date: '10/05/1990',
        national_Id: '4436834',
    },
    from_bank_receiver_person: {
        name: 'Mathew Soto',
        gender: 'Male',
        phone: '+1 212-475-5009',
        address: '3718 37 W 20th St #610, New York, NY 10011',
        birth_date: '10/05/1990',
        national_Id: '4436834',
    },
    to_bank_receiver_person: {
        name: 'Keith Soto',
        gender: 'Male',
        phone: '+1 212-475-5009',
        address: '3718 37 W 20th St #610, New York, NY 10011',
        birth_date: '10/05/1990',
        national_Id: '4436834',
    },
},
{
    id: '837424',
    sender: 'Johnny Allen',
    receiver: 'Prince Smith',
    email: 'johnsmith@gmail.com',
    sent: 178346,
    total: 185000,
    subtotal: 180000,
    fee: 4500,
    fee_percent: 2.5,
    received: 500,
    rate: 357.5,
    sender_fee: 1.56,
    deliver_fee: 3.35,
    start_date: '20-10-19 5:23:10',
    end_date: '22-10-19 5:23:10',
    from_currency: 'NGN',
    to_currency: 'USD',
    duration: '0-1 days',
    status: 'completed',
    from_local: 'ng',
    to_local: 'us',
    from_country: 'Nigeria',
    to_country: 'USA',
    from_bank: 'Africa Bank',
    to_bank: 'Zelle',
    to_bank_receiver: 'Mathew Soto',
    from_bank_receiver: 'Keith Soto',
    reciverPerson: {
        name: 'Prince Smith',
        gender: 'Male',
        phone: '+1 212-475-5009',
        address: '3718 37 W 20th St #610, New York, NY 10011',
        birth_date: '10/05/1990',
        national_Id: '4436834',
    },
    senderPerson: {
        name: 'Prince Smith',
        gender: 'Male',
        phone: '+1 212-475-5009',
        address: '3718 37 W 20th St #610, New York, NY 10011',
        birth_date: '10/05/1990',
        national_Id: '4436834',
    },
    from_bank_receiver_person: {
        name: 'Mathew Soto',
        gender: 'Male',
        phone: '+1 212-475-5009',
        address: '3718 37 W 20th St #610, New York, NY 10011',
        birth_date: '10/05/1990',
        national_Id: '4436834',
    },
    to_bank_receiver_person: {
        name: 'Keith Soto',
        gender: 'Male',
        phone: '+1 212-475-5009',
        address: '3718 37 W 20th St #610, New York, NY 10011',
        birth_date: '10/05/1990',
        national_Id: '4436834',
    },
},
];

export const headers = [{
    text: 'Sender',
    align: 'center',
    class: 'tw-text-black default-font py-2 text-start tw-rounded-lg tw-rounded-r-none first_cell',
    value: 'sender',
},
{
    text: 'Sent',
    align: 'end',
    value: 'sent',
    sortable: false,
    class: 'tw-text-black default-font py-2 text-end ',
},
{
    text: 'Received',
    align: 'end',
    value: 'received',
    sortable: false,
    class: 'tw-text-black default-font py-2 text-end ',
},
{
    text: 'Rate',
    align: 'end',
    value: 'rate',
    sortable: false,
    class: 'tw-text-black default-font py-2 text-end ',
},
{
    text: 'Sender Fee',
    align: 'end',
    value: 'sender_fee',
    sortable: false,
    class: 'tw-text-black default-font py-2 text-end',
},
{
    text: 'Deliver Fee',
    align: 'end',
    value: 'deliver_fee',
    sortable: false,
    class: 'tw-text-black default-font py-2 text-end ',
},
{
    text: 'Start Date',
    align: 'start',
    value: 'start_date',
    class: 'tw-text-secondary default-font py-2 text-start',
},
{
    text: 'End Date',
    align: 'start',
    value: 'end_date',
    class: 'tw-text-secondary default-font py-2 text-start',
},
{
    text: 'Status',
    align: 'center',
    value: 'status',
    sortable: true,
    class: 'tw-text-secondary default-font py-2 text-center',
},
{
    align: 'center',
    value: 'action',
    sortable: false,
    class: 'default-font py-2 text-center last_cell tw-rounded-lg tw-rounded-l-none',
},
];

function toFixedFix(n, prec) {
    const k = Math.pow(10, prec);
    return `${Math.round(n * k) / k}`;
}

export function numberFormat(number, decimals, decPoint, thousandsSep) {
    // Strip all characters but numerical ones.
    const fNumber = (`${number}`).replace(/[^0-9+\-Ee.]/g, '');
    const n = !isFinite(+fNumber) ? 0 : +fNumber;
    const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
    const sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep;
    const dec = (typeof decPoint === 'undefined') ? '.' : decPoint;
    let s = '';
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : `${Math.round(n)}`).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

export const JSONtoCSV = (arr, columns, delimiter = ',') => [
    columns.join(delimiter),
    ...arr.map((obj) => columns.reduce(
        (acc, key) => `${acc}${!acc.length ? '' : delimiter}"${!obj[key] ? '' : obj[key]}"`,
        '',
    )),
].join('\n');

export const groupBy = (arr, fn) => arr.map(typeof fn === 'function' ? fn : (val) => val[fn]).reduce((acc, val, i) => {
    acc[val] = (acc[val] || []).concat(arr[i]);
    return acc;
}, {});

export const copyToClipboard = (str) => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
    }
};
