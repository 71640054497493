<template>
  <div class="tw-w-full d-flex justify-center">
    <form class="tw-w-2/3">
            <v-row tw>
              <v-col cols="12" class="d-flex flex-column">
                <label class="f-label default-font mb-1">Bank license:</label>
                <upload-input :types="bankLicense.types" v-model="bankLicense.file"></upload-input>

              </v-col>
              <v-col cols="12" class="d-flex flex-column">
                <label class="f-label default-font mb-1">Income Statement:</label>
                <upload-input :types="incomeDoc.types" v-model="incomeDoc.file"></upload-input>

              </v-col>
              <v-col cols="12" class="d-flex flex-column pa-2 mt-4">
                <label class="f-label default-font mb-1">Registration document:</label>
                <upload-input :types="registrationDoc.types" v-model="registrationDoc.file"></upload-input>
              </v-col>
              <v-col cols="12" class="mt-8">
                <v-btn
                  color="primary"
                  id="signBtn"
                  class="default-font text-capitalize text-center px-5"
                  to="/about-bank"
                >Save change</v-btn>
              </v-col>
            </v-row>
          </form>
  </div>
</template>
<script>
import UploadInput from '../globals/UplaodInput.vue';

const defaultTypes = ['png', 'pdf', 'jpg'];

export default {
    components: { UploadInput },
    data() {
        return {
            registrationDoc: {
                types: defaultTypes,
                file: undefined,
            },
            incomeDoc: {
                types: defaultTypes,
                file: undefined,
            },
            bankLicense: {
                types: defaultTypes,
                file: undefined,
            },
        };
    },
};
</script>
