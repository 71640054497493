<template>
    <v-app-bar flat color="white" class="px-8">
        <v-btn
            v-if="backBtnRoute"
            :to="backBtnRoute"
            color="dark-black"
            class="default-font text-capitalize text-center mr-4"
            icon>
            <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <slot name="title"> <v-toolbar-title>Portail</v-toolbar-title> </slot>
        <div class="flex">
            <slot></slot>
        </div>
        <div>
            <v-avatar>
                <v-img src="../../assets/profile.png"></v-img>
            </v-avatar>
            <v-btn text class="text-capitalize tw-text-black default-font tw-bg-white" to="/profile">
                Profile
                <v-icon small>$vuetify.icons.left-mark</v-icon>
            </v-btn>
        </div>
    </v-app-bar>
</template>

<script>

export default {
    props: {
        backBtnRoute: {
            type: Object,
            required: false,
        },
    },
};
</script>
<style scoped>
.v-btn::before{
    background-color: white;
}
</style>
