import api from './index';

const resource = 'admin/card-verification';

export default {
    getAll(params) {
        return api.get(`${resource}`, {
            params,
        });
    },

    approve(id) {
        return api.post(`${resource}/approve/${id}`);
    },

    decline(id) {
        return api.post(`${resource}/decline/${id}`);
    },
};
