<template>
    <v-select
        v-model="paymentProviderFilter"
        :items="items"
        :menu-props="{
            bottom: true,
            offsetY: true,
            'content-class': 'mt-3 tw-rounded-lg',
        }"
        :disabled="disabled"
        class="custom-select"
        clearable
        multiple
        outlined
        dense

    >

        <template #selection="{ item }">
            <span class="default-font filter-title tw-text-black mr-2">
                {{ item.text }}
            </span>
        </template>

    </v-select>
</template>

<script>
export default {
    props: {
        disabled: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        paymentProviderFilter: {
            get() {
                return this.$store.state.transaction.paymentProviderFilter;
            },
            set(val) {
                this.$store.dispatch('transaction/updatePaymentProviderFilter', val);
            },
        },
        items() {
            return [
                {
                    text: 'Worldremit',
                    value: 'worldremit',
                    disabled: this.disabled,
                },
                {
                    text: 'Remitly',
                    value: 'remitly',
                    disabled: this.disabled,
                },
                {
                    text: 'Sendwave',
                    value: 'sendwave',
                    disabled: this.disabled,
                },
            ];
        },
    },
};
</script>
