<template>
    <div>
        <v-data-table
            :items="invitedUsers"
            :headers="headers"
            :items-per-page="pagination.pageSize"
            :loading="loading"
            :item-class="() => `tw-bg-hoverBg tr-row px-6 py-4 mt-2 tw-w-full
                                default-font tw-text-black`"
            hide-default-footer
            class="tw-bg-transparent custom-table">

            <template #item.status="{ item }">
                <InvitedUserStatusChip
                    :value="getUserStatus(item)">
                </InvitedUserStatusChip>
            </template>

            <template #item.last_event_date="{ item }">
                {{ getLastEventDate(item) }}
            </template>
        </v-data-table>

        <v-pagination
            v-model="pagination.page"
            :length="pagination.pageCount"
            next-icon="$next"
            prev-icon="$prev"
            class="custom-pagination"
            color="#7598B5">
        </v-pagination>
    </div>
</template>

<script>
/* eslint-disable camelcase */
import UsersApi from '../../api/UsersApi';

import InvitedUserStatusChip from './InvitedUserStatusChip.vue';

const headers = [
    {
        text: 'Email',
        value: 'email',
        align: 'center',
        class: 'tw-text-black default-font py-2',
    },
    {
        text: 'Name',
        value: 'full_name',
        align: 'center',
        class: 'tw-text-black default-font py-2',
    },
    {
        text: 'Status',
        value: 'status',
        align: 'center',
        class: 'tw-text-black default-font py-2',
    },
    {
        text: 'Date of last event',
        value: 'last_event_date',
        align: 'center',
        class: 'tw-text-black default-font py-2',
    },
];

export default {
    components: {
        InvitedUserStatusChip,
    },
    props: {
        userId: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        headers,
        invitedUsers: [],
        pagination: {
            page: 1,
            pageCount: 1,
            pageSize: 10,
        },
        loading: false,
    }),
    methods: {
        async loadInvitedUsers() {
            try {
                this.loading = true;

                const params = {
                    ...this.pagination,
                };

                const { data } = await UsersApi.getInvitedUsers(this.userId, params);
                this.invitedUsers = data.data;
                this.pagination = data.pagination;
            } catch (error) {
                this.$swal({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                });
            } finally {
                this.loading = false;
            }
        },
        getUserStatus(user) {
            if (user.joined_at) {
                if (user.last_transaction_created_at && user.status !== 'new') {
                    return user.status;
                }
                return 'registered';
            }
            return 'invited';
        },
        getLastEventDate(user) {
            const {
                last_transaction_created_at,
                joined_at,
                created_at,
            } = user;

            return this.$options.filters.datetime(last_transaction_created_at
                || joined_at
                || created_at);
        },
    },
    watch: {
        'pagination.page': {
            immediate: true,
            handler() {
                this.loadInvitedUsers();
            },
        },
    },
};
</script>
