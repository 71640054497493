var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-0 log-tab-style  tab-title",attrs:{"headers":_vm.headers,"items":_vm.apiLogs,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event},"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-style font-light-13"},[_vm._v(_vm._s(_vm._f("date")(item.date))+" at "+_vm._s(_vm._f("time")(item.date)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2 text-uppercase tab-content",attrs:{"color":_vm.statusColor(item.status).bg,"text-color":_vm.statusColor(item.status).color,"dark":""}},[_vm._v(" "+_vm._s((item.status == '200') ? '200 OK' : item.status)+" ")])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-style font-dark-14"},[_vm._v(_vm._s(item.description))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.action)+" "),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("$sup")])]}}])}),_c('v-pagination',{staticClass:"mt-2 custom-pagination",attrs:{"next-icon":"$next","prev-icon":"$prev","color":"#7598B5","length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }