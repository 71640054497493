var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-w-full"},[_c('app-bar',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-toolbar-title',{staticClass:"default-font font-21 mr-10 tw-text-black"},[_vm._v(" Hybrid Order Options ")])]},proxy:true}])}),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4","lg":"3"}},[_c('v-text-field',{staticClass:"form-control",attrs:{"label":"Exchange rate","type":"number","step":"2","name":"exchange_rate","persistent-hint":"","outlined":"","dense":""},model:{value:(_vm.exchange_rate),callback:function ($$v) {_vm.exchange_rate=$$v},expression:"exchange_rate"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","lg":"3"}},[_c('v-text-field',{staticClass:"form-control",attrs:{"label":"Fee","type":"number","step":"2","name":"fee","persistent-hint":"","outlined":"","dense":""},model:{value:(_vm.fee),callback:function ($$v) {_vm.fee=_vm._n($$v)},expression:"fee"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","lg":"3"}},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.isSaveAllDisabled,"depressed":""},on:{"click":_vm.updateItems}},[_vm._v(" Save ALl ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"ml-auto",attrs:{"cols":"12","md":"3","lg":"2"}},[_c('v-select',{attrs:{"items":_vm.thirdPartyCompanies,"item-text":"company.name","item-value":"id","menu-props":{
                        bottom: true,
                        offsetY: true,
                        'content-class': 'mt-3 tw-rounded-lg',
                    },"placeholder":"Provider","prepend-inner-icon":"$filter","clearable":"","outlined":"","dense":""},model:{value:(_vm.filters.third_party_company_id),callback:function ($$v) {_vm.$set(_vm.filters, "third_party_company_id", $$v)},expression:"filters.third_party_company_id"}})],1),_c('v-col',{staticClass:"ml-auto",attrs:{"cols":"12","md":"3","lg":"2"}},[_c('v-select',{attrs:{"items":_vm.matcherAmounts,"menu-props":{
                        bottom: true,
                        offsetY: true,
                        'content-class': 'mt-3 tw-rounded-lg',
                    },"placeholder":"Amount","prepend-inner-icon":"$filter","clearable":"","outlined":"","dense":""},model:{value:(_vm.filters.matcher_amount),callback:function ($$v) {_vm.$set(_vm.filters, "matcher_amount", $$v)},expression:"filters.matcher_amount"}})],1),_c('v-col',{staticClass:"ml-auto",attrs:{"cols":"12","md":"3","lg":"2"}},[_c('v-select',{attrs:{"items":_vm.matcherCountries,"item-text":"country","item-value":"abbreviation","menu-props":{
                        bottom: true,
                        offsetY: true,
                        'content-class': 'mt-3 tw-rounded-lg',
                    },"placeholder":"From Country","prepend-inner-icon":"$filter","clearable":"","outlined":"","dense":""},model:{value:(_vm.filters.matcher_country),callback:function ($$v) {_vm.$set(_vm.filters, "matcher_country", $$v)},expression:"filters.matcher_country"}})],1),_c('v-col',{staticClass:"ml-auto",attrs:{"cols":"12","md":"3","lg":"2"}},[_c('v-select',{attrs:{"items":_vm.pbCountries,"item-text":"country","item-value":"abbreviation","menu-props":{
                        bottom: true,
                        offsetY: true,
                        'content-class': 'mt-3 tw-rounded-lg',
                    },"placeholder":"To Country","prepend-inner-icon":"$filter","clearable":"","outlined":"","dense":""},model:{value:(_vm.filters.pb_country),callback:function ($$v) {_vm.$set(_vm.filters, "pb_country", $$v)},expression:"filters.pb_country"}})],1),_c('v-col',{staticClass:"ml-auto",attrs:{"cols":"12","md":"3","lg":"2"}},[_c('v-select',{attrs:{"items":_vm.matcherCurrencies,"menu-props":{
                        bottom: true,
                        offsetY: true,
                        'content-class': 'mt-3 tw-rounded-lg',
                    },"item-text":"currency_code","item-value":"currency_code","placeholder":"From Currency","prepend-inner-icon":"$filter","clearable":"","outlined":"","dense":""},model:{value:(_vm.filters.matcher_currency),callback:function ($$v) {_vm.$set(_vm.filters, "matcher_currency", $$v)},expression:"filters.matcher_currency"}})],1),_c('v-col',{staticClass:"ml-auto",attrs:{"cols":"12","md":"3","lg":"2"}},[_c('v-select',{attrs:{"items":_vm.pbCurrencies,"menu-props":{
                        bottom: true,
                        offsetY: true,
                        'content-class': 'mt-3 tw-rounded-lg',
                    },"placeholder":"To Currency","prepend-inner-icon":"$filter","clearable":"","outlined":"","dense":""},model:{value:(_vm.filters.pb_currency),callback:function ($$v) {_vm.$set(_vm.filters, "pb_currency", $$v)},expression:"filters.pb_currency"}})],1),_c('v-col',[_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":function($event){_vm.showLogDialog = true}}},[_vm._v(" Show Log ")])],1)],1)],1),_c('div',{staticClass:"d-flex flex-column"},[_c('v-data-table',{staticClass:"px-12 py-4 tw-bg-transparent custom-table",attrs:{"item-key":"id","headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"items-per-page":_vm.pagination.pageSize,"hide-default-footer":"","item-class":function () { return 'default-font tw-text-black tw-cursor-pointer'; }},on:{"click:row":function (item, slot) { return _vm.editItem(item); }}}),_c('v-pagination',{staticClass:"custom-pagination",attrs:{"length":_vm.pagination.pageCount,"next-icon":"$next","prev-icon":"$prev","color":"#7598B5"},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1),_c('HybridOrderOptionsEditDialog',{attrs:{"hybrid-order-option":_vm.editedItem},on:{"input":function($event){_vm.editedItem = {}},"save":_vm.updateItem},model:{value:(_vm.showEditDialog),callback:function ($$v) {_vm.showEditDialog=$$v},expression:"showEditDialog"}}),_c('v-dialog',{attrs:{"max-width":"1200"},model:{value:(_vm.showLogDialog),callback:function ($$v) {_vm.showLogDialog=$$v},expression:"showLogDialog"}},[(_vm.showLogDialog)?_c('HybridOrderOptionsChangelog'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }