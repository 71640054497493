<template>
  <div class="status-tab">
    <v-simple-table class="tr-style">
      <template v-slot:default>
        <thead class="header-style">
          <tr>
            <th class="tab-title text-left">API Development</th>
            <th class="text-right"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in apiDevelopment" :key="item.name">
            <td class="tab-title">{{ item.name }}</td>
            <td class="text-capitalize text-right">
              <v-chip
                class="ma-2 text-uppercase default-font"
                :color="statusColor(item.status).bg"
                :text-color="statusColor(item.status).color"
              >{{item.status}}</v-chip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-simple-table class="tr-style my-8">
      <template v-slot:default>
        <thead class="header-style">
          <tr>
            <th class="tab-title text-left">API Sandbox</th>
            <th class="text-right"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in apiSandbox" :key="item.name">
            <td class="tab-title">{{ item.name }}</td>
            <td class="text-capitalize text-right">
              <v-chip
                class="ma-2 text-uppercase"
                :color="statusColor(item.status).bg"
                :text-color="statusColor(item.status).color"
              >{{item.status}}</v-chip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div class="tw-mt-20">
      <span class="tab-title px-0">Pasts incidents</span>

      <v-expansion-panels flat>
        <v-expansion-panel v-for="(incident,i) in incidents" :key="i">
          <v-expansion-panel-header hide-actions>
            <template v-slot:default="{ open }">
              <div class="tab-title">
                <span v-if="open" key="0">
                  <v-icon x-small>$down</v-icon>
                </span>
                <span v-else key="1">
                  <v-icon x-small>$sup</v-icon>
                </span>
                <span class="ml-2">{{ incident.date }}</span>
              </div>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="tab-title px-0">
            <v-row>
              <v-divider vertical class="inline"></v-divider>
              <v-col class="ml-4">
                <div class="my-5 font-21 tw-text-red">{{ incident.content.title }}</div>
                <div
                  v-for="(content,j) in incident.content.comments"
                  :key="j"
                  class="d-flex flex-column"
                >
                  <span class>{{ resolvedStatus(content.resolved) }}</span>
                  <span class="tw-text-secondary">{{ content.createdAt }}</span>
                  <p class="mb-6 mt-4">{{ content.comment }}</p>
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>
<script>
import {
    apiDevelopment,
    apiSandbox,
    incidents,
} from '@/utils/Developer/api-status';

export default {
    data() {
        return {
            page: 1,
            pageCount: 0,
            itemsPerPage: 5,
            apiDevelopment,
            apiSandbox,
            incidents,
            items: 5,
            logIcon: '$sup',
        };
    },
    methods: {
        statusColor(status) {
            switch (status) {
            case 'Operational':
                return { bg: '#C5F5E7', color: '#15ABAB' };
            default:
                return { bg: '#FFE3E2', color: '#F07877' };
            }
        },
        resolvedStatus(resolved) {
            switch (resolved) {
            case 'true':
                return 'Resolved';
            default:
                return 'In progress';
            }
        },
    },
};
</script>
<style lang="scss">
.status-tab {
  .header-style th {
    background-color: #f7fbfc !important;
    border: 1px solid #f7fbfc !important;
    box-sizing: border-box !important;
    box-shadow: 0px 8px 33px rgba(224, 224, 224, 0.1) !important;
    border-radius: 6px !important;
    line-height: 69.09px !important;
  }

  .tr-style tr {
    line-height: 69.09px !important;
  }
  .tr-style tr {
    line-height: 69.09px !important;
  }
  .tr-style tr:hover {
    background-color: white !important;
  }

  .tr-style table {
    border: 1px solid #eaf0f5 !important;
    box-sizing: border-box !important;
    box-shadow: 0px 8px 33px rgba(224, 224, 224, 0.1) !important;
    border-radius: 6px !important;
    border-spacing: inherit !important;
  }

  .tab-title {
    font-family: Muli !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 15px !important;
    line-height: 136.5% !important;
    /* or 20px */

    /* Body black */

    color: #2e5677 !important;
  }
  table tr td,
  table tr th {
    padding-left: 32px !important;
  }
  .v-expansion-panel-header {
    padding-left: 0 !important;
  }
}
</style>
