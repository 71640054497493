<template>
    <v-card class="elevation-12">
        <v-card-title class="d-flex justify-space-between">
            {{ value ? 'Update' : 'Create' }} ribbon
        </v-card-title>
        <v-card-text class="pt-3">
            <TextEditor
              :value="value ? value.content : null"
              @change="onChange"
            />
        </v-card-text>
        <v-card-actions>
            <v-btn @click="submit">
                {{ value ? 'Save' : 'Create' }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import TextEditor from '@/components/TextEditor.vue';

export default {
    components: {
        TextEditor,
    },
    props: {
        value: {
            type: Object,
            required: false,
        },
    },
    data: () => ({
        content: null,
    }),
    methods: {
        onChange(v) {
            this.content = v;
        },
        submit() {
            this.$emit('submit', this.content);
        },
    },
};
</script>
