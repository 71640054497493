<template>
    <div class="d-flex">

        <v-select
            v-model="sort_by"
            :items="sortItems"
            :menu-props="{
                bottom: true,
                offsetY: true,
                'content-class': 'mt-3 tw-rounded-lg',
            }"
            label="Sort By"
            hide-details
            outlined
            dense>
            <template #selection="{ item }">
                <span class="default-font filter-title tw-text-black mr-2">
                    {{ item.text }}
                </span>
            </template>
        </v-select>

        <v-btn
            v-if="sort_dir === 'desc'"
            denset
            text
            @click="sort_dir = 'asc'">
            <v-icon>
                mdi-arrow-up
            </v-icon>
        </v-btn>
        <v-btn
            v-else
            dense
            text
            @click="sort_dir = 'desc'">
            <v-icon>
                mdi-arrow-down
            </v-icon>
        </v-btn>

    </div>
</template>

<script>
/* eslint-disable camelcase */
export default {
    data: () => ({
        sortItems: [
            {
                text: 'User Created',
                value: 'users.joined_at',
            },
            {
                text: 'Last Signed in',
                value: 'token.created_at',
            },
        ],
    }),
    computed: {
        sort_by: {
            get() {
                return this.$store.state.users.filters.sort_by;
            },
            set(sort_by) {
                this.$store.dispatch('users/updateFilters', { sort_by });
            },
        },
        sort_dir: {
            get() {
                return this.$store.state.users.filters.sort_dir;
            },
            set(sort_dir) {
                this.$store.dispatch('users/updateFilters', { sort_dir });
            },
        },
    },
};
</script>
