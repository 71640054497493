<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    allow-overflow
    offset-y
    bottom
    transition="slide-y-transition"
    content-class="mt-3"
    max-width="300px"
    :disabled="disabled"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        class="default-font text-capitalize px-3 py-4 filter-title tw-text-black d-flex justify-space-between sent-filter"
        large
        text
        v-on="on"
        :disabled="disabled"
      >
        {{filterValue}}
        <v-icon color="#7598B5" small>mdi-chevron-down</v-icon>
      </v-btn>
    </template>

    <selector
      @apply-filter="filterChanged"
      v-if="menu"
      :disabled="disabled"
    ></selector>
  </v-menu>
</template>

<script>
import Selector from './Selector.vue';

export default {
    components: { Selector },
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
        storeKey: {
            type: String,
            default: 'transaction',
        },
    },
    data: () => ({
        fav: true,
        menu: false,
        message: false,
        hints: true,
        to: undefined,
        from: undefined,
    }),
    watch: {
      sentFilter: {
        deep: true,
        handler() {
            this.to = this.sentFilter.to;
            this.from = this.sentFilter.from;
        },
      },
    },
    methods: {
        filterChanged(filterData) {
            this.menu = false;
            this.to = filterData.to;
            this.from = filterData.from;
            this.updateSentFilter({ to: this.to, from: this.from });
        },
        updateSentFilter(payload) {
            return this.$store.dispatch(`${this.storeKey}/updateSentFilter`, payload);
        },
    },
    computed: {
        sentFilter() {
            return this.$store.state[this.storeKey].sentFilter;
        },
        filterValue() {
            const isAnyValu = !this.to && !this.from;
            const to = !this.to ? 'Any Amount' : this.to;
            const from = !this.from ? 'Any Amount' : this.from;
            return isAnyValu ? 'Any Amount' : `${from} - ${to}`;
        },
    },
    mounted() {
        this.to = this.sentFilter.to;
        this.from = this.sentFilter.from;
    },
};
</script>
<style>
.sent-filter {
  @apply tw-w-full tw-bg-white tw-text-black tw-border tw-border-solid tw-border-binput tw-box-border;
}
</style>
