<template>
  <div class="tw-w-4/5 d-flex flex-column justify-center align-center mx-auto my-8">

    <div v-if="!item" class="tw-w-full d-flex justify-space-between align-center tw-px-24">
        Loading...
    </div>

    <div v-if="item" class="tw-w-full d-flex justify-space-between align-center tw-px-24">
      <div class="group-17 tw-max-w-sm tw-flex">
        <v-tooltip bottom content-class="tooltip" transition="fade-transition">
          <template v-slot:activator="{ on }">
            <v-avatar class="tw-flex-shrink-0" v-on="on">
              <img :src="postersBeneficiary.avatar || '/img/man.svg'" :alt="postersBeneficiary.name" />
            </v-avatar>
          </template>
          <person-info :person="postersBeneficiary"></person-info>
        </v-tooltip>

        <div class="tw-ml-3 tw-pt-1">
          <h4 class="tw-text-primary default-font font-14">
            <router-link
                :to="{
                    name: 'User',
                    params: {
                        id: postersBeneficiary.id,
                    },
                }">
                {{ postersBeneficiary.name }}
            </router-link>
          </h4>
          <p class="default-font font-12 tw-text-black d-flex align-center">
            <gb-flag
              v-if="postersBeneficiary.country_abbreviation"
              class="mr-1"
              :code="postersBeneficiary.country_abbreviation"
              size="small"
            />
            {{ postersBeneficiary.country }}
          </p>
        </div>
      </div>

            <div class="tw-relative d-flex align-center justify-center">
                <top-arrow></top-arrow>
                <v-tooltip bottom content-class="tooltip" transition="fade-transition">
                    <template v-slot:activator="{ on }">
                        <div class="tw-absolute tw-inset-x-auto tw-cursor-pointer" v-on="on">
                            <span class="amount default-font tw-text-primary mr-1">{{ matcherAmount | price }}</span>
                            <span class="currency default-font tw-text-primary">{{item.matcher_currency}}</span>
                        </div>
                    </template>
                    <amount :transaction="{
                        to_currency: item.matcher_currency,
                        from_currency: item.poster_currency,
                        received: matcherAmount,
                        subtotal: posterAmount,
                        rate: item.exchange_rate,
                        fee: item.poster_fee,
                        fee_currency: item.poster_currency,
                        total: posterAmount + item.poster_fee,
                    }"
                    />
                </v-tooltip>
            </div>
      <div class="group-17 tw-max-w-sm tw-flex tw-flex-row-reverse">
        <v-tooltip bottom content-class="tooltip" transition="fade-transition">
          <template v-slot:activator="{ on }">
            <v-avatar class="tw-flex-shrink-0" v-on="on">
              <img :src="poster.avatar || '/img/man.svg'" :alt="poster.name" />
            </v-avatar>
          </template>
          <person-info :person="poster"></person-info>
        </v-tooltip>
        <div class="tw-mr-3 tw-pt-1">
          <h4 class="tw-text-primary default-font font-14">
            <router-link
                :to="{
                    name: 'User',
                    params: {
                        id: poster.id,
                    },
                }">
                {{ poster.name }}
            </router-link>
          </h4>
          <p class="default-font font-12 tw-text-black d-flex align-center">
            <gb-flag class="mr-1" :code="item.poster_country" size="small" />{{ poster.country }}
          </p>
        </div>
      </div>
    </div>

    <div v-if="item" class="tw-w-full d-flex justify-space-between align-center tw-px-24">
        <bank-status v-if="item.postersBeneficiaryBank" :bank="item.postersBeneficiaryBank" />
        <div class="tw-relative d-flex align-center justify-center"></div>
        <bank-status :bank="item.posterBank" />
    </div>

    <div
        v-if="item && item.match"
        class="tw-w-full d-flex justify-space-between align-center tw-relative"
    >
      <div class="d-flex justify-center align-center tw-ml-20">
        <span
            class="default-font font-13 tw-text-black mr-1"
            v-if="item.matcherPaymentProvider"
        >
            {{ item.matcherPaymentProvider.name }}
        </span>
        <left-arrow></left-arrow>
        <div class=" tw-cursor-pointer">
          <span class="amount default-font tw-text-primary mr-1">
            {{ (matcherAmount + parseFloat(item.match.matcher_fee)) | price }}
          </span>
          <span class="currency default-font tw-text-primary">{{item.matcher_currency}}</span>
        </div>
      </div>
      <div class="d-flex justify-center tw-mr-6">
        <div class="d-flex flex-column justify-space-between tw-flex-none tw-py-5">

          <div class=" tw-cursor-pointer">
            <span class="amount default-font tw-text-primary mr-1">
              {{ (posterAmount + posterFee) | price }}
            </span>
            <span class="currency default-font tw-text-primary">{{item.poster_currency}}</span>
          </div>

          <div class=" tw-cursor-pointer" v-if="item.completed_at">
            <span class="amount default-font tw-text-primary mr-1">{{ matchersBeneficiaryAmount | price }}</span>
            <span class="currency default-font tw-text-primary">{{item.poster_currency}}</span>
          </div>
          <div class=" tw-cursor-pointer" v-else>
            <span class="amount default-font tw-text-primary mr-1">---</span>
          </div>

        </div>
        <right-arrow></right-arrow>
        <div class="tw-flex-none d-flex align-center">
          <v-img
            v-if="posterBankLogo"
            width="70"
            :src="posterBankLogo"
          />
        </div>
      </div>
    </div>

    <div
        v-if="item && item.match"
        class="tw-w-full d-flex justify-space-between align-center tw-px-24 mt-3"
    >
      <div class="group-17 tw-max-w-sm tw-flex">
        <v-tooltip bottom content-class="tooltip" transition="fade-transition">
          <template v-slot:activator="{ on }">
            <v-avatar class="tw-flex-shrink-0" v-on="on">
              <img :src="matcher.avatar || '/img/man.svg'" :alt="matcher.name" />
            </v-avatar>
          </template>
          <person-info :person="matcher"></person-info>
        </v-tooltip>
        <div class="tw-ml-3 tw-pt-1">
          <h4 class="tw-text-primary default-font font-14">
            <router-link
                :to="{
                    name: 'User',
                    params: {
                        id: matcher.id,
                    },
                }">
                {{ matcher.name }}
            </router-link>
          </h4>
          <p class="default-font font-12 tw-text-black d-flex align-center">
            <gb-flag class="mr-1" :code="matcher.country_abbreviation" size="small" />{{ matcher.country }}
          </p>
        </div>
      </div>
            <div class="tw-relative d-flex align-center justify-center">
                <bottom-arrow></bottom-arrow>
                <v-tooltip bottom content-class="tooltip" transition="fade-transition">
                    <template v-slot:activator="{ on }">
                        <div class="tw-absolute tw-inset-x-auto tw-cursor-pointer" v-on="on">
                            <span class="amount default-font tw-text-primary mr-1">{{ matcherAmount | price }}</span>
                            <span class="currency default-font tw-text-primary">{{ item.matcher_currency }}</span>
                        </div>
                    </template>
                    <amount :transaction="{
                        to_currency: item.matcher_currency,
                        from_currency: item.poster_currency,
                        received: matcherAmount,
                        subtotal: posterAmount,
                        rate: item.exchange_rate,
                        fee: item.match.matcher_fee,
                        fee_currency: item.matcher_currency,
                        total: posterAmount + item.poster_fee,
                    }"
                    />
                </v-tooltip>
            </div>
      <div class="group-17 tw-max-w-sm tw-flex tw-flex-row-reverse">
        <v-tooltip bottom content-class="tooltip" transition="fade-transition">
          <template v-slot:activator="{ on }">
            <v-avatar class="tw-flex-shrink-0" v-on="on">
              <img :src="matchersBeneficiary.avatar || '/img/man.svg'" :alt="matchersBeneficiary.name" />
            </v-avatar>
          </template>
          <person-info :person="matchersBeneficiary"></person-info>
        </v-tooltip>
        <div class="tw-mr-3 tw-pt-1">
          <h4 class="tw-text-primary default-font font-14">{{ matchersBeneficiary.name }}</h4>
          <p class="default-font font-12 tw-text-black d-flex align-center">
            <gb-flag class="mr-1" :code="item.poster_country" size="small" />{{ poster.country }}
          </p>
        </div>
      </div>
    </div>

    <div v-if="item && item.match" class="tw-w-full d-flex justify-space-between align-center tw-px-24">
        <bank-status :bank="item.matcherBank" />
        <div class="tw-relative d-flex align-center justify-center"></div>
        <bank-status
            :bank="{
                name: matchersBeneficiary.institution_name,
            }"
        />
    </div>

  </div>
</template>
<script>
/* eslint-disable camelcase */
import TransactionsApi from '@/api/TransactionsApi';
import TopArrow from './svg/TopArrow.vue';
import LeftArrow from './svg/LeftArrow.vue';
import RightArrow from './svg/RightArrow.vue';
import BottomArrow from './svg/BottomArrow.vue';
import PersonInfo from './tooltips/PersonInfo.vue';
import Amount from './tooltips/Amount.vue';
import BankStatus from './BankStatus.vue';

export default {
    components: {
        BottomArrow,
        LeftArrow,
        RightArrow,
        TopArrow,
        PersonInfo,
        Amount,
        BankStatus,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            item: null,
        };
    },
    watch: {
        id: 'loadItem',
    },
    mounted() {
        this.loadItem();
    },
    computed: {
        poster() {
            return this.getUserObject(this.item.user);
        },
        postersBeneficiary() {
            if (!this.item.postersBeneficiary) return {};
            return this.getUserObject(this.item.postersBeneficiary);
        },
        matcher() {
            if (!this.item.match) return {};

            return this.getUserObject(this.item.match.user);
        },
        matchersBeneficiary() {
            return this.item.matchersBeneficiary || {};
        },
        posterBankLogo() {
            const {
              posterBank,
              poster_country: country,
            } = this.item;

            if (!posterBank) {
                return posterBank;
            }

            const bankCode = posterBank.account.bank_code;

            if (!bankCode) {
                return null;
            }

            return `/img/banks/${country.toLowerCase()}/${bankCode}.png`;
        },
        posterAmount() {
          const {
            is_partial_match_allowed: isPartialMatchAllowed,
            match,
          } = this.item;

          if (match && isPartialMatchAllowed) {
            return match.poster_amount;
          }

          return this.item.poster_amount;
        },
        posterFee() {
            const {
                match,
                is_partial_match_allowed: isPartialMatchAllowed,
                poster_fee: posterFee,
            } = this.item;

            if (match && isPartialMatchAllowed) {
                const feePercentage = posterFee / this.item.poster_amount;
                if (match.poster_amount !== this.item.poster_amount) {
                  return Math.floor(match.poster_amount * feePercentage);
                }
            }

            return posterFee;
        },
        matcherAmount() {
          const {
            is_partial_match_allowed: isPartialMatchAllowed,
            match,
          } = this.item;

          if (match && isPartialMatchAllowed) {
            return match.matcher_amount;
          }

          return this.item.matcher_amount;
        },
        matchersBeneficiaryAmount() {
          const {
            is_partial_match_allowed: isPartialMatchAllowed,
            match,
          } = this.item;

          if (match && isPartialMatchAllowed) {
            return match.poster_amount;
          }

          return this.item.poster_amount;
        },
    },
    methods: {
        loadItem() {
            this.item = null;
            TransactionsApi.get(this.id).then((response) => {
                this.item = response.data;
            }).catch(() => {});
        },
        getUserObject(user) {
            const {
                id,
                avatar,
                firstname,
                lastname,
                phone,
                email,
                dob,
                address,
                country,
                country_abbreviation: countryAbbreviation,
                city,
                state,
                zipcode,
                payment_methods,
                delivery_methods,
            } = user;
            return {
                id,
                avatar,
                name: `${firstname} ${lastname}`,
                phone,
                email,
                birth_date: dob,
                address,
                country,
                country_abbreviation: countryAbbreviation,
                city,
                state,
                zipcode,
                payment_methods,
                delivery_methods,
            };
        },
    },
};
</script>
<style scoped>
.groupe-17 {
  width: 156.1px;
  height: 53.82px;
  left: 307.58px;
  top: 329.03px;
}
.default-font {
  line-height: 136.5% !important;
  font-weight: 600;
}
.amount {
  /* or 27px */
  text-align: right;
  font-size: 20px;
}

.tooltip {
  background: #2E5677;
border-radius: 11px;
max-width: 380px;
min-width: 300px;
}
</style>
