<template>
    <v-dialog v-model="display" width="80%">
        <template v-slot:activator="{ on }">
            <v-btn text class="text-capitalize default-font" v-on="on">Matches history</v-btn>
        </template>

        <v-card flat class="py-2">
            <v-card-title>
                Match history for order ID: "{{ orderId }}"
                <v-btn
                    v-if="order && order.published_at"
                    color="warning"
                    class="ml-4"
                    x-small
                    @click="copyToClipboard(orderUrl)"
                >
                    Copy
                </v-btn>
            </v-card-title>
            <v-data-table
                item-key="id"
                :headers="headers"
                :items="items"
                :loading="loading"
                hide-default-footer
                single-expand
                class="px-12 py-4 tw-bg-transparent custom-table"
            >
                <template v-slot:item.matcher_name="{ item }">
                    {{ getMatcherName(item) }}
                </template>
                <template v-slot:item.matcher_email="{ item }">
                    {{ item.user ? item.user.email : '' }}
                </template>
                <template v-slot:item.accepted_at="{ item }">
                    {{ item.accepted_at | date }} {{ item.accepted_at | time }}
                </template>
                <template v-slot:item.cancelled_at="{ item }">
                    <template v-if="item.cancelled_at">
                        {{ item.cancelled_at | date }} {{ item.cancelled_at | time }}
                    </template>
                </template>
                <template #item.initiated_by="{ item }">
                    {{ item.initiator && item.initiator.full_name_shown }}
                </template>
                <template #item.actions="{ item }">
                    <template v-if="item.cancelled_at">
                        <v-btn
                            depressed
                            small
                            @click="rematch(item)"
                        >
                            Auto-rematch
                        </v-btn>
                    </template>
                </template>
            </v-data-table>

            <v-pagination
                :value="pagination.page"
                @input="onPageChange"
                next-icon="$next"
                prev-icon="$prev"
                class="custom-pagination"
                color="#7598B5"
                :length="pagination.pageCount"
            ></v-pagination>
        </v-card>
    </v-dialog>
</template>
<script>
import OrdersApi from '../../api/OrdersApi';
import MatchesApi from '../../api/MatchesApi';

const headers = [
    {
        text: 'ID',
        value: 'id',
        sortable: false,
    },
    {
        text: 'Matcher Name',
        value: 'matcher_name',
        sortable: false,
    },
    {
        text: 'Matcher Email',
        value: 'matcher_email',
        sortable: false,
    },
    {
        text: 'Matched At',
        value: 'accepted_at',
        sortable: false,
    },
    {
        text: 'Cancelled At',
        value: 'cancelled_at',
        sortable: false,
    },
    {
        text: 'Initiated by',
        value: 'initiated_by',
        sortable: false,
    },
    {
        text: '',
        value: 'actions',
        sortable: false,
    },
];

export default {
    props: {
        orderId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            display: false,
            headers,
            loading: false,
            order: {},
            items: [],
            pagination: {
                page: 1,
                pageSize: 100,
            },
        };
    },
    computed: {
        orderUrl() {
            return `${process.env.VUE_APP_KAOSHI_CLIENT_URL}/offers/${this.orderId}`;
        },
    },
    watch: {
        display(val) {
            if (val) {
                this.loadItems();
                this.loadOrder();
            }
        },
    },
    methods: {
        loadItems() {
            this.loading = true;
            OrdersApi.getMatches(this.orderId, this.pagination)
                .then((response) => {
                    this.loading = false;
                    const { models, pagination } = response.data;
                    this.items = models;
                    this.pagination = pagination;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        onPageChange(page) {
            this.pagination.page = page;
            this.loadItems();
        },
        getMatcherName(item) {
            const {
                firstname,
                middlename,
                lastname,
            } = item.user;

            if (middlename) {
                return `${firstname} ${middlename} ${lastname}`;
            }

            return `${firstname} ${lastname}`;
        },
        async loadOrder() {
            try {
                const { data } = await OrdersApi.get(this.orderId);

                this.$set(this, 'order', data);
            } catch (error) {
                this.$swal({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                });
            }
        },
        async rematch(item) {
            try {
                const { isConfirmed } = await this.$swal({
                    icon: 'warning',
                    title: 'Are You Sure?',
                    text: 'Are you sure you want to rematch this order?',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                });

                if (!isConfirmed) return;

                await MatchesApi.rematch(item.id);

                this.$swal({
                    icon: 'success',
                    title: 'Order remached successfully',
                });

                await this.loadItems();
            } catch (error) {
                this.$swal({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                });
            }
        },
    },
};
</script>
