<template>
    <div>
        <v-select
            v-model="period"
            v-bind="$attrs"
            :items="items"
            :menu-props="{
                bottom: true,
                offsetY: true,
                'content-class': 'mt-3 tw-rounded-lg',
            }"
            :disabled="disabled"
            prepend-inner-icon="$calendar"
            return-object
        >
        </v-select>

        <v-dialog
            v-if="period && period.value === 'custom'"
            v-model="modal"
            width="320px"
            persistent
        >
            <template #activator="{ on }">
                <input
                    v-on="on"
                    :value="dateRangeText"
                    :style="{
                        minWidth: '210px',
                        background: 'white',
                    }"
                    :disabled="disabled"
                    class="mb-3 f-input default-font pa-2 tw-border-binput focus:tw-border-primary"
                    label="Date range"
                    placeholder="Date range"
                    readonly
                />
            </template>
            <v-date-picker
                v-model="dates"
                :disabled="disabled"
                scrollable
                range
            >
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-btn
                    :disabled="disabled"
                    color="primary"
                    text
                    @click="modal = false"
                >
                    Cancel
                </v-btn>
                <v-btn
                    :disabled="disabled"
                    color="primary"
                    text
                    @click="onDateRangeSelected"
                >
                    OK
                </v-btn>
            </v-date-picker>
        </v-dialog>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    props: {
        value: {
            type: Array,
            default: () => ([]),
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        items: [
            {
                range: [
                    moment.utc().startOf('day').format('YYYY-MM-DD'),
                    moment.utc().endOf('day').format('YYYY-MM-DD'),
                ],
                value: 'today',
                text: 'Today',
            },
            {
                range: [
                    moment.utc().subtract(1, 'days').startOf('day').format('YYYY-MM-DD'),
                    moment.utc().subtract(1, 'days').endOf('day').format('YYYY-MM-DD'),
                ],
                value: 'yesterday',
                text: 'Yesterday',
            },
            {
                range: [
                    moment.utc().startOf('week').format('YYYY-MM-DD'),
                    moment.utc().endOf('week').format('YYYY-MM-DD'),
                ],
                value: 'this_week',
                text: 'This week',
            },
            {
                range: [
                    moment.utc().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'),
                    moment.utc().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD'),
                ],
                value: 'last_week',
                text: 'Last week',
            },
            {
                range: [
                    moment.utc().startOf('month').format('YYYY-MM-DD'),
                    moment.utc().endOf('month').format('YYYY-MM-DD'),
                ],
                value: 'this_month',
                text: 'This month',
            },
            {
                range: [
                    moment.utc().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
                    moment.utc().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
                ],
                value: 'last_month',
                text: 'Last month',
            },
            {
                range: [],
                value: 'all_time',
                text: 'All time',
            },
            {
                range: [null, null],
                value: 'custom',
                text: 'Custom period',
            },
        ],
        period: {},
        modal: false,
        dates: [null, null],
    }),
    computed: {
        customRangeIndex() {
            return this.items.findIndex(({ value }) => value === 'custom');
        },
        dateRangeText() {
            if (!this.period) {
                return false;
            }

            const value = this.period.range.join(' - ');
            return value === ' - ' ? null : value;
        },
    },
    methods: {
        updatePeriodFilter({ range }) {
            return this.$emit('update', range);
        },
        onDateRangeSelected() {
            this.items[this.customRangeIndex].range = this.dates;
            this.updatePeriodFilter(this.items[this.customRangeIndex]);
            this.modal = false;
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(val) {
                this.$set(this.items[this.customRangeIndex], 'range', val);

                const item = this.items.find(({ range }) => range[0] === val[0] && range[1] === val[1]);
                this.$set(this, 'period', item);
            },
        },
        'period.range': {
            handler(val) {
                this.$emit('input', val);
            },
        },
        'period.value': {
            handler(val) {
                if (val === 'custom') this.modal = true;
            },
        },
    },
};
</script>
