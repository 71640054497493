<template>
  <v-dialog v-model="dialog" max-width="608px" class="modal-style">
    <template v-slot:activator="{ on }">
      <v-btn color="primary" outlined="" dark class="mb-2 default-font font-12 text-capitalize" v-on="on">
        <v-icon left>+</v-icon>Add endpoint
      </v-btn>
    </template>
    <v-card class="modal-style">
      <v-card-title>
        <span class="headline-style">Add a webhook endpoint</span>
      </v-card-title>

      <v-card-text class="pb-n1">
        <v-container>
              <span class="input-label">Webhook</span>
              <v-text-field
                label="https://testurl.com"
                solo
                background-color="#F7FBFC"
                class="input-field input-field-color mt-2"
                elevation-0
              ></v-text-field>
              <span class="input-label">Event</span>
              <v-select
                :items="events"
                label="Select event"
                class="input-field select-input  mt-2"
                elevation="0"
                solo
              ></v-select>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-container>
          <v-row>
            <v-col cols="6" sm="4" md="3">
              <v-btn
                text @click="save"
                elevation="0"
                class="default-font font-12 success-btn text-capitalize"
              >Configure</v-btn>
            </v-col>
            <v-col cols="4" sm="3" md="2">
              <v-btn
                text @click="close"
                elevation="0"
                class="default-font font-12 danger-btn text-capitalize"
              >Cancel</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
    data() {
        return {
            dialog: false,
        };
    },
    props: {
        events: {
            type: Array,
        },
        isExpanded: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        deleteItem() {
        },
        close() {
            this.dialog = false;
        },
        save() {
            this.close();
        },
    },
};
</script>
