import Vue from 'vue';
import AuthApi from '@/api/AuthApi';

export default {
    state: {
        user: {},
        authToken: {},
        handlingSession: false,
    },

    actions: {
        async login({ commit, dispatch }, payload) {
            try {
                const { data: { data } } = await AuthApi.login(payload);
                const { user, token } = data;

                const canAccess = user.role === 'admin'
                    || user.role === 'manager'
                    || user.role === 'principal';

                if (canAccess) {
                    commit('SET_USER', user);
                    commit('SET_AUTH_TOKEN', token);
                } else {
                    dispatch('logout');
                    throw new Error('You don\'t have permission to log in');
                }
            } catch (error) {
                commit('SET_USER', {});
                commit('SET_AUTH_TOKEN', {});
                throw error;
            }
        },
        async refreshToken({ commit }, payload) {
            try {
                const { data: { data } } = await AuthApi.refreshToken(payload);
                commit('SET_USER', data.user);
                commit('SET_AUTH_TOKEN', data.token);
            } catch (error) {
                commit('SET_USER', {});
                commit('SET_AUTH_TOKEN', {});
                throw error;
            }
        },
        async logout({ commit }) {
            try {
                await AuthApi.logout();
                commit('SET_USER', {});
                commit('SET_AUTH_TOKEN', {});
            } catch (error) {
                commit('SET_USER', {});
                commit('SET_AUTH_TOKEN', {});
                throw error;
            }
        },
        changeAuthState({ commit }) {
            return new Promise((resolve) => {
                commit('changeAuthState');
                resolve(true);
            });
        },
    },

    mutations: {
        SET_USER(state, payload) {
            Vue.set(state, 'user', payload);
        },
        SET_AUTH_TOKEN(state, payload) {
            Vue.set(state, 'authToken', payload);
        },
        SET_HANDLING_SESSION(state, payload) {
            Vue.set(state, 'handlingSession', payload);
        },
        changeAuthState(state) {
            state.isAuth = !state.isAuth;
        },
    },

    getters: {
        user: ({ user }) => user,
        isAuth: ({ authToken }) => !!authToken && !!authToken.access_token,
        current: (state, { isAuth }) => (isAuth ? 'default' : 'auth'),
    },
};
