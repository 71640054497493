<template >
  <div  class="tw-w-full">
     <app-bar>
      <template slot="title">
          <router-link to="/supports">
        <v-icon x-large class="back-style mr-6">$back</v-icon>
      </router-link>
        <v-toolbar-title class="default-font font-21 mr-10 tw-text-black">Open New Case</v-toolbar-title>
      </template>
    </app-bar>
    <open-new-case></open-new-case>
  </div>
</template>
<script>
import OpenNewCase from '@/components/supports/NewCase.vue';
import AppBar from '@/components/globals/AppBar.vue';

export default {
    components: { OpenNewCase, AppBar },
};
</script>

<style>
.font-21 {
  font-size: 21px !important;
}
.font-14 {
  font-size: 14px !important;
}
</style>
