var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mx-12"},[_c('v-col',[_c('div',{staticClass:"d-flex flex-column"},[_c('v-data-table',{staticClass:"tw-bg-transparent custom-table",attrs:{"item-key":"id","headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"items-per-page":_vm.pagination.pageSize,"hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('ReferralTransactionRow',{attrs:{"item":item,"headers":headers},on:{"row-clicked":function($event){return expand(!isExpanded)}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("datetime")(value))+" ")]}},{key:"item.order.confirmed_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("datetime")(value))+" ")]}},{key:"item.order.completed_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("datetime")(value))+" ")]}}],null,true)})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length,"transition":"slide-y-transition"}},[_c('ReferralTransactionDetail',{attrs:{"item":item}})],1)]}}])}),_c('v-pagination',{staticClass:"custom-pagination",attrs:{"length":_vm.pagination.pageCount,"next-icon":"$next","prev-icon":"$prev","color":"#7598B5"},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }