var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"80%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"text-capitalize default-font",attrs:{"text":""}},on),[_vm._v("Matches history")])]}}]),model:{value:(_vm.display),callback:function ($$v) {_vm.display=$$v},expression:"display"}},[_c('v-card',{staticClass:"py-2",attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" Match history for order ID: \""+_vm._s(_vm.orderId)+"\" "),(_vm.order && _vm.order.published_at)?_c('v-btn',{staticClass:"ml-4",attrs:{"color":"warning","x-small":""},on:{"click":function($event){return _vm.copyToClipboard(_vm.orderUrl)}}},[_vm._v(" Copy ")]):_vm._e()],1),_c('v-data-table',{staticClass:"px-12 py-4 tw-bg-transparent custom-table",attrs:{"item-key":"id","headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"hide-default-footer":"","single-expand":""},scopedSlots:_vm._u([{key:"item.matcher_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getMatcherName(item))+" ")]}},{key:"item.matcher_email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user ? item.user.email : '')+" ")]}},{key:"item.accepted_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.accepted_at))+" "+_vm._s(_vm._f("time")(item.accepted_at))+" ")]}},{key:"item.cancelled_at",fn:function(ref){
var item = ref.item;
return [(item.cancelled_at)?[_vm._v(" "+_vm._s(_vm._f("date")(item.cancelled_at))+" "+_vm._s(_vm._f("time")(item.cancelled_at))+" ")]:_vm._e()]}},{key:"item.initiated_by",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.initiator && item.initiator.full_name_shown)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.cancelled_at)?[_c('v-btn',{attrs:{"depressed":"","small":""},on:{"click":function($event){return _vm.rematch(item)}}},[_vm._v(" Auto-rematch ")])]:_vm._e()]}}])}),_c('v-pagination',{staticClass:"custom-pagination",attrs:{"value":_vm.pagination.page,"next-icon":"$next","prev-icon":"$prev","color":"#7598B5","length":_vm.pagination.pageCount},on:{"input":_vm.onPageChange}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }