<template>
    <div>
        <app-bar>
            <template slot="title">
                <v-toolbar-title class="default-font font-21 mr-10 text--primary">Dispute Resolution</v-toolbar-title>
            </template>
            <div class="d-flex justify-space-between mr-10">
                <search v-model="search" placeholder="Search by Country Name..."></search>
            </div>
        </app-bar>
        <!-- <filters
        :toCountries="toCountries"
        :fromCountries="fromCountries"
        @apply-filter="applyFilters"
        :filters="filters"
        @show-rate-form="newRateDialog = true"
    ></filters> -->
        <div class="d-flex flex-column">
            <v-data-table
                item-key="id"
                :headers="headers"
                :items="items"
                :search="search"
                :loading="loading"
                :items-per-page="pagination.pageSize"
                hide-default-footer
                class="px-12 py-4 custom-table"
            >
                <template v-slot:item="{ item }">
                    <dispute-row
                        :item="item"
                        @view-dispute="viewDispute"
                        @load-disputes="loadItems"
                    ></dispute-row>
                </template>
            </v-data-table>
            <v-pagination
                v-model="pagination.page"
                :length="pagination.pageCount"
                next-icon="$next"
                prev-icon="$prev"
                class="custom-pagination"
                color="#7598B5">
            </v-pagination>
        </div>

        <dispute-modal
            :dispute="dispute"
            :visible="showDisputeModal"
            @close="showDisputeModal = false">
        </dispute-modal>
    </div>
</template>

<script>
import DisputesApi from '../../api/DisputesApi';

import AppBar from '../../components/globals/AppBar.vue';
import Search from '../../components/globals/Search.vue';
import DisputeRow from './DisputeRow.vue';
import DisputeModal from './DisputeModal.vue';

export default {
    components: {
        DisputeRow,
        AppBar,
        Search,
        DisputeModal,
    },
    data() {
        return {
            headers: [
                {
                    text: 'Date',
                    align: 'start',
                    sortable: false,
                    class: 'default-font py-2',
                },
                {
                    text: 'Transaction ID',
                    align: 'start',
                    sortable: false,
                    class: 'default-font py-2 text-start',
                },
                {
                    text: 'Match ID',
                    align: 'start',
                    value: 'match_id',
                    sortable: false,
                    class: 'default-font py-2 text-start',
                },
                {
                    text: 'Amount',
                    align: 'start',
                    sortable: false,
                    class: 'default-font py-2 text-start',
                },
                {
                    text: 'Matcher',
                    align: 'start',
                    sortable: false,
                    class: 'default-font py-2 text-start',
                },
                {
                    text: 'Poster Beneficiary',
                    align: 'start',
                    sortable: false,
                    class: 'default-font py-2 text-start',
                },
                {
                    text: 'Status',
                    value: 'status',
                    align: 'start',
                    sortable: false,
                    class: 'default-font py-2 text-start',
                },
                {
                    align: 'center',
                    value: 'action',
                    sortable: false,
                    class: 'default-font py-2 text-center last_cell',
                },
            ],
            search: null,
            loading: false,
            pagination: {
                page: 1,
                pageSize: 10,
            },
            items: [],
            showDisputeModal: false,
            dispute: {},
        };
    },
    methods: {
        async loadItems() {
            try {
                this.loading = true;

                const {
                    type,
                    pagination: {
                        page,
                        pageSize,
                    },
                } = this;

                const { data } = await DisputesApi.getAll({
                    type,
                    page,
                    pageSize,
                });

                const { models, pagination } = data;
                this.items = models;
                this.pagination = pagination;
            } finally {
                this.loading = false;
            }
        },
        viewDispute(item) {
            this.dispute = item;
            this.showDisputeModal = true;
        },
    },
    watch: {
        'pagination.page': {
            immediate: true,
            handler() {
                this.loadItems();
            },
        },
    },
};
</script>
