<template>
    <v-select
        :items="options"
        outlined
        dense
        class="custom-select"
        :value="refundStatusFilter"
        @change="updateRefundStatusFilter"
        multiple
        :menu-props="{ bottom: true, offsetY: true, 'content-class': 'mt-3 tw-rounded-lg' }"
        :disabled="disabled"
    >
        <template v-slot:selection="{ item }">
            <span class="default-font filter-title tw-text-black mr-2">{{ item.text }}</span>
        </template>
    </v-select>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    props: {
        disabled: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        items: [
            {
                text: 'Initiated',
                value: 'initiated',
            },
            {
                text: 'Pending',
                value: 'pending',
            },
            {
                text: 'Completed',
                value: 'completed',
            },
            {
                text: 'Failed',
                value: 'failed',
            },
        ],
    }),
    methods: {
        ...mapActions('transaction', ['updateRefundStatusFilter']),
    },
    computed: {
        ...mapGetters('transaction', ['refundStatusFilter']),
        options() {
            return this.items.map((o) => ({
                ...o,
                disabled: this.disabled,
            }));
        },
    },
};
</script>
