/* eslint-disable no-useless-escape */
import axios from 'axios';
import Vue from 'vue';
import store from '../store';

const routerMode = process.env.VUE_APP_ROUTER_MODE || 'hash';
const base = routerMode === 'hash' ? '/#' : '';

/**
 * A flag to ensure we don't try to fetch the refresh token twice (this breaks things).
 *
 * @type {boolean}
 */
let isAlreadyFetchingAccessToken = false;

/**
 * An array to hold the calls that queued while we were refreshing.
 *
 * @type {*[]}
 */
let subscribers = [];

/**
 * Add Authorization header to every request
 * @param {Object} config axios request object
 */
const addAuthorizationHeader = (config) => {
    const request = config;
    const { authToken } = store.state.auth;
    const token = authToken?.access_token;
    const tokenType = authToken?.token_type;

    if (token) {
        request.headers.Authorization = `${tokenType} ${token}`;
    }

    return {
        ...request,
        withCredentials: false,
    };
};

/**
 * Adds a queued caller.
 *
 * @param callback
 */
function addSubscriber(callback) {
    subscribers.push(callback);
}

/**
 * When we get a valid access token, try all our queued calls again.
 *
 * @param accessToken
 */
function onAccessTokenFetched(accessToken) {
    // When the refresh is successful, we start retrying the requests one by one and empty the queue
    subscribers.forEach((callback) => callback(accessToken));

    subscribers = [];
}

/**
 * Attempts to get a new token, and replays any captured requests if successful.
 *
 * @see  https://www.techynovice.com/setting-up-JWT-token-refresh-mechanism-with-axios/
 *
 * @param error
 * @returns {Promise<unknown>}
 */
async function resetTokenAndReattemptRequest(error) {
    try {
        const { response: errorResponse } = error;

        const kaoshiMoney = JSON.parse(localStorage.getItem('kaoshi-admin'));
        const { authToken } = kaoshiMoney.auth;

        const payload = { refresh_token: authToken.refresh_token };
        if (!payload) {
            // We can't refresh, throw the error anyway.
            return Promise.reject(error);
        }

        /*
         * Proceed to the token refresh procedure. We create a new Promise that will retry the request, clone all
         * the request configuration from the failed request in the error object.
         */
        const retryOriginalRequest = new Promise((resolve) => {
            /*
             * We need to add the request retry to the queue since there another request that already attempt to
             * refresh the token
             */
            addSubscriber((accessToken) => {
                // The original request's configuration (including the form data, base URL etc) is in the error
                // response configuration.
                const originalRequest = errorResponse.config;
                originalRequest.headers.Authorization = `Bearer ${accessToken}`;

                resolve(axios(originalRequest));
            });
        });

        if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true;

            try {
                await store.dispatch('refreshToken', payload);

                isAlreadyFetchingAccessToken = false;
                const newToken = store.state.auth.authToken?.access_token;
                return onAccessTokenFetched(newToken);
            } catch (err) {
                window.location = `${base}/login`;
                isAlreadyFetchingAccessToken = false;
            }
        }

        return retryOriginalRequest;
    } catch (err) {
        return Promise.reject(err);
    }
}

/**
 * Handles the "error" responses.
 *
 * Use the refresh token, if available, to login; otherwise this logs the user out.
 *
 * @param error
 * @returns {Promise<void>}
 */
const handleResponseError = async (error) => {
    if (error instanceof axios.Cancel) {
        return Promise.reject(error.message);
    }

    const { response } = error;
    let errorMessage = '';

    if (!response) {
        let { message } = error;
        if (message === 'Network Error') {
            message = 'You may be experiencing connectivity issues. Check your internet connection';
        }
        errorMessage = message;
    }

    const { data } = response;
    const { message } = data;

    const invalidCredentials = new RegExp('^You entered an invalid username or password$');
    if (message && message.match && message.match(invalidCredentials)) {
        return Promise.reject(message);
    }

    const sessionHasExpired = new RegExp('^(Your access token has expired|Expired user credentials)\.?$');

    if (message && message.match && message.match(sessionHasExpired)) {
        try {
            const result = await resetTokenAndReattemptRequest(error);
            return result;
        } catch (e) {
            // @todo Sending these through the Regular Expression engine is a little silly but it works...
            if (e && typeof e === 'string') {
                const refreshExpired = new RegExp('^Your refresh token has expired\.?$');
                const refreshIsInvalid = new RegExp('^Your refresh token is invalid\.?$');

                if (e.match(refreshExpired) || e.match(refreshIsInvalid)) {
                    store.commit('SET_USER', {});
                    store.commit('SET_AUTH_TOKEN', {});

                    const { handlingSession } = store.state.auth;
                    store.commit('SET_HANDLING_SESSION', false);

                    // If we are handling the session, they'll already have seen (and possibly read!) a dialog
                    // explaining why they've been logged out.
                    if (!handlingSession) {
                        const alertConfig = {
                            toast: false,
                            showCancelButton: false,
                            showConfirmButton: true,

                            backdrop: false,
                            allowOutsideClick: false,

                            type: 'info',
                            title: 'Session Ended',
                            text: 'You were logged out because your session ended.',
                        };

                        Vue.swal.fire(alertConfig);
                    }

                    if (window.location === '/') {
                        window.location = `${base}/login`;
                    } else {
                        window.location = `${base}/`;
                    }

                    return Promise.resolve();
                }
            }

            return Promise.reject(error.response.data.message);
        }
    }

    errorMessage = message || data;

    Vue.swal({
        icon: 'error',
        title: 'Error',
        text: errorMessage,
    });

    return Promise.reject(error);
};

export {
    addAuthorizationHeader,
    handleResponseError,
};
