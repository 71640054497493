import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Developer from '../views/Developer.vue';
import Login from '../components/Auth/Login.vue';
import ResetPassword from '../components/Auth/ResetPassword.vue';
import ResetPasswordInstructions from '../components/Auth/ResetPasswordInstructions.vue';
import Sign from '../components/Auth/Sign.vue';
import Step2 from '../components/Auth/Step2.vue';
import Step3 from '../components/Auth/Step3.vue';
import Step4 from '../components/Auth/Step4.vue';
import Transactions from '../views/Transaction.vue';
import TransactionNew from '../views/TransactionNew.vue';
import TransactionDetails from '../views/TransactionDetails.vue';
import DeveloperRequestAccess from '../views/DeveloperRequestAccess.vue';
import DeveloperLogDetails from '../views/DeveloperLogDetails.vue';
import Offers from '../views/Offers.vue';
import CreateOffer from '../views/CreateOffer.vue';
import SupportThread from '../views/SupportThread.vue';
import NewCase from '../views/OpenCase.vue';
import Profile from '../views/Profile.vue';
import MessageChannel from '../views/MessageChannel.vue';
import CardVerifications from '../views/card-verifications/index.vue';
import DocVerifications from '../views/doc-verifications/DocVerifications.vue';
import ExchangeRates from '../views/exchange-rates/index.vue';
import DisputeResolution from '../views/dispute-resolutions/Disputes.vue';
import HealthInsurancePayments from '../views/health-insurance-payments/HealthInsurancePayments.vue';
import ReferralTransactions from '../views/referral-transactions/ReferralTransactions.vue';
import WithdrawReferralTransactions from '../views/referral-transactions/WithdrawReferralTransactions.vue';
import Users from '../views/users/Users.vue';
import User from '../views/users/User.vue';
import HybridOrderOptions from '../views/hybrid-order-options/HybridOrderOptions.vue';
import Ribbons from '../views/ribbons/ListView.vue';
import ThirdPartyTransactions from '../views/third-party-transactions/Index.vue';

import before from '../middlewares/before';
import role from '../middlewares/before/role';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/developer',
        name: 'Developer',
        component: Developer,
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            guestGuard: true,
        },
    },
    {
        path: '/reset-password',
        name: 'Password Reset',
        component: ResetPassword,
        meta: {
            guestGuard: true,
        },
    },
    {
        path: '/reset-password-instructions',
        name: 'Password Reset Instructions',
        component: ResetPasswordInstructions,
        meta: {
            guestGuard: true,
        },
    },
    {
        path: '/sign',
        name: 'Sign',
        component: Sign,
        meta: {
            guestGuard: true,
        },
    },
    {
        path: '/about-bank',
        name: 'About Bank',
        component: Step2,
        meta: {
            guestGuard: true,
        },
    },
    {
        path: '/billing',
        name: 'Billing',
        component: Step3,
        meta: {
            guestGuard: true,
        },
    },
    {
        path: '/licence',
        name: 'Licensure and compliance',
        component: Step4,
        meta: {
            guestGuard: true,
        },
    },
    {
        path: '/transactions',
        name: 'Transactions',
        component: Transactions,
        meta: {
            title: 'Transactions',
        },
    },
    {
        path: '/transactions-new',
        name: 'TransactionsNew',
        component: TransactionNew,
        meta: {
            title: 'Transactions',
        },
    },
    {
        path: '/transactions/:id',
        name: 'TransactionDetails',
        component: TransactionDetails,
        meta: {
            title: 'Transaction Detail',
        },
        props: true,
    },
    {
        path: '/offers',
        name: 'Offers',
        component: Offers,
        meta: {
            title: 'Global Offers',
        },
    },
    {
        path: '/offers/create',
        name: 'Create Offer',
        component: CreateOffer,
        meta: {
            title: 'Create Offer',
        },
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
    },
    {
        path: '/developer-request',
        name: 'DeveloperRequestAccess',
        component: DeveloperRequestAccess,
    },
    {
        path: '/developer-log-details/:id',
        name: 'DeveloperLogDetails',
        component: DeveloperLogDetails,
    },
    {
        path: '/supports',
        name: 'SupportThread',
        component: SupportThread,
    },
    {
        path: '/new-case',
        name: 'NewCase',
        component: NewCase,
    },
    {
        path: '/supports/:id',
        name: 'SupportChannel',
        component: MessageChannel,
    },
    {
        path: '/card-verifications',
        name: 'Payment Method Authentication',
        component: CardVerifications,
        meta: {
            middleware: [
                role('admin'),
            ],
        },
    },
    {
        path: '/doc-verifications/:type(identity|address)',
        name: 'Document Verification',
        component: DocVerifications,
        props: true,
        meta: {
            middleware: [
                role('admin'),
            ],
        },
    },
    {
        path: '/exchange-rates',
        name: 'Exchange Rates',
        component: ExchangeRates,
    },
    {
        path: '/dispute-resolutions',
        name: 'Dispute Resolutions',
        component: DisputeResolution,
    },
    {
        path: '/health-insurance-payments',
        name: 'Health Insurance Payments',
        component: HealthInsurancePayments,
    },
    {
        path: '/referral-transactions',
        name: 'Referral Transactions',
        component: ReferralTransactions,
    },
    {
        path: '/withdrawal-referral-transactions',
        name: 'Withdrawal Referral Transactions',
        component: WithdrawReferralTransactions,
    },
    {
        path: '/users',
        name: 'Users',
        component: Users,
        meta: {
            title: 'Users',
        },
    },
    {
        path: '/users/:id',
        name: 'User',
        component: User,
        props: true,
        meta: {
            title: 'User',
        },
    },
    {
        path: '/hybrid-order-options',
        name: 'HybridOrderOptions',
        component: HybridOrderOptions,
        meta: {
            title: 'Hybrid Order Options',
        },
    },
    {
        path: '/ribbons',
        name: 'Ribbons',
        component: Ribbons,
        meta: {
            title: 'Ribbons',
        },
    },
    {
        path: '/third-party-transactions',
        name: 'Third Party Transactions',
        component: ThirdPartyTransactions,
        meta: {
            title: 'Third Party Transactions',
        },
    },

];

const router = new VueRouter({
    routes,
    mode: process.env.VUE_APP_ROUTER_MODE || 'hash',
});

router.beforeEach(before);

export default router;
