<template>
    <v-select
        :items="options"
        outlined
        dense
        class="custom-select"
        :value="statusFilter"
        @change="updateStatusFilter"
        multiple
        :menu-props="{ bottom: true, offsetY: true, 'content-class': 'mt-3 tw-rounded-lg' }"
        :disabled="disabled"
        clearable
    >
        <template v-slot:selection="{ item }">
            <span class="default-font filter-title tw-text-black mr-2">{{ item.text }}</span>
        </template>
    </v-select>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    props: {
        disabled: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        items: [
            {
                text: 'Initiated',
                value: 'initiated',
            },
            {
                text: 'Processing',
                value: 'processing',
            },
            {
                text: 'Completed',
                value: 'completed',
            },
            {
                text: 'Failed',
                value: 'failed',
            },
            {
                text: 'Expired',
                value: 'expired',
            },
            {
                text: 'Dispute',
                value: 'dispute',
            },
            {
                text: 'Attention Needed',
                value: 'attention_needed',
            },
        ],
    }),
    methods: {
        ...mapActions('thirdPartyTransaction', ['updateStatusFilter']),
    },
    computed: {
        ...mapGetters('thirdPartyTransaction', ['statusFilter']),
        options() {
            return this.items.map((o) => ({
                ...o,
                disabled: this.disabled,
            }));
        },
    },
};
</script>
