/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
class Model {
    constructor(record) {
        this.fill(record);
    }

    fill = (record) => {
        if (!record) { record = {}; }
        const fields = this.constructor.fields();
        for (const key in fields) {
            const field = fields[key];
            const value = record[key];
            this[key] = value === null ? null : field(value);
        }
    }

    static fields() {
        return {};
    }
}

export default Model;
