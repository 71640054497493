import api from './index';

const resource = 'admin/health-insurance-payments';

export default {
    /**
     * Get all Health Insurance Payments
     * @param {Object} params query params
     */
    getAll(params) {
        return api.get(`${resource}`, {
            params,
        });
    },

    /**
     * Confirm Health Insurance Payment
     * @param {Number} id Health Insurance Payment ID
     */
    confirm(id) {
        return api.post(`${resource}/${id}/confirm`);
    },
};
