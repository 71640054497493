<template>
    <v-app id="inspire">
        <v-navigation-drawer
            app
            v-model="drawer"
            mini-variant
            permanent
            :mini-variant-width="120"
            class="tw-bg-navBg"
        >
            <v-list flat class="d-flex flex-column justify-space-around tw-mt-5 pa-5">
                <v-list-item class="px-2">
                    <v-list-item-avatar :size="70">
                        <v-img src="../assets/logo2.svg"></v-img>
                    </v-list-item-avatar>
                </v-list-item>
                <template v-for="item in items">
                    <v-tooltip right :key="item.title">
                        <template v-slot:activator="{ on, attrs }">
                            <v-list-item
                                v-bind="attrs"
                                v-on="on"
                                link
                                :to="item.link"
                                class="ma-2 tw-rounded-lg hover:tw-bg-linkHoverBg"
                                active-class="tw-bg-primary text--white"
                            >
                                <v-badge
                                    v-on="on"
                                    v-bind="attrs"
                                    overlap
                                    color="error lighten-1"
                                    :content="item.badge"
                                    :value="!!item.badge">
                                    <v-list-item-avatar tile size=25>
                                        <v-icon :color="iconColor(item.link)">{{item.icon}}</v-icon>
                                    </v-list-item-avatar>
                                </v-badge>
                            </v-list-item>
                        </template>
                        <span>{{ item.title }}</span>
                    </v-tooltip>
                </template>

                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-list-item v-bind="attrs" v-on="on" class="tw-rounded-lg mt-5 hover:tw-bg-linkHoverBg">
                            <MaintenanceButton />
                        </v-list-item>
                    </template>
                    <span>Maintenance Mode</span>
                </v-tooltip>

                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-list-item
                            v-on="on"
                            v-bind="attrs"
                            @click="logout"
                            class="tw-rounded-lg mt-5 hover:tw-bg-linkHoverBg"
                        >
                            <v-list-item-avatar tile size=25>
                                <v-icon :color="iconColor('logout')">$logout</v-icon>
                            </v-list-item-avatar>
                        </v-list-item>
                    </template>
                    <span>Logout</span>
                </v-tooltip>
            </v-list>
        </v-navigation-drawer>
        <v-main>
            <v-slide-y-transition mode="out-in">
                <router-view />
            </v-slide-y-transition>
        </v-main>
    </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Socket from '../services/Socket';
import UsersApi from '../api/UsersApi';
import DocVerificationApi from '../api/DocVerificationApi';

import MaintenanceButton from '../components/globals/MaintenanceButton.vue';

export default {
    props: {
        source: String,
    },
    components: {
        MaintenanceButton,
    },
    data() {
        return {
            drawer: true,
            show: false,
            identityVerificationsCount: 0,
            addressVerificationsCount: 0,
        };
    },
    computed: {
        ...mapGetters([
            'user',
        ]),
        ...mapGetters('notifications', [
            'cardVerificationNotificationsCount',
        ]),
        items() {
            return [
                { title: 'Create Order', icon: '$vuetify.icons.plus', link: '/offers/create' },
                { title: 'Transactions', icon: '$vuetify.icons.transaction', link: '/transactions' },
                { title: 'Third Party Transactions', icon: '$vuetify.icons.transaction', link: '/third-party-transactions' },
                ...(this.user.role === 'admin'
                    ? [{
                        title: 'Payment Method Authentication',
                        icon: 'mdi-credit-card  ',
                        link: '/card-verifications',
                        badge: this.cardVerificationNotificationsCount,
                    },
                    {
                        title: 'Identity Verification',
                        icon: 'mdi-identifier',
                        link: '/doc-verifications/identity',
                        badge: this.identityVerificationsCount,
                    },
                    {
                        title: 'Address Verification',
                        icon: 'mdi-map-marker-outline',
                        link: '/doc-verifications/address',
                        badge: this.addressVerificationsCount,
                    }] : []
                ),
                { title: 'Exchange Rate Limit', icon: 'mdi-chart-line-variant', link: '/exchange-rates' },
                // { title: 'Offers', icon: '$vuetify.icons.offer', link: '/offers' },
                { title: 'Users', icon: 'mdi-account-group-outline', link: '/users' },
                { title: 'Developer', icon: '$vuetify.icons.developer', link: '/developer' },
                { title: 'Support', icon: '$vuetify.icons.support', link: '/supports' },
                { title: 'Dispute Resolution', icon: 'mdi-cogs', link: '/dispute-resolutions' },
                { title: 'Hybrid Order Options', icon: '$vuetify.icons.transaction', link: '/hybrid-order-options' },
                { title: 'Ribbons', icon: 'mdi-ribbon', link: '/ribbons' },
                { title: 'Referrals', icon: 'mdi-link-box-outline', link: '/referral-transactions' },
            ];
        },
    },
    methods: {
        ...mapActions('notifications', [
            'storeCardVerificationNotifications',
        ]),
        iconColor(link) {
            return this.$route.path === link ? 'white' : 'iconColor';
        },
        async logout() {
            try {
                await this.$store.dispatch('logout');
                this.$router.push({
                    name: 'Login',
                });
            } catch (error) {
                this.$swal({
                    type: 'error',
                    title: 'Error logout',
                    text: error,
                });
            }
        },

        async loadCardVerNotifications() {
            const { data } = await UsersApi.getNotifications(this.user?.id, {
                object_type: 'card_verification_request',
                status: 'unread',
            });
            this.storeCardVerificationNotifications(data);
        },

        async loadIdentityVerificationsCount() {
            const { data: { pagination } } = await DocVerificationApi.getAll({
                type: 'identity',
                status: 'unapproved',
                pageSize: 1,
            });

            this.identityVerificationsCount = pagination.rowCount;
        },

        async loadAddressVerificationsCount() {
            const { data: { pagination } } = await DocVerificationApi.getAll({
                type: 'address',
                status: 'unapproved',
                pageSize: 1,
            });

            this.addressVerificationsCount = pagination.rowCount;
        },

        loadDocVerificationsCount() {
            this.loadIdentityVerificationsCount();
            this.loadAddressVerificationsCount();
        },

        addSocketEventListeners() {
            Socket.on('docVerificationRequestCreated', this.loadDocVerificationsCount);
            Socket.on('docVerificationRequestUpdated', this.loadDocVerificationsCount);
            Socket.on('docVerificationRequestDestroyed', this.loadDocVerificationsCount);
            Socket.on('cardVerificationRequestCreated', this.loadCardVerNotifications);
        },

        removeSocketEventListeners() {
            Socket.remove('docVerificationRequestCreated');
            Socket.remove('docVerificationRequestUpdated');
            Socket.remove('docVerificationRequestDestroyed');
            Socket.remove('cardVerificationRequestCreated');
        },
    },
    mounted() {
        this.loadDocVerificationsCount();
        this.loadCardVerNotifications();
        this.addSocketEventListeners();
    },
    beforeDestroy() {
        this.removeSocketEventListeners();
    },
};
</script>
<style scoped>
.logo-name {
  width: inherit !important;
  height: initial !important;
  position: relative !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 131.5% !important;
  top: 7.95%;
}
.nav-link:hover::before {
    opacity: 0.04;
}
</style>
