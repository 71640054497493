<template>
  <v-card>
    <div class="menu-arrow" style="left: 35px;"></div>
    <v-row class="px-4 justify-center">
      <v-col cols="6">
        <label class="default-font filter-title tw-text-secondary mb-2 d-block">From:</label>
        <input
          class="sent-filter t-filter f-input default-font pa-2 tw-border-binput focus:tw-border-primary"
          v-model="from"
          placeholder="Any amount"
          :disabled="disabled"
        />
      </v-col>
      <v-col cols="6">
        <label class="default-font filter-title tw-text-secondary mb-2 d-block">To:</label>
        <input
          class="sent-filter t-filter f-input default-font pa-2 tw-border-binput focus:tw-border-primary"
          v-model="to"
          placeholder="Any amount"
          :disabled="disabled"
        />
      </v-col>
    </v-row>

    <v-card-actions>
      <v-btn
        outlined
        width="100%"
        color="primary"
        @click="closeMenu"
        :disabled="disabled"
      >
        Apply
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
    props: {
      disabled: {
        type: Boolean,
        default: true,
      },
    },
    data() {
        return {
            to: undefined,
            from: undefined,
        };
    },
    methods: {
        closeMenu() {
            const { from, to } = this;
            this.$emit('apply-filter', { from, to });
        },
    },
};
</script>
