<template>
    <div>
        <app-bar>
            <template slot="title">
                <v-toolbar-title class="default-font font-21 mr-10 text--primary">Exchange Rate Limit</v-toolbar-title>
            </template>
            <div class="d-flex justify-space-between mr-10">
                <search v-model="search" placeholder="Search by Country Name..."></search>
            </div>
        </app-bar>
        <filters
            :countries="countries"
            :filters.sync="filters"
            @apply-filter="applyFilters"
            @show-rate-form="() => editItem({})"
        ></filters>
        <div class="d-flex flex-column">
            <v-data-table
                item-key="id"
                :headers="headers"
                :items="items"
                :search="search"
                :loading="loading"
                :items-per-page="pagination.pageSize"
                hide-default-footer
                class="px-12 py-4 custom-table"
            >
                <template #item="{ item }">
                    <RateRow
                        :item="item"
                        @edit="editItem"
                        @delete="deleteItem"
                    >
                    </RateRow>
                </template>
            </v-data-table>
            <v-pagination
                v-model="pagination.page"
                next-icon="$next"
                prev-icon="$prev"
                class="custom-pagination"
                color="#7598B5"
                :length="pagination.pageCount"
            ></v-pagination>
        </div>

        <NewRate
            :visible="newRateDialog"
            :countries="countries"
            :model="item"
            @save="saveItem"
            @close="closeDialog"
        >
        </NewRate>
    </div>
</template>
<script>
import AppBar from '@/components/globals/AppBar.vue';
import Search from '@/components/globals/Search.vue';
import CountriesApi from '../../api/CountriesApi';
import ExchangeRateLimitsApi from '../../api/ExchangeRateLimitsApi';
import RateRow from './RateRow.vue';
import Filters from './Filters.vue';
import NewRate from './NewRate.vue';

export default {
    components: {
        AppBar,
        Search,
        RateRow,
        Filters,
        NewRate,
    },
    data() {
        return {
            countries: [],
            headers: [
                {
                    text: 'From',
                    align: 'start',
                    value: 'from',
                    sortable: false,
                    class: 'default-font py-2',
                },
                {
                    text: 'To',
                    align: 'start',
                    value: 'to',
                    sortable: false,
                    class: 'default-font py-2 text-start',
                },
                {
                    text: 'Min Exchange Rate',
                    align: 'start',
                    value: 'min_exchange_rate',
                    sortable: false,
                    class: 'default-font py-2 text-start',
                },
                {
                    text: 'Max Exchange Rate',
                    align: 'start',
                    value: 'max_exchange_rate',
                    sortable: false,
                    class: 'default-font py-2 text-start',
                },
                {
                    align: 'center',
                    value: 'action',
                    sortable: false,
                    class: 'default-font py-2 text-center last_cell',
                },
            ],
            loading: false,
            items: [],
            item: {},
            pagination: {
                page: 1,
                pageSize: 10,
            },
            search: null,
            filters: {},
            newRateDialog: false,
        };
    },
    methods: {
        async loadCountries() {
            try {
                const { data: { data } } = await CountriesApi.getAll();
                data.push({
                    abbreviation: 'NG',
                    country: 'Nigeria',
                    continent: 'Africa',
                    currency: 'USD',
                    sending: 0,
                    receiving: 1,
                    skip_payment_method_check: 0,
                    locale: 'ng',
                });
                const countries = data.map((country) => ({
                    ...country,
                    key: `${country.abbreviation}-${country.currency}`,
                }));
                this.$set(this, 'countries', countries);
            } catch (error) {
                this.$swal({
                    icon: 'error',
                    title: 'Error loading countries',
                    text: error,
                });
            }
        },

        saveItem(item) {
            if (item.id) {
                return this.updateItem(item);
            }
            return this.createItem(item);
        },

        async createItem(payload) {
            try {
                this.loading = true;
                await ExchangeRateLimitsApi.create(payload);

                this.$swal({
                    icon: 'success',
                    title: 'Exchange Rate Limit created!',
                });

                this.closeDialog();
                await this.loadItems();
            } catch (error) {
                this.$swal({
                    icon: 'error',
                    title: 'Error creating Exchange Rate Limit',
                    text: error,
                });
            } finally {
                this.loading = false;
            }
        },

        async loadItems() {
            try {
                this.loading = true;

                const {
                    filters,
                    pagination: {
                        page,
                        pageSize,
                    },
                } = this;

                const { data } = await ExchangeRateLimitsApi.getAll({
                    ...filters,
                    page,
                    pageSize,
                });

                const { data: models, pagination } = data;
                this.items = models;
                this.pagination = pagination;
            } finally {
                this.loading = false;
            }
        },

        async updateItem(item) {
            try {
                const { isConfirmed } = await this.$swal({
                    icon: 'warning',
                    title: 'Are You Sure?',
                    text: 'Are You sure to update this Exchange Rate Limit?',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                });

                if (!isConfirmed) return;

                this.loading = true;
                await ExchangeRateLimitsApi.update(item);

                this.$swal({
                    icon: 'success',
                    title: 'Exchange Rate Limit updated!',
                });

                await this.loadItems();
            } catch (error) {
                this.$swal({
                    icon: 'error',
                    title: 'Error updating Exchange Rate Limit',
                    text: error,
                });
            } finally {
                this.loading = false;
            }
        },

        async deleteItem(item) {
            try {
                const { isConfirmed } = await this.$swal({
                    icon: 'warning',
                    title: 'Are You Sure?',
                    text: 'Are You sure to remove this Exchange Rate Limit?',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                });

                if (!isConfirmed) return;

                this.loading = true;
                await ExchangeRateLimitsApi.delete(item.id);

                this.$swal({
                    icon: 'success',
                    title: 'Exchange Rate Limit deleted!',
                });

                await this.loadItems();
            } catch (error) {
                this.$swal({
                    icon: 'error',
                    title: 'Error deleting Exchange Rate Limit',
                    text: error,
                });
            } finally {
                this.loading = false;
            }
        },

        editItem(item = {}) {
            this.$set(this, 'item', item);
            this.newRateDialog = true;
        },

        closeDialog() {
            this.$set(this, 'item', {});
            this.newRateDialog = false;
        },

        applyFilters() {
            // this.durations = filters.durations;
            // this.ratings = filters.ratings;
            // this.deliveryMethods = filters.deliveryMethods;
            // this.payments = filters.payments;
            // this.showFilters = false;
        },
    },
    watch: {
        filters: {
            deep: true,
            handler() {
                this.loadItems();
            },
        },
        'pagination.page': {
            immediate: true,
            handler() {
                this.loadItems();
            },
        },
    },
    mounted() {
        this.loadCountries();
    },
};
</script>
