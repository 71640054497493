<template>
    <div  class="tw-w-full">
        <app-bar>
            <template slot="title">
                <v-toolbar-title class="default-font font-21 mr-10">Developer Tools</v-toolbar-title>
            </template>
            <div class="d-flex justify-space-between tw-pr-20 tw-pl-10">
                <search v-model="search" placeholder="Search Webhooks"></search>
                <div>
                    <v-btn
                        text
                        color="primary"
                        href="https://kaoshi-staging.shanenelson.ca/docs/bank-api/"
                        target="_blank"
                        class="text-capitalize default-font tw-text-sm"
                    >
                        <v-icon small>$vuetify.icons.launch</v-icon>API documentation
                    </v-btn>
                </div>
            </div>
        </app-bar>

        <v-row class="tw-w-full tw-mt-10 mx-0 tw-absolute ml-n10">
            <v-tabs v-model="tab" class="tw-pl-20 border-btm">
                <v-tab
                    v-for="item in items"
                    class="text-capitalize default-font"
                    :key="item.title">{{item.title}}</v-tab>
            </v-tabs>
            <v-tabs-items class="col-12 mt-10 tw-ml-20 tw-mr-5" v-model="tab">
                <v-tab-item >
                    <api-logs-tab></api-logs-tab>
                </v-tab-item>

                <v-tab-item >
                    <api-status-tab></api-status-tab>
                </v-tab-item>

                <v-tab-item >
                    <webhooks-tab></webhooks-tab>
                </v-tab-item>

                <v-tab-item >
                    <whitelist-ip-tab></whitelist-ip-tab>
                </v-tab-item>

                <v-tab-item>
                    <api-keys-tab></api-keys-tab>
                </v-tab-item>
            </v-tabs-items>
        </v-row>
    </div>
</template>

<script>
// @ is an alias to /src
import AppBar from '@/components/globals/AppBar.vue';
import ApiLogsTab from '@/components/Developer/ApiLogsTab.vue';
import ApiStatusTab from '@/components/Developer/ApiStatusTab.vue';
import WebhooksTab from '@/components/Developer/WebhooksTab.vue';
import WhitelistIpTab from '@/components/Developer/WhitelistIpTab.vue';
import ApiKeysTab from '@/components/Developer/ApiKeysTab.vue';
import Search from '@/components/globals/Search.vue';

export default {
    name: 'Developer',
    components: {
        AppBar,
        ApiLogsTab,
        ApiStatusTab,
        WebhooksTab,
        WhitelistIpTab,
        ApiKeysTab,
        Search,
    },
    data() {
        return {
            tab: null,
            items: [
                { title: 'API Logs', content: 'Content 1' },
                { title: 'API Status', content: 'Content 2' },
                { title: 'Webhooks', content: 'Content 3' },
                { title: 'Whitelist IP', content: 'Content 4' },
                { title: 'API Keys', content: 'Content 5' },
            ],
            search: null,
        };
    },
};
</script>
<style>
.v-tab--active{
  color: #2E5677 !important;
}
.border-btm {
  border-bottom: 1px solid #EAF0F5;
}
</style>
