var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{staticClass:"custom-select",attrs:{"value":_vm.matcherCountryFilter,"items":_vm.items,"menu-props":{
        bottom: true,
        offsetY: true,
        'content-class': 'mt-3 tw-rounded-lg',
    },"outlined":"","dense":"","disabled":_vm.disabled},on:{"change":_vm.updateMatcherCountryFilter},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"default-font filter-title tw-text-black d-flex tw-w-full"},[_vm._v(" "+_vm._s(item.text)+" ")])]}},{key:"selection",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"default-font filter-title tw-text-black"},[_vm._v(" "+_vm._s(item.text)+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }