<template>
  <div class="tw-w-full d-flex list-container mb-4">
    <div class="tw-w-1/5 d-flex justify-center flex-column px-2 border-right">
     <v-tooltip right v-if="item.service.garanty_by" color="white" content-class="guaranty tw-rounded-lg">
      <template v-slot:activator="{ on }">
        <img :src="item.service.logo_url" class="tw-h-24 tw-w-24 mx-auto tw-bg-transparent mt-5" v-on="on"/>
      </template>
      <div class="d-flex flex-column justify-center align-cender pa-4">
        <span class="default tw-font-normal font-14 tw-text-secondary"> Guaranted by</span>
        <span class="default-font tw-font-extrabold font-14 tw-text-black"> {{item.service.garanty_by}}</span>
        <img :src="item.service.garanty_by_logo" class="tw-h-16 tw-w-16 mx-auto tw-bg-transparent"/>
      </div>
    </v-tooltip>
    <img v-else :src="item.service.logo_url" class="tw-h-24 tw-w-24 mx-auto tw-bg-transparent"/>
      <div class="d-flex justify-center t-w-full mt-2 mb-5">
        <span class="default-font font-15 tw-font-bold">{{item.rate}}</span>
        <v-rating
          x-small
          dense
          color="#F07877"
          background-color="#BED1E1"
          readonly
          :value="Number.parseInt(Math.round(item.rate))"
          class="ml-1"
        ></v-rating>
      </div>
    </div>
    <div class="tw-w-3/5 border-right">
      <div class="tw-w-full d-flex justify-center my-6">
        <div class="default-font">
          <div class="tw-text-dark tw-font-normal">
            <span class="amount mr-1">{{ item.sent | price }}</span>
            <span class="currency">{{item.from_currency}}</span>
          </div>
          <span class="default-font tw-text-secondary tw-font-normal font-14">You send</span>
        </div>
        <div class="tw-relative d-flex align-start pt-2 justify-center">
          <v-tooltip
              bottom
              content-class="tw-border tw-border-rounded tw-bg-navBg tw-border-dark px-5 py-2"
            >
              <template v-slot:activator="{ on }">
                <v-icon class="mx-2" v-on="on">{{paymentMethods.icon}}</v-icon>
              </template>
              <span>Payment: {{ paymentMethods.text }}</span>
            </v-tooltip>
          <svg
            width="184"
            height="30"
            viewBox="0 0 184 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.60791 15.0598H179.018"
              stroke="#A9C1D4"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-dasharray="2 5"
            />
            <path
              d="M178.979 18.5967L182.516 15.0598M182.516 15.0598L178.979 11.5229M182.516 15.0598H178.046"
              stroke="#A9C1D4"
              stroke-width="1.5"
              stroke-linecap="round"
            />
            <rect
              x="46.373"
              y="0.0861816"
              width="86.6197"
              height="29.1044"
              rx="14.5522"
              fill="white"
            />
          </svg>

          <div class="tw-absolute tw-inset-x-auto">
            <span class="amount default-font font-14 tw-text-dark">0-{{item.duration}} days</span>
          </div>
           <v-tooltip
              bottom
              content-class="tw-border tw-border-rounded tw-bg-navBg tw-border-dark px-5 py-2"
            >
              <template v-slot:activator="{ on }">
                <v-icon class="mx-2" v-on="on">{{deliveryMethods.icon}}</v-icon>
              </template>
              <span>Delivery: {{ deliveryMethods.text }}</span>
            </v-tooltip>
        </div>
        <div class="default-font ml-4 text-right">
          <div class="tw-text-dark tw-font-normal">
            <span class="amount mr-1">{{ item.received | price }}</span>
            <span class="currency">{{item.to_currency}}</span>
          </div>
          <span class="default-font tw-text-secondary tw-font-normal font-14">Recepient gets</span>
        </div>
      </div>
      <div class="tw-w-full d-flex justify-center mt-2">
          <div class="mr-4">

          <label class="tw-inline-block default-font tw-font-normal tw-text-secondary font-14 mr-2">Exchange rate:</label>
          <div class=" tw-inline-block tw-text-dark tw-font-normal font-15">
            <span class="mr-1">{{ item.exchange_rate | price }}</span>
            <span class="currency">{{item.to_currency}}</span>
          </div>
          </div>
          <div>
          <label class="tw-inline-block default-font tw-font-normal tw-text-secondary font-14 mr-2">Transfer fee:</label>
          <div class=" tw-inline-block tw-text-dark tw-font-normal font-15">
            <span class="currency">$</span>
            <span class="mr-1">{{ item.transferr_fee | price }}</span>
          </div>
          </div>
      </div>
    </div>
    <div class="tw-w-1/5 d-flex justify-center align-center flex-column">
      <v-icon color="primary" large>mdi-gift-outline</v-icon>
      <p class="text-center default-font  font-14 tw-text-dark tw-max-w-1/2 mb-0">Recieve a ${{item.gift_amount}} {{item.gift}}</p>
      <p class="text-center default-font tw-font-normal  font-14 tw-text-dark font-13 tw-max-w-2/3">Promocode: {{item.promo_code}}</p>
    </div>
  </div>
</template>
<script>
export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    computed: {
        paymentMethods() {
            if (this.item.payment_method === 'card') {
                return {
                    text: 'Credit card',
                    icon: '$card',
                };
            }
            return {
                text: 'Bank account ',
                icon: '$bank',
            };
        },
        deliveryMethods() {
            if (this.item.delivery_method === 'card') {
                return {
                    text: 'Credit card',
                    icon: '$card',
                };
            }
            return {
                text: 'Bank account ',
                icon: '$bank',
            };
        },
    },
};
</script>
<style scoped>
.list-container {
  background: #f7fbfc;
  box-shadow: 0px 8px 33px rgba(224, 224, 224, 0.1);
  border-radius: 9px;
}
.border-right {
  border-right: 1px solid rgba(201, 205, 221, 0.3);
}
.amount {
  /* or 27px */
  font-size: 29px;
}
.guaranty {
  border: 1px solid #EAEFF8;
box-sizing: border-box;

/* Profile shadow */
box-shadow: 0px 18px 50px rgba(94, 101, 140, 0.15);
}
</style>
