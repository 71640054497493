<template>
    <v-simple-table>
        <template v-slot:default>
            <tbody>
                <tr>
                    <td>Order ID</td>
                    <td>{{ item.id }}</td>
                </tr>
                <tr>
                    <td>Type</td>
                    <td>{{ item.type }}</td>
                </tr>
                <tr>
                    <td>Status</td>
                    <td><transaction-status :item="item" /></td>
                </tr>
                <tr>
                    <td> Partial Match Allowed </td>
                    <td> {{ item.is_partial_match_allowed ? "Yes": "No" }} </td>
                </tr>
                <tr>
                    <td>Poster Amount</td>
                    <td>
                        <span class="tw-text-black">{{item.poster_currency}}</span> {{ getPosterAmount(item) | price }}
                    </td>
                </tr>
                <tr>
                    <td>Matcher Amount</td>
                    <td>
                        <span class="tw-text-black">
                            {{item.matcher_currency}}
                        </span>
                        {{ getMatcherAmount(item) | price }}
                    </td>
                </tr>
                <tr v-if="item.match">
                    <td>Matcher Amount in Matcher Currency</td>
                    <td>
                        <span class="tw-text-black">
                            {{item.match.matcher_currency}}
                        </span>
                        {{ item.match.matcher_amount | price }}
                    </td>
                </tr>
                <tr>
                    <td>Exchange Rate</td>
                    <td>
                        {{ item.exchange_rate | price }}
                    </td>
                </tr>
                <tr>
                    <td>Poster Fee</td>
                    <td>
                        <span>{{item.poster_currency}}</span> {{ getPosterFee(item) | price }}
                    </td>
                </tr>
                <tr v-if="item.match">
                    <td>Matcher Fee</td>
                    <td>
                        <span>{{item.type === 'hybrid' ? 'USD' : item.matcher_currency}}</span>
                        {{ item.match.matcher_fee | price }}
                    </td>
                </tr>
                <tr v-if="item.match">
                    <td>Matcher Fee in Matcher Currency</td>
                    <td>
                        <span>{{item.match.matcher_currency}}</span>
                        {{ item.match.matcher_payment_method_fee | price }}
                    </td>
                </tr>
                <tr>
                    <td>Collection Fee</td>
                    <td>
                        <span>{{item.poster_currency}}</span>
                        {{ item.collection_fee | price }}
                    </td>
                </tr>
                <tr>
                    <td>Payout Fee</td>
                    <td>
                        <span>{{item.poster_currency}}</span>
                        {{ item.poster_payout_fee | price }}
                    </td>
                </tr>
                <tr>
                    <td>Stamp Duty Fee</td>
                    <td>
                        <span>{{item.poster_currency}}</span>
                        {{ item.stampduty_fee | price }}
                    </td>
                </tr>
                <tr>
                    <td>Posted Date</td>
                    <td>
                        <span v-if="item.posted_at">{{ item.posted_at | date }}</span>
                        <span v-if="item.posted_at"> at {{ item.posted_at | time }}</span>
                    </td>
                </tr>
                <tr>
                    <td>Confirmed Date</td>
                    <td>
                        <span v-if="item.confirmed_at">{{ item.confirmed_at | date }}</span>
                        <span v-if="item.confirmed_at"> at {{ item.confirmed_at | time }}</span>
                    </td>
                </tr>
                <tr>
                    <td>Completed/Cancelled/Removed Date</td>
                    <td>
                        <div v-if="item.completed_at">
                            <span>{{ item.completed_at | date }}</span>
                            <span> at {{ item.completed_at | time }}</span>
                        </div>
                        <div v-if="item.cancelled_at">
                            <span>{{ item.cancelled_at | date }}</span>
                            <span> at {{ item.cancelled_at | time }}</span>
                        </div>
                        <div v-if="item.removed_at">
                            <span>{{ item.removed_at | date }}</span>
                            <span> at {{ item.removed_at | time }}</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td> Memo </td>
                    <td> {{ item.admin_memo }} </td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
import TransactionStatus from '@/components/transactions/TransactionStatus.vue';

import TransactionMixin from '../../mixins/transactions';

export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    mixins: [
        TransactionMixin,
    ],
    components: {
        TransactionStatus,
    },
    data: () => ({}),
    methods: {
        //
    },
};
</script>
