import axios from 'axios';

class UnifiedApiService {
    static getAxiosInstance() {
        if (!process.env.VUE_APP_UNIFIED_API_URL) {
            throw new Error('Missed VUE_APP_UNIFIED_API_URL environment variable');
        }

        if (!process.env.VUE_APP_UNIFIED_API_TOKEN) {
            throw new Error('Missed VUE_APP_UNIFIED_API_TOKEN environment variable');
        }

        const instance = axios.create({
            baseURL: process.env.VUE_APP_UNIFIED_API_URL,
        });

        // eslint-disable-next-line dot-notation
        instance.defaults.headers.common['Authorization'] = `Bearer ${process.env.VUE_APP_UNIFIED_API_TOKEN}`;

        return instance;
    }

    static getBanks(params) {
        const instance = this.getAxiosInstance();
        return instance.get('/config/banks', {
            params,
        });
    }
}

export default UnifiedApiService;
