<template>
    <v-app>
        <v-main class="tw-bg-secondary">
            <v-container fluid fill-height align-baseline>
                <v-row class="ma-0">
                    <v-col cols=12>
                        <v-row class="justify-center align-center">
                            <logo/>
                        </v-row>
                        <v-slide-y-transition mode="out-in">
                            <router-view></router-view>
                        </v-slide-y-transition>
                    </v-col>
                </v-row>
                <div class="d-flex tw-absolute justify-center tw-bottom-0 pa-2 tw-w-full">
                    <p class="copyright">© 2019 Kasohi. All rights reserved.</p>
                    <span  class="copyright">Privacy Policy</span>
                </div>
            </v-container>
        </v-main>
  </v-app>
</template>

<script>
import Logo from '../components/Logo.vue';

export default {
    components: { Logo },
};
</script>

<style scoped>
.copyright {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 136.5%;

    /* or 16px */
    text-align: center;

    /* TEXT */
    color: #1A244E;
    opacity: 0.5;
    margin-bottom: 0px;
}
</style>
