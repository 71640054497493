<template>
    <div>
        <td v-if="item.status"
            class="py-2" >
            <v-icon
                v-if="item.status === 'approved'"
                color="success"
            >
                mdi-check
            </v-icon>
            <v-alert
                v-else
                dense
                outlined
                type="error"
                class="caption"
                max-width="150"
            >
                {{ item.status }}
            </v-alert>
        </td>
        <td v-else
            class="py-2">
            <v-menu
                content-class="mt-3 tw-rounded-lg"
                transition="slide-y-transition"
                offset-y
                bottom>
                <template #activator="{ on }">
                    <v-btn
                        color="secondary"
                        dark
                        outlined
                        elevation="0"
                        large
                        class="default-font font-12 text-capitalize justify-space-between"
                        v-on="on">
                        Actions
                        <v-icon x-small class="ml-2">mdi-chevron-down</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item>
                        <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                            <v-btn
                                :disabled="!isAdmin"
                                class="text-capitalize default-font"
                                text
                                @click="approve">
                                Accept
                            </v-btn>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                            <v-btn
                                class="text-capitalize default-font"
                                text
                                @click="decline">
                                Cancel
                            </v-btn>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </td>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters([
            'user',
        ]),
        isAdmin() {
            return this.user.role === 'admin';
        },
    },
    methods: {
        approve() {
            this.$emit('approve');
        },
        decline() {
            this.$emit('decline');
        },
    },
};
</script>
