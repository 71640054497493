<template>
    <v-row class="ma-0">
        <v-col cols=12>
            <v-row class="mt-8">
                <v-card
                    id="loginCard"
                    class="mx-auto pl-7 pr-7 pt-5 pb-5 tw-border-none"
                    max-width="500"
                    outlined
                >
                    <v-card-title
                        id="logTitle"
                        class="justify-center">
                        Check Your Email!
                    </v-card-title>
                    <v-card-subtitle class="justify-center mt-5">
                        <p class="text-center font-15 reset__subtitle">Instructions for resetting your password will be waiting for you there.</p>
                    </v-card-subtitle>
                    <v-card-text>
                        <p class="body-2 indigo--text mb-0 pa-6">
                            Didn't get a password reset link in the email?
                            <br/>
                            <router-link :to="{ path: 'password-reset' }">
                                <strong class="try-again">Try Again</strong>
                            </router-link>
                        </p>
                    </v-card-text>
                </v-card>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
export default {
};
</script>
