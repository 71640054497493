<template>
    <div class="tw-w-full">
        <app-bar>
            <template #title>
                <v-toolbar-title class="default-font font-21 mr-10 tw-text-black">
                    Ribbons
                </v-toolbar-title>
            </template>
        </app-bar>

        <v-container fluid>
            <v-row class="mb-4">
                <v-col>
                  <v-btn
                    text
                    @click="openForm()"
                  >
                      Create new ribbon
                  </v-btn>
                </v-col>
            </v-row>

            <v-data-iterator
              :items="items"
              :items-per-page="4"
              :page.sync="pagination.page"
              @page-count="pagination.pageCount = $event"
              hide-default-footer
            >
                <template v-slot:item="{ item }">
                    <ribbon-row
                      @edit="openForm(item)"
                      @publish="publish(item.id)"
                      @unpublish="unpublish(item.id)"
                      @remove="remove(item.id)"
                      :item="item"
                    />
                </template>
                <template v-slot:footer>
                    <v-pagination
                        v-model="pagination.page"
                        next-icon="$next"
                        prev-icon="$prev"
                        class="custom-pagination"
                        color="#7598B5"
                        :length="pagination.pageCount"
                    ></v-pagination>
                </template>
            </v-data-iterator>
        </v-container>

        <v-overlay
            :value="loading"
            absolute>
            <v-progress-circular
                :size="70"
                :width="7"
                indeterminate>
            </v-progress-circular>
        </v-overlay>

        <v-dialog
            v-model="showForm"
            max-width="500"
            tabindex="-1"
            role="dialog"
            aria-labelledby="confirmOrderModal"
            aria-hidden="true"
        >
            <RibbonForm
                v-if="showForm"
                :value="selectedItem"
                @submit="onFormSubmit"
            />
        </v-dialog>
    </div>
</template>

<script>
import RibbonsApi from '../../api/RibbonsApi';
import AppBar from '../../components/globals/AppBar.vue';
import RibbonRow from './RibbonRow.vue';
import RibbonForm from './Form.vue';

export default {
    components: {
        AppBar,
        RibbonRow,
        RibbonForm,
    },
    data() {
        return {
            loading: false,
            items: [],
            pagination: {
                page: 1,
                pageSize: 10,
            },
            showForm: false,
            selectedItem: null,
        };
    },
    mounted() {
        this.loadItems();
    },
    watch: {
        showForm(newValue, oldValue) {
            if (!newValue && oldValue) {
                this.selectedItem = null;
            }
        },
    },
    methods: {
        async loadItems() {
            try {
                this.loading = true;

                const {
                    pagination: {
                        page,
                        pageSize,
                    },
                } = this;

                const { data: { models, pagination } } = await RibbonsApi.getAll({
                    page,
                    pageSize,
                });

                this.items = models;
                this.pagination = pagination;
            } catch (error) {
                this.$swal({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                });
            } finally {
                this.loading = false;
            }
        },
        openForm(item) {
            this.showForm = true;
            this.selectedItem = item;
        },
        onFormSubmit(content) {
            if (this.selectedItem) {
                this.update(this.selectedItem.id, {
                    content,
                });
            } else {
                this.create({
                    content,
                });
            }
        },
        closeDialog() {
            this.showForm = false;
            this.selectedItem = null;
        },
        async update(id, payload) {
            try {
                this.loading = true;
                await RibbonsApi.update(id, payload);

                this.closeDialog();
                this.loadItems();
            } catch (error) {
                this.$swal({
                    icon: 'error',
                    title: 'Error updating ribbon',
                    text: error,
                });
            } finally {
                this.loading = false;
            }
        },
        async create(payload) {
            try {
                this.loading = true;
                await RibbonsApi.create(payload);

                this.closeDialog();
                this.loadItems();
            } catch (error) {
                this.$swal({
                    icon: 'error',
                    title: 'Error creating ribbon',
                    text: error,
                });
            } finally {
                this.loading = false;
            }
        },
        publish(id) {
            return this.update(id, {
                is_active: true,
            });
        },
        unpublish(id) {
            return this.update(id, {
                is_active: false,
            });
        },
        async remove(id) {
            try {
                this.loading = true;
                await RibbonsApi.delete(id);

                this.loadItems();
            } catch (error) {
                this.$swal({
                    icon: 'error',
                    title: 'Error creating ribbon',
                    text: error,
                });
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
