import api from './index';

const resource = 'admin/doc-verification';

export default {
    /**
     * Get all Document Verification Requests
     * @param {Object} params query params
     */
    getAll(params) {
        return api.get(`${resource}`, {
            params,
        });
    },

    /**
     * Approve Document Verification Request
     * @param {Number} id Document Verification ID
     */
    approve(id) {
        return api.post(`${resource}/${id}/approve`);
    },

    /**
     * Decline Document Verification Request
     * @param {Object} payload Document Verification Updated payload
     * @param {Number} payload.id Document Verification ID
     */
    decline(payload) {
        return api.post(`${resource}/${payload.id}/decline`, payload);
    },
};
