<template>
   <div class="tw-flex tw-h-full tw-flex-col wrapper py-5 px-8 mb-3 tw-cursor-pointer" @click="goToChannel">
          <div class="tw-flex tw-justify-between">
            <div class="tw-flex tw-flex-col">
              <div class="tw-flex tw-items-top">
                <svg
                  v-if="!item.readed"
                  class="mr-2 mt-2"
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="3.83602" cy="4.00014" r="3.7076" fill="#F07877" />
                </svg>
                <div class="d-block">
                    <h2 class="header" :class="{'tw-font-semibold': item.readed}">{{item.title}}</h2>
                    <span class="subtitle">{{item.createdAt}}</span>
                </div>
              </div>
            </div>
            <div class="tw-flex tw-justify-between tw-items-center">
              <div class="subtile pr-6 support-id">ID {{item.id}}</div>
              <v-chip
              class="ma-2 text-uppercase status tw-cursor-pointer"
              :color="statusColor(item.status).bg"
              :text-color="statusColor(item.status).color"
            >{{item.status}}</v-chip>
            </div>
          </div>
          <div class="tw-flex content-wrapper" :class="{'ml-4': !item.readed}">
            <v-avatar size=30 class="mr-5">
              <img :src="item.authorImg" :alt="item.id" />
            </v-avatar>

            <p
              class="content"
            >{{item.content}}</p>
          </div>
  </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    data: () => ({}),
    methods: {
        statusColor(status) {
            switch (status) {
            case 'opened':
                return { bg: '#C5F5E7', color: '#15ABAB' };
            default:
                return { bg: '#FFE3E2', color: '#F07877' };
            }
        },
        goToChannel() {
            this.$router.push({ name: 'SupportChannel', params: { id: this.item.id } });
        },
    },
};
</script>

<style scoped>
.header {
    font-family: Muli;
font-style: normal;
font-weight: 800;
font-size: 16px;
line-height: 136.5%;

/* identical to box height, or 22px */

color: #404159;
}
.subtitle {
    font-family: Muli;
font-style: normal;
font-weight: normal;
font-size: 13px;
line-height: 136.5%;

/* identical to box height, or 18px */

/* Gray text */
color: #7598B5;
}
.support-id {
    color: #9192A4;
}
.content {
    font-family: Muli;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 175%;

/* or 24px */

/* Text black */
color: #2E5677;
max-width: 66.66%;
}
.content-wrapper {
    max-width: 66.66%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.wrapper {
    background: #F7FBFC;
    box-shadow: 0px 8px 33px rgba(224, 224, 224, 0.1);
    border-radius: 6px;
}
</style>
