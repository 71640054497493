<template>
    <div>
        <app-bar
            :back-btn-route="{
                name: 'Users',
            }">
            <template #title>
                <v-toolbar-title class="default-font font-21 mr-10 tw-text-black">
                    {{ user.full_name }}
                </v-toolbar-title>
            </template>
        </app-bar>

        <v-container>
            <v-row>
                <v-col cols="4">
                    <v-card class="text-center">
                        <v-card-title class="justify-center">
                            {{ user.full_name }}
                        </v-card-title>
                        <v-card-text>
                            <p>{{ user.city }}, {{ user.country }}</p>
                            <v-avatar
                                size="124">
                                <v-img
                                    :src="user.avatar || '/img/man.svg'">
                                </v-img>
                            </v-avatar>
                            <p>{{ user.email }}</p>
                            <p>
                                <user-status-chip
                                    :value="user.status"
                                    class="mr-2 mb-2"
                                    small>
                                </user-status-chip>
                                <user-identity-verified-chip
                                    :value="user.is_identity_verified"
                                    class="mr-2 mb-2"
                                    small>
                                </user-identity-verified-chip>
                                <UserBankAddedChip
                                    :value="user.is_payment_methods_added"
                                    type="Payment"
                                    class="mr-2 mb-2"
                                    small
                                    @click="showPaymentMethodModal = true">
                                </UserBankAddedChip>
                                <UserBankAddedChip
                                    :value="user.is_delivery_methods_added"
                                    type="Delivery"
                                    class="mr-2 mb-2"
                                    small
                                    @click="showDeliveryMethodModal = true">
                                </UserBankAddedChip>
                            </p>
                            <p>Lives at {{ fullAddress }}</p>
                            <p>Born on {{ user.dob }}</p>
                            <p>Phone: {{ user.phone }}</p>
                            <p>Kaoshi Network Email: {{ user.kaoshi_email }}</p>
                            <p>Account Created at {{ user.joined_at | datetime }}</p>
                            <p>Last signed in {{ user.last_logged_at | datetime }}</p>
                            <p>Last post {{ user.last_posted_at | datetime }}</p>
                            <p>Last match {{ user.last_matched_at | datetime }}</p>
                            <p>Last completed transaction {{ user.last_transaction_completed_at | datetime }}</p>
                            <p v-if="user.inviter">
                                Invited by {{ user.inviter.full_name }} ({{ user.inviter.email }})
                            </p>
                            <p>Customer Support Officer Tag:
                                <strong>{{ user.accountOfficer && user.accountOfficer.tag }}</strong>
                            </p>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                v-if="!user.kaoshi_email"
                                @click="createKaoshiEmail">
                                Create Kaoshi Email
                            </v-btn>
                            <v-btn
                                v-if="isUserBanned"
                                @click="unbanUser">
                                Unban User
                            </v-btn>
                            <v-btn
                                v-else
                                color="error"
                                @click="banUser">
                                Ban User
                            </v-btn>
                            <v-btn
                                color="error"
                                @click="deleteUser">
                                Delete
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-col>

                <v-col cols="8">
                    <v-tabs
                        v-model="tab"
                        grow>
                        <v-tab
                            to="#payments">
                            Payments
                        </v-tab>
                        <v-tab
                            v-if="user.can_post_offers"
                            to="#requests">
                            Requests
                        </v-tab>
                        <v-tab
                            to="#received">
                            Received
                        </v-tab>
                        <v-tab
                            to="#invited-users">
                            Invited Users
                        </v-tab>
                        <v-tab
                            to="#rewards">
                            Rewards
                        </v-tab>
                    </v-tabs>

                    <v-tabs-items
                        v-model="tab">

                        <v-row
                            v-if="tab !== 'rewards'"
                            class="mt-6">
                            <v-spacer></v-spacer>
                            <v-col>
                                <v-select
                                    v-model="transactionType"
                                    :items="['p2p', 'hybrid']"
                                    label="Transaction type"
                                    :menu-props="{
                                        bottom: true,
                                        offsetY: true,
                                        'content-class': 'mt-3 tw-rounded-lg',
                                    }"
                                    clearable
                                    outlined
                                    dense>
                                    <template #selection="{ item }">
                                        <span class="default-font filter-title tw-text-black mr-2">
                                            {{ item }}
                                        </span>
                                    </template>
                                </v-select>
                            </v-col>

                            <v-col>
                                <v-select
                                    v-model="transactionState"
                                    :items="transactionStates"
                                    label="Transaction state"
                                    :menu-props="{
                                        bottom: true,
                                        offsetY: true,
                                        'content-class': 'mt-3 tw-rounded-lg',
                                    }"
                                    clearable
                                    outlined
                                    dense>
                                    <template #selection="{ item }">
                                        <span class="default-font filter-title tw-text-black mr-2">
                                            {{ item.text }}
                                        </span>
                                    </template>
                                </v-select>
                            </v-col>

                            <v-col>
                                <v-select
                                    :value="period"
                                    :items="periods"
                                    label="Period"
                                    :menu-props="{
                                        bottom: true,
                                        offsetY: true,
                                        'content-class': 'mt-3 tw-rounded-lg',
                                    }"
                                    return-object
                                    outlined
                                    dense
                                    @change="setPeriod">
                                    <template #selection="{ item }">
                                        <span class="default-font filter-title tw-text-black mr-2">
                                            {{ item.text }}
                                        </span>
                                    </template>
                                </v-select>

                                <input
                                    v-if="period.text === 'Custom period'"
                                    :value="dateRangeText"
                                    class="mb-3 f-input default-font pa-2 tw-border-binput focus:tw-border-primary"
                                    label="Date range"
                                    placeholder="Date range"
                                    readonly
                                    :style="{
                                        minWidth: '210px',
                                        background: 'white',
                                    }"
                                    @click="showCalendarDialog = true"
                                />
                            </v-col>
                        </v-row>

                        <v-tab-item value="payments">

                            <orders-tab
                                v-if="user.can_post_offers"
                                :userId="id"
                                :type="transactionType"
                                :state="transactionState"
                                :period="period">
                            </orders-tab>

                            <pending-matches-tab
                                v-if="user.can_accept_offers"
                                :userId="id"
                                :type="transactionType"
                                :state="transactionState"
                                :period="period">
                            </pending-matches-tab>
                        </v-tab-item>

                        <v-tab-item value="requests">
                            <requests-tab
                                :userId="id"
                                :status="transactionState"
                                :period="period">
                            </requests-tab>
                        </v-tab-item>

                        <v-tab-item value="received">
                            <received-orders-tab
                                :userId="id"
                                :state="transactionState"
                                :period="period">
                            </received-orders-tab>
                        </v-tab-item>

                        <v-tab-item value="invited-users">
                            <InvitedUsersTab
                                :userId="id">
                            </InvitedUsersTab>
                        </v-tab-item>

                        <v-tab-item value="rewards">
                            <RewardsTab
                                :userId="id">
                            </RewardsTab>
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
            </v-row>

            <v-row>
                <v-col
                    v-for="(currency, key) in currencies"
                    :key="key"
                    cols="12">
                    <Chart
                        type="bar"
                        :options="{
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                                title: {
                                    display: true,
                                    text: currency,
                                },
                                legend: {
                                    display: true,
                                }
                            },
                        }"
                        :data="currencyData(currency)">
                    </Chart>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <h2 class="title secondary--text mb-4">Total amounts:</h2>
                    <p
                        v-for="(type, key) in totalAmounts"
                        :key="key">
                        {{ type.name }}:
                        <span
                            v-for="(amount, currency) in type.data"
                            :key="currency"
                            class="primary--text font-weight-bold">
                            {{ amount }} {{ currency }};
                        </span>
                    </p>
                </v-col>
            </v-row>
        </v-container>

        <v-dialog
            ref="dialog"
            v-model="showCalendarDialog"
            width="320px"
            persistent>

            <v-date-picker
                v-model="dates"
                scrollable
                range>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="showCalendarDialog = false">
                    Cancel
                </v-btn>

                <v-btn
                    text
                    color="primary"
                    @click="setCustomDateRange">
                    OK
                </v-btn>
            </v-date-picker>
        </v-dialog>

        <UserBankDetailsModal
            v-model="showPaymentMethodModal"
            :user="user"
            type="payment">
        </UserBankDetailsModal>

        <UserBankDetailsModal
            v-model="showDeliveryMethodModal"
            :user="user"
            type="delivery">
        </UserBankDetailsModal>
    </div>
</template>

<script>
/* eslint-disable camelcase */
import moment from 'moment';
import {
    values,
    flatten,
    map,
    uniqBy,
    groupBy,
    sumBy,
    mapValues,
} from 'lodash';

import AppBar from '../../components/globals/AppBar.vue';
import UserStatusChip from '../../components/Users/UserStatusChip.vue';
import UserIdentityVerifiedChip from '../../components/Users/UserIdentityVerifiedChip.vue';
import UserBankAddedChip from '../../components/Users/UserBankAddedChip.vue';
import UserBankDetailsModal from '../../components/Users/UserBankDetailsModal.vue';
import OrdersTab from '../../components/Users/OrdersTab.vue';
import ReceivedOrdersTab from '../../components/Users/ReceivedOrdersTab.vue';
import PendingMatchesTab from '../../components/Users/PendingMatchesTab.vue';
import RequestsTab from '../../components/Users/RequestsTab.vue';
import InvitedUsersTab from '../../components/Users/InvitedUsersTab.vue';
import RewardsTab from '../../components/Users/RewardsTab.vue';
import Chart from '../../components/globals/Chart.vue';

import UsersApi from '../../api/UsersApi';
import Socket from '../../services/Socket';

const FIRST_ORDER_DATE = '2020-01-01';
const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const periods = [
    {
        startDate: moment.utc().startOf('day').format(DATE_FORMAT),
        endDate: moment.utc().endOf('day').format(DATE_FORMAT),
        text: 'Today',
        interval: '4 HOUR',
    },
    {
        startDate: moment.utc().subtract(1, 'days').startOf('day').format(DATE_FORMAT),
        endDate: moment.utc().subtract(1, 'days').endOf('day').format(DATE_FORMAT),
        text: 'Yesterday',
        interval: '4 HOUR',
    },
    {
        startDate: moment.utc().startOf('week').format(DATE_FORMAT),
        endDate: moment.utc().endOf('week').format(DATE_FORMAT),
        text: 'This week',
        interval: '1 DAY',
    },
    {
        startDate: moment.utc().subtract(1, 'weeks').startOf('week').format(DATE_FORMAT),
        endDate: moment.utc().subtract(1, 'weeks').endOf('week').format(DATE_FORMAT),
        text: 'Last week',
        interval: '1 DAY',
    },
    {
        startDate: moment.utc().startOf('month').format(DATE_FORMAT),
        endDate: moment.utc().endOf('month').format(DATE_FORMAT),
        text: 'This month',
        interval: '1 WEEK',
    },
    {
        startDate: moment.utc().subtract(1, 'months').startOf('month').format(DATE_FORMAT),
        endDate: moment.utc().subtract(1, 'months').endOf('month').format(DATE_FORMAT),
        text: 'Last month',
        interval: '1 WEEK',
    },
    {
        startDate: moment.utc().subtract(2, 'months').startOf('months').format(DATE_FORMAT),
        endDate: moment.utc().endOf('months').format(DATE_FORMAT),
        text: '3 month',
        interval: '1 MONTH',
    },
    {
        startDate: moment.utc().subtract(5, 'months').startOf('months').format(DATE_FORMAT),
        endDate: moment.utc().endOf('months').format(DATE_FORMAT),
        text: '6 month',
        interval: '1 MONTH',
    },
    {
        startDate: moment.utc().startOf('year').format(DATE_FORMAT),
        endDate: moment.utc().endOf('year').format(DATE_FORMAT),
        text: '1 year',
        interval: '1 MONTH',
    },
    {
        startDate: moment.utc().subtract(4, 'years').startOf('year').format(DATE_FORMAT),
        endDate: moment.utc().endOf('year').format(DATE_FORMAT),
        text: '5 years',
        interval: '1 YEAR',
    },
    {
        startDate: moment.utc(FIRST_ORDER_DATE).startOf('year').format(DATE_FORMAT),
        endDate: moment.utc().endOf('year').format(DATE_FORMAT),
        text: 'All Time',
        interval: '1 YEAR',
    },
    {
        startDate: null,
        endDate: null,
        text: 'Custom period',
        interval: '1 MONTH',
    },
];

export default {
    components: {
        AppBar,
        UserStatusChip,
        UserIdentityVerifiedChip,
        UserBankAddedChip,
        UserBankDetailsModal,
        OrdersTab,
        ReceivedOrdersTab,
        PendingMatchesTab,
        RequestsTab,
        InvitedUsersTab,
        RewardsTab,
        Chart,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        tab: 0,
        user: {},
        statistic: {},
        periods,
        period: periods[0],
        showCalendarDialog: false,
        showPaymentMethodModal: false,
        showDeliveryMethodModal: false,
        dates: [null, null],
        transactionType: undefined,
        transactionStates: [
            {
                text: 'Completed',
                value: 'completed',
            },
            {
                text: 'Uncompleted',
                value: 'uncompleted',
            },
        ],
        transactionState: undefined,
    }),
    computed: {
        fullAddress() {
            const {
                address,
                address2,
                city,
                state,
                country,
                street_number,
            } = this.user;

            return [
                street_number,
                address,
                address2,
                city,
                state,
                country,
            ].filter(Boolean).join(', ');
        },
        isUserBanned() {
            return this.user?.status === 'banned';
        },
        currencies() {
            const items = flatten(values(this.statistic));
            return map(uniqBy(items, 'currency'), 'currency').filter(Boolean);
        },
        transactionTypes() {
            let showCompleted = true;
            let showUncompleted = true;

            if (this.transactionState === 'completed') {
                showCompleted = true;
                showUncompleted = false;
            } else if (this.transactionState === 'uncompleted') {
                showCompleted = false;
                showUncompleted = true;
            }

            return [
                {
                    key: 'uncompletedOrders',
                    name: 'Posted',
                    color: '#227C9D',
                    visible: !!this.user?.can_post_offers && showUncompleted,
                },
                {
                    key: 'completedOrders',
                    name: 'Post Completed',
                    color: '#61a5bd',
                    visible: !!this.user?.can_post_offers && showCompleted,
                },
                {
                    key: 'completedPaymentRequests',
                    name: 'Requested',
                    color: '#FE6D73',
                    visible: !!this.user?.can_post_offers && showCompleted,
                },
                {
                    key: 'uncompletedMatches',
                    name: 'Matched',
                    color: '#17C3B2',
                    visible: !!this.user?.can_accept_offers && showUncompleted,
                },
                {
                    key: 'completedMatches',
                    name: 'Match Completed',
                    color: '#8edad2',
                    visible: !!this.user?.can_accept_offers && showCompleted,
                },
                {
                    key: 'uncompletedReceivedOrders',
                    name: 'Received',
                    color: '#FFCB77',
                    visible: showUncompleted,
                },
                {
                    key: 'completedReceivedOrders',
                    name: 'Received Completed',
                    color: '#FFE7A7',
                    visible: showCompleted,
                },
            ].filter(({ visible }) => Boolean(visible));
        },

        /**
         * Compute total amounts for each transaction type for given period
         */
        totalAmounts() {
            return this.transactionTypes.map(({ key, name }) => {
                let transactions = this.statistic[key] || [];
                transactions = transactions.filter(({ currency }) => Boolean(currency));
                const currencies = groupBy(transactions, 'currency');
                const data = mapValues(currencies, (obj) => sumBy(obj, 'amount'));

                return {
                    name,
                    data,
                };
            });
        },

        dateRangeText() {
            const { startDate, endDate } = this.period;
            const value = `${startDate} - ${endDate}`;
            return value;
        },
    },
    methods: {
        async loadUser() {
            try {
                const { data } = await UsersApi.get(this.id);
                this.user = data;
            } catch (error) {
                this.$swal({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                });
            }
        },

        async banUser() {
            try {
                const { isConfirmed } = await this.$swal({
                    icon: 'warning',
                    title: 'Are You Sure?',
                    text: 'Are you sure you want to ban this user\'s account. This action is permanent and irreversible.',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                });

                if (!isConfirmed) return;

                const { data } = await UsersApi.ban(this.user.id);
                this.user = data;
            } catch (error) {
                this.$swal({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                });
            }
        },

        async unbanUser() {
            try {
                const { isConfirmed } = await this.$swal({
                    icon: 'warning',
                    title: 'Are You Sure?',
                    text: 'Are you sure you want to unban this user\'s account. This action is permanent and irreversible.',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                });

                if (!isConfirmed) return;

                const { data } = await UsersApi.unban(this.user.id);
                this.user = data;
            } catch (error) {
                this.$swal({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                });
            }
        },

        async deleteUser() {
            try {
                const { isConfirmed } = await this.$swal({
                    icon: 'warning',
                    title: 'Are You Sure?',
                    text: 'Are you sure you want to delete this user\'s account. This action is permanent and irreversible.',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                });

                if (!isConfirmed) return;

                await UsersApi.delete(this.user.id);
                this.$swal({
                    icon: 'success',
                    title: 'User deleted successfully',
                });
            } catch (error) {
                this.$swal({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                });
            }
        },

        addSocketEventListeners() {
            Socket.on(`userSaved-${this.id}`, this.loadUser);
        },
        removeSocketEventListeners() {
            Socket.remove(`userSaved-${this.id}`);
        },
        async createKaoshiEmail() {
            const { data } = await UsersApi.createKaoshiEmail(this.user.id);
            this.user = data;
        },
        async loadStatistic() {
            const {
                id,
                period: { startDate, endDate, interval },
                transactionType: type,
            } = this;

            const params = {
                created_at: [
                    startDate,
                    endDate,
                ],
                interval,
                type,
            };

            const { data } = await UsersApi.getStatistic(id, params);
            this.statistic = data;
        },
        currencyData(currency) {
            const items = flatten(values(this.statistic));
            const labels = map(uniqBy(items, 'date'), 'date');

            const datasets = this.transactionTypes.map(({ key, name, color }) => {
                const transactions = this.statistic[key] || [];
                const dates = groupBy(transactions, 'date');
                const data = values(dates).map((date) => {
                    const obj = date.find(({ currency: dateCurrency }) => dateCurrency === currency);
                    if (obj) {
                        return obj.amount;
                    }
                    return null;
                });

                return {
                    label: name,
                    data,
                    backgroundColor: color,
                };
            });

            return {
                labels,
                datasets,
            };
        },

        setPeriod(period) {
            const { startDate, endDate } = period;
            if (startDate && endDate) {
                this.period = period;
            } else {
                this.showCalendarDialog = true;
            }
        },

        setCustomDateRange() {
            const index = this.periods.findIndex(({ text }) => text === 'Custom period');
            const [startDate, endDate] = this.dates;
            const period = {
                ...this.periods[index],
                startDate,
                endDate,
            };

            this.$set(this.periods, index, period);
            this.$set(this, 'period', period);

            this.showCalendarDialog = false;
        },
    },
    watch: {
        period: {
            deep: true,
            immediate: true,
            handler: 'loadStatistic',
        },
        transactionState: 'loadStatistic',
        transactionType: 'loadStatistic',
    },
    mounted() {
        this.loadUser();
        this.addSocketEventListeners();
    },
    beforeDestroy() {
        this.removeSocketEventListeners();
    },
};
</script>
