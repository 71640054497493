<template>
    <div class="tw-w-full">
        <app-bar>
            <template slot="title">
                <v-toolbar-title class="default-font font-21 mr-10 tw-text-black">
                    Payment Method Authentication
                </v-toolbar-title>
            </template>
            <div class="d-flex justify-space-between mr-10">
                <search v-model="search" placeholder="Search by Card Number or Name..."></search>
            </div>
        </app-bar>
        <div class="d-flex flex-column">
            <v-data-table
                item-key="id"
                :headers="headers"
                :items="items"
                :loading="loading"
                :items-per-page="pagination.pageSize"
                hide-default-footer
                class="px-12 py-4 tw-bg-transparent custom-table"
            >
                <template v-slot:item="{ item, headers, isExpanded, expand }">
                    <card-row
                        :item="item"
                        @click="expand(!isExpanded)"
                        @approve="approve(item)"
                        @decline="decline(item)"
                    >
                    </card-row>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <tr>
                        <td class="py-2" :colspan="headers.length - 1" transition="slide-y-transition">
                            <v-img
                                :src="item.image_url"
                                class="mx-auto"
                                max-width="480"
                                @click="imageDialog = item.image_url"
                            />
                        </td>
                        <td class="py-2" transition="slide-y-transition">
                            <v-btn text class="mb-3" @click="imageDialog = item.image_url">
                                <v-icon>mdi-magnify</v-icon>
                                Zoom
                            </v-btn>
                            <br>
                            <v-btn text :href="item.image_url" target="_blank">
                                <v-icon>mdi-download</v-icon>
                                Download
                            </v-btn>
                        </td>
                    </tr>
                </template>
            </v-data-table>
            <v-pagination
                :value="pagination.page"
                @input="onPageChange"
                next-icon="$next"
                prev-icon="$prev"
                class="custom-pagination"
                color="#7598B5"
                :length="pagination.pageCount"
            ></v-pagination>
        </div>

        <v-dialog :value="!!imageDialog" @input="() => imageDialog = false" width="90%">
            <v-img v-if="imageDialog" :src="imageDialog" class="card-image mx-auto" max-width="100%"></v-img>
        </v-dialog>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import AppBar from '@/components/globals/AppBar.vue';
import Search from '@/components/globals/Search.vue';
import CardVerificationApi from '@/api/CardVerificationApi';
import UsersApi from '../../api/UsersApi';
import Socket from '../../services/Socket';
import CardRow from './CardRow.vue';

export default {
    components: {
        AppBar,
        Search,
        CardRow,
    },
    data() {
        return {
            headers: [
                {
                    text: 'Verified Name',
                    align: 'start',
                    value: 'user.full_name',
                    sortable: false,
                    class: 'tw-text-black default-font py-2',
                },
                {
                    text: 'Card Number',
                    align: 'start',
                    value: 'number',
                    sortable: false,
                    class: 'tw-text-black default-font py-2 text-start',
                },
                {
                    text: 'Expiration Date',
                    align: 'start',
                    value: 'expiry_date',
                    sortable: false,
                    class: 'tw-text-black default-font py-2 text-start',
                },
                {
                    text: 'Card Type',
                    align: 'start',
                    value: 'card_type',
                    sortable: false,
                    class: 'py-2',
                },
                {
                    text: 'Status',
                    align: 'center',
                    value: 'status',
                    sortable: false,
                    class: 'default-font py-2 text-center last_cell tw-rounded-lg tw-rounded-l-none',
                },
                {
                    text: 'Response Message',
                    align: 'center',
                    value: 'resp_message',
                    sortable: false,
                    class: 'default-font py-2 text-center last_cell tw-rounded-lg tw-rounded-l-none',
                },
                {
                    align: 'center',
                    value: 'action',
                    sortable: false,
                    class: 'default-font py-2 text-center last_cell tw-rounded-lg tw-rounded-l-none',
                },
            ],
            loading: true,
            items: [],
            pagination: {
                page: 1,
                pageSize: 10,
            },
            search: null,
            total: 0,
            imageDialog: false,
        };
    },
    computed: {
        ...mapGetters([
            'user',
        ]),
        ...mapGetters('notifications', [
            'cardVerificationNotifications',
        ]),
    },
    mounted() {
        this.addSocketEventListeners();
        this.markCardVerificationsNotificationsAsRead();
    },
    methods: {
        ...mapActions('notifications', [
            'storeCardVerificationNotifications',
        ]),
        loadItems() {
            this.loading = true;
            const { search } = this;
            const { page, pageSize } = this.pagination;
            CardVerificationApi.getAll({
                page,
                pageSize,
                search,
            })
                .then((response) => {
                    this.loading = false;
                    const { models, pagination } = response.data;
                    this.items = models;
                    this.pagination = pagination;
                })
                .catch((err) => {
                    this.loading = false;
                    throw err;
                });
        },
        addItem(item) {
            this.items.push(item);
        },
        onPageChange(page) {
            this.pagination.page = page;
            this.loadItems();
        },
        confirmApprove() {
            const html = `<p>You confirm that the name on the debit card matched the name on the database.</p>
              <br>
              <p>You also confirmed that the debit card number is correct</p>
              <br>
              <p>You confirm that the card has not expired</p>`;

            return this.$swal({
                icon: 'warning',
                title: 'Are You Sure You Want To Confirm This Card?',
                html,
                showCancelButton: true,
                confirmButtonText: 'Yes',
            });
        },
        approve(item) {
            const { number } = item;

            this.confirmApprove()
                .then((result) => {
                    if (!result.value) {
                        return false;
                    }

                    this.loading = true;
                    return CardVerificationApi.approve(item.id)
                        .then(() => {
                            this.loading = false;
                            this.$swal({
                                icon: 'success',
                                title: 'Approved',
                                text: `Card ${number} successfully verified`,
                            });
                            this.loadItems();
                        })
                        .catch(() => {
                            this.loading = false;
                        });
                });
        },
        confirmDecline() {
            return this.$swal({
                icon: 'warning',
                title: 'Are You Sure You Want To Decline This Card?',
                showCancelButton: true,
                confirmButtonText: 'Yes',
            });
        },
        decline(item) {
            const { number } = item;

            this.confirmDecline()
                .then((result) => {
                    if (!result.value) {
                        return false;
                    }

                    this.loading = true;
                    return CardVerificationApi.decline(item.id)
                        .then(() => {
                            this.loading = false;
                            this.$swal({
                                icon: 'success',
                                title: 'Declined',
                                text: `Card ${number} successfully declined`,
                            });
                            this.loadItems();
                        })
                        .catch(() => {
                            this.loading = false;
                        });
                });
        },
        async markCardVerificationsNotificationsAsRead() {
            const notificationIds = this.cardVerificationNotifications?.data?.map(({ id }) => id);
            await UsersApi.markNotificationsAsRead(this?.user?.id, notificationIds);
            const { data } = await UsersApi.getNotifications(this.user?.id, {
                object_type: 'card_verification_request',
                status: 'unread',
            });
            this.storeCardVerificationNotifications(data);
        },
        addSocketEventListeners() {
            Socket.on('cardVerificationRequestCreated', this.addItem);
        },
        removeSocketEventListeners() {
            Socket.remove('cardVerificationRequestCreated');
        },
    },
    watch: {
        search: {
            immediate: true,
            handler: 'loadItems',
        },
    },
    beforeDestroy() {
        this.removeSocketEventListeners();
    },
};
</script>
