var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-bar',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-toolbar-title',{staticClass:"default-font font-21 mr-10 tw-text-black"},[_vm._v(" Users ")])]},proxy:true}])},[_c('div',{staticClass:"d-flex justify-space-between mr-10"},[_c('search',{attrs:{"placeholder":"Search user by name or email"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',[_c('ExportModal',{attrs:{"file-formats":_vm.fileFormats},on:{"export":function (ref) {
                    var format = ref.format;
                    var options = ref.options;

                    return _vm.exportUsers(format, options);
}}})],1),_c('v-btn',{staticClass:"text-capitalize default-font",attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.showFilters = !_vm.showFilters}}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("$vuetify.icons.filter")]),_vm._v(" "+_vm._s(_vm.showFilters ? 'Hide filters' : 'Show filters')+" ")],1)],1)]),_c('div',{staticClass:"d-flex flex-column mt-5 px-12 py-4"},[_c('v-expand-transition',[(_vm.showFilters)?_c('UsersFilters'):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"ml-auto",attrs:{"cols":"3"}},[_c('UsersSortDropdown')],1)],1),_c('v-overlay',{attrs:{"value":_vm.loading,"absolute":""}},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"indeterminate":""}})],1),_c('v-row',_vm._l((_vm.users),function(user){return _c('v-col',{key:user.id,attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('v-card',{staticClass:"overflow-hidden",staticStyle:{"height":"100%"},attrs:{"to":{
                        name: 'User',
                        params: {
                            id: user.id,
                        },
                    }}},[_c('div',{staticClass:"d-flex flex-no-wrap"},[_c('v-img',{attrs:{"src":user.avatar || '/img/man.svg',"width":"125","max-width":"125"}}),_c('div',[_c('v-card-title',[_vm._v(" "+_vm._s(user.firstname)+" "),_c('br'),_vm._v(" "+_vm._s(user.lastname)+" ")]),_c('v-card-text',[_c('div',[_vm._v(_vm._s(_vm._f("fullYearDate")(user.dob)))]),_c('div',[_vm._v(_vm._s(user.email))]),_c('user-status-chip',{staticClass:"mr-2 mb-2",attrs:{"value":user.status,"small":""}}),_c('user-identity-verified-chip',{staticClass:"mr-2 mb-2",attrs:{"value":user.is_identity_verified,"small":""}}),_c('user-bank-added-chip',{staticClass:"mr-2 mb-2",attrs:{"value":user.is_payment_methods_added,"type":"Payment","small":""}}),_c('user-bank-added-chip',{staticClass:"mr-2 mb-2",attrs:{"value":user.is_delivery_methods_added,"type":"Delivery","small":""}})],1)],1)],1)])],1)}),1),_c('v-pagination',{staticClass:"custom-pagination",attrs:{"length":_vm.pagination.pageCount,"next-icon":"$next","prev-icon":"$prev","total-visible":"10","color":"#7598B5"},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }