<template>
    <div>
        <app-bar>
            <template #title>
                <v-toolbar-title class="default-font font-21 mr-10 tw-text-black">
                    Global Offers
                </v-toolbar-title>
            </template>
        </app-bar>

        <v-container
            class="d-flex justify-center align-start"
            fill-height>
            <v-card class="elevation-12">
                <v-card-title class="primary white--text">
                    Create Your Transfer
                </v-card-title>
                <v-form>
                    <v-card-text>
                        <v-row class="align-content-center">
                            <v-col class="d-flex align-center justify-center">
                                <v-sheet color="grey" width="100%" height="1px"></v-sheet>
                            </v-col>
                            <v-col class="flex-grow-0 flex-shrink-1">
                                <h2 class="overline text-no-wrap">YOU SEND</h2>
                            </v-col>
                            <v-col class="d-flex align-center justify-center">
                                <v-sheet color="grey" width="100%" height="1px"></v-sheet>
                            </v-col>
                        </v-row>

                        <v-row class="align-content-center">
                            <v-col class="flex-grow-0 flex-shrink-1">
                                <v-avatar color="indigo lighten-3">
                                    <v-img
                                        v-if="posterCurrency.flagLink"
                                        v-show="posterCurrency.currency_code"
                                        :alt="posterCurrency.currency_code"
                                        :src="posterCurrency.flagLink"
                                    />
                                    <v-icon v-else>mdi-flag-variant-outline</v-icon>
                                </v-avatar>
                            </v-col>
                            <v-col>
                                <v-select
                                    label="Sender Currency"
                                    name="Currency"
                                    item-value="currency_code"
                                    item-text="country_name"
                                    v-model="offer.poster_currency"
                                    :items="posterCurrencies"
                                    outlined
                                >
                                    <template #item="data">
                                        {{ data.item.country_name }} ({{ data.item.currency_code }})
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col>
                                <v-select
                                    v-if="sendingAmountSelectIsDisplayed"
                                    name="poster_amount"
                                    label="Sending Amount"
                                    v-model="offer.poster_amount"
                                    @change="updateReceivingAmount"
                                    type="number"
                                    :value="posterCurrency.currencySteps"
                                    disabled
                                    outlined
                                />
                                <v-text-field
                                    label="Amount"
                                    v-else
                                    type="number"
                                    disabled
                                    v-model="offer.poster_amount"
                                    outlined
                                />
                            </v-col>
                        </v-row>

                        <!-- EXCHANGE RATE -->
                        <v-text-field
                            label="Set your exchange rate"
                            class="form-control"
                            type="number"
                            step="2"
                            name="exchange-rate"
                            hint="The higher the exchange rate, the faster the transaction processing."
                            persistent-hint
                            v-model="displayedExchangeRate"
                            :error-messages="errors.first('exchange-rate')"
                            outlined
                        />

                        <v-alert
                            text
                            dark
                            dense
                            prominent
                            color="indigo"
                            icon="mdi-information-outline"
                            class="text-left"
                        >
                            Exchange rate must be between {{ minExchangeRate > 0 ? minExchangeRate.toFixed(2) : '--' }} and {{ maxExchangeRate.toFixed(2) }}.
                            <strong>Suggested:</strong> 1 {{ matcherCurrency.currency_code }} = {{ suggestedExchangeRate.toFixed(2) }} {{ posterCurrency.currency_code }}.
                        </v-alert>

                        <v-slider
                            v-if="displayedExchangeRate"
                            :min="minExchangeRate.toFixed(2)"
                            :max="maxExchangeRate.toFixed(2)"
                            :value="displayedExchangeRate"
                            @input="onSliderChange"
                            @start="sliderActive = true"
                            @end="sliderActive = false"
                            thumb-label="always"
                            style="width: 85%"
                            class="mx-auto mt-16"
                            step="0.01"
                        >
                        </v-slider>

                        <!-- RECIPIENT GETS -->
                        <v-row class="align-content-center">

                            <v-col class="d-flex align-center justify-center">
                                <v-sheet color="grey" width="100%" height="1px"></v-sheet>
                            </v-col>
                            <v-col class="flex-grow-0 flex-shrink-1">
                                <h2 class="overline text-no-wrap">RECIPIENT GETS</h2>
                            </v-col>
                            <v-col class="d-flex align-center justify-center">
                                <v-sheet color="grey" width="100%" height="1px"></v-sheet>
                            </v-col>

                        </v-row>

                        <v-row class="align-content-center">
                            <v-col class="flex-grow-0 flex-shrink-1">
                                <!-- CURRENCY -->
                                <v-avatar
                                    color="indigo lighten-3"
                                >
                                    <v-img
                                        v-if="matcherCurrency.flagLink"
                                        v-show="matcherCurrency.currency_code"
                                        :alt="matcherCurrency.currency_code"
                                        :src="matcherCurrency.flagLink"
                                    ></v-img>
                                    <v-icon v-else>mdi-flag-variant-outline</v-icon>
                                </v-avatar>
                            </v-col>
                            <v-col>
                                <v-select
                                    label="Recipient Country"
                                    v-model="matcherCountry"
                                    :items="filteredCountries"
                                    item-text="country"
                                    return-object
                                    outlined
                                />
                                <v-text-field
                                    v-if="matcherCountry"
                                    label="Recipient Currency"
                                    name="Currency"
                                    :value="offer.matcher_currency"
                                    readonly
                                    outlined
                                />
                            </v-col>
                            <div class="col-sm-12 col-lg-5">

                                <!-- CURRENCY AMT -->
                                <v-select
                                    v-if="!sendingAmountSelectIsDisplayed"
                                    name="poster_amount"
                                    label="Recipient Gets"
                                    placeholder="Select Amount"
                                    v-model="offer.matcher_amount"
                                    @change="updateSendingAmount"
                                    type="number"
                                    :items="matcherCurrency.currencySteps"
                                    outlined
                                />
                                <v-text-field
                                    label="Amount"
                                    v-else
                                    type="number"
                                    v-model="offer.matcher_amount"
                                    outlined
                                />
                            </div>
                        </v-row>
                        <!-- TO USER ACCOUNT -->
                        <v-combobox
                            v-if="!useUnifiedRecipient"
                            :items="postersBeneficiariesEmails"
                            label="Recipient's Kaoshi Handle (Email Address)"
                            placeholder="email@example.com"
                            type="email"
                            name="kaoshi_handle"
                            v-validate="'required|email'"
                            validate-on-blur
                            :error-messages="errors.first('email')"
                            outlined
                            @update:search-input="updateKaoshiHandle"
                        />
                        <v-textarea
                            v-model="offer.memo"
                            label="Memo"
                            outlined>
                        </v-textarea>

                        <v-checkbox
                            v-if="isPartialMatchCheckboxShown"
                            v-model="offer.is_partial_match_allowed"
                            label="Allow partial match"
                            name="is_partial_match_allowed"
                            color="indigo"
                            outlined
                            required
                        />
                    </v-card-text>
                    <v-card-actions class="pb-10">
                        <v-spacer/>
                        <v-btn
                            x-large color="indigo"
                            dark class="px-6"
                            @click="handleSubmit"
                        >
                            Continue
                        </v-btn>
                        <v-spacer/>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-container>

        <div class="text-center">
            <v-dialog
                v-model="showConfirmOrderModal"
                max-width="450"
                tabindex="-1"
                role="dialog"
                aria-labelledby="confirmOrderModal"
                aria-hidden="true"
            >
                <confirm-order-modal
                    :globalOffer="offer"
                    v-if="showConfirmOrderModal"
                    title="Confirm your transaction"
                    @confirm="handleRecipientReceived"
                    @cancel="cancelRecipientDetails"
                ></confirm-order-modal>
            </v-dialog>
        </div>

        <v-dialog
            max-width="500"
            v-model="unifiedRecipientDialog"
        >
            <UnifiedRecipient
                v-if="unifiedRecipientDialog"
                :countryCode="matcherCountry.abbreviation"
                :currencyCode="matcherCountry.currency"
                @submit="onUnifiedRecipientSubmit"
            />
        </v-dialog>
    </div>
</template>

<script>
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-expressions */
import { mapGetters } from 'vuex';

import AppBar from '@/components/globals/AppBar.vue';
import ConfirmOrderModal from '../components/modals/ConfirmOrderModal.vue';
import UnifiedRecipient from '../components/UnifiedRecipient.vue';

import {
    exchangeRates,
    currencies,
    findCurrency,
    addCurrencySteps,
    addFlagLink,
    compare,
} from '../services/CurrencyService';
import ExchangeRateService from '../services/ExchangeRateService';

import CountriesApi from '../api/CountriesApi';
import UserApi from '../api/UserApi';
import OrdersApi from '../api/OrdersApi';

const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

export default {
    components: {
        AppBar,
        ConfirmOrderModal,
        UnifiedRecipient,
    },
    data: () => ({
        showConfirmOrderModal: false,
        selectRecipient: false,
        loading: false,
        displayedExchangeRate: 0,
        sliderActive: false,
        offer: {
            poster_currency: 'EUR',
            poster_payment_method_type: 'nigerian_bank_payment',
            matcher_currency: 'USD',
            matcher_country: 'US',
            poster_amount: 0,
            matcher_amount: 0,
            exchange_rate: 0,
            memo: null,
            is_partial_match_allowed: false,
        },
        kaoshiHandle: '',
        pbDebounce: null,
        exchangeRates,
        suggestedExchangeRateRange: 0.1,
        currencies,
        userPaymentMethods: [],
        countries: [],
        countriesForCurrency: [],
        postersBeneficiaries: [],
        exchangeRateLimits: {
            min: 0,
            max: 1,
        },
        unifiedRecipientDialog: false,
    }),
    methods: getMethods(),
    computed: getComputed(),
    watch: getWatchers(),
    async mounted() {
        if (!this.user.has_valid_address) {
            this.$swal({
                type: 'error',
                title: 'Address needed',
                text: 'Please enter your address and phone number before creating an offer.',
            });
        }

        if (!this.user.phone) {
            this.$swal({
                type: 'error',
                title: 'Phone number needed',
                text: 'Please enter your phone number before creating an offer.',
            });
        }

        this.loading = true;

        await this.loadCountries();
        this.setExchangeRate();
        this.$nextTick(this.setDefaultOffer);

        this.loading = false;
    },
};

function getMethods() {
    return {
        validateForm() {
            const errors = [];

            const { type } = this;

            const formIsValid = this.offer.poster_payment_method_type !== undefined;
            if (!formIsValid) {
                errors.push('Please select the method you can pay with');
            }

            // const exchangeRateIsTooHigh = this.displayedExchangeRate > this.maxExchangeRate;
            // if (exchangeRateIsTooHigh) {
            //     errors.push(
            //         `Exchange rate should be below ${this.maxExchangeRate.toFixed(
            //             2,
            //         )}`,
            //     );
            // }

            // const exchangeRateIsTooLow = this.displayedExchangeRate < this.minExchangeRate;
            // if (exchangeRateIsTooLow) {
            //     errors.push(
            //         `Exchange rate should be above ${this.minExchangeRate.toFixed(
            //             2,
            //         )}`,
            //     );
            // }

            if (!this.offer.matcher_amount) {
                errors.push('Please enter the receiving amount!');
            }

            if (!this.offer.matcher_country && type === 'p2p') {
                errors.push('Please enter the receiving country');
            }

            if (!this.useUnifiedRecipient) {
                if (!this.kaoshiHandle) {
                    errors.push('Please enter the recipient\'s email address');
                } else if (!validateEmail(this.kaoshiHandle)) {
                    errors.push('Please enter a valid email address');
                }
            }

            return errors;
        },
        async handleSubmit() {
            if (this.loading) {
                return false;
            }

            const errors = this.validateForm();
            if (errors.length > 0) {
                this.$swal({
                    type: 'error',
                    title: 'Error',
                    text: errors[0],
                });
                return false;
            }

            if (this.useUnifiedRecipient) {
                this.unifiedRecipientDialog = true;
                return true;
            }

            try {
                // load poster's beneficiary
                this.loading = true;
                const { data: { data } } = await UserApi.getKaoshiUser(this.kaoshiHandle);
                this.offer.recipient = data;
                this.showConfirmOrderModal = true;
            } catch (error) {
                this.offer.recipient = null;
                this.showConfirmOrderModal = true;
            } finally {
                this.loading = false;
            }

            return true;
        },
        updateReceivingAmount() {
            const receivingAmount = parseFloat(this.offer.poster_amount) * parseFloat(this.offer.exchange_rate);
            this.offer.matcher_amount = this.round(receivingAmount, 2);
        },
        updateSendingAmount() {
            const sendingAmount = parseFloat(this.offer.matcher_amount) * parseFloat(this.offer.exchange_rate);
            this.offer.poster_amount = this.round(sendingAmount, 2);
        },
        round(value, decimalPlaces) {
            return value.toFixed(decimalPlaces);
        },
        setExchangeRate() {
            this.displayedExchangeRate = this.suggestedExchangeRate.toFixed(2);
        },
        cancelRecipientDetails() {
            this.showConfirmOrderModal = false;
        },
        handleRecipientReceived() {
            this.showConfirmOrderModal = false;
            if (!this.offer.recipient) {
                this.$swal({
                    type: 'warning',
                    title: 'Recipient not on Kaoshi',
                    text: `Your recipient is not registered on Kaoshi.
                        An email has been sent to this recipient at the email you provided, inviting them to register their email on Kaoshi to receive the money you are sending them.
                        Please do let them know.
                        Thank you.`,
                    showCancelButton: true,
                }).then((result) => {
                    if (result.value) {
                        this.storeGlobalOffer();
                    }
                });
            } else {
                this.storeGlobalOffer();
            }
        },
        async storeGlobalOffer() {
            this.loading = true;

            this.offer.poster_recipient_username = this.kaoshiHandle;

            try {
                await OrdersApi.create(this.offer);
                this.$swal({
                    type: 'success',
                    title: 'Transfer Request Created!',
                    text: 'Click OK to continue',
                    preConfirm: () => {
                        this.$router.push({ name: 'Transactions' });
                    },
                });
            } finally {
                this.loading = false;
            }
        },
        async loadCountries() {
            try {
                const { data: { data } } = await CountriesApi.getAll();
                this.$set(this, 'countries', data);
            } catch (error) {
                this.$swal({
                    type: 'error',
                    title: 'Error loading countries',
                    text: error,
                });
            }
        },
        /**
         * Load Posters Beneficiaries
        */
        async getPostersBeneficiaries() {
            try {
                const { data: { data } } = await UserApi.getPostersBeneficiaries(this.user.id, {
                    email: this.kaoshiHandle,
                });
                this.postersBeneficiaries = data;
            } catch (error) {
                this.$swal({
                    type: 'error',
                    title: 'Error loading third party offers',
                    text: error,
                });
            }
        },
        loadPostersBeneficiariesDebounced() {
            if (!this.kaoshiHandle || this.kaoshiHandle.length < 2) {
                return;
            }

            clearTimeout(this.pbDebounce);
            this.pbDebounce = setTimeout(() => {
                this.getPostersBeneficiaries();
            }, 300);
        },
        onSliderChange(val) {
            if (this.sliderActive) {
                this.displayedExchangeRate = val;
            }
        },
        /**
         * Manually update kaoshiHandle.
         * v-model can't be used because of this bug
         * https://github.com/vuetifyjs/vuetify/issues/4679
         */
        updateKaoshiHandle(val) {
            this.kaoshiHandle = val;
            this.loadPostersBeneficiariesDebounced();
        },
        async refreshExchangeRate() {
            const posterCurrency = this.offer.poster_currency;
            const matcherCurrency = this.offer.matcher_currency;
            let matcherCountry;
            if (this.globalOffer) {
                ({ matcher_country: matcherCountry } = this.globalOffer);
            }

            if (!matcherCountry && matcherCurrency === 'USD') {
                matcherCountry = 'NG';
            }

            this.loading = true;
            const limits = await ExchangeRateService.getLimits(matcherCurrency, posterCurrency, matcherCountry);
            this.exchangeRateLimits = limits;
            this.loading = false;
        },
        setDefaultOffer() {
            const userCountry = this.countries.find((item) => item.abbreviation === this.user.country_abbreviation);
            this.offer.poster_currency = userCountry.currency;
            this.matcherCountry = this.fromFilter;

            const fromCurrency = findCurrency(
                this.offer.poster_currency,
            );
            if (!fromCurrency.is_poster_from_currency) {
                this.offer.poster_currency = 'NGN';
            }
            if (!this.comparisons.fromCurrencyIsLessThanToCurrency) {
                this.offer.poster_amount = this.amountFilter;
            } else {
                this.offer.matcher_amount = this.amountFilter;
            }
        },
        onUnifiedRecipientSubmit(recipient) {
            this.offer.recipient = recipient;
            this.showConfirmOrderModal = true;
        },
    };
}

function getComputed() {
    return {
        ...mapGetters([
            'user',
            'fromFilter',
            'recepientFilter',
            'amountFilter',
        ]),
        matcherCountry: {
            get() {
                if (!this.filteredCountries) {
                    return null;
                }

                const abbreviation = this.offer.matcher_country;
                return this.filteredCountries.find((item) => item.abbreviation === abbreviation);
            },
            set(country) {
                this.offer.matcher_country = country.abbreviation;
                this.offer.matcher_currency = country.currency;
            },
        },
        posterCurrencies() {
            return this.currencies.filter((currency) => currency.is_poster_from_currency);
        },
        posterCurrency() {
            let posterCurrency = findCurrency(
                this.offer.poster_currency,
            );
            posterCurrency = addCurrencySteps(posterCurrency);
            posterCurrency = addFlagLink(posterCurrency);
            return posterCurrency;
        },
        matcherCurrency() {
            let matcherCurrency = findCurrency(
                this.offer.matcher_currency,
            );
            const { country } = this.matcherCountry || {};

            matcherCurrency = addCurrencySteps(matcherCurrency, {
                max_amount: country === 'Nigeria Domiciliary Account' ? 2000 : null,
            });
            matcherCurrency = addFlagLink(matcherCurrency);
            return matcherCurrency;
        },
        exchangeRate() {
            return this.offer.exchange_rate;
        },
        sendingAmount() {
            return this.offer.poster_amount;
        },
        receivingAmount() {
            return this.offer.matcher_amount;
        },
        comparisons() {
            return compare(this.matcherCurrency, this.posterCurrency);
        },
        filteredToCurrencies() {
            return this.currencies.filter((currency) => this.posterCurrency.can_trade_with.includes(
                currency.currency_code,
            ));
        },
        sendingAmountSelectIsDisplayed() {
            return this.comparisons.fromCurrencyIsLessThanToCurrency;
        },
        minExchangeRate() {
            return this.exchangeRateLimits.min;
        },
        maxExchangeRate() {
            return this.exchangeRateLimits.max;
        },
        suggestedExchangeRate() {
            const diff = this.maxExchangeRate - this.minExchangeRate;
            return Number(this.minExchangeRate + (diff / 2));
        },
        /**
         * NOTE: We need this computed property because of this issue:
         * https://github.com/vuetifyjs/vuetify/issues/5479
         */
        postersBeneficiariesEmails() {
            return this.postersBeneficiaries.map(({ email }) => email);
        },
        exchangeRateRules() {
            return [
                (value) => {
                    const min = this.minExchangeRate;
                    const max = this.maxExchangeRate;

                    if (value < min) return `Exchange rate should be above ${min.toFixed(2)}`;
                    if (value > max) return `Exchange rate should be below ${max.toFixed(2)}`;

                    return true;
                },
            ];
        },
        filteredCountries() {
            if (!this.countries) {
                return [];
            }

            const supportedCountries = [
                'US',
                'CA',
                'GB',
                'DE',
                'NL',
                'SE',
                'FI',
                'FR',
                'ES',
                'IT',
                'AT',
                'BE',
                'DK',
                'PT',
                'CN',
                'IN',
                'TR',
                'AE',
                'BR',
            ];

            const { poster_currency } = this.offer;
            const countries = this.countries.filter((country) => {
                // NOTE: Hide Sweden for Ghana because of exchange rate
                if (poster_currency === 'GHS') {
                    return supportedCountries.indexOf(country.abbreviation) >= 0
                        && country.abbreviation !== 'SE';
                }
                return supportedCountries.indexOf(country.abbreviation) >= 0;
            });

            countries.push({
                abbreviation: null,
                country: 'Nigeria Domiciliary Account',
                currency: 'USD',
                receiving: 1,
                sending: 0,
            });

            countries.push({
                abbreviation: 'GH',
                country: 'Ghana',
                currency: 'GHS',
                receiving: 1,
                sending: 0,
            });

            countries.push({
                abbreviation: 'KE',
                country: 'Kenya',
                currency: 'KES',
                receiving: 1,
                sending: 0,
            });

            countries.push({
                abbreviation: 'ZA',
                country: 'South Africa',
                currency: 'ZAR',
                receiving: 1,
                sending: 0,
            });

            return countries;
        },
        type() {
            if (!this.offer.matcher_country) {
                if (this.user.country_abbreviation === 'NG' && this.offer.matcher_currency === 'USD') {
                    return 'hybrid';
                }
            }
            return 'p2p';
        },
        isPartialMatchCheckboxShown() {
            const countries = [
                'GH',
                'KE',
                'ZA',
            ];

            if (this.type === 'hybrid') {
                return false;
            }

            if (countries.includes(this.offer.matcher_country)) {
                return false;
            }

            if (this.useUnifiedRecipient) {
                return false;
            }

            return true;
        },
        useUnifiedRecipient() {
            const countries = [
                'CN',
                'IN',
                'TR',
                'AE',
                'BR',
            ];

            if (!this.matcherCountry) {
                return false;
            }

            return countries.indexOf(this.matcherCountry.abbreviation) >= 0;
        },
    };
}

function getWatchers() {
    return {
        posterCurrency,
        matcherCurrency,
        exchangeRate,
        sendingAmount,
        receivingAmount,
        displayedExchangeRate,
        'offer.poster_currency': 'refreshExchangeRate',
        'offer.matcher_currency': 'refreshExchangeRate',
        suggestedExchangeRate: 'setExchangeRate',
        isPartialMatchCheckboxShown() {
            this.set(this.offer, 'is_partial_match_allowed', false);
        },
    };

    function posterCurrency() {
        this.setExchangeRate();

        const fromCurrencyCanTradeWithToCurrency = this.posterCurrency.can_trade_with.includes(
            this.matcherCurrency.currency_code,
        );
        if (!fromCurrencyCanTradeWithToCurrency) {
            this.offer.matcher_currency = this.posterCurrency.can_trade_with[0];
        }

        if (this.sendingAmountSelectIsDisplayed) {
            const sendingAmountInSelect = this.posterCurrency.currencySteps.includes(
                this.offer.poster_amount,
            );
            if (!sendingAmountInSelect) {
                this.offer.poster_amount = this.posterCurrency.currencySteps[0];
            }
        }
    }

    function matcherCurrency() {
        this.setExchangeRate();

        if (!this.sendingAmountSelectIsDisplayed) {
            const receivingAmountInSelect = this.matcherCurrency.currencySteps.includes(
                this.offer.matcher_amount,
            );
            if (!receivingAmountInSelect) {
                this.offer.matcher_amount = this.matcherCurrency.currencySteps[0];
            }
        }
    }

    function exchangeRate() {
        if (this.comparisons.fromCurrencyIsLessThanToCurrency) {
            this.updateReceivingAmount();
        } else {
            this.updateSendingAmount();
        }
    }

    function sendingAmount() {
        if (this.sendingAmountSelectIsDisplayed) {
            if (
                !this.posterCurrency.currencySteps.includes(
                    this.offer.poster_amount,
                )
            ) {
                this.offer.poster_amount === this.posterCurrency.currencySteps[0];
            }
        }
    }

    function receivingAmount() {
        if (!this.sendingAmountSelectIsDisplayed) {
            if (
                !this.matcherCurrency.currencySteps.includes(
                    this.offer.matcher_amount,
                )
            ) {
                this.offer.matcher_amount === this.posterCurrency.currencySteps[0];
            }
        }
    }

    function displayedExchangeRate() {
        this.offer.exchange_rate = this.displayedExchangeRate;
    }
}
</script>
