var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-select',{staticClass:"custom-select",attrs:{"items":_vm.filterByItems,"outlined":"","dense":"","value":_vm.periodFilterTarget,"itemValue":"value","itemText":"title","placeholder":"Filter by","menu-props":{ bottom: true, offsetY: true, 'content-class': 'mt-3 tw-rounded-lg' },"disabled":_vm.disabled},on:{"change":_vm.updatePeriodFilterTarget},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"default-font filter-title tw-text-black mr-2"},[_vm._v(_vm._s(item.title))])]}}])}),_c('v-select',{staticClass:"custom-select",attrs:{"items":_vm.items,"outlined":"","dense":"","prepend-inner-icon":"$calendar","value":_vm.periodFilter,"return-object":"","itemText":"title","menu-props":{ bottom: true, offsetY: true, 'content-class': 'mt-3 tw-rounded-lg' },"disabled":_vm.disabled},on:{"change":_vm.updatePeriodFilter},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"default-font filter-title tw-text-black mr-2"},[_vm._v(_vm._s(item.title))])]}}])}),(_vm.periodFilter.value === 'custom')?_c('v-dialog',{ref:"dialog",attrs:{"persistent":"","width":"320px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('input',_vm._g({staticClass:"mb-3 f-input default-font pa-2 tw-border-binput focus:tw-border-primary",style:({
                    minWidth: '210px',
                    background: 'white',
                }),attrs:{"label":"Date range","placeholder":"Date range","readonly":"","disabled":_vm.disabled},domProps:{"value":_vm.dateRangeText}},on))]}}],null,false,963356269),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"range":"","scrollable":"","disabled":_vm.disabled},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-spacer'),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary","disabled":_vm.disabled},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary","disabled":_vm.disabled},on:{"click":_vm.onDateRangeSelected}},[_vm._v(" OK ")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }