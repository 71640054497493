export default [
    {
        id: 97405,
        title: 'Question about live testing',
        createdAt: '2 days ago',
        status: 'opened',
        content: 'I designed this website for an investment fund that helps promising blockchain startups to get off the ground',
        authorImg: 'https://cdn.vuetifyjs.com/images/john.jpg',
        readed: false,
    },
    {
        id: 97408,
        title: 'Question about live testing',
        createdAt: '4 days ago',
        status: 'opened',
        content: 'I designed this website for an investment fund that helps promising blockchain startups to get off the ground',
        authorImg: 'https://cdn.vuetifyjs.com/images/john.jpg',
        readed: true,

    },
    {
        id: 97407,
        title: 'Question about live testing',
        createdAt: '1 days ago',
        status: 'closed',
        content: 'This is one of the simpler, no-frills text generators based only on Lorem Ipsum. You basically are only able to adjust the amount of paragraphs or words. You can also create bulleted lists or generate your text based on how many bytes it should be.',
        authorImg: 'https://cdn.vuetifyjs.com/images/john.jpg',
        readed: true,

    },
    {
        id: 97485,
        title: 'Question about live testing',
        createdAt: '2 days ago',
        status: 'opened',
        content: 'I designed this website for an investment fund that helps promising blockchain startups to get off the ground',
        authorImg: 'https://cdn.vuetifyjs.com/images/john.jpg',
        readed: true,

    },
    {
        id: 97415,
        title: 'Question about live testing',
        createdAt: '2 days ago',
        status: 'closed',
        content: 'I designed this website for an investment fund that helps promising blockchain startups to get off the ground',
        authorImg: 'https://cdn.vuetifyjs.com/images/john.jpg',
        readed: true,
    },
];

export const statusColor = (status) => {
    switch (status) {
    case 'opened':
        return { bg: '#C5F5E7', color: '#15ABAB' };
    default:
        return { bg: '#FFE3E2', color: '#F07877' };
    }
};
