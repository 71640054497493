<template>
    <div>
        <app-bar>
            <template #title>
                <v-toolbar-title class="default-font font-21 mr-10 tw-text-black">
                    Users
                </v-toolbar-title>
            </template>
            <div class="d-flex justify-space-between mr-10">
                <search
                    v-model="search"
                    placeholder="Search user by name or email">
                </search>
                <div>
                    <ExportModal
                        :file-formats="fileFormats"
                        @export="({ format, options }) => exportUsers(format, options)">
                    </ExportModal>
                </div>
                <v-btn
                    color="primary"
                    class="text-capitalize default-font"
                    text
                    @click="showFilters = !showFilters">
                    <v-icon class="mr-3">$vuetify.icons.filter</v-icon>
                    {{ showFilters ? 'Hide filters' : 'Show filters' }}
                </v-btn>
            </div>
        </app-bar>

        <div class="d-flex flex-column mt-5 px-12 py-4">

            <v-expand-transition>
                <UsersFilters v-if="showFilters"/>
            </v-expand-transition>

            <v-row>
                <v-col
                    cols="3"
                    class="ml-auto">
                    <UsersSortDropdown/>
                </v-col>
            </v-row>

            <v-overlay
                :value="loading"
                absolute>
                <v-progress-circular
                    :size="70"
                    :width="7"
                    indeterminate>
                </v-progress-circular>
            </v-overlay>

            <v-row>
                <v-col
                    v-for="user in users"
                    :key="user.id"
                    cols="12"
                    md="6"
                    lg="4"
                    xl="3">
                    <v-card
                        :to="{
                            name: 'User',
                            params: {
                                id: user.id,
                            },
                        }"
                        class="overflow-hidden"
                        style="height: 100%;">
                        <div class="d-flex flex-no-wrap">
                            <v-img
                                :src="user.avatar || '/img/man.svg'"
                                width="125"
                                max-width="125">
                            </v-img>

                            <div>
                                <v-card-title>
                                    {{ user.firstname }}
                                    <br>
                                    {{ user.lastname }}
                                </v-card-title>

                                <v-card-text>
                                    <div>{{ user.dob | fullYearDate }}</div>
                                    <div>{{ user.email }}</div>
                                    <user-status-chip
                                        :value="user.status"
                                        class="mr-2 mb-2"
                                        small>
                                    </user-status-chip>
                                    <user-identity-verified-chip
                                        :value="user.is_identity_verified"
                                        class="mr-2 mb-2"
                                        small>
                                    </user-identity-verified-chip>
                                    <user-bank-added-chip
                                        :value="user.is_payment_methods_added"
                                        type="Payment"
                                        class="mr-2 mb-2"
                                        small>
                                    </user-bank-added-chip>
                                    <user-bank-added-chip
                                        :value="user.is_delivery_methods_added"
                                        type="Delivery"
                                        class="mr-2 mb-2"
                                        small>
                                    </user-bank-added-chip>
                                </v-card-text>
                            </div>
                        </div>
                    </v-card>
                </v-col>
            </v-row>

            <v-pagination
                v-model="pagination.page"
                :length="pagination.pageCount"
                next-icon="$next"
                prev-icon="$prev"
                total-visible="10"
                class="custom-pagination"
                color="#7598B5">
            </v-pagination>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AppBar from '../../components/globals/AppBar.vue';
import Search from '../../components/globals/Search.vue';
import UserStatusChip from '../../components/Users/UserStatusChip.vue';
import UserIdentityVerifiedChip from '../../components/Users/UserIdentityVerifiedChip.vue';
import UserBankAddedChip from '../../components/Users/UserBankAddedChip.vue';
import UsersFilters from '../../components/Users/UsersFilters.vue';
import UsersSortDropdown from '../../components/Users/UsersSortDropdown.vue';
import ExportModal from '../../components/transactions/ExportModal.vue';

import UsersApi from '../../api/UsersApi';
import Socket from '../../services/Socket';

export default {
    components: {
        AppBar,
        Search,
        UserStatusChip,
        UserIdentityVerifiedChip,
        UserBankAddedChip,
        UsersFilters,
        ExportModal,
        UsersSortDropdown,
    },
    data: () => ({
        fileFormats: [
            'pdf',
            'xls',
            'csv',
            {
                format: 'csv',
                displayName: 'CSV (Lite)',
                options: {
                    isLite: true,
                },
            },
        ],
        users: [],
        search: '',
        debounce: null,
        showFilters: false,
        loading: false,
    }),
    computed: {
        ...mapGetters('users', [
            'filters',
        ]),
        pagination: {
            get() {
                return this.$store.state.users.pagination;
            },
            set(value) {
                this.$store.dispatch('users/updatePagination', value);
            },
        },
    },
    methods: {
        async loadUsers() {
            try {
                this.loading = true;

                const {
                    search,
                    filters,
                    pagination: {
                        page,
                        pageSize,
                    },
                } = this;

                const { data: { data, pagination } } = await UsersApi.getAll({
                    ...filters,
                    page,
                    pageSize,
                    search,
                });

                this.users = data;
                this.pagination = pagination;
            } catch (error) {
                this.$swal({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                });
            } finally {
                this.loading = false;
            }
        },
        async exportUsers(format, options) {
            try {
                const {
                    search,
                    filters,
                } = this;

                const { data: { url } } = await UsersApi.export({
                    ...filters,
                    ...options,
                    search,
                    format,
                });

                window.open(url);
            } catch (error) {
                this.$swal({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                });
            }
        },
        loadUsersDebounced() {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.loadUsers();
            }, 300);
        },
        addSocketEventListeners() {
            Socket.on('userSaved', this.loadUsers);
        },
        removeSocketEventListeners() {
            Socket.remove('userSaved');
        },
    },
    mounted() {
        this.$store.dispatch('users/updatePagination', {}); // hide the pagination when the page loads
        this.loadUsers();
        this.addSocketEventListeners();
    },
    beforeDestroy() {
        this.removeSocketEventListeners();
    },
    watch: {
        search() {
            this.pagination.page = 1;
            this.loadUsersDebounced();
        },
        filters: {
            deep: true,
            handler() {
                this.loadUsers();
            },
        },
        'pagination.page': {
            immediate: true,
            handler() {
                this.loadUsers();
            },
        },
    },
};
</script>
