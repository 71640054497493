<template>
  <v-container class="px-10">
    <app-bar></app-bar>
  </v-container>
</template>

<script>
// @ is an alias to /src
import AppBar from '@/components/globals/AppBar.vue';

export default {
    name: 'Home',
    components: {
        AppBar,
    },
};
</script>
