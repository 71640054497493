<template>
    <v-chip
        class="ma-2 text-uppercase status tw-cursor-pointer"
        :color="status.bg"
        :text-color="status.color"
    >
        {{ status.label }}
    </v-chip>
</template>
<script>
export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    computed: {
        status() {
            if (!this.item) return {};

            const { status } = this.item;

            const colors = {
                initiated: {
                    bg: '#FFEBA6',
                    color: '#D89342',
                },
                processing: {
                    bg: '#FFEBA6',
                    color: '#D89342',
                },
                completed: {
                    bg: '#C5F5E7',
                    color: '#15ABAB',
                },
                failed: {
                    bg: '#FFE3E2',
                    color: '#F07877',
                },
                expired: {
                    bg: '#FFE3E2',
                    color: '#F07877',
                },
                dispute: {
                    bg: '#FFE3E2',
                    color: '#F07877',
                },
                attention_needed: {
                    bg: '#FFE3E2',
                    color: '#F07877',
                },
            };

            return {
                label: status.split('_').join(' '),
                ...colors[status],
            };
        },
    },
};
</script>
