import api from './index';

const resource = '/users';

export default {

    /**
     * Get all user's posters beneficiaries
     * @param {Number} userId user id
     */
    getPostersBeneficiaries(userId, params) {
        return api.get(`${resource}/${userId}/posters-beneficiaries`, {
            params,
        });
    },

    /**
     * Get user's kaoshi info
     * @param {String} email user email
     */
    getKaoshiUser(email) {
        return api.get(`${resource}/fetch-kaoshi-info/${email}`);
    },

};
