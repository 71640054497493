<template>
    <v-chip
        v-bind="$attrs"
        :color="color"
        class="text-capitalize">
        {{ value }}
    </v-chip>
</template>

<script>
/* eslint-disable quote-props */

const colorMap = {
    'new': null,
    'transacted-completed': 'success',
    'transacted-matched': 'success',
    'transacted-posted': 'success',
    'banned': 'error',
};

export default {
    props: {
        value: {
            type: String,
            default: 'new',
        },
    },
    computed: {
        color() {
            return colorMap[this.value];
        },
    },
};
</script>
