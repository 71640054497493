<template>
  <div class="tw-w-full d-flex justify-center">
    <form class="tw-w-2/3">
      <v-row class="tw-w-2/3">
        <v-col cols="12" class="d-flex flex-column pa-2">
          <label class="mb-1">E-mail:</label>
          <input
            type="email"
            v-model="email"
            class="f-input default-font pa-3 input-gb-field-color tw-border-binput focus:tw-border-primary"
          />
        </v-col>
        <v-col cols="12" class="pa-2 mt-8">
          <h2 class="default-font tw-font-normal font-16">Change password</h2>
        </v-col>
        <v-col cols="12" class="d-flex flex-column pa-2 mt-3">
          <label class="mb-1">Current password:</label>
          <input
            type="password"
            v-model="currentPassword"
            class="f-input default-font pa-3 input-gb-field-color tw-border-binput focus:tw-border-primary"
          />
        </v-col>
        <v-col cols="12" class="d-flex flex-column pa-2 mt-3">
          <label class="mb-1">New password:</label>
          <input
            type="password"
            v-model="newPassword"
            class="f-input default-font pa-3 input-gb-field-color tw-border-binput focus:tw-border-primary"
          />
        </v-col>
        <v-col cols="12" class="d-flex flex-column pa-2 mt-3">
          <label class="mb-1">Repeate new password:</label>
          <input
            type="password"
            v-model="confirmNewPassword"
            class="f-input default-font pa-3 input-gb-field-color tw-border-binput focus:tw-border-primary"
          />
        </v-col>
        <v-col cols="12" class="mt-8">
          <v-btn
            color="primary"
            id="signBtn"
            class="default-font text-capitalize text-center px-5"
            to="/about-bank"
          >Save change</v-btn>
        </v-col>
      </v-row>
    </form>
  </div>
</template>
<script>
export default {
    data: () => ({
        email: 'John@mail.com',
        currentPassword: 'John Smith',
        newPassword: 'John Smith',
        confirmNewPassword: 'John Smith',
        agree: false,
    }),
};
</script>
<style scoped>
label {
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;

  /* or 22px */

  /* Gray text */
  color: #7598b5;
}
</style>
