export default [
    {
        author: 'Victoria Gardner',
        createdAt: '12-03-2020 12:05:10',
        content: `Hello Ryan,
        This is one of the simpler, no-frills text generators based only on Lorem Ipsum. You basically are only able to adjust the amount of paragraphs or words. You can also create bulleted lists or generate your text based on how many bytes it should be.`,
        authorImg: '/img/victoria.svg',
    },
    {
        author: 'Ryan King',
        createdAt: '12-03-2020 18:05:10',
        content: `Hi  @Victoria
        What I liked about this one was the English option. It’s pretty similar with most of these generators in their function and features—but this one simply asks whether you want the ‘lorem ipsum’ gibberish or do you want your gibberish in English words. 
        You are also able to insert random styling, which is extremely useful..<br/><br/>


        <p>Added HTML snippet: <strong>Code Example</strong></p>`,
        authorImg: '/img/ryan.svg',
        code: {
            content: `{
            "report_type": "payouts.summary.1",
            "parameters":  {
                "interval_start":   "1575158400",
                "interva;_end":   "1575244800"
            },
            "include": [
                "reporting_query.result"
            ]
        }`,
            type: 'application/json',
        },
    },
    {
        author: 'Victoria Gardner',
        createdAt: '24-03-2020 12:05:10',
        content: `Hello Ryan,
        This is one of the simpler, no-frills text generators based only on Lorem Ipsum. You basically are only able to adjust the amount of paragraphs or words. You can also create bulleted lists or generate your text based on how many bytes it should be.`,
        authorImg: '/img/victoria.svg',
    },
];
