const DEFAULT_PERIOD_FILTER = {
    range: [],
    value: 'all_time',
    title: 'All time',
};

export default {
    namespaced: true,
    state: {
        sentFilter: {
            to: undefined,
            from: undefined,
        },
        receivedFilter: {
            to: undefined,
            from: undefined,
        },
        matcherCountryFilter: null,
        currencyFilter: [],
        statusFilter: [],
        refundStatusFilter: [],
        typeFilter: [],
        partialMatchFilter: [],
        periodFilter: DEFAULT_PERIOD_FILTER,
        paymentTypeFilter: [],
        paymentProviderFilter: [],
        periodFilterTarget: 'posted_at',
        orderBy: 'posted_at',
    },

    actions: {
        updateSentFilter({ commit }, payload) {
            commit('SET_SENTFILTER', payload);
        },
        updateReceivedFilter({ commit }, payload) {
            commit('SET_RECIEVEDFILTER', payload);
        },
        updateMatcherCountryFilter({ commit }, payload) {
            commit('SET_MATCHERCOUNTRYFILTER', payload);
        },
        updateCurrencyFilter({ commit }, payload) {
            commit('SET_CURRENCYFILTER', payload);
        },
        updateStatusFilter({ commit }, payload) {
            commit('SET_STATUSFILTER', payload);
        },
        updateTypeFilter({ commit }, payload) {
            commit('SET_TYPEFILTER', payload);
        },
        updatePartialMatchFilter({ commit }, payload) {
            commit('SET_PARTIALMATCHFILTER', payload);
        },
        updatePeriodFilter({ commit }, payload) {
            commit('SET_PERIODFILTER', payload);
        },
        updatePeriodFilterTarget({ commit }, payload) {
            commit('SET_PERIODFILTERTARGET', payload);
        },
        updatePaymentTypeFilter({ commit }, payload) {
            commit('SET_PAYMENT_TYPE_FILTER', payload);
        },
        updatePaymentProviderFilter({ commit }, payload) {
            commit('SET_PAYMENT_PROVIDER_FILTER', payload);
        },
        clearAllFilters({ commit }) {
            commit('CLEAR_FILTERS');
        },
        updateOrderBy({ commit }, payload) {
            commit('SET_ORDERBY', payload);
        },
        updateRefundStatusFilter({ commit }, payload) {
            commit('SET_REFUNDSTATUSFILTER', payload);
        },
    },

    mutations: {
        SET_SENTFILTER(state, payload) {
            state.sentFilter = payload;
        },
        SET_RECIEVEDFILTER(state, payload) {
            state.receivedFilter = payload;
        },
        SET_MATCHERCOUNTRYFILTER(state, payload) {
            state.matcherCountryFilter = payload;
        },
        SET_CURRENCYFILTER(state, payload) {
            state.currencyFilter = payload || [];
        },
        SET_STATUSFILTER(state, payload) {
            state.statusFilter = payload;
        },
        SET_REFUNDSTATUSFILTER(state, payload) {
            state.refundStatusFilter = payload;
        },
        SET_TYPEFILTER(state, payload) {
            state.typeFilter = payload;
        },
        SET_PARTIALMATCHFILTER(state, payload) {
            state.partialMatchFilter = payload;
        },
        SET_PERIODFILTER(state, payload) {
            state.periodFilter = payload;
        },
        SET_PAYMENT_TYPE_FILTER(state, payload) {
            state.paymentTypeFilter = payload;
        },
        SET_PAYMENT_PROVIDER_FILTER(state, payload) {
            state.paymentProviderFilter = payload;
        },
        SET_PERIODFILTERTARGET(state, payload) {
            state.periodFilterTarget = payload;
        },
        SET_ORDERBY(state, payload) {
            state.orderBy = payload;
        },
        CLEAR_FILTERS(state) {
            state.sentFilter = {
                to: undefined,
                from: undefined,
            };

            state.receivedFilter = {
                to: undefined,
                from: undefined,
            };

            state.matcherCountryFilter = null;
            state.currencyFilter = [];
            state.statusFilter = [];
            state.refundStatusFilter = [];
            state.typeFilter = [];
            state.periodFilter = DEFAULT_PERIOD_FILTER;
            state.periodFilterTarget = 'posted_at';
            state.paymentTypeFilter = [];
            state.paymentProviderFilter = null;
        },
    },

    getters: {
        sentFilter(state) {
            return state.sentFilter;
        },
        receivedFilter(state) {
            return state.receivedFilter;
        },
        matcherCountryFilter(state) {
            return state.matcherCountryFilter;
        },
        currencyFilter(state) {
            return state.currencyFilter;
        },
        statusFilter(state) {
            return state.statusFilter;
        },
        refundStatusFilter(state) {
            return state.refundStatusFilter;
        },
        typeFilter(state) {
            return state.typeFilter;
        },
        partialMatchFilter(state) {
            return state.partialMatchFilter;
        },
        periodFilter(state) {
            return state.periodFilter;
        },
        periodFilterTarget(state) {
            return state.periodFilterTarget;
        },
        orderBy(state) {
            return state.orderBy;
        },
        paymentTypeFilter(state) {
            return state.paymentTypeFilter;
        },
        paymentProviderFilter(state) {
            return state.paymentProviderFilter;
        },
    },
};
