<template>
    <div class="tw-w-full">
        <AppBar>
            <template #title>
                <v-toolbar-title class="default-font font-21 mr-10 tw-text-black">
                    Referrals
                </v-toolbar-title>
            </template>
            <div class="d-flex justify-space-between mr-10">
                <Search
                    v-model="search"
                    placeholder="Search...">
                </Search>
            </div>
        </AppBar>

        <v-row class="mx-12 my-8">
            <v-col>
                <v-btn-toggle dense>
                    <v-btn :to="{ name: 'Referral Transactions' }" class="text-none">
                        Credit
                    </v-btn>
                    <v-btn class="secondary white--text text-none">
                        Withdrawal
                    </v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>

        <WithdrawReferralTransactions />
    </div>
</template>
<script>
import AppBar from '../../components/globals/AppBar.vue';
import Search from '../../components/globals/Search.vue';
import WithdrawReferralTransactions from '../../components/referralTransactions/WithdrawReferralTransactions.vue';

export default {
    components: {
        AppBar,
        Search,
        WithdrawReferralTransactions,
    },
    data: () => ({
        search: '',
    }),
};
</script>
