/* eslint-disable camelcase */
import Model from './Model';

class HybridOrderOption extends Model {
    static fields() {
        return {
            exchange_rate: Number,
            id: Number,
            fee: Number,
            matcher_amount: Number,
            matcher_country: String,
            matcher_currency: String,
            pb_country: String,
            pb_currency: String,
            third_party_company_id: Number,
            thirdPartyCompany: Object,
        };
    }

    /**
     * Get Exchange Rate with currencies
     */
    get exchangeRateFormatted() {
        return `${this.exchange_rate} ${this.pb_currency}/${this.matcher_currency}`;
    }

    /**
     * Get Matcher Amount with currency
     */
    get matcherAmountFormatted() {
        return `${this.matcher_amount} ${this.matcher_currency}`;
    }

    /**
     * Get Pb Amount with currency
     */
    get pbAmountFormatted() {
        return `${this.matcher_amount} ${this.pb_currency}`;
    }
}

export default HybridOrderOption;
