<template>
    <v-data-iterator
      :items="filterdItems"
      :items-per-page="4"
      :page.sync="page"
      @page-count="pageCount = $event"
      :search="search"
      hide-default-footer
    >
      <template v-slot:item="{ item }">
          <list-item :item="item"></list-item>
      </template>
      <template v-slot:footer>
        <v-pagination
            v-model="page"
            next-icon="$next"
            prev-icon="$prev"
            color="#7598B5"
            class="custom-pagination"
            :length="pageCount"
            ></v-pagination>
      </template>
    </v-data-iterator>
</template>
<script>
import { mapGetters } from 'vuex';
import ListItem from './ListItem.vue';

export default {
    props: {
        items: {
            type: Array,
            required: true,
        },
        search: {
            type: String,
        },
    },
    components: {
        ListItem,
    },
    data: () => ({
        itemsPerPage: 5,
        page: 1,
        pageCount: 0,
    }),
    methods: {
        pageCounted() {
        },
        filterByAmount(tr) {
            return (!this.amountFilter || tr.sent === this.amountFilter);
        },
        filterByFrom(tr) {
            const isAll = this.fromFilter === 'All';
            return (isAll || tr.from === this.fromFilter);
        },
        filterByRecepient(tr) {
            const isAll = this.recepientFilter === 'All';
            return (isAll || tr.to === this.recepientFilter);
        },
        filterByStatus(tr) {
            return !this.recepientFilter || tr.to === this.recepientFilter;
        },
    },
    computed: {
        ...mapGetters(['amountFilter', 'fromFilter', 'recepientFilter']),
        filterdItems() {
            return this.items.filter(
                (item) => this.filterByAmount(item)
                && this.filterByFrom(item)
                && this.filterByRecepient(item),
            );
        },
    },
};
</script>
<style>
.v-pagination__item {
    border: none;
    box-shadow: none !important;
    font-family: Muli;
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 136.5%;
}

</style>
