<template>
    <div class="d-flex flex-column">
        <v-row class="px-12 justify-end align-center">

            <v-col
                cols="12"
                md="9">
                <v-row>
                    <v-col
                        cols="auto"
                        class="text-center">
                        <small class="tw-text-secondary">Poster Amount</small>
                        <p class="text-h5 font-weight-black font-italic tw-text-primary mb-0">
                            {{ transactionsStatistic.total_poster_amount }}
                        </p>
                    </v-col>

                    <v-col
                        cols="auto"
                        class="text-center">
                        <small class="tw-text-secondary">Matcher Amount</small>
                        <p class="text-h5 font-weight-black font-italic tw-text-primary mb-0">
                            {{ transactionsStatistic.total_matcher_amount }}
                        </p>
                    </v-col>

                    <v-col
                        cols="auto"
                        class="text-center">
                        <small class="tw-text-secondary">Poster Fee</small>
                        <p class="text-h5 font-weight-black font-italic tw-text-primary mb-0">
                            {{ transactionsStatistic.total_poster_fee }}
                        </p>
                    </v-col>

                    <v-col
                        cols="auto"
                        class="text-center">
                        <small class="tw-text-secondary">Matcher Fee</small>
                        <p class="text-h5 font-weight-black font-italic tw-text-primary mb-0">
                            {{ transactionsStatistic.total_matcher_fee }}
                        </p>
                    </v-col>

                    <v-col
                        cols="auto"
                        class="text-center">
                        <small class="tw-text-secondary">Collection Fee</small>
                        <p class="text-h5 font-weight-black font-italic tw-text-primary mb-0">
                            {{ transactionsStatistic.total_collection_fee }}
                        </p>
                    </v-col>

                    <v-col
                        cols="auto"
                        class="text-center">
                        <small class="tw-text-secondary">Payout Fee</small>
                        <p class="text-h5 font-weight-black font-italic tw-text-primary mb-0">
                            {{ transactionsStatistic.total_payout_fee }}
                        </p>
                    </v-col>

                    <v-col
                        cols="auto"
                        class="text-center">
                        <small class="tw-text-secondary">Poster Payout Fee</small>
                        <p class="text-h5 font-weight-black font-italic tw-text-primary mb-0">
                            {{ transactionsStatistic.total_poster_payout_fee }}
                        </p>
                    </v-col>

                    <v-col
                        cols="auto"
                        class="text-center">
                        <small class="tw-text-secondary">Stampduty Fee</small>
                        <p class="text-h5 font-weight-black font-italic tw-text-primary mb-0">
                            {{ transactionsStatistic.total_stampduty_fee }}
                        </p>
                    </v-col>
                </v-row>
            </v-col>

            <v-col
                cols="12"
                md="3">
                <SortDropdown />
            </v-col>
        </v-row>
        <v-row class="px-12 justify-start align-center" v-if="isAdmin || isPrincipal">
            <div class="px-3">
                <h4 class="tw-text-primary">Bulk operations</h4>
            </div>
            <v-btn
                color="secondary"
                dark
                outlined
                class="text-capitalize default-font mr-3"
                @click="() => bulkSetHidden(true)"
            >
                Hide all
            </v-btn>
            <v-btn
                color="secondary"
                dark
                outlined
                class="text-capitalize default-font mr-3"
                @click="() => bulkSetHidden(false)"
            >
                Unhide all
            </v-btn>
        </v-row>
        <v-data-table
            item-key="id"
            :headers="headers"
            :items="transactions"
            :loading="loading"
            :items-per-page="pagination.pageSize"
            hide-default-footer
            single-expand
            class="px-12 py-4 tw-bg-transparent custom-table"
        >
            <template v-slot:item="{ item, headers, expand, isExpanded }">
                <transaction-row :item="item" :headers="headers" @row-clicked="expand(!isExpanded)">
                    <template v-slot:id="{ item }">
                        <div class="tw-cursor-pointer">
                            <span class="mr-1">{{ item.id }}</span>
                        </div>
                    </template>
                    <template v-slot:payment_type="{ item }">
                        <div class="tw-cursor-pointer">
                            <span class="mr-1">{{ getPaymentType(item) }}</span>
                        </div>
                    </template>
                    <template v-slot:reference="{ item }">
                        <div class="tw-cursor-pointer">
                            <span class="mr-1">{{ getReference(item) }}</span>
                        </div>
                    </template>
                    <template v-slot:poster_amount="{ item }">
                        <div class="d-flex flex-column tw-cursor-pointer">
                            <span>{{ getPosterAmount(item) | price }}</span>
                            <span class="tw-text-black">{{item.poster_currency}}</span>
                        </div>
                    </template>
                    <template v-slot:matcher_amount="{ item }">
                        <div class="d-flex flex-column tw-cursor-pointer">
                            <span>{{ getMatcherAmount(item) | price }}</span>
                            <span class="tw-text-black">{{item.matcher_currency}}</span>
                        </div>
                    </template>
                    <template v-slot:match.matcher_amount="{ item }">
                        <div v-if="item.match_id" class="d-flex flex-column tw-cursor-pointer">
                            <span>{{ item.match_matcher_amount | price }}</span>
                            <span class="tw-text-black">{{item.match_matcher_currency}}</span>
                        </div>
                    </template>
                    <template v-slot:rate="{ item }">
                        <div class="tw-cursor-pointer">
                            <span class="mr-1">{{ item.exchange_rate | price }}</span>
                        </div>
                    </template>
                    <template v-slot:poster_fee="{ item }">
                        <div class="tw-cursor-pointer">
                            <span class="mr-1">{{ getPosterFee(item) | price }}</span>
                            <span>{{item.poster_currency}}</span>
                        </div>
                    </template>
                    <template v-slot:matcher_fee="{ item }">
                        <div v-if="item.match_id" class="tw-cursor-pointer">
                            <span class="mr-1">{{ item.match_matcher_fee | price }}</span>
                            <span>{{item.type === 'hybrid' ? 'USD' : item.matcher_currency}}</span>
                        </div>
                    </template>
                    <template v-slot:match.matcher_payment_method_fee="{ item }">
                        <div class="tw-cursor-pointer" v-if="item.match_id">
                            <span class="mr-1">{{ item.match_matcher_payment_method_fee | price }}</span>
                            <span>{{item.match_matcher_currency}}</span>
                        </div>
                    </template>
                    <template #collection_fee="{ item }">
                        <div class="tw-cursor-pointer">
                            <span class="mr-1">{{ item.collection_fee | price }}</span>
                            <span>{{item.poster_currency}}</span>
                        </div>
                    </template>
                    <template #payout_fee="{ item }">
                        <div class="tw-cursor-pointer">
                            <span class="mr-1">{{ item.payout_fee | price }}</span>
                            <span>{{item.poster_currency}}</span>
                        </div>
                    </template>
                    <template #poster_payout_fee="{ item }">
                        <div class="tw-cursor-pointer">
                            <span class="mr-1">{{ item.poster_payout_fee | price }}</span>
                            <span>{{item.poster_currency}}</span>
                        </div>
                    </template>
                    <template #stampduty_fee="{ item }">
                        <div class="tw-cursor-pointer">
                            <span class="mr-1">{{ item.stampduty_fee | price }}</span>
                            <span>{{item.poster_currency}}</span>
                        </div>
                    </template>
                    <template v-slot:posted_at="{ item }">
                        <div class="d-flex flex-column tw-cursor-pointer" v-if="item.posted_at">
                            <span>{{ item.posted_at | date }}</span>
                            <span>at {{ item.posted_at | time }}</span>
                        </div>
                    </template>
                    <template v-slot:confirmed_at="{ item }">
                        <div class="d-flex flex-column tw-cursor-pointer" v-if="item.confirmed_at">
                            <span>{{ item.confirmed_at | date }}</span>
                            <span>at {{ item.confirmed_at | time }}</span>
                        </div>
                    </template>
                    <template v-slot:matcher_payment_completed_at="{ item }">
                        <div class="d-flex flex-column tw-cursor-pointer" v-if="item.matcher_payment_completed_at">
                            <span>{{ item.matcher_payment_completed_at | date }}</span>
                            <span>at {{ item.matcher_payment_completed_at | time }}</span>
                        </div>
                    </template>
                    <template v-slot:completed_at="{ item }">
                        <div class="d-flex flex-column tw-cursor-pointer" v-if="item.completed_at">
                            <span>{{ item.completed_at | date }}</span>
                            <span>at {{ item.completed_at | time }}</span>
                        </div>
                        <div class="d-flex flex-column tw-cursor-pointer" v-else-if="item.cancelled_at">
                            <span>{{ item.cancelled_at | date }}</span>
                            <span>at {{ item.cancelled_at | time }}</span>
                        </div>
                        <div class="d-flex flex-column tw-cursor-pointer" v-else-if="item.removed_at">
                            <span>{{ item.removed_at | date }}</span>
                            <span>at {{ item.removed_at | time }}</span>
                        </div>
                    </template>
                    <template v-slot:is_partial_match_allowed="{ item }">
                        <div class="d-flex flex-column tw-cursor-pointer">
                            <span>
                                {{ item.is_partial_match_allowed ? "Yes": "No" }}
                            </span>
                        </div>
                    </template>
                    <template v-slot:status="{ item }">
                        <transaction-status :item="item" />
                    </template>
                    <template v-slot:refund_status="{ item }">
                        <transaction-refund-status :item="item" />
                    </template>
                    <template v-slot:action="{ item }">
                        <v-menu transition="slide-y-transition" bottom offset-y content-class="mt-3 tw-rounded-lg">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    dark
                                    outlined
                                    elevation="0"
                                    large
                                    class="default-font font-12 text-capitalize justify-space-between"
                                    v-on="on"
                                >
                                    Actions
                                    <v-icon x-small class="ml-2">mdi-chevron-down</v-icon>
                                </v-btn>
                            </template>
                            <v-list>

                                <v-list-item v-if="isAdmin">
                                    <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                                        <v-btn
                                            text
                                            class="text-capitalize default-font"
                                            @click="() => cancelMatch(item)"
                                            :disabled="!canCancelMatch(item)"
                                        >
                                            Cancel Match
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item v-if="isAdmin">
                                    <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                                        <v-btn
                                            text
                                            class="text-capitalize default-font"
                                            @click="() => approveMatch(item)"
                                            :disabled="!canApproveMatch(item)"
                                        >
                                            Approve Match
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item>
                                    <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                                        <v-btn
                                            text
                                            class="text-capitalize default-font"
                                            @click="() => markAsMatcherPaid(item.id)"
                                            :disabled="!canCancelMatch(item)"
                                        >
                                            Mark as Paid
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item>
                                    <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                                        <v-btn
                                            text
                                            class="text-capitalize default-font"
                                            @click="() => markAsRefunded(item.id)"
                                            :disabled="!item.can_be_marked_as_refunded"
                                        >
                                            Mark as Refunded
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item v-if="isAdmin">
                                    <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                                        <v-btn
                                            text
                                            class="text-capitalize default-font"
                                            @click="() => completeOrder(item.id)"
                                            :disabled="!canCompleteOrder(item)"
                                        >
                                            Complete Order
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item v-if="isAdmin || isPrincipal">
                                    <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                                        <v-btn
                                            text
                                            class="text-capitalize default-font"
                                            @click="() => expireOrder(item.id)"
                                            :disabled="!canExpireOrder(item)"
                                        >
                                            Expire Order
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item v-if="isAdmin || isPrincipal">
                                    <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                                        <v-btn
                                            text
                                            class="text-capitalize default-font"
                                            @click="() => setHidden(item.id, !item.hidden)"
                                            :disabled="!canExpireOrder(item)"
                                        >
                                            {{ item.hidden ? 'Unhide' : 'Hide' }} Order
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item>
                                    <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                                        <MatchHistoryModal :orderId="item.id" />
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item v-if="isAdmin && item.match_id">
                                    <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                                        <MatchValidationJourneyModal
                                            :id="item.match_id"
                                        />
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item v-if="isAdmin">
                                    <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                                        <v-btn
                                            text
                                            class="text-capitalize default-font"
                                            @click="() => captureOrder(item.id)"
                                            :disabled="item.status !== 'unable_to_capture'"
                                        >
                                            Capture
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item v-if="isAdmin">
                                    <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                                        <v-btn
                                            text
                                            class="text-capitalize default-font"
                                            @click="() => voidOrder(item.id)"
                                            :disabled="!canVoid(item)"
                                        >
                                            Void
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item v-if="isAdmin">
                                    <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                                        <v-btn
                                            text
                                            class="text-capitalize default-font"
                                            @click="() => publishOrder(item.id)"
                                            :disabled="!canPublish(item)"
                                        >
                                            Publish
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item>
                                    <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                                        <v-btn
                                            text
                                            class="text-capitalize default-font"
                                            :href="$router.resolve({
                                                name: 'TransactionDetails',
                                                params: {
                                                    id: item.id,
                                                },
                                            }).href"
                                        >
                                            Validation Details
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item v-if="isAdmin">
                                    <v-list-item-title class="default-font tw-font-normal font-14 tw-text-black-400">
                                        <v-btn
                                            text
                                            class="text-capitalize default-font"
                                            @click="() => investigateOrder(item.id)"
                                            :disabled="!canInvestigate(item)"
                                        >
                                            Investigate
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item>

                            </v-list>
                        </v-menu>
                    </template>
                </transaction-row>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" transition="slide-y-transition">
                    <transaction-detail :id="item.id"></transaction-detail>
                </td>
            </template>
        </v-data-table>
        <v-pagination
            :value="pagination.page"
            @input="onPageChange"
            next-icon="$next"
            prev-icon="$prev"
            class="custom-pagination"
            color="#7598B5"
            :total-visible="5"
            :length="pagination.pageCount"
        ></v-pagination>
        <PublishOrderConfirmationDialog ref="publishOrderConfirmationDialog" />
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

import TransactionRow from './TransactionRow.vue';
import TransactionDetail from './TransactionDetail.vue';
import TransactionStatus from './TransactionStatus.vue';
import TransactionRefundStatus from './TransactionRefundStatus.vue';
import MatchHistoryModal from './MatchHistoryModal.vue';
import MatchValidationJourneyModal from './MatchValidationJourneyModal.vue';
import SortDropdown from './SortDropdown.vue';
import PublishOrderConfirmationDialog from './PublishOrderConfirmationDialog.vue';

const headers = [
    {
        text: 'Transaction ID',
        align: 'center',
        class: 'tw-text-black default-font py-2 text-start tw-rounded-lg tw-rounded-r-none first_cell',
        value: 'id',
    },
    {
        text: 'Reference',
        align: 'center',
        class: 'tw-text-black default-font py-2 text-end ',
        value: 'reference',
    },
    {
        text: 'Payment Type',
        align: 'center',
        class: 'tw-text-black default-font py-2 text-end',
        value: 'payment_type',
    },
    {
        text: 'Type',
        align: 'center',
        class: 'tw-text-black default-font py-2 text-end ',
        value: 'type',
    },
    {
        text: 'Poster Amount',
        align: 'end',
        value: 'poster_amount',
        sortable: false,
        class: 'tw-text-black default-font py-2 text-end ',
    },
    {
        text: 'Matcher Amount',
        align: 'end',
        value: 'matcher_amount',
        sortable: false,
        class: 'tw-text-black default-font py-2 text-end ',
    },
    {
        text: 'Matcher Amount in Matcher Currency',
        align: 'end',
        value: 'match.matcher_amount',
        sortable: false,
        class: 'tw-text-black default-font py-2 text-end ',
    },
    {
        text: 'Rate',
        align: 'end',
        value: 'rate',
        sortable: false,
        class: 'tw-text-black default-font py-2 text-end ',
    },
    {
        text: 'Poster Fee',
        align: 'end',
        value: 'poster_fee',
        sortable: false,
        class: 'tw-text-black default-font py-2 text-end',
    },
    {
        text: 'Matcher Fee',
        align: 'end',
        value: 'matcher_fee',
        sortable: false,
        class: 'tw-text-black default-font py-2 text-end ',
    },
    {
        text: 'Matcher Fee in Matcher Currency',
        align: 'end',
        value: 'match.matcher_payment_method_fee',
        sortable: false,
        class: 'tw-text-black default-font py-2 text-end ',
    },
    {
        text: 'Collection Fee',
        align: 'end',
        value: 'collection_fee',
        sortable: false,
        class: 'tw-text-black default-font py-2 text-end ',
    },
    {
        text: 'Payout Fee',
        align: 'end',
        value: 'payout_fee',
        sortable: false,
        class: 'tw-text-black default-font py-2 text-end ',
    },
    {
        text: 'Poster Payout Fee',
        align: 'end',
        value: 'poster_payout_fee',
        sortable: false,
        class: 'tw-text-black default-font py-2 text-end ',
    },
    {
        text: 'Stampduty Fee',
        align: 'end',
        value: 'stampduty_fee',
        sortable: false,
        class: 'tw-text-black default-font py-2 text-end ',
    },
    {
        text: 'Posted',
        align: 'start',
        value: 'posted_at',
        class: 'tw-text-secondary default-font py-2 text-start',
    },
    {
        text: 'Confirmed',
        align: 'start',
        value: 'confirmed_at',
        class: 'tw-text-secondary default-font py-2 text-start',
    },
    {
        text: 'Validated At',
        align: 'start',
        value: 'matcher_payment_completed_at',
        class: 'tw-text-secondary default-font py-2 text-start',
    },
    {
        text: 'Completed/Cancelled',
        align: 'start',
        value: 'completed_at',
        class: 'tw-text-secondary default-font py-2 text-start',
    },
    {
        text: 'Partial Match Allowed',
        align: 'start',
        value: 'is_partial_match_allowed',
        class: 'tw-text-secondary default-font py-2 text-start',
    },
    {
        text: 'Memo',
        align: 'center',
        value: 'admin_memo',
        sortable: false,
        class: 'tw-text-secondary default-font py-2 text-start',
    },
    {
        text: 'Status',
        align: 'center',
        value: 'status',
        sortable: true,
        class: 'tw-text-secondary default-font py-2 text-center',
    },
    {
        text: 'Refund Status',
        align: 'center',
        value: 'refund_status',
        sortable: true,
        class: 'tw-text-secondary default-font py-2 text-center',
    },
    {
        align: 'center',
        value: 'action',
        sortable: false,
        class: 'default-font py-2 text-center last_cell tw-rounded-lg tw-rounded-l-none',
    },
];

export default {
    components: {
        TransactionRow,
        TransactionDetail,
        TransactionStatus,
        TransactionRefundStatus,
        MatchHistoryModal,
        MatchValidationJourneyModal,
        SortDropdown,
        PublishOrderConfirmationDialog,
    },
    props: {
        transactions: {
            type: Array,
            default() {
                return [];
            },
        },
        pagination: {
            type: Object,
            default() {
                return {};
            },
        },
        transactionsStatistic: {
            type: Object,
            default: () => ({}),
        },
        loading: {
            type: Boolean,
            default() {
                return true;
            },
        },
    },
    data() {
        return {
            headers,
            page: 1,
            pageCount: 0,
            itemsPerPage: 10,
        };
    },
    computed: {
        ...mapGetters([
            'user',
        ]),
        isAdmin() {
            return this.user.role === 'admin';
        },
        isPrincipal() {
            return this.user.role === 'principal';
        },
    },
    methods: {
        expandRow(item) {
            if (this.expanded.includes(item)) {
                this.expanded = [];
            } else {
                this.expanded.push(item);
            }
        },
        isCompleted(status) {
            return status === 'completed';
        },
        actionColor(status) {
            return this.isCompleted(status) ? '#EAF0F5' : '#F07877';
        },
        onPageChange(page) {
            this.$emit('pageChange', page);
        },
        confirm(text = 'Are you sure you want to take this action?') {
            return this.$swal({
                type: 'info',
                title: text,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => result.value);
        },
        canCancelMatch(item) {
            const {
                match_id,
                match_cancelled_at,
                match_matcher_payment_completed_at,
                match_completed_at,
            } = item;
            if (!match_id) {
                return false;
            }

            return !match_cancelled_at && !match_matcher_payment_completed_at && !match_completed_at;
        },
        cancelMatch(item) {
            if (item.match_id) {
                this.confirm('Are you sure you want to take this action - Cancel Match?').then((value) => {
                    if (value) {
                        this.$emit('cancelMatch', {
                            id: item.match_id,
                        });
                    }
                });
            }
        },
        canApproveMatch(item) {
            return this.canCancelMatch(item);
        },
        approveMatch(item) {
            if (item.match_id) {
                this.confirm('Are you sure you want to take this action - Approve Match?').then((value) => {
                    if (value) {
                        this.$emit('approveMatch', {
                            id: item.match_id,
                        });
                    }
                });
            }
        },
        markAsMatcherPaid(id) {
            this.confirm('Are you sure you want to take this action - Mark as Paid?').then((value) => {
                if (value) {
                    this.$emit('markAsMatcherPaid', id);
                }
            });
        },
        canCompleteOrder(item) {
            if (!item.published_at) return false;
            if (!item.matched_at) return false;
            if (!item.matcher_payment_completed_at) return false;

            if (item.completed_at) return false;
            if (item.cancelled_at) return false;
            if (item.is_removed) return false;

            return true;
        },
        completeOrder(id) {
            this.confirm('Are you sure you want to take this action - Complete Order?').then((value) => {
                if (value) {
                    this.$emit('completeOrder', id);
                }
            });
        },
        canExpireOrder(item) {
            if (item.matched_at) return false;
            if (item.matcher_payment_completed_at) return false;
            if (item.completed_at) return false;
            if (item.cancelled_at) return false;
            if (item.is_removed) return false;

            return true;
        },
        expireOrder(id) {
            this.confirm('Are you sure you want to take this action - Expire Order?').then((value) => {
                if (value) {
                    this.$emit('expireOrder', id);
                }
            });
        },
        setHidden(id, hidden) {
            const text = hidden ? 'Hide' : 'Unhide';
            this.confirm(`Are you sure you want to take this action - ${text} Order?`).then((value) => {
                if (value) {
                    this.$emit('setHidden', {
                        id,
                        hidden,
                    });
                }
            });
        },
        bulkSetHidden(hidden) {
            const text = hidden ? 'Hide' : 'Unhide';
            this.confirm(`Are you sure you want to take this action - ${text} selected orders?`).then((value) => {
                if (value) {
                    this.$emit('bulkSetHidden', {
                        hidden,
                    });
                }
            });
        },
        captureOrder(id) {
            this.confirm('Are you sure you want to take this action - Capture Order?').then((value) => {
                if (value) {
                    this.$emit('captureOrder', id);
                }
            });
        },
        voidOrder(id) {
            this.confirm('Are you sure you want to take this action - Void Order?').then((value) => {
                if (value) {
                    this.$emit('voidOrder', id);
                }
            });
        },
        canVoid(item) {
            if (item.status === 'unable_to_void') {
                return true;
            }

            if (item.removed_at) {
                return true;
            }

            return false;
        },
        canInvestigate(item) {
            if (!item.match_id) return false;

            if (item.statusText === 'Published') return true;
            if (item.statusText === 'Unpublished') return true;
            if (item.statusText === 'Expired') return true;
            if (item.statusText === 'Canceled') return true;

            return false;
        },
        investigateOrder(id) {
            this.confirm('Are you sure you want to take this action - Investigate Order?').then((value) => {
                if (value) {
                    this.$emit('investigateOrder', id);
                }
            });
        },
        getPosterAmount(item) {
            const {
                match_id,
                match_poster_amount,
                is_partial_match_allowed: isPartialMatchAllowed,
            } = item;

            if (match_id && isPartialMatchAllowed) {
                return match_poster_amount;
            }

            return item.poster_amount;
        },
        getMatcherAmount(item) {
            const {
                match_id,
                match_matcher_amount,
                is_partial_match_allowed: isPartialMatchAllowed,
            } = item;

            if (match_id && isPartialMatchAllowed) {
                return match_matcher_amount;
            }

            return item.matcher_amount;
        },
        getPosterFee(item) {
            const {
                match_id,
                match_poster_amount,
                is_partial_match_allowed: isPartialMatchAllowed,
                poster_fee: posterFee,
            } = item;

            if (match_id && isPartialMatchAllowed) {
                const feePercentage = posterFee / item.poster_amount;
                if (match_poster_amount !== item.poster_amount) {
                    return Math.floor(match_poster_amount * feePercentage);
                }
            }

            return posterFee;
        },
        canPublish(item) {
            return !item.published_at;
        },
        publishOrder(id) {
            this.$refs.publishOrderConfirmationDialog.show().then((paymentType) => {
                if (!paymentType) {
                    this.$swal({
                        icon: 'error',
                        title: 'Payment type is required',
                    });
                } else {
                    this.$emit('publishOrder', {
                        id,
                        paymentType,
                    });
                }
            });
        },
        getReference(item) {
            const {
                flwRef,
                poster_manual_payment_ref: manualPaymentRef,
            } = item;

            if (flwRef) {
                return flwRef;
            }

            return manualPaymentRef || '';
        },
        getPaymentType(item) {
            const {
                is_admin: isAdmin,
                payment_type: paymentType,
            } = item;

            if (isAdmin) {
                return 'Admin';
            }

            return paymentType || '';
        },
        markAsRefunded(id) {
            this.confirm('Are you sure you want to take this action - Mark as Refunded?').then((value) => {
                if (value) {
                    this.$emit('markAsRefunded', id);
                }
            });
        },
    },
};
</script>
<style lang="scss">
.custom-table {
  .v-data-table-header th.sortable .v-data-table-header__icon {
    color: #2E5677 !important;
  }

  th {
    border: none !important;
  }
}
</style>
