<template>
  <div class="tw-w-full">
    <app-bar>
      <template slot="title">
        <router-link to="/developer#api-keys">
          <v-icon x-large class="back-style mr-">$back</v-icon>
        </router-link>
        <v-toolbar-title class="default-font font-21 mr-10">Request access to use 100 items in Development</v-toolbar-title>
      </template>
    </app-bar>
    <v-container d-flex flex-column>
      <v-row no-gutters class="mt-6">
          <v-col cols="9" sm="9" class="request-input">
            <v-row >
              <v-col cols="4" sm="4" class="default-font key-item-margin">
                <span class="font-style font-light-bold-14">First name:</span>
                <v-text-field
                  solo
                  flat
                  background-color="#F7FBFC"
                  class="request__input-field input-field-color mt-2"
                  v-model="firstName"
                  elevation-0
                ></v-text-field>
              </v-col>
              <v-col cols="4" sm="4" class="default-font key-item-margin">
                <span class="font-style font-light-bold-14">Last name:</span>
                <v-text-field
                  solo
                  flat
                  background-color="#F7FBFC"
                  class="request__input-field input-field-color mt-2"
                  v-model="name"
                  elevation-0
                ></v-text-field>
              </v-col>
              <v-col cols="4" sm="4" class="default-font key-item-margin">
                <span class="font-style font-light-bold-14">Phone number</span>
                <v-text-field
                  solo
                  flat
                  background-color="#F7FBFC"
                  v-model="phone"
                  class="request__input-field input-field-color mt-2"
                  elevation-0
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row >
              <v-col cols="12" sm="12" class="default-font key-item-margin">
                <span class="input-label">Please, describe what you're building</span>
                <v-textarea
                  solo
                  flat
                  height="200px"
                  v-model="content"
                  background-color="#F7FBFC"
                  class="request__input-field input-field-color mt-2 tw--text-secondary"
                  elevation-0
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row >
              <v-col cols="12" sm="12" class="default-font key-item-margin">
                  <v-btn
                    text @click="save"
                    elevation="0"
                    class="font-style font-dark-15 success-btn text-capitalize"
                  >Request access</v-btn>
              </v-col>
            </v-row>
            <v-row >
              <v-col cols="6" sm="6" class="default-font input-label font-12 text-left mb-12">
                  We'll use this information to electronically validate your identity. It will only be used in accordance with our privacy statement. You'll receive an email once your request has been processed.
              </v-col>
            </v-row>
          </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import AppBar from '@/components/globals/AppBar.vue';

export default {
    name: 'Developer',
    components: {
        AppBar,
    },
    data() {
        return {
            firstName: 'Ryan',
            name: 'King',
            phone: '+1 212-967-0785',
            content: 'What I liked about this one was the English option. It’s pretty similar with most of these generators in their function and features — but this one simply asks whether you want the ‘lorem ipsum’ gibberish or do you want your gibberish in English words. You are also able to insert random styling, which is extremely useful.',
        };
    },
    methods: {
        save() {
            this.$router.push('/developer');
        },
    },
};
</script>
<style>
.request-input .v-text-field__details{
  display: none;
}
.request-input .v-text-field--solo::before{
  box-shadow: none !important;
}
.back-style{
  height: 45px;
  width: 45px;
}
</style>
<style scoped>
.request__input-field {
font-family: Muli;
font-style: normal;
font-weight: normal;
font-size: 15px !important;
line-height: 136.5%;

/* Text black */
color: #2E5677 !important;
}
</style>
