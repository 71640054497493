var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mx-12"},[_c('v-col',[_c('v-row',[_c('v-col'),_c('v-col',[_c('v-select',{attrs:{"items":_vm.statuses,"label":"Status","clearable":"","outlined":"","dense":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('v-col',[_c('PeriodFilter',{attrs:{"label":"Period","clearable":"","outlined":"","dense":""},model:{value:(_vm.created_at),callback:function ($$v) {_vm.created_at=$$v},expression:"created_at"}})],1)],1),_c('div',{staticClass:"d-flex flex-column"},[_c('v-data-table',{staticClass:"tw-bg-transparent custom-table",attrs:{"item-key":"id","headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"items-per-page":_vm.pagination.pageSize,"hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('ReferralTransactionRow',{attrs:{"item":item,"headers":headers},on:{"row-clicked":function($event){return expand(!isExpanded)}},scopedSlots:_vm._u([{key:"item.completed_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("datetime")(value))+" ")]}},{key:"item.confirmed_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("datetime")(value))+" ")]}},{key:"item.status.status",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"color":_vm.statusColor(value)}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"content-class":"mt-3 tw-rounded-lg","transition":"slide-y-transition","offset-y":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"default-font font-12 text-capitalize justify-space-between",attrs:{"color":"secondary","dark":"","outlined":"","elevation":"0","large":""}},on),[_vm._v(" Actions "),_c('v-icon',{staticClass:"ml-2",attrs:{"x-small":""}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.cancel(item)}}},[_c('v-list-item-title',{staticClass:"default-font tw-font-normal font-14 tw-text-black-400"},[_vm._v(" Cancel ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.approve(item)}}},[_c('v-list-item-title',{staticClass:"default-font tw-font-normal font-14 tw-text-black-400"},[_vm._v(" Approve ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.complete(item)}}},[_c('v-list-item-title',{staticClass:"default-font tw-font-normal font-14 tw-text-black-400"},[_vm._v(" Mark as Completed ")])],1)],1)],1)]}}],null,true)})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length,"transition":"slide-y-transition"}},[_c('WithdrawReferralTransactionDetail',{attrs:{"item":item}})],1)]}}])}),_c('v-pagination',{staticClass:"custom-pagination",attrs:{"length":_vm.pagination.pageCount,"next-icon":"$next","prev-icon":"$prev","color":"#7598B5"},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }