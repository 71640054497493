<template>
    <div v-if="methodDetails">
        <p>
            <strong>{{ title }}:</strong>
        </p>

        <div
            v-for="({ label, property }, key) in methodFields"
            :key="key">
            <p v-if="methodDetails[property]">
                {{ label }}: {{ methodDetails[property] }}

                <v-btn
                    v-if="copyButtons"
                    icon
                    @click="copyToClipboard(methodDetails[property])">
                    <v-icon small class="mr-1">$copy</v-icon>
                </v-btn>
            </p>
        </div>
    </div>
</template>

<script>
import { startCase } from 'lodash';

export default {
    props: {
        user: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
            default: 'payment',
        },
        copyButtons: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        methodFields: [
            {
                label: 'Bank',
                property: 'bank',
            },
            {
                label: 'Sort Code',
                property: 'sort_code',
            },
            {
                label: 'Bank Account',
                property: 'account_number',
            },
            {
                label: 'IBAN',
                property: 'iban',
            },
            {
                label: 'BIC',
                property: 'bic',
            },
            {
                label: 'Email',
                property: 'email',
            },
        ],
    }),
    computed: {
        title() {
            return startCase(this.type);
        },
        paymentMethod() {
            return this.user?.payment_methods?.[0] || {};
        },
        deliveryMethod() {
            return this.user?.delivery_methods?.[0] || {};
        },
        methodDetails() {
            const {
                account,
                provider,
                method,
                institution_name: institutionName,
            } = this[`${this.type}Method`];

            if (method === 'CARD') {
                return {
                    bank: institutionName,
                };
            }

            if (method === 'ZELLE') {
                return {
                    provider,
                    method,
                    name: account.holder_name,
                    email: account.zelle_email,
                };
            }

            if (method === 'INTERAC') {
                return {
                    provider,
                    method,
                    name: account.holder_name,
                    email: account.interac_email,
                };
            }

            if (method === 'FASTER' && provider === 'PLAID') {
                return {
                    provider,
                    method,
                    name: account.holder_name,
                    account_number: account.account,
                    sort_code: account.sort_code,
                };
            }

            if (method === 'FASTER' && provider === 'KLARNA') {
                return {
                    provider,
                    method,
                    name: account.holder_name,
                    bank: institutionName,
                    account_number: account.account_number,
                    sort_code: account.bank_code,
                };
            }

            if (method === 'SEPA' && provider === 'KLARNA') {
                return {
                    provider,
                    method,
                    bank: institutionName,
                    name: account.holder_name,
                    iban: account.iban,
                    bic: account.bic,
                };
            }

            if (provider === 'MONO') {
                return {
                    provider,
                    method,
                    account_number: account.accountNumber,
                    bank_name: institutionName,
                };
            }

            if (provider === 'FLUTTERWAVE') {
                return {
                    provider,
                    method,
                    account_number: account.account_number,
                    branch_code: account.branch_code,
                    bank_name: institutionName,
                };
            }

            return null;
        },
    },
};
</script>
