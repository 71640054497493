import axios from 'axios';
import { pickBy, identity } from 'lodash';

const axiosInstance = axios.create({
    baseURL: 'https://scraper.kaoshinetwork.com/',
});

export default {

    /**
     * Get all third party offers
     * @param {Object} params filters
     */
    fees(params) {
        return axiosInstance.get('/fees', {
            params: pickBy(params, identity),
        });
    },

    /**
     * Get all payment methods
     */
    paymentMethods() {
        return axiosInstance.get('/payment-methods');
    },

    /**
     * Get all delivery methods
     */
    deliveryMethods() {
        return axiosInstance.get('/delivery-methods');
    },

    /**
     * Get all countries
     */
    countries() {
        return axiosInstance.get('/countries');
    },

    /**
     * Get Exchange rates
     * @param {Object} params query params
     */
    getExchangeRates(params) {
        return axiosInstance.get('/exchange-rate', {
            params,
        });
    },
};
