/* eslint-disable camelcase */
import Vue from 'vue';
import VueFlags from '@growthbunker/vueflags';
import moment from 'moment';
import { startCase } from 'lodash';
import VueCodemirror from 'vue-codemirror';
import VueSweetalert2 from 'vue-sweetalert2';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './plugins/vee-validate';
import global from './mixins/global';
import { numberFormat } from './utils';
import 'sweetalert2/dist/sweetalert2.min.css'; // eslint-disable-line
import 'codemirror/lib/codemirror.css';
import './assets/styles/main.scss';
import UserBankDetails from './components/Users/UserBankDetails.vue';

Vue.use(VueCodemirror);

Vue.use(VueFlags, {
    iconPath: '/flags/',
});
Vue.use(VueSweetalert2);
Vue.config.productionTip = false;

Vue.mixin(global);

const DECIMALS = 2;
const DECIMA_SEP = '.';
const THOUSANDS_SEP = ',';

Vue.filter('price', (value) => {
    const formated = numberFormat(
        value, DECIMALS,
        DECIMA_SEP,
        THOUSANDS_SEP,
    );

    return formated;
});

Vue.filter('fullYearDate', (value) => (value?.length > 4
    ? moment(value).format('L')
    : moment(value).format('YYYY')));
Vue.filter('date', (value) => moment(value).format('L'));
Vue.filter('time', (value) => moment(value).format('h:mm:ss A'));
Vue.filter('datetime', (value) => moment(value).format('L, h:mm:ss A'));
Vue.filter('humanize', (value) => startCase(value));
Vue.filter('userFullname', (user) => {
    const { firstname, middlename, lastname } = user;
    return [firstname, middlename, lastname].filter(Boolean).join(' ');
});

Vue.filter('userFullAddress', (user) => {
    const {
        building_number,
        street_number,
        address,
        address2,
        city,
        state,
        country,
        zipcode,
    } = user;

    const fullAddress = [
        building_number,
        street_number,
        address,
        address2,
        city,
        state,
        country,
        zipcode,
    ].filter(Boolean).join(', ');

    return fullAddress;
});

Vue.component('UserBankDetails', UserBankDetails);

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount('#app');
