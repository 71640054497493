<template>
    <v-dialog
        v-model="exportDialog"
        width="unset"
    >
        <template v-slot:activator="{ on }">
            <v-btn text color="primary" class="text-capitalize default-font tw-text-sm" v-on="on">
                <v-icon large>$vuetify.icons.export</v-icon>Export
            </v-btn>
        </template>

        <v-card flat class=" tw-rounded-lg px-5 pb-5 default-font tw-bg-white align-self-center">
            <v-card-title>
                <v-spacer></v-spacer>
                <v-btn icon large color="secondary" dark>
                    <v-icon dark @click="exportDialog = !exportDialog">$delete</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <div class="d-flex flex-column default-font tw-text-black">
                    <h2 class="font-20 text-center">Export Data</h2>
                    <h4 class="font-14 mt-4 text-center tw-font-semibold">How would you like to export?</h4>
                    <div class="export__wrapper d-flex tw-full justify-center  mt-4">
                        <div
                            v-for="(item, key) in fileFormats"
                            :key="key"
                            class="export__content hover:tw-bg-hoverBg"
                            @click="emitExport(item.format || item, item.options)">
                            <div class="export__logo">
                                <v-avatar class="align-self-center tw-w-full text-center" tile size="64">
                                    <img :src="`/img/${item.format || item}.svg`"/>
                                </v-avatar>
                            </div>
                            <span class="export__text default-font font-15 tw-text-black mt-2 text-center">
                                Export as {{ item.displayName || toUpper(item.format || item) }}
                            </span>
                        </div>
                    </div>
                </div>
            </v-card-text>
        </v-card>

    </v-dialog>
</template>

<script>
import { capitalize, toUpper } from 'lodash';

export default {
    props: {
        fileFormats: {
            type: Array,
            default: () => ([
                'pdf',
                'xls',
            ]),
        },
    },
    data() {
        return {
            exportDialog: false,
        };
    },
    methods: {
        toUpper,
        emitExport(format, options) {
            const payload = { format, options };
            this.$emit('export', payload);
            this.$emit(`exportTo${capitalize(format)}`, payload);
        },
        exportToPdf() {
            this.$emit('exportToPdf');
        },
        exportToXls() {
            this.$emit('exportToXls');
        },
    },
};
</script>
<style scoped>
.export__content {
  border: 1px solid #EAF0F5;
box-sizing: border-box;
border-radius: 11px;
}

.export__content {
  @apply tw-flex tw-flex-col tw-justify-center tw-mr-5 tw-p-8 tw-cursor-pointer
}
</style>
