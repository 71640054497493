<template>
    <v-row class="mx-12">
        <v-col>
            <v-row>
                <v-col></v-col>
                <v-col>
                    <v-select
                        v-model="status"
                        :items="statuses"
                        label="Status"
                        clearable
                        outlined
                        dense />
                </v-col>

                <v-col>
                    <PeriodFilter
                        v-model="created_at"
                        label="Period"
                        clearable
                        outlined
                        dense>
                    </PeriodFilter>
                </v-col>
            </v-row>

            <div class="d-flex flex-column">
                <v-data-table
                    item-key="id"
                    :headers="headers"
                    :items="items"
                    :loading="loading"
                    :items-per-page="pagination.pageSize"
                    class="tw-bg-transparent custom-table"
                    hide-default-footer
                    disable-sort>

                    <template #item="{ item, headers, expand, isExpanded }">
                        <ReferralTransactionRow
                            :item="item"
                            :headers="headers"
                            @row-clicked="expand(!isExpanded)">

                            <template #item.completed_at="{ value }">
                                {{ value | datetime }}
                            </template>

                            <template #item.confirmed_at="{ value }">
                                {{ value | datetime }}
                            </template>

                            <template #item.status.status="{ value }">
                                <v-chip
                                    :color="statusColor(value)">
                                    {{ value }}
                                </v-chip>
                            </template>

                            <template #item.actions="{ item }">
                                <v-menu
                                    content-class="mt-3 tw-rounded-lg"
                                    transition="slide-y-transition"
                                    offset-y
                                    bottom>
                                    <template #activator="{ on }">
                                        <v-btn
                                            color="secondary"
                                            dark
                                            outlined
                                            elevation="0"
                                            large
                                            class="default-font font-12 text-capitalize justify-space-between"
                                            v-on="on">
                                            Actions
                                            <v-icon x-small class="ml-2">mdi-chevron-down</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item @click="cancel(item)">
                                            <v-list-item-title
                                                class="default-font tw-font-normal font-14 tw-text-black-400">
                                                Cancel
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click="approve(item)">
                                            <v-list-item-title
                                                class="default-font tw-font-normal font-14 tw-text-black-400">
                                                Approve
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click="complete(item)">
                                            <v-list-item-title
                                                class="default-font tw-font-normal font-14 tw-text-black-400">
                                                Mark as Completed
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                        </ReferralTransactionRow>
                    </template>

                    <template #expanded-item="{ headers, item }">
                        <td
                            :colspan="headers.length"
                            transition="slide-y-transition">
                            <WithdrawReferralTransactionDetail :item="item" />
                        </td>
                    </template>

                </v-data-table>

                <v-pagination
                    v-model="pagination.page"
                    :length="pagination.pageCount"
                    next-icon="$next"
                    prev-icon="$prev"
                    class="custom-pagination"
                    color="#7598B5">
                </v-pagination>
            </div>
        </v-col>
    </v-row>
</template>
<script>
import ReferralTransactionsApi from '../../api/ReferralTransactionsApi';

import PeriodFilter from '../globals/PeriodFilter.vue';
import ReferralTransactionRow from './ReferralTransactionRow.vue';
import WithdrawReferralTransactionDetail from './WithdrawReferralTransactionDetail.vue';

export default {
    components: {
        PeriodFilter,
        ReferralTransactionRow,
        WithdrawReferralTransactionDetail,
    },
    props: {
        defaultFilters: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        loading: false,
        items: [],
        pagination: {
            page: 1,
            pageSize: 10,
        },
        search: '',
        statuses: ['initiated', 'completed', 'pending', 'cancelled'],
        status: null,
        created_at: null,
    }),
    computed: {
        headers() {
            return [
                {
                    text: 'Transaction ID',
                    value: 'id',
                },
                {
                    text: 'Reference',
                    value: 'reference', // TODO: add value
                },
                {
                    text: 'Referral Fee',
                    value: 'amountFormatted',
                },
                {
                    text: 'Initiated at',
                    value: 'created_at',
                },
                {
                    text: 'Confirmed at',
                    value: '',
                },
                {
                    text: 'Completed at',
                    value: '',
                },
                {
                    text: 'Status',
                    value: 'status.status',
                },
                {
                    text: 'Actions',
                    value: 'actions',
                },
            ];
        },
    },
    methods: {
        async loadItems() {
            try {
                this.loading = true;

                const {
                    defaultFilters,
                    search,
                    status,
                    created_at,
                    pagination: {
                        page,
                        pageSize,
                    },
                } = this;

                const data = await ReferralTransactionsApi.getAll({
                    ...defaultFilters,
                    type: 'withdraw',
                    status,
                    created_at,
                    search,
                    page,
                    pageSize,
                });

                const { models, pagination } = data;
                this.items = models;
                this.pagination = pagination;
            } finally {
                this.loading = false;
            }
        },

        statusColor(status) {
            if (status === 'completed') {
                return 'success';
            } if (status === 'initiated') {
                return 'indigo';
            } if (status === 'cancelled') {
                return 'error';
            }
            return null;
        },

        async approve(referralTransaction) {
            try {
                const { value } = await this.$swal({
                    icon: 'warning',
                    title: 'Are you sure you want to approve this Withdraw Referral Transaction?',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                });

                if (value) {
                    this.loading = true;
                    await ReferralTransactionsApi.approve(referralTransaction.id);

                    this.$swal({
                        icon: 'success',
                        title: 'Approved',
                        text: 'Payment successfully initiated',
                    });
                    this.loadItems();
                }
            } finally {
                this.loading = false;
            }
        },

        async cancel(referralTransaction) {
            try {
                const { value } = await this.$swal({
                    icon: 'warning',
                    title: 'Are you sure you want to cancel this Withdraw Referral Transaction?',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                });

                if (value) {
                    this.loading = true;
                    await ReferralTransactionsApi.cancel(referralTransaction.id);

                    this.$swal({
                        icon: 'success',
                        title: 'Cancelled',
                        text: 'Payment successfully cancelled',
                    });
                    this.loadItems();
                }
            } finally {
                this.loading = false;
            }
        },

        async complete(referralTransaction) {
            try {
                const { value } = await this.$swal({
                    icon: 'warning',
                    title: 'Are you sure you want to complete this Withdraw Referral Transaction?',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                });

                if (value) {
                    this.loading = true;
                    await ReferralTransactionsApi.complete(referralTransaction.id);

                    this.$swal({
                        icon: 'success',
                        title: 'Completed',
                        text: 'Payment successfully completed',
                    });
                    this.loadItems();
                }
            } finally {
                this.loading = false;
            }
        },
    },
    watch: {
        search: 'loadItems',
        status: 'loadItems',
        created_at: 'loadItems',
        'pagination.page': {
            immediate: true,
            handler() {
                this.loadItems();
            },
        },
    },
};
</script>
