<template>
    <v-card class="elevation-12">
        <v-toolbar
            color="primary"
            class="mb-6"
            dark
            flat
        >
            <v-toolbar-title>Recipient Details</v-toolbar-title>
        </v-toolbar>

        <v-overlay
            :value="loading"
            absolute>
            <v-progress-circular
                :size="70"
                :width="7"
                indeterminate>
            </v-progress-circular>
        </v-overlay>

        <v-card-text v-if="step === 0">
            <v-form
                class="text-center"
                validate-on-blur
                ref="form"
                data-vv-scope="personal-details"
                @keyup.enter="submitPersonalDetails"
                @submit.prevent="submitPersonalDetails"
            >
        <v-row>
            <v-col cols="6">
                <v-text-field
                    label="First Name"
                    name="firstName"
                    v-model="firstName"
                    v-validate="'required'"
                    :error-messages="errors.first('personal-details.firstName')"
                    outlined
                    color="primary"
                />
            </v-col>
            <v-col cols="6">
                <v-text-field
                    label="Last Name"
                    name="lastName"
                    v-model="lastName"
                    v-validate="'required'"
                    :error-messages="errors.first('personal-details.lastName')"
                    outlined
                    color="primary"
                />
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <v-text-field
                    label="Country"
                    name="country"
                    :value="countryName"
                    readonly
                    outlined
                    color="primary"
                />
            </v-col>
            <v-col cols="6">
                <v-text-field
                    label="City"
                    name="city"
                    v-model="city"
                    v-validate="'required'"
                    :error-messages="errors.first('personal-details.city')"
                    outlined
                    color="primary"
                />
            </v-col>
            <v-col cols="6">
                <v-text-field
                    label="Street Number"
                    name="streetNumber"
                    v-model="streetNumber"
                    v-validate="'required'"
                    :error-messages="errors.first('personal-details.streetNumber')"
                    outlined
                    color="primary"
                />
            </v-col>
            <v-col cols="6">
                <v-text-field
                    label="Street Name"
                    name="streetName"
                    v-model="streetName"
                    v-validate="'required'"
                    :error-messages="errors.first('personal-details.streetName')"
                    outlined
                    color="primary"
                />
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-text-field
                    label="Phone Number"
                    name="phone"
                    v-model="phone"
                    v-validate="'required'"
                    :error-messages="errors.first('personal-details.phone')"
                    outlined
                    color="primary"
                />
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-text-field
                    label="Email"
                    name="email"
                    v-model="email"
                    v-validate="'required|email'"
                    :error-messages="errors.first('personal-details.email')"
                    outlined
                    color="primary"
                />
            </v-col>
        </v-row>
        </v-form>
    </v-card-text>

    <v-card-text v-if="step === 1">
        <v-form
                class="text-center"
                validate-on-blur
                ref="form"
                data-vv-scope="bank-details"
                @keyup.enter="submit"
                @submit.prevent="submit"
            >
        <v-row>
            <v-col cols="12">
                <v-select
                    label="Bank"
                    name="bank"
                    v-model="bankId"
                    v-validate="'required'"
                    :items="banks"
                    item-text="name"
                    item-value="bank_id"
                    :error-messages="errors.first('bank-details.bank')"
                    outlined
                    color="primary"
                />
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-text-field
                    label="Account Number"
                    name="accountNumber"
                    v-model="accountNumber"
                    v-validate="'required'"
                    :error-messages="errors.first('bank-details.accountNumber')"
                    outlined
                    color="primary"
                />
            </v-col>
        </v-row>

        </v-form>
        </v-card-text>

        <v-card-actions
            v-if="step === 0"
            class="justify-end pb-4"
        >
            <v-btn
                class="text--white text-capitalize"
                color="primary"
                dark
                elevation="2"
                depressed
                type="button"
                @click="submitPersonalDetails"
            >
                Next
            </v-btn>
        </v-card-actions>
        <v-card-actions
            v-else
            class="justify-space-between pb-4"
        >
            <v-btn
                class="text-capitalize"
                color="primary"
                text
                type="button"
                @click="step = 0"
            >
                Back
            </v-btn>
            <v-btn
                class="text--white text-capitalize"
                color="primary"
                dark
                elevation="2"
                depressed
                type="button"
                @click="submit"
            >
                Submit
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import countries from '../modules/countries.json';
import UnifiedApiService from '../api/UnifiedApiService';

export default {
    props: {
        countryCode: {
            type: String,
            required: true,
        },
        currencyCode: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        step: 0,
        loading: false,
        banks: [],
        bankId: null,
        firstName: null,
        lastName: null,
        streetNumber: null,
        streetName: null,
        city: null,
        phone: null,
        email: null,
        accountNumber: null,
    }),
    computed: {
        countryName() {
            if (!this.countryCode) return null;

            const country = countries.find(({ iso2 }) => iso2 === this.countryCode);
            return country.name || this.countryCode;
        },
    },
    mounted() {
        this.getBanks();
    },
    watch: {
        country: {
            handler: () => {
                this.getBanks();
                this.bankId = null;
            },
        },
    },
    methods: {
        async getBanks() {
            try {
                this.loading = true;

                const { data } = await UnifiedApiService.getBanks({
                    countryCode: this.countryCode,
                    currencyCode: this.currencyCode,
                });

                this.banks = data;
            } catch (err) {
                this.$swal({
                    type: 'error',
                    title: 'Error loading banks',
                    text: err,
                });
            } finally {
                this.loading = false;
            }
        },
        async submitPersonalDetails() {
            const formIsValid = await this.$validator.validateAll('personal-details');
            if (formIsValid) {
                this.step = 1;
            }
        },
        async submit() {
            const formIsValid = await this.$validator.validateAll('bank-details');
            if (formIsValid) {
                this.$emit('submit', {
                    bankId: this.bankId,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    streetNumber: this.streetNumber,
                    streetName: this.streetName,
                    city: this.city,
                    phone: this.phone,
                    email: this.email,
                    accountNumber: this.accountNumber,
                });
            }
        },
    },
};
</script>
