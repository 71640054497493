<template>
    <div class="tw-w-4/5 d-flex flex-column justify-center align-center mx-auto my-8">

        <div v-if="!item" class="tw-w-full d-flex justify-space-between align-center tw-px-24">
            Loading...
        </div>

        <div v-if="item" class="tw-w-full d-flex justify-space-between align-center tw-px-24 mt-3">
            <div class="group-17 tw-max-w-sm tw-flex">
                <v-tooltip bottom content-class="tooltip" transition="fade-transition">
                    <template v-slot:activator="{ on }">
                        <v-avatar class="tw-flex-shrink-0" v-on="on">
                            <img :src="matcher.avatar || '/img/man.svg'" :alt="matcher.name" />
                        </v-avatar>
                    </template>
                    <person-info :person="matcher"></person-info>
                </v-tooltip>
                <div class="tw-ml-3 tw-pt-1">
                    <h4 class="tw-text-primary default-font font-14">
                        <router-link :to="{
                            name: 'User',
                            params: {
                                id: matcher.id,
                            },
                        }">
                            {{ matcher.name }}
                        </router-link>
                    </h4>
                    <p class="default-font font-12 tw-text-black d-flex align-center">
                        <gb-flag class="mr-1" :code="matcher.country_abbreviation" size="small" />{{ matcher.country }}
                    </p>
                </div>
            </div>
            <div class="tw-relative d-flex align-center justify-center">
                <bottom-arrow></bottom-arrow>
                <v-tooltip bottom content-class="tooltip" transition="fade-transition">
                    <template v-slot:activator="{ on }">
                        <div class="tw-absolute tw-inset-x-auto tw-cursor-pointer" v-on="on">
                            <span class="amount default-font tw-text-primary mr-1">{{ item.matcher_amount | price }}</span>
                            <span class="currency default-font tw-text-primary">{{ item.matcher_currency }}</span>
                        </div>
                    </template>
                    <amount :transaction="{
                        to_currency: item.matcher_currency,
                        from_currency: item.poster_currency,
                        received: item.matcher_amount,
                        subtotal: item.poster_amount ,
                        rate: item.exchange_rate,
                        total: item.poster_amount
                    }" />
                </v-tooltip>
            </div>
            <div class="group-17 tw-max-w-sm tw-flex tw-flex-row-reverse">
                <v-tooltip bottom content-class="tooltip" transition="fade-transition">
                    <template v-slot:activator="{ on }">
                        <v-avatar class="tw-flex-shrink-0" v-on="on">
                            <img src="/img/man.svg" :alt="recipient.name" />
                        </v-avatar>
                    </template>
                    <person-info :person="recipient"></person-info>
                </v-tooltip>
                <div class="tw-mr-3 tw-pt-1">
                    <h4 class="tw-text-primary default-font font-14">{{ recipient.name }}</h4>
                    <p class="default-font font-12 tw-text-black d-flex align-center">
                        <gb-flag class="mr-1" :code="item.poster_country" size="small" />{{ recipient.country }}
                    </p>
                </div>
            </div>
        </div>

        <div v-if="item" class="tw-w-full d-flex justify-space-between align-center tw-px-24">
            <bank-status :bank="item.userBank" />
            <div class="tw-relative d-flex align-center justify-center"></div>
            <p>
                <span class="default-font font-16 tw-text-black">
                    {{ recipientBank.name, }}
                </span>
            </p>
        </div>

    </div>
</template>
<script>
/* eslint-disable camelcase */
import ThirdPartyTransactionsApi from '@/api/ThirdPartyTransactionsApi';
import BottomArrow from '../transactions/svg/BottomArrow.vue';
import PersonInfo from '../transactions/tooltips/PersonInfo.vue';
import Amount from '../transactions/tooltips/Amount.vue';
import BankStatus from '../transactions/BankStatus.vue';
import countries from '../../modules/countries.json';

export default {
    components: {
        BottomArrow,
        PersonInfo,
        Amount,
        BankStatus,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            item: null,
        };
    },
    watch: {
        id: 'loadItem',
    },
    mounted() {
        this.loadItem();
    },
    computed: {
        poster() {
            return this.getUserObject(this.item.user);
        },
        postersBeneficiary() {
            if (!this.item.postersBeneficiary) return {};
            return this.getUserObject(this.item.postersBeneficiary);
        },
        matcher() {
            return this.getUserObject(this.item.user);
        },
        recipient() {
            if (!this.item) return {};

            const {
                country: countryAbbreviation,
                recipientInfo,
                institution_name: institutionName,
            } = this.item.recipient;

            const {
                name,
                email,
                phone,
            } = recipientInfo;

            const country = countries.find(({ iso2 }) => iso2 === countryAbbreviation);

            return {
                name,
                phone,
                email,
                country_abbreviation: countryAbbreviation,
                country: country.name,
                institution_name: institutionName,
            };
        },
        recipientBank() {
            if (!this.item) return {};

            const {
                institution_name: institutionName,
            } = this.item.recipient;

            return {
                name: institutionName,
            };
        },
        posterBankLogo() {
            const {
                posterBank,
                poster_country: country,
            } = this.item;

            if (!posterBank) {
                return posterBank;
            }

            const bankCode = posterBank.account.bank_code;

            if (!bankCode) {
                return null;
            }

            return `/img/banks/${country.toLowerCase()}/${bankCode}.png`;
        },
    },
    methods: {
        loadItem() {
            this.item = null;
            ThirdPartyTransactionsApi.get(this.id).then((response) => {
                this.item = response.data;
            }).catch(() => { });
        },
        getUserObject(user) {
            const {
                id,
                avatar,
                firstname,
                lastname,
                phone,
                email,
                dob,
                address,
                country,
                country_abbreviation: countryAbbreviation,
                city,
                state,
                zipcode,
                payment_methods,
                delivery_methods,
            } = user;

            return {
                id,
                avatar,
                name: `${firstname} ${lastname}`,
                phone,
                email,
                birth_date: dob,
                address,
                country,
                country_abbreviation: countryAbbreviation,
                city,
                state,
                zipcode,
                payment_methods,
                delivery_methods,
            };
        },
    },
};
</script>
<style scoped>
.groupe-17 {
    width: 156.1px;
    height: 53.82px;
    left: 307.58px;
    top: 329.03px;
}

.default-font {
    line-height: 136.5% !important;
    font-weight: 600;
}

.amount {
    /* or 27px */
    text-align: right;
    font-size: 20px;
}

.tooltip {
    background: #2E5677;
    border-radius: 11px;
    max-width: 380px;
    min-width: 300px;
}
</style>
