<template>
    <v-dialog
        v-model="innerValue"
        max-width="400px"
    >
        <v-card
            class="py-2"
            flat
        >
            <v-card-title class="d-flex justify-space-between">
                {{ title }}
                <v-btn
                    icon
                    @click="innerValue = false">
                    <v-icon color="#15ABAB">
                        mdi-close
                    </v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>
                <UserBankDetails
                    :user="user"
                    :type="type">
                </UserBankDetails>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { startCase } from 'lodash';
import UserBankDetails from './UserBankDetails.vue';

export default {
    components: {
        UserBankDetails,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
            default: 'payment',
        },
    },
    data: () => ({
        innerValue: null,
        methodFields: [
            {
                label: 'Bank',
                property: 'bank',
            },
            {
                label: 'Sort Code',
                property: 'sort_code',
            },
            {
                label: 'Bank Account',
                property: 'account_number',
            },
            {
                label: 'IBAN',
                property: 'iban',
            },
            {
                label: 'BIC',
                property: 'bic',
            },
            {
                label: 'Email',
                property: 'email',
            },
        ],
    }),
    computed: {
        title() {
            return `${startCase(this.type)} Method Details`;
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(val) {
                this.innerValue = val;
            },
        },
        innerValue(val) {
            this.$emit('input', val);
        },
    },
};
</script>
