<template>
    <v-select
        :items="options"
        outlined
        dense
        return-object
        class="custom-select"
        :value="currencyFilter"
        @change="updateCurrencyFilter"
        :menu-props="{ bottom: true, offsetY: true, 'content-class': 'mt-3 tw-rounded-lg' }"
        :disabled="disabled"
        placeholder="Any currencies"
        multiple
        clearable
    >
        <template v-slot:item="{ item, attrs }">
            <div class="d-flex align-center tw-w-full py-3" v-if="item.from">
                <v-checkbox
                    :value="attrs.inputValue"
                    class="mt-0 mr-3"
                    hide-details>
                </v-checkbox>
                <span class="default-font filter-title tw-text-black mr-2">{{ item.from }}</span>
                <v-icon color="secondary">$arrow-right</v-icon>
                <span class="default-font filter-title tw-text-black ml-2">{{ item.to }}</span>
            </div>
            <template v-else>
                <span class="default-font filter-title tw-text-black d-flex justify-center tw-w-full">
                    {{ item.text }}
                </span>
            </template>
        </template>

        <template v-slot:selection="{ item }">
            <div class="d-flex justify-center tw-w-full" v-if="item.from">
                <span class="default-font filter-title tw-text-black mr-2">{{ item.from }}</span>
                <v-icon color="secondary">$arrow-right</v-icon>
                <span class="default-font filter-title tw-text-black ml-2">{{ item.to }}</span>
            </div>
            <template v-else> <span class="default-font filter-title tw-text-black"> {{ item.text }} </span></template>
        </template>
    </v-select>
</template>

<script>
export default {
    props: {
        disabled: {
            type: Boolean,
            default: true,
        },
        storeKey: {
            type: String,
            default: 'transaction',
        },
    },
    data: () => ({
        items: [
            {
                from: 'NGN',
                to: 'USD',
                value: 'NGN-USD',
            },
            // {
            //     from: 'USD',
            //     to: 'NGN',
            //     value: 'USD-NGN',
            // },
            // {
            //     from: 'EUR',
            //     to: 'NGN',
            //     value: 'EUR-NGN',
            // },
            {
                from: 'NGN',
                to: 'EUR',
                value: 'NGN-EUR',
            },
            // {
            //     from: 'CAD',
            //     to: 'NGN',
            //     value: 'CAD-NGN',
            // },
            {
                from: 'NGN',
                to: 'CAD',
                value: 'NGN-CAD',
            },
            // {
            //     from: 'GBP',
            //     to: 'NGN',
            //     value: 'GBP-NGN',
            // },
            {
                from: 'NGN',
                to: 'GBP',
                value: 'NGN-GBP',
            },
            {
                from: 'GHS',
                to: 'USD',
                value: 'GHS-USD',
            },
            {
                from: 'GHS',
                to: 'CAD',
                value: 'GHS-CAD',
            },
            {
                from: 'GHS',
                to: 'GBP',
                value: 'GHS-GBP',
            },
            {
                from: 'GHS',
                to: 'EUR',
                value: 'GHS-EUR',
            },
        ],
    }),
    methods: {
        updateCurrencyFilter(payload) {
            return this.$store.dispatch(`${this.storeKey}/updateCurrencyFilter`, payload);
        },
    },
    computed: {
        currencyFilter() {
            return this.$store.state[this.storeKey].currencyFilter;
        },
        options() {
            return this.items.map((o) => ({
                ...o,
                disabled: this.disabled,
            }));
        },
    },
};
</script>

<style>
.custom-select .v-select__selections {
    padding: 6px 0 !important;
}
.custom-select .v-input__append-inner {
    margin-top: 9px !important;
}
.custom-select.v-text-field--outlined fieldset{
      @apply tw-w-full tw-bg-white tw-text-black tw-border tw-border-solid tw-border-binput tw-box-border;
}
.custom-select.v-text-field--outlined.v-input--is-focused fieldset {
    @apply tw-border tw-border-solid tw-border-primary tw-box-border;
}
</style>
