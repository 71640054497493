<template>
    <v-chip
        v-bind="$attrs"
        :color="color">
        {{ text }}
    </v-chip>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            required: false,
        },
    },
    computed: {
        color() {
            return this.value
                ? 'success'
                : null;
        },
        text() {
            return this.value
                ? 'Identity Verified'
                : 'Identity Not Verified';
        },
    },
};
</script>
